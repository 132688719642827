define('vmpower/models/vm-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        provider: _emberData.default.attr('string'),
        instanceType: _emberData.default.attr('string'),
        cpus: _emberData.default.attr('number'),
        baselineIops: _emberData.default.attr('number'),
        maxIops: _emberData.default.attr('number'),
        memoryGiB: _emberData.default.attr('number'),
        pricePerHour: _emberData.default.attr('number'),
        monthlyCost: function () {
            return (this.get('pricePerHour') * 720).toFixed(2);
        }.property('pricePerHour')
    });
});