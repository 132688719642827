define("vmpower/templates/components/ax-unused-disk-overview", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 28
                },
                "end": {
                  "line": 16,
                  "column": 28
                }
              },
              "moduleName": "vmpower/templates/components/ax-unused-disk-overview.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "task-cat purple");
              var el2 = dom.createElement("strong");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "nbsp", [["subexpr", "concat", ["unused since ", ["subexpr", "moment-from-now", [["get", "volume.unusedSince", ["loc", [null, [15, 118], [15, 136]]]]], [], ["loc", [null, [15, 101], [15, 137]]]]], [], ["loc", [null, [15, 77], [15, 138]]]]], [], ["loc", [null, [15, 70], [15, 140]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 20
              },
              "end": {
                "line": 19,
                "column": 20
              }
            },
            "moduleName": "vmpower/templates/components/ax-unused-disk-overview.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "collection-item dismissable volume-collection-item");
            dom.setAttribute(el1, "style", "overflow-y: hidden; touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "class", "cloud-icon");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "truncate");
            dom.setAttribute(el2, "for", "task1");
            dom.setAttribute(el2, "style", "text-decoration: none; vm-name");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "secondary-content ultra-small");
            var el4 = dom.createTextNode("Est Cost ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("$");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("/mo");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "task-cat blue");
            var el3 = dom.createElement("strong");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" GiB");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "task-cat red");
            var el3 = dom.createElement("strong");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" Disk");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "task-cat green");
            var el3 = dom.createElement("strong");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" Storage");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "task-cat action-button-color");
            var el3 = dom.createElement("strong");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" I/Ops");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element0, [3]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element1, 'src');
            morphs[1] = dom.createMorphAt(element2, 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [1, 1]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [5, 0]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element0, [7, 0]), 0, 0);
            morphs[5] = dom.createMorphAt(dom.childAt(element0, [9, 0]), 0, 0);
            morphs[6] = dom.createMorphAt(dom.childAt(element0, [11, 0]), 0, 0);
            morphs[7] = dom.createMorphAt(element0, 13, 13);
            morphs[8] = dom.createMorphAt(element0, 15, 15);
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["subexpr", "cdn-path", [["subexpr", "concat", ["img/", ["get", "volume.provider", ["loc", [null, [7, 83], [7, 98]]]], "_logo_small.png"], [], ["loc", [null, [7, 68], [7, 117]]]]], [], ["loc", [null, [7, 57], [7, 119]]]]]]], ["content", "volume.volumeId", ["loc", [null, [8, 101], [8, 120]]]], ["content", "volume.monthlyCost", ["loc", [null, [8, 182], [8, 204]]]], ["content", "volume.sizeGiB", ["loc", [null, [10, 64], [10, 82]]]], ["content", "volume.diskType", ["loc", [null, [11, 63], [11, 82]]]], ["content", "volume.storageType", ["loc", [null, [12, 65], [12, 87]]]], ["content", "volume.iops", ["loc", [null, [13, 79], [13, 94]]]], ["block", "if", [["get", "volume.unused", ["loc", [null, [14, 34], [14, 47]]]]], [], 0, null, ["loc", [null, [14, 28], [16, 35]]]], ["inline", "md-check", [], ["name", "Unused Alerts", "checked", ["subexpr", "@mut", [["get", "volume.unusedVolumeDetection", ["loc", [null, [17, 68], [17, 96]]]]], [], []]], ["loc", [null, [17, 28], [17, 98]]]]],
          locals: ["volume"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 16
            },
            "end": {
              "line": 29,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-unused-disk-overview.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "volume-overview-preload");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col s12 center");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "collection-item action-button-color");
          dom.setAttribute(el3, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "progress");
          var el5 = dom.createTextNode("\n                                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "indeterminate");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [2]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createAttrMorph(element3, 'class');
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "ember-collection", [], ["scrolled", ["subexpr", "action", ["scrolled"], [], ["loc", [null, [5, 49], [5, 68]]]], "cell-layout", ["subexpr", "percentage-columns-layout", [["get", "volumes.length", ["loc", [null, [5, 108], [5, 122]]]], ["get", "columns", ["loc", [null, [5, 123], [5, 130]]]], 150], [], ["loc", [null, [5, 81], [5, 135]]]], "items", ["subexpr", "@mut", [["get", "volumes", ["loc", [null, [5, 142], [5, 149]]]]], [], []]], 0, null, ["loc", [null, [5, 20], [19, 41]]]], ["attribute", "class", ["concat", ["row preload-header action-button-color ", ["subexpr", "if", [["get", "loadingMore", ["loc", [null, [20, 105], [20, 116]]]], "loading", "loaded"], [], ["loc", [null, [20, 100], [20, 137]]]]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 16
            },
            "end": {
              "line": 38,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-unused-disk-overview.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col s12 center");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          var el3 = dom.createTextNode("\n                            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "rainy-icon");
          var el4 = dom.createTextNode("\n                                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "material-icons large white-text");
          var el5 = dom.createTextNode("check_circle");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h5");
          dom.setAttribute(el2, "class", "valign");
          var el3 = dom.createElement("strong");
          var el4 = dom.createTextNode("Excellent!:");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" No Unused Disks found. Check back here for updates.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 88
            },
            "end": {
              "line": 41,
              "column": 235
            }
          },
          "moduleName": "vmpower/templates/components/ax-unused-disk-overview.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "material-icons left");
          var el2 = dom.createTextNode("storage");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 50,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-unused-disk-overview.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "blog-card");
        var el2 = dom.createTextNode("\n        ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "id", "task-card");
        dom.setAttribute(el2, "class", "collection");
        var el3 = dom.createTextNode("\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "vm-card");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "class", "card-action-buttons");
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "class", "tooltipped");
        dom.setAttribute(el4, "data-tooltip", "Go to Volumes");
        dom.setAttribute(el4, "data-position", "top");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n            ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3, "class", "collection-header pull-up-20");
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "task-card-title");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "material-icons medium left");
        var el6 = dom.createTextNode("storage");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Unused Volumes");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n                ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "task-card-date");
        var el5 = dom.createTextNode("VM Disks may remain in your account even after a VM is deleted.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 1]), 0, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "volumes.length", ["loc", [null, [4, 22], [4, 36]]]]], [], 0, 1, ["loc", [null, [4, 16], [38, 23]]]], ["block", "link-to", ["dashboard.unused-volumes"], ["class", "btn-floating waves-effect waves-light action-button-color"], 2, null, ["loc", [null, [41, 88], [41, 247]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});