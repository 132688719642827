define('vmpower/routes/signout', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        setupController: function setupController() {
            if (this.get('session.data.authenticated.user.id')) {
                this.get('session').invalidate();
                appInsights.trackEvent('signout', { email: this.get('session.data.authenticated.user.email') });
                window.Intercom('shutdown');
                this.store.unloadAll();
            }
        }
    });
});