define("vmpower/templates/components/ax-snapshot-list", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 80
            },
            "end": {
              "line": 3,
              "column": 137
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("cloud subscriptions");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 16
              },
              "end": {
                "line": 14,
                "column": 177
              }
            },
            "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "material-icons left");
            var el2 = dom.createTextNode("storage");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Search by Volume");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 12
            },
            "end": {
              "line": 15,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["dashboard.backups.volumes"], ["class", "waves-effect waves-light btn action-button-color right"], 0, null, ["loc", [null, [14, 16], [14, 189]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 12
            },
            "end": {
              "line": 17,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "waves-effect waves-light btn action-button-color right disabled");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "material-icons left");
          var el3 = dom.createTextNode("storage");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Search by Volume");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 20
              },
              "end": {
                "line": 21,
                "column": 174
              }
            },
            "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "material-icons left");
            var el2 = dom.createTextNode("computer");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Search by VM");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 16
            },
            "end": {
              "line": 22,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["dashboard.backups.vms"], ["class", "waves-effect waves-light btn action-button-color right"], 0, null, ["loc", [null, [21, 20], [21, 186]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 16
            },
            "end": {
              "line": 24,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "waves-effect waves-light btn red action-button-color right disabled");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "material-icons left");
          var el3 = dom.createTextNode("computer");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Search by VM");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 16
            },
            "end": {
              "line": 31,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "waves-effect waves-light btn action-button-color right start-backup-btn");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "material-icons left");
          var el3 = dom.createTextNode("cloud_done");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Start Backup");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element25 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element25);
          return morphs;
        },
        statements: [["element", "action", ["showBackupModal"], [], ["loc", [null, [30, 23], [30, 51]]]]],
        locals: [],
        templates: []
      };
    }();
    var child6 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 16
            },
            "end": {
              "line": 33,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "waves-effect disabled waves-light btn action-button-color right");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "material-icons left");
          var el3 = dom.createTextNode("cloud_done");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Start Backup");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child7 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 24
                },
                "end": {
                  "line": 49,
                  "column": 24
                }
              },
              "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "material-icons left activator");
              var el2 = dom.createTextNode("arrow_back");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 68,
                    "column": 48
                  },
                  "end": {
                    "line": 70,
                    "column": 48
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "truncate");
                var el2 = dom.createElement("strong");
                var el3 = dom.createTextNode("Status:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "green-text");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                return morphs;
              },
              statements: [["content", "snapshot.state", ["loc", [null, [69, 125], [69, 143]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 70,
                    "column": 48
                  },
                  "end": {
                    "line": 72,
                    "column": 48
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "truncate");
                var el2 = dom.createElement("strong");
                var el3 = dom.createTextNode("Status:");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
                return morphs;
              },
              statements: [["content", "snapshot.state", ["loc", [null, [71, 100], [71, 118]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 28
                },
                "end": {
                  "line": 90,
                  "column": 28
                }
              },
              "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "collection-item");
              dom.setAttribute(el1, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);");
              var el2 = dom.createTextNode("\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "row");
              var el3 = dom.createTextNode("\n                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "col s6");
              var el4 = dom.createTextNode("\n                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "r1ow");
              var el5 = dom.createTextNode("\n                                                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Captured:");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode(" ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("br");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "tooltipped");
              dom.setAttribute(el5, "data-tooltip", "VMPower will delete this snapshot after this date");
              dom.setAttribute(el5, "data-position", "top");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Expires on:");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode(" ");
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "truncate");
              var el6 = dom.createElement("strong");
              var el7 = dom.createTextNode("Snapshot ID:");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                                            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "row");
              var el5 = dom.createTextNode("\n                                                \n                                            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "col s6");
              var el4 = dom.createTextNode("\n                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "row");
              var el5 = dom.createTextNode("\n                                                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("strong");
              var el6 = dom.createTextNode("Actions:");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "row");
              var el5 = dom.createTextNode("\n                                                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "tooltipped");
              dom.setAttribute(el5, "data-position", "top");
              dom.setAttribute(el5, "data-tooltip", "Change Expiration");
              var el6 = dom.createElement("a");
              dom.setAttribute(el6, "class", "btn-floating action-button-color");
              var el7 = dom.createElement("i");
              dom.setAttribute(el7, "class", "material-icons left activator");
              var el8 = dom.createTextNode("access_time");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "tooltipped");
              dom.setAttribute(el5, "data-position", "top");
              dom.setAttribute(el5, "data-tooltip", "Create New Disk From Snapshot");
              var el6 = dom.createElement("a");
              dom.setAttribute(el6, "class", "btn-floating action-button-color");
              var el7 = dom.createElement("i");
              dom.setAttribute(el7, "class", "material-icons left activator");
              var el8 = dom.createTextNode("create_new_folder");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              dom.setAttribute(el5, "class", "tooltipped");
              dom.setAttribute(el5, "data-position", "top");
              dom.setAttribute(el5, "data-tooltip", "Delete Snapshot");
              var el6 = dom.createElement("a");
              dom.setAttribute(el6, "class", "btn-floating red");
              var el7 = dom.createElement("i");
              dom.setAttribute(el7, "class", "material-icons left activator");
              var el8 = dom.createTextNode("delete");
              dom.appendChild(el7, el8);
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [1, 1]);
              var element16 = dom.childAt(element15, [1, 1]);
              var element17 = dom.childAt(element15, [3, 3]);
              var element18 = dom.childAt(element17, [1]);
              var element19 = dom.childAt(element17, [3]);
              var element20 = dom.childAt(element17, [5]);
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]), 2, 2);
              morphs[1] = dom.createMorphAt(dom.childAt(element16, [5]), 2, 2);
              morphs[2] = dom.createMorphAt(dom.childAt(element16, [7]), 1, 1);
              morphs[3] = dom.createMorphAt(element16, 9, 9);
              morphs[4] = dom.createElementMorph(element18);
              morphs[5] = dom.createElementMorph(element19);
              morphs[6] = dom.createElementMorph(element20);
              return morphs;
            },
            statements: [["inline", "moment-format", [["get", "snapshot.timestamp", ["loc", [null, [64, 97], [64, 115]]]]], [], ["loc", [null, [64, 81], [64, 117]]]], ["inline", "moment-format", [["get", "snapshot.expiry", ["loc", [null, [66, 203], [66, 218]]]]], [], ["loc", [null, [66, 187], [66, 220]]]], ["content", "snapshot.snapshotId", ["loc", [null, [67, 100], [67, 123]]]], ["block", "if", [["subexpr", "is-equal", [["get", "snapshot.state", ["loc", [null, [68, 64], [68, 78]]]], "Complete"], [], ["loc", [null, [68, 54], [68, 90]]]]], [], 0, 1, ["loc", [null, [68, 48], [72, 55]]]], ["element", "action", ["changeSnapshotExpiry", ["get", "snapshot", ["loc", [null, [83, 86], [83, 94]]]]], [], ["loc", [null, [83, 54], [83, 96]]]], ["element", "action", ["restoreSnapshot", ["get", "snapshot", ["loc", [null, [84, 81], [84, 89]]]]], [], ["loc", [null, [84, 54], [84, 91]]]], ["element", "action", ["deleteSnapshot", ["get", "snapshot", ["loc", [null, [85, 80], [85, 88]]]]], [], ["loc", [null, [85, 54], [85, 90]]]]],
            locals: ["snapshot"],
            templates: [child0, child1]
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 91,
                  "column": 28
                },
                "end": {
                  "line": 107,
                  "column": 28
                }
              },
              "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "collection-item");
              dom.setAttribute(el1, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); ");
              var el2 = dom.createTextNode("\n                                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col s12 center");
              var el3 = dom.createTextNode("\n                                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "preloader-wrapper big active");
              var el4 = dom.createTextNode("\n                                            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "spinner-layer spinner-blue-only");
              var el5 = dom.createTextNode("\n                                            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5, "class", "circle-clipper left");
              var el6 = dom.createTextNode("\n                                                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("div");
              dom.setAttribute(el6, "class", "circle");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                                            ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5, "class", "gap-patch");
              var el6 = dom.createTextNode("\n                                                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("div");
              dom.setAttribute(el6, "class", "circle");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                                            ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("div");
              dom.setAttribute(el5, "class", "circle-clipper right");
              var el6 = dom.createTextNode("\n                                                ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("div");
              dom.setAttribute(el6, "class", "circle");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                                            ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child3 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 107,
                    "column": 28
                  },
                  "end": {
                    "line": 114,
                    "column": 28
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "collection-item");
                dom.setAttribute(el1, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); ");
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col s12 center");
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("i");
                dom.setAttribute(el3, "class", "material-icons medium");
                dom.setAttribute(el3, "style", "float:unset");
                var el4 = dom.createTextNode("cloud_off");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("h5");
                dom.setAttribute(el3, "class", "black-text");
                dom.setAttribute(el3, "style", "");
                var el4 = dom.createTextNode("No Backups found");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                            ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 107,
                  "column": 28
                },
                "end": {
                  "line": 114,
                  "column": 28
                }
              },
              "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "is-equal", [["get", "selectedVm.snapshots.length", ["loc", [null, [107, 48], [107, 75]]]], 0], [], ["loc", [null, [107, 38], [107, 78]]]]], [], 0, null, ["loc", [null, [107, 28], [114, 28]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 12
              },
              "end": {
                "line": 118,
                "column": 12
              }
            },
            "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "active");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "tooltipped");
            dom.setAttribute(el2, "style", "position:relative; left:10px; top:15px");
            dom.setAttribute(el2, "data-tooltip", "Back");
            dom.setAttribute(el2, "data-position", "top");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "collapsible-header action-button-color white-text");
            dom.setAttribute(el2, "style", "line-height:4rem");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4, "class", "cloud-icon");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "collapsible-body orange lighten-5");
            dom.setAttribute(el2, "style", "display: block;");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            dom.setAttribute(el3, "class", "collection");
            dom.setAttribute(el3, "style", "position:relative; height:440px");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element21 = dom.childAt(fragment, [1]);
            var element22 = dom.childAt(element21, [3]);
            var element23 = dom.childAt(element22, [1, 1]);
            var element24 = dom.childAt(element21, [5, 1]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(element21, [1]), 1, 1);
            morphs[1] = dom.createAttrMorph(element23, 'src');
            morphs[2] = dom.createMorphAt(element22, 3, 3);
            morphs[3] = dom.createMorphAt(element24, 1, 1);
            morphs[4] = dom.createMorphAt(element24, 2, 2);
            return morphs;
          },
          statements: [["block", "link-to", ["dashboard.backups.vms"], ["class", "btn-floating waves-effect waves-light action-button-color back-btn"], 0, null, ["loc", [null, [47, 24], [49, 36]]]], ["attribute", "src", ["concat", [["subexpr", "cdn-path", [["subexpr", "concat", ["img/", ["get", "selectedVm.provider", ["loc", [null, [53, 79], [53, 98]]]], "_logo_small.png"], [], ["loc", [null, [53, 64], [53, 117]]]]], [], ["loc", [null, [53, 53], [53, 119]]]]]]], ["content", "selectedVm.displayName", ["loc", [null, [55, 24], [55, 50]]]], ["block", "ember-collection", [], ["scrolled", ["subexpr", "action", ["scrolledSnapshot"], [], ["loc", [null, [59, 57], [59, 84]]]], "cell-layout", ["subexpr", "percentage-columns-layout", [["get", "selectedVm.snapshots.length", ["loc", [null, [59, 124], [59, 151]]]], ["get", "vmListColums", ["loc", [null, [59, 152], [59, 164]]]], 115], [], ["loc", [null, [59, 97], [59, 169]]]], "items", ["subexpr", "@mut", [["get", "selectedVm.snapshots", ["loc", [null, [59, 176], [59, 196]]]]], [], []]], 1, null, ["loc", [null, [59, 28], [90, 49]]]], ["block", "if", [["get", "selectedVm.snapshotsLoading", ["loc", [null, [91, 34], [91, 61]]]]], [], 2, 3, ["loc", [null, [91, 28], [114, 35]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 125,
                      "column": 32
                    },
                    "end": {
                      "line": 132,
                      "column": 32
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "collapsible-header action-button-color white-text");
                  dom.setAttribute(el1, "style", "line-height:4rem");
                  var el2 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("\n                                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("img");
                  dom.setAttribute(el3, "class", "cloud-icon");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element12 = dom.childAt(fragment, [1]);
                  var element13 = dom.childAt(element12, [1, 1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element13, 'src');
                  morphs[1] = dom.createMorphAt(element12, 3, 3);
                  return morphs;
                },
                statements: [["attribute", "src", ["concat", [["subexpr", "cdn-path", [["subexpr", "concat", ["img/", ["get", "vm.provider", ["loc", [null, [128, 99], [128, 110]]]], "_logo_small.png"], [], ["loc", [null, [128, 84], [128, 129]]]]], [], ["loc", [null, [128, 73], [128, 131]]]]]]], ["content", "vm.displayName", ["loc", [null, [130, 40], [130, 58]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 120,
                    "column": 20
                  },
                  "end": {
                    "line": 134,
                    "column": 20
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "active");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "tooltipped");
                dom.setAttribute(el2, "style", "position:relative; left:10px; top:15px");
                dom.setAttribute(el2, "data-tooltip", "Select VM");
                dom.setAttribute(el2, "data-position", "top");
                var el3 = dom.createTextNode("\n                                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element14 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element14, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(element14, 3, 3);
                return morphs;
              },
              statements: [["inline", "md-check", [], ["bubbles", false, "class", "left vm-check", "style", "margin-left:5px", "checked", ["subexpr", "@mut", [["get", "vm.selected", ["loc", [null, [123, 115], [123, 126]]]]], [], []]], ["loc", [null, [123, 36], [123, 128]]]], ["block", "link-to", ["dashboard.backups.backup", ["get", "vm", ["loc", [null, [125, 70], [125, 72]]]]], [], 0, null, ["loc", [null, [125, 32], [132, 44]]]]],
              locals: ["vm"],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 119,
                  "column": 16
                },
                "end": {
                  "line": 135,
                  "column": 16
                }
              },
              "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "ember-collection", [], ["scrolled", ["subexpr", "action", ["scrolled"], [], ["loc", [null, [120, 49], [120, 68]]]], "cell-layout", ["subexpr", "percentage-columns-layout", [["get", "vms.length", ["loc", [null, [120, 108], [120, 118]]]], ["get", "vmListColums", ["loc", [null, [120, 119], [120, 131]]]], 61], [], ["loc", [null, [120, 81], [120, 135]]]], "items", ["subexpr", "@mut", [["get", "vms", ["loc", [null, [120, 142], [120, 145]]]]], [], []]], 0, null, ["loc", [null, [120, 20], [134, 41]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 143,
                      "column": 105
                    },
                    "end": {
                      "line": 143,
                      "column": 161
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("cloud subscription");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 135,
                    "column": 16
                  },
                  "end": {
                    "line": 146,
                    "column": 16
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col s12 center");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "row");
                var el4 = dom.createTextNode("\n                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "rainy-icon");
                var el5 = dom.createTextNode("\n                                    ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("canvas");
                dom.setAttribute(el5, "id", "vms-placeholder");
                dom.setAttribute(el5, "width", "120");
                dom.setAttribute(el5, "height", "120");
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                            ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("h5");
                dom.setAttribute(el3, "class", "valign");
                var el4 = dom.createElement("i");
                dom.setAttribute(el4, "class", "mdi-action-info");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("No VMs found. Try adding a ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" now.");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]), 2, 2);
                return morphs;
              },
              statements: [["block", "link-to", ["dashboard.subscriptions"], [], 0, null, ["loc", [null, [143, 105], [143, 173]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 135,
                  "column": 16
                },
                "end": {
                  "line": 146,
                  "column": 16
                }
              },
              "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "not", [["get", "loadingVms", ["loc", [null, [135, 31], [135, 41]]]]], [], ["loc", [null, [135, 26], [135, 42]]]]], [], 0, null, ["loc", [null, [135, 16], [146, 16]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 118,
                "column": 12
              },
              "end": {
                "line": 147,
                "column": 12
              }
            },
            "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "vms.length", ["loc", [null, [119, 22], [119, 32]]]]], [], 0, 1, ["loc", [null, [119, 16], [146, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 8
            },
            "end": {
              "line": 148,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "selectedVm", ["loc", [null, [44, 18], [44, 28]]]]], [], 0, 1, ["loc", [null, [44, 12], [147, 19]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child8 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 152,
                    "column": 24
                  },
                  "end": {
                    "line": 154,
                    "column": 24
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "material-icons left activator");
                var el2 = dom.createTextNode("arrow_back");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 173,
                      "column": 48
                    },
                    "end": {
                      "line": 175,
                      "column": 48
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "truncate");
                  var el2 = dom.createElement("strong");
                  var el3 = dom.createTextNode("Status:");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2, "class", "green-text");
                  var el3 = dom.createTextNode(" ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                  return morphs;
                },
                statements: [["content", "snapshot.state", ["loc", [null, [174, 125], [174, 143]]]]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 175,
                      "column": 48
                    },
                    "end": {
                      "line": 177,
                      "column": 48
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "truncate");
                  var el2 = dom.createElement("strong");
                  var el3 = dom.createTextNode("Status:");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
                  return morphs;
                },
                statements: [["content", "snapshot.state", ["loc", [null, [176, 100], [176, 118]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 164,
                    "column": 28
                  },
                  "end": {
                    "line": 195,
                    "column": 28
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "collection-item");
                dom.setAttribute(el1, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); ");
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "row");
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "col s6");
                var el4 = dom.createTextNode("\n                                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "r1ow");
                var el5 = dom.createTextNode("\n                                                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("span");
                var el6 = dom.createElement("strong");
                var el7 = dom.createTextNode("Captured:");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode(" ");
                dom.appendChild(el5, el6);
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("br");
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("span");
                dom.setAttribute(el5, "class", "tooltipped");
                dom.setAttribute(el5, "data-tooltip", "VMPower will delete this snapshot after this date");
                dom.setAttribute(el5, "data-position", "top");
                var el6 = dom.createElement("strong");
                var el7 = dom.createTextNode("Expires on:");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode(" ");
                dom.appendChild(el5, el6);
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("span");
                dom.setAttribute(el5, "class", "truncate");
                var el6 = dom.createElement("strong");
                var el7 = dom.createTextNode("Snapshot ID:");
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n");
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("                                            ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "row");
                var el5 = dom.createTextNode("\n                                                \n                                            ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                        ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "col s6");
                var el4 = dom.createTextNode("\n                                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "row");
                var el5 = dom.createTextNode("\n                                                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("strong");
                var el6 = dom.createTextNode("Actions:");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                            ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "row");
                var el5 = dom.createTextNode("\n                                                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("span");
                dom.setAttribute(el5, "class", "tooltipped");
                dom.setAttribute(el5, "data-position", "top");
                dom.setAttribute(el5, "data-tooltip", "Change Expiration");
                var el6 = dom.createElement("a");
                dom.setAttribute(el6, "class", "btn-floating action-button-color");
                var el7 = dom.createElement("i");
                dom.setAttribute(el7, "class", "material-icons left activator");
                var el8 = dom.createTextNode("access_time");
                dom.appendChild(el7, el8);
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("span");
                dom.setAttribute(el5, "class", "tooltipped");
                dom.setAttribute(el5, "data-position", "top");
                dom.setAttribute(el5, "data-tooltip", "Create New Disk From Snapshot");
                var el6 = dom.createElement("a");
                dom.setAttribute(el6, "class", "btn-floating action-button-color");
                var el7 = dom.createElement("i");
                dom.setAttribute(el7, "class", "material-icons left activator");
                var el8 = dom.createTextNode("create_new_folder");
                dom.appendChild(el7, el8);
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("span");
                dom.setAttribute(el5, "class", "tooltipped");
                dom.setAttribute(el5, "data-position", "top");
                dom.setAttribute(el5, "data-tooltip", "Delete Snapshot");
                var el6 = dom.createElement("a");
                dom.setAttribute(el6, "class", "btn-floating red");
                var el7 = dom.createElement("i");
                dom.setAttribute(el7, "class", "material-icons left activator");
                var el8 = dom.createTextNode("delete");
                dom.appendChild(el7, el8);
                dom.appendChild(el6, el7);
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                            ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                        ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1, 1]);
                var element3 = dom.childAt(element2, [1, 1]);
                var element4 = dom.childAt(element2, [3, 3]);
                var element5 = dom.childAt(element4, [1]);
                var element6 = dom.childAt(element4, [3]);
                var element7 = dom.childAt(element4, [5]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 2, 2);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [5]), 2, 2);
                morphs[2] = dom.createMorphAt(dom.childAt(element3, [7]), 1, 1);
                morphs[3] = dom.createMorphAt(element3, 9, 9);
                morphs[4] = dom.createElementMorph(element5);
                morphs[5] = dom.createElementMorph(element6);
                morphs[6] = dom.createElementMorph(element7);
                return morphs;
              },
              statements: [["inline", "moment-format", [["get", "snapshot.timestamp", ["loc", [null, [169, 97], [169, 115]]]]], [], ["loc", [null, [169, 81], [169, 117]]]], ["inline", "moment-format", [["get", "snapshot.expiry", ["loc", [null, [171, 203], [171, 218]]]]], [], ["loc", [null, [171, 187], [171, 220]]]], ["content", "snapshot.snapshotId", ["loc", [null, [172, 100], [172, 123]]]], ["block", "if", [["subexpr", "is-equal", [["get", "snapshot.state", ["loc", [null, [173, 64], [173, 78]]]], "Complete"], [], ["loc", [null, [173, 54], [173, 90]]]]], [], 0, 1, ["loc", [null, [173, 48], [177, 55]]]], ["element", "action", ["changeSnapshotExpiry", ["get", "snapshot", ["loc", [null, [188, 86], [188, 94]]]]], [], ["loc", [null, [188, 54], [188, 96]]]], ["element", "action", ["restoreSnapshot", ["get", "snapshot", ["loc", [null, [189, 81], [189, 89]]]]], [], ["loc", [null, [189, 54], [189, 91]]]], ["element", "action", ["deleteSnapshot", ["get", "snapshot", ["loc", [null, [190, 80], [190, 88]]]]], [], ["loc", [null, [190, 54], [190, 90]]]]],
              locals: ["snapshot"],
              templates: [child0, child1]
            };
          }();
          var child2 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 196,
                    "column": 28
                  },
                  "end": {
                    "line": 212,
                    "column": 28
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "collection-item");
                dom.setAttribute(el1, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); ");
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col s12 center");
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "preloader-wrapper big active");
                var el4 = dom.createTextNode("\n                                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "spinner-layer spinner-blue-only");
                var el5 = dom.createTextNode("\n                                            ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", "circle-clipper left");
                var el6 = dom.createTextNode("\n                                                ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("div");
                dom.setAttribute(el6, "class", "circle");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                                            ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", "gap-patch");
                var el6 = dom.createTextNode("\n                                                ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("div");
                dom.setAttribute(el6, "class", "circle");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                                            ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", "circle-clipper right");
                var el6 = dom.createTextNode("\n                                                ");
                dom.appendChild(el5, el6);
                var el6 = dom.createElement("div");
                dom.setAttribute(el6, "class", "circle");
                dom.appendChild(el5, el6);
                var el6 = dom.createTextNode("\n                                            ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                                            ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                        ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          var child3 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 212,
                      "column": 28
                    },
                    "end": {
                      "line": 219,
                      "column": 28
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  dom.setAttribute(el1, "class", "collection-item");
                  dom.setAttribute(el1, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); ");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col s12 center");
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("i");
                  dom.setAttribute(el3, "class", "material-icons medium");
                  dom.setAttribute(el3, "style", "float:unset");
                  var el4 = dom.createTextNode("cloud_off");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("h5");
                  dom.setAttribute(el3, "class", "black-text");
                  dom.setAttribute(el3, "style", "");
                  var el4 = dom.createTextNode("No Backups found");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                            ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 212,
                    "column": 28
                  },
                  "end": {
                    "line": 219,
                    "column": 28
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "is-equal", [["get", "selectedVolume.snapshots.length", ["loc", [null, [212, 48], [212, 79]]]], 0], [], ["loc", [null, [212, 38], [212, 82]]]]], [], 0, null, ["loc", [null, [212, 28], [219, 28]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 149,
                  "column": 12
                },
                "end": {
                  "line": 223,
                  "column": 12
                }
              },
              "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "active");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "tooltipped");
              dom.setAttribute(el2, "style", "position:relative; left:10px; top:15px");
              dom.setAttribute(el2, "data-tooltip", "Back");
              dom.setAttribute(el2, "data-position", "top");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "collapsible-header action-button-color white-text");
              dom.setAttribute(el2, "style", "line-height:4rem");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("img");
              dom.setAttribute(el4, "class", "cloud-icon");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "collapsible-body orange lighten-5");
              dom.setAttribute(el2, "style", "display: block;");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3, "class", "collection");
              dom.setAttribute(el3, "style", "position:relative; height:450px");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [3]);
              var element10 = dom.childAt(element9, [1, 1]);
              var element11 = dom.childAt(element8, [5, 1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
              morphs[1] = dom.createAttrMorph(element10, 'src');
              morphs[2] = dom.createMorphAt(element9, 3, 3);
              morphs[3] = dom.createMorphAt(element11, 1, 1);
              morphs[4] = dom.createMorphAt(element11, 2, 2);
              return morphs;
            },
            statements: [["block", "link-to", ["dashboard.backups.volumes"], ["class", "btn-floating waves-effect waves-light action-button-color back-btn"], 0, null, ["loc", [null, [152, 24], [154, 36]]]], ["attribute", "src", ["concat", [["subexpr", "cdn-path", [["subexpr", "concat", ["img/", ["get", "selectedVolume.provider", ["loc", [null, [158, 79], [158, 102]]]], "_logo_small.png"], [], ["loc", [null, [158, 64], [158, 121]]]]], [], ["loc", [null, [158, 53], [158, 123]]]]]]], ["content", "selectedVolume.volumeId", ["loc", [null, [160, 24], [160, 51]]]], ["block", "ember-collection", [], ["scrolled", ["subexpr", "action", ["scrolledSnapshotVolume"], [], ["loc", [null, [164, 57], [164, 90]]]], "cell-layout", ["subexpr", "percentage-columns-layout", [["get", "selectedVolume.snapshots.length", ["loc", [null, [164, 130], [164, 161]]]], ["get", "vmListColums", ["loc", [null, [164, 162], [164, 174]]]], 115], [], ["loc", [null, [164, 103], [164, 179]]]], "items", ["subexpr", "@mut", [["get", "selectedVolume.snapshots", ["loc", [null, [164, 186], [164, 210]]]]], [], []]], 1, null, ["loc", [null, [164, 28], [195, 49]]]], ["block", "if", [["get", "selectedVolume.snapshotsLoading", ["loc", [null, [196, 34], [196, 65]]]]], [], 2, 3, ["loc", [null, [196, 28], [219, 35]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 227,
                        "column": 32
                      },
                      "end": {
                        "line": 232,
                        "column": 32
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "collapsible-header action-button-color white-text");
                    var el2 = dom.createTextNode("\n                                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2, "class", "cloud-icon");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var element1 = dom.childAt(element0, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element1, 'src');
                    morphs[1] = dom.createMorphAt(element0, 3, 3);
                    return morphs;
                  },
                  statements: [["attribute", "src", ["concat", [["subexpr", "cdn-path", [["subexpr", "concat", ["img/", ["get", "volume.provider", ["loc", [null, [229, 95], [229, 110]]]], "_logo_small.png"], [], ["loc", [null, [229, 80], [229, 129]]]]], [], ["loc", [null, [229, 69], [229, 131]]]]]]], ["content", "volume.volumeId", ["loc", [null, [230, 40], [230, 59]]]]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 225,
                      "column": 20
                    },
                    "end": {
                      "line": 234,
                      "column": 20
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  dom.setAttribute(el1, "class", "active");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "link-to", ["dashboard.backups.volume", ["get", "volume", ["loc", [null, [227, 70], [227, 76]]]]], [], 0, null, ["loc", [null, [227, 32], [232, 44]]]]],
                locals: ["volume"],
                templates: [child0]
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 224,
                    "column": 16
                  },
                  "end": {
                    "line": 235,
                    "column": 16
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "ember-collection", [], ["scrolled", ["subexpr", "action", ["scrolledVolumes"], [], ["loc", [null, [225, 49], [225, 75]]]], "cell-layout", ["subexpr", "percentage-columns-layout", [["get", "volumes.length", ["loc", [null, [225, 115], [225, 129]]]], ["get", "vmListColums", ["loc", [null, [225, 130], [225, 142]]]], 46], [], ["loc", [null, [225, 88], [225, 146]]]], "items", ["subexpr", "@mut", [["get", "volumes", ["loc", [null, [225, 153], [225, 160]]]]], [], []]], 0, null, ["loc", [null, [225, 20], [234, 41]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 243,
                        "column": 109
                      },
                      "end": {
                        "line": 243,
                        "column": 165
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("cloud subscription");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 235,
                      "column": 16
                    },
                    "end": {
                      "line": 246,
                      "column": 16
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col s12 center");
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "row");
                  var el4 = dom.createTextNode("\n                                ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "rainy-icon");
                  var el5 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("canvas");
                  dom.setAttribute(el5, "id", "vms-placeholder");
                  dom.setAttribute(el5, "width", "120");
                  dom.setAttribute(el5, "height", "120");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                                ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                            ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("h5");
                  dom.setAttribute(el3, "class", "valign");
                  var el4 = dom.createElement("i");
                  dom.setAttribute(el4, "class", "mdi-action-info");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("No Volumes found. Try adding a ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode(" now.");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]), 2, 2);
                  return morphs;
                },
                statements: [["block", "link-to", ["dashboard.subscriptions"], [], 0, null, ["loc", [null, [243, 109], [243, 177]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 235,
                    "column": 16
                  },
                  "end": {
                    "line": 246,
                    "column": 16
                  }
                },
                "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "not", [["get", "loadingVms", ["loc", [null, [235, 31], [235, 41]]]]], [], ["loc", [null, [235, 26], [235, 42]]]]], [], 0, null, ["loc", [null, [235, 16], [246, 16]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 223,
                  "column": 12
                },
                "end": {
                  "line": 247,
                  "column": 12
                }
              },
              "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "volumes.length", ["loc", [null, [224, 22], [224, 36]]]]], [], 0, 1, ["loc", [null, [224, 16], [246, 23]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 148,
                "column": 8
              },
              "end": {
                "line": 248,
                "column": 8
              }
            },
            "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "selectedVolume", ["loc", [null, [149, 18], [149, 32]]]]], [], 0, 1, ["loc", [null, [149, 12], [247, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 148,
              "column": 8
            },
            "end": {
              "line": 248,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "searchByVolumes", ["loc", [null, [148, 18], [148, 33]]]]], [], 0, null, ["loc", [null, [148, 8], [248, 8]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child9 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 249,
              "column": 8
            },
            "end": {
              "line": 251,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ax-backup-modal", [], ["selectedVms", ["subexpr", "@mut", [["get", "selectedVms", ["loc", [null, [250, 42], [250, 53]]]]], [], []], "closeBackupModal", ["subexpr", "action", ["closeBackupModal"], [], ["loc", [null, [250, 71], [250, 98]]]]], ["loc", [null, [250, 12], [250, 100]]]]],
        locals: [],
        templates: []
      };
    }();
    var child10 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 252,
              "column": 8
            },
            "end": {
              "line": 254,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ax-backup-expiry-modal", [], ["snapshot", ["subexpr", "@mut", [["get", "activeSnapshot", ["loc", [null, [253, 46], [253, 60]]]]], [], []], "closeBackupModal", ["subexpr", "action", ["closeExpiryModal"], [], ["loc", [null, [253, 78], [253, 105]]]]], ["loc", [null, [253, 12], [253, 107]]]]],
        locals: [],
        templates: []
      };
    }();
    var child11 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 255,
              "column": 8
            },
            "end": {
              "line": 257,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ax-restore-modal", [], ["snapshot", ["subexpr", "@mut", [["get", "activeSnapshot", ["loc", [null, [256, 40], [256, 54]]]]], [], []], "closeBackupModal", ["subexpr", "action", ["closeRestoreModal"], [], ["loc", [null, [256, 72], [256, 100]]]]], ["loc", [null, [256, 12], [256, 102]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 259,
            "column": 6
          }
        },
        "moduleName": "vmpower/templates/components/ax-snapshot-list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        dom.setAttribute(el1, "style", "margin-top:-50px");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        dom.setAttribute(el2, "class", "center header");
        var el3 = dom.createTextNode("Snapshot management");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h6");
        dom.setAttribute(el2, "class", "center header");
        var el3 = dom.createTextNode("Search for and create VM Snapshots in any of your ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(". Click on the Virtual Machine or volume to see their associated backups");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row center");
        dom.setAttribute(el1, "style", "margin-top:-15px");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col s12 l6 m6");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col s12 l6 m6");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        dom.setAttribute(el1, "style", "margin-top:-25px");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "indeterminate");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "class", "collapsible vm-list backup-list");
        dom.setAttribute(el2, "data-collapsible", "expandable");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element26 = dom.childAt(fragment, [2]);
        var element27 = dom.childAt(element26, [1]);
        var element28 = dom.childAt(element26, [3]);
        var element29 = dom.childAt(element28, [1]);
        var element30 = dom.childAt(fragment, [4]);
        var element31 = dom.childAt(element30, [1]);
        var element32 = dom.childAt(element30, [3]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 3]), 1, 1);
        morphs[1] = dom.createMorphAt(element27, 1, 1);
        morphs[2] = dom.createMorphAt(element27, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element29, [1]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element29, [3]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element28, [3, 1]), 1, 1);
        morphs[6] = dom.createAttrMorph(element31, 'class');
        morphs[7] = dom.createMorphAt(element32, 1, 1);
        morphs[8] = dom.createMorphAt(element32, 2, 2);
        morphs[9] = dom.createMorphAt(element32, 3, 3);
        morphs[10] = dom.createMorphAt(element32, 4, 4);
        return morphs;
      },
      statements: [["block", "link-to", ["dashboard.subscriptions"], [], 0, null, ["loc", [null, [3, 80], [3, 149]]]], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "searchTerm", ["loc", [null, [7, 34], [7, 44]]]]], [], []], "placeholder", "Search for a VM, try 'on', 'off', 'aws' or the instance name/id"], ["loc", [null, [7, 8], [7, 124]]]], ["inline", "md-check", [], ["bubbles", false, "class", "left vm-check", "style", "margin-left:5px", "checked", ["subexpr", "@mut", [["get", "selectAll", ["loc", [null, [8, 87], [8, 96]]]]], [], []], "name", "Select All"], ["loc", [null, [8, 8], [8, 116]]]], ["block", "if", [["get", "searchByVMs", ["loc", [null, [13, 18], [13, 29]]]]], [], 1, 2, ["loc", [null, [13, 12], [17, 19]]]], ["block", "if", [["get", "searchByVolumes", ["loc", [null, [20, 22], [20, 37]]]]], [], 3, 4, ["loc", [null, [20, 16], [24, 23]]]], ["block", "if", [["get", "selectedVms", ["loc", [null, [29, 22], [29, 33]]]]], [], 5, 6, ["loc", [null, [29, 16], [33, 23]]]], ["attribute", "class", ["concat", ["progress loader ", ["subexpr", "if", [["get", "loadingVms", ["loc", [null, [39, 37], [39, 47]]]], "loading"], [], ["loc", [null, [39, 32], [39, 59]]]]]]], ["block", "if", [["get", "searchByVMs", ["loc", [null, [43, 14], [43, 25]]]]], [], 7, 8, ["loc", [null, [43, 8], [248, 15]]]], ["block", "if", [["get", "showBackupModal", ["loc", [null, [249, 14], [249, 29]]]]], [], 9, null, ["loc", [null, [249, 8], [251, 15]]]], ["block", "if", [["get", "showExpiryModal", ["loc", [null, [252, 14], [252, 29]]]]], [], 10, null, ["loc", [null, [252, 8], [254, 15]]]], ["block", "if", [["get", "showRestoreModal", ["loc", [null, [255, 14], [255, 30]]]]], [], 11, null, ["loc", [null, [255, 8], [257, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11]
    };
  }());
});