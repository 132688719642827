define('vmpower/helpers/ember-notification-not', ['exports', 'ember-notif-hub/helpers/ember-notification-not'], function (exports, _emberNotificationNot) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    Object.defineProperty(exports, 'default', {
        enumerable: true,
        get: function () {
            return _emberNotificationNot.default;
        }
    });
    Object.defineProperty(exports, 'emberNotificationNot', {
        enumerable: true,
        get: function () {
            return _emberNotificationNot.emberNotificationNot;
        }
    });
});