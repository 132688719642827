define('vmpower/routes/appsource', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        clientId: function () {
            return _environment.default.AzureAcitveDirectoryV2ClientId;
        }.property(),
        redirectUri: function () {
            return encodeURIComponent(_environment.default.AzureActiveDirectoryLoginRedirectUri);
        }.property(),
        nonce: function () {
            return lil.uuid();
        }.property(),
        setupController: function setupController() {
            var target = 'https://login.microsoftonline.com/common/OAuth2/v2.0/Authorize?client_id=' + this.get('clientId') + '&response_type=id_token+code&response_mode=form_post&scope=openid+profile+email+https%3A%2F%2Fgraph.microsoft.com%2Fuser.read&state=' + this.get('state') + '&redirect_uri=' + this.get('redirectUri') + '&nonce=' + this.get('nonce');
            window.location.replace(target);
        }
    });
});