define('vmpower/helpers/divide-by', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.divideBy = divideBy;
    function divideBy(params /*, hash*/) {

        if (params.length < 2) {
            return null;
        }
        if (typeof params[0] !== 'number' || typeof params[1] !== 'number') {
            return null;
        }

        return params[0] / params[1];
    }

    exports.default = Ember.Helper.helper(divideBy);
});