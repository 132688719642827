define('vmpower/models/vm-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    vms: _emberData.default.hasMany('virtualMachine'),
    autoOffEnabled: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    autoOffWarningHours: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    autoOffHours: _emberData.default.attr('number', {
      defaultValue: 3
    }),
    cpuAverage: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    cpuMax: _emberData.default.attr('number', {
      defaultValue: 10
    }),
    diskReadAverage: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    diskReadMax: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    diskWriteAverage: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    diskWriteMax: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    networkInMax: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    networkInAverage: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    networkOutAverage: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    networkOutMax: _emberData.default.attr('number', {
      defaultValue: 5
    }),
    lastLoginHours: _emberData.default.attr('number', {
      defaultValue: 12
    }),
    ignoreHoursEnabled: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    ignoreHoursStart: _emberData.default.attr('number', {
      defaultValue: 9
    }),
    ignoreHoursEnd: _emberData.default.attr('number', {
      defaultValue: 17
    }),
    autoOffTimezone: _emberData.default.attr('string', {
      defaultValue: moment.tz.guess()
    }),
    includeCpuAverage: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    includeCpuMax: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    includeDiskReadAverage: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    includeDiskReadMax: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    includeDiskWriteAverage: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    includeDiskWriteMax: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    includeNetworkInAverage: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    includeNetworkInMax: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    includeNetworkOutAverage: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    includeNetworkOutMax: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    includeLastUserLogin: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    monthlyCost: function () {
      var totalMonthlyCost = 0;
      this.get('vms').forEach(function (vm) {
        if (!vm.get('pricePerHour')) {
          return;
        }
        totalMonthlyCost += vm.get('pricePerHour') * 24 * 30;
      });
      return parseFloat(totalMonthlyCost.toFixed(2));
    }.property('vms.@each.pricePerHour'),
    vmObserver: Ember.observer('vms', function () {
      var _this = this;

      var cloudDict = {};
      var result = [];
      return this.get('vms').then(function (vms) {
        vms.forEach(function (vm) {
          if (!cloudDict[vm.get('provider')]) {
            return cloudDict[vm.get('provider')] = 1;
          }
          cloudDict[vm.get('provider')] = cloudDict[vm.get('provider')] + 1;
        });
        Object.keys(cloudDict).forEach(function (key) {
          result.pushObject({
            name: key,
            count: cloudDict[key]
          });
        });
        _this.set('providers', result);
      });
    })
  });
});