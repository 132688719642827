define('vmpower/components/ax-forgot-password-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        init: function init() {
            var _this = this;

            this._super();
            Ember.TextSupport.reopen({
                attributeBindings: ['data-parsley-required', 'data-parsley-minlength', 'data-parsley-type', 'data-parsley-equalto']
            });
            Ember.run.scheduleOnce('afterRender', function () {
                // Form Validation
                Ember.$('#forgot-password-form').parsley().on('form:submit', function () {
                    try {
                        getOwner(_this).lookup('route:forgot-password').send('sendResetLink', _this.get('email'));
                    } catch (e) {
                        Ember.debug(e);
                    } finally {
                        return false;
                    }
                });
            });
        }
    });
});