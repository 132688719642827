define('vmpower/components/ax-select', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        content: null,

        selectedValue: Ember.computed.alias('value'),
        displayValue: '',
        targetProperty: '',
        init: function init() {
            var _this = this;

            this._super();
            var content = this.get('content');

            if (!content) {
                this.set('content', []);
            }
            Ember.run.scheduleOnce('afterRender', function () {
                if (_this.get('selectedValue')) {
                    _this._setSelectedValue();
                }
                if (_this.get('doNotInitFirstValue')) {
                    return;
                }
                _this.set('selectedValue', _this.get('content.firstObject'));
                _this.send('change');
            });
        },

        _setSelectedValue: function _setSelectedValue() {
            var _this2 = this;

            var valuePath = this.get('valuePath');
            var selectedObject;

            this.get('content').forEach(function (obj) {
                if (obj.get) {
                    if (obj.get(valuePath) === _this2.get('selectedValue')) {
                        selectedObject = obj;
                    }
                } else {
                    if (obj[valuePath] === _this2.get('selectedValue')) {
                        selectedObject = obj;
                    }
                }
            });

            if (!selectedObject) {
                return;
            }

            var targetEl = this.$('.' + this.get('name') + ' option[value="' + selectedObject[valuePath] + '"]');

            if (targetEl) {
                targetEl.prop('selected', 'selected');
            }
        },
        selectedValueObserver: Ember.observer('selectedValue', function () {
            this._setSelectedValue();
        }),
        actions: {
            change: function change() {
                var selectedEl = this.$('select')[0];
                var selectedIndex = selectedEl.selectedIndex;
                var valuePath = this.get('valuePath');
                var content = this.get('content');
                var selectedValue;
                if (content[selectedIndex] !== undefined) {
                    if (content[selectedIndex].get) {
                        selectedValue = content[selectedIndex].get(valuePath);
                    } else {
                        selectedValue = content[selectedIndex][valuePath];
                    }
                }
                this.set('selectedValue', content[selectedIndex]);
                this.set('value', content[selectedIndex]);
                this.set('targetValue', selectedValue);
                // send action to target
                if (this.get('target') && this.get('action')) {
                    this.get('target').send(this.get('action'), selectedValue);
                }
            }
        }
    });
});