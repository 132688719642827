define('vmpower/helpers/has-permission', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        accounts: Ember.inject.service(),
        // This service name is only an example
        compute: function compute(params) {
            var permissions = this.get('accounts.permissions');
            if (!permissions) {
                return false;
            }
            var hasPermission = permissions.reduce(function (accumulator, heldPermission) {
                var permissionRegExp = new RegExp(heldPermission.id);

                if (accumulator === true) {
                    return accumulator;
                } else {
                    return params[0].match(permissionRegExp) !== null;
                }
            }, false);
            return hasPermission;
        }
    });
});