define('vmpower/helpers/nbsp', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.nbsp = nbsp;
    function nbsp(params /*, hash*/) {
        if (!params[0]) {
            return;
        }
        return new Ember.String.htmlSafe(params[0].replace(/ /g, '&nbsp;'));
    }

    exports.default = Ember.Helper.helper(nbsp);
});