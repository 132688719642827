define("vmpower/templates/components/ax-vm-chart-card", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 20,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-vm-chart-card.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row vm-datapoint-preloader");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "preloader-wrapper big active valign");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "spinner-layer spinner-blue-only");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "circle-clipper left");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "circle");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "gap-patch");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "circle");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "circle-clipper right");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "circle");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 8
              },
              "end": {
                "line": 25,
                "column": 8
              }
            },
            "moduleName": "vmpower/templates/components/ax-vm-chart-card.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "ax-line-chart", [], ["chartName", ["subexpr", "@mut", [["get", "chartName", ["loc", [null, [22, 34], [22, 43]]]]], [], []], "fillColor", ["subexpr", "@mut", [["get", "fillColor", ["loc", [null, [22, 54], [22, 63]]]]], [], []], "strokeColor", ["subexpr", "@mut", [["get", "strokeColor", ["loc", [null, [23, 30], [23, 41]]]]], [], []], "pointColor", ["subexpr", "@mut", [["get", "pointColor", ["loc", [null, [23, 53], [23, 63]]]]], [], []], "datapoints", ["subexpr", "@mut", [["get", "datapoints", ["loc", [null, [23, 75], [23, 85]]]]], [], []], "pointName", ["subexpr", "@mut", [["get", "pointName", ["loc", [null, [24, 28], [24, 37]]]]], [], []], "height", ["subexpr", "@mut", [["get", "height", ["loc", [null, [24, 45], [24, 51]]]]], [], []], "width", ["subexpr", "@mut", [["get", "width", ["loc", [null, [24, 58], [24, 63]]]]], [], []], "fillColor", "green", "divideYBy", ["subexpr", "@mut", [["get", "divideYBy", ["loc", [null, [24, 92], [24, 101]]]]], [], []]], ["loc", [null, [22, 8], [24, 103]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 12
                },
                "end": {
                  "line": 32,
                  "column": 12
                }
              },
              "moduleName": "vmpower/templates/components/ax-vm-chart-card.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h6");
              dom.setAttribute(el2, "class", "valign white-text");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "mdi-action-info");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("Enable idle tracking to show VM metrics.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element4, 'class');
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["valign-wrapper placeholder-vm-", ["get", "pointType", ["loc", [null, [29, 56], [29, 65]]]], "-history center"]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 12
                  },
                  "end": {
                    "line": 36,
                    "column": 12
                  }
                },
                "moduleName": "vmpower/templates/components/ax-vm-chart-card.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("h6");
                dom.setAttribute(el2, "class", "valign white-text");
                var el3 = dom.createElement("i");
                dom.setAttribute(el3, "class", "mdi-action-info");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("No ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" data available. Check ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" to see if diagnostics is enabled and that the VM is running.");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var element3 = dom.childAt(element2, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element2, 'class');
                morphs[1] = dom.createMorphAt(element3, 2, 2);
                morphs[2] = dom.createMorphAt(element3, 4, 4);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["valign-wrapper placeholder-vm-", ["get", "pointType", ["loc", [null, [33, 56], [33, 65]]]], "-history center"]]], ["content", "chartName", ["loc", [null, [34, 78], [34, 91]]]], ["content", "vm.provider", ["loc", [null, [34, 114], [34, 129]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 36,
                    "column": 12
                  },
                  "end": {
                    "line": 40,
                    "column": 12
                  }
                },
                "moduleName": "vmpower/templates/components/ax-vm-chart-card.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("h6");
                dom.setAttribute(el2, "class", "valign white-text");
                var el3 = dom.createElement("i");
                dom.setAttribute(el3, "class", "mdi-action-info");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("No ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" data available. Check ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" to see if diagnostics is enabled and that the VM is running.");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                morphs[1] = dom.createMorphAt(element1, 2, 2);
                morphs[2] = dom.createMorphAt(element1, 4, 4);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["valign-wrapper placeholder-vm-", ["get", "pointType", ["loc", [null, [37, 56], [37, 65]]]], "-history center"]]], ["content", "chartName", ["loc", [null, [38, 78], [38, 91]]]], ["content", "vm.provider", ["loc", [null, [38, 114], [38, 129]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 12
                },
                "end": {
                  "line": 40,
                  "column": 12
                }
              },
              "moduleName": "vmpower/templates/components/ax-vm-chart-card.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "vm.cloudNativeDiagnosticsLink", ["loc", [null, [32, 22], [32, 51]]]]], [], 0, 1, ["loc", [null, [32, 12], [40, 12]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 8
              },
              "end": {
                "line": 43,
                "column": 8
              }
            },
            "moduleName": "vmpower/templates/components/ax-vm-chart-card.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col s12 center");
            dom.setAttribute(el2, "style", "height: 210px");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "not", [["get", "vm.idleVmDetection", ["loc", [null, [28, 23], [28, 41]]]]], [], ["loc", [null, [28, 18], [28, 42]]]]], [], 0, 1, ["loc", [null, [28, 12], [40, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 8
            },
            "end": {
              "line": 44,
              "column": 6
            }
          },
          "moduleName": "vmpower/templates/components/ax-vm-chart-card.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["get", "vm.idleVmDetection", ["loc", [null, [21, 19], [21, 37]]]], ["get", "datapoints.length", ["loc", [null, [21, 38], [21, 55]]]]], [], ["loc", [null, [21, 14], [21, 56]]]]], [], 0, 1, ["loc", [null, [21, 8], [43, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 61,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-vm-chart-card.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "blog-card");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "id", "task-card");
        dom.setAttribute(el2, "class", "collection");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "vm-datapoint-card");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3, "class", "collection-header");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h5");
        dom.setAttribute(el5, "class", "task-card-title");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "material-icons small left");
        var el7 = dom.createTextNode("date_range");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "vm-aggregates-container");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h6");
        var el6 = dom.createTextNode("Average: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h6");
        var el6 = dom.createTextNode("Max: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "last-computed-tag");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        var el6 = dom.createTextNode("Last computed: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0, 1]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element6, [5]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element7, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element6, [1, 1]), 1, 1);
        morphs[2] = dom.createMorphAt(element8, 1, 1);
        morphs[3] = dom.createMorphAt(element8, 2, 2);
        morphs[4] = dom.createMorphAt(element9, 1, 1);
        morphs[5] = dom.createMorphAt(element9, 2, 2);
        morphs[6] = dom.createMorphAt(dom.childAt(element6, [7, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "loading", ["loc", [null, [4, 12], [4, 19]]]]], [], 0, 1, ["loc", [null, [4, 6], [44, 13]]]], ["content", "chartName", ["loc", [null, [48, 89], [48, 102]]]], ["inline", "to-fixed", [["get", "average", ["loc", [null, [52, 34], [52, 41]]]], 2], [], ["loc", [null, [52, 23], [52, 45]]]], ["content", "unit", ["loc", [null, [52, 45], [52, 53]]]], ["inline", "to-fixed", [["get", "max", ["loc", [null, [53, 30], [53, 33]]]], 2], [], ["loc", [null, [53, 19], [53, 37]]]], ["content", "unit", ["loc", [null, [53, 37], [53, 45]]]], ["inline", "moment-from-now", [["get", "updated", ["loc", [null, [56, 46], [56, 53]]]]], [], ["loc", [null, [56, 28], [56, 55]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});