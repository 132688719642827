define('vmpower/adapters/ember-notification-local-error', ['exports', 'ember-notif-hub/adapters/ember-notification-local-error'], function (exports, _emberNotificationLocalError) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    Object.defineProperty(exports, 'default', {
        enumerable: true,
        get: function () {
            return _emberNotificationLocalError.default;
        }
    });
});