define("vmpower/models/login-session", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    instanceId: _emberData.default.attr("string"),
    user: _emberData.default.attr("string"),
    loginAt: _emberData.default.attr("date"),
    logoutAt: _emberData.default.attr("date"),
    currentlyLoggedIn: _emberData.default.attr('boolean')
  });
});