define('vmpower/components/ax-one-click-links-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        // init: function () {
        //     this._super();
        // },
        // session: Ember.inject.service('session'),
        // accounts: Ember.inject.service(),
        linkListColumns: [100],
        hasMoreData: true,
        store: Ember.inject.service(),
        actions: {
            // oneClickModal: function (vm) {
            //     this.get('modals').openOneClickModal(vm);
            // },
            scrolled: function scrolled(scrollLeft, scrollTop, lastObject) {
                var _this = this;

                if (lastObject.index + 7 > this.get('links.length') && this.get('hasMoreData') && !this.get('loadingMore')) {
                    console.log('scrolled');
                    this.set('loadingMore', true);
                    this.get('store').query('one-click-link', { limit: 15, next: lastObject.item.id }).then(function (results) {
                        results.forEach(function (link) {
                            if (!_this.get('links').contains(link)) {
                                _this.get('links').pushObject(link);
                            }
                        });
                        if (results.get('length') === 0) {
                            // Ember.$('#vm-overview-preload').hide();
                            _this.set('hasMoreData', false);
                        }
                        _this.set('loadingMore', false);
                    });
                }
            },
            refresh: function refresh() {
                var p = this.get('store').query('one-click-link', { reload: 'true' });
                // .then(() => {
                //     return this.get('store').findAll('virtual-machine');
                // });
                // this.get('emberNotificationCenter').pushNotification({
                //     title: 'Updating Virtual Machines',
                //     description: 'Updating available virtual machines across all cloud subscriptions'
                // }, p);
            }
        },
        model: function model() {
            var user = this.get('session.data.authenticated.user');
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            var id = this.get('session.data.authenticated.user.id');
            return this.store.find('user', id);
        }
    });
});