define('vmpower/controllers/dashboard/historical', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        barChartSets: new Ember.A([]),
        modals: Ember.inject.service(),
        dataObserver: Ember.observer('barchartSets.length', function () {
            Ember.debug('BAR CHART DATA CHANGED');
        }),
        averageIdleProportion: function () {
            var _this = this;

            var average = 0;
            if (!this.get('activeStats.length') || !this.get('idleStats.length')) {
                return 0;
            }
            if (this.get('activeStats.length') !== this.get('idleStats.length')) {
                return 0;
            }
            this.get('idleStats').forEach(function (value, i) {
                average += value.get('totalCost') / (_this.get('activeStats')[i].get('totalCost') + value.get('totalCost'));
            });
            return 100 * (average / this.get('idleStats.length'));
        }.property('idleStats.@each', 'activeStats.@each'),
        averageIdleCountProportion: function () {
            var _this2 = this;

            var average = 0;
            if (!this.get('activeStats.length') || !this.get('idleStats.length')) {
                return 0;
            }
            if (this.get('activeStats.length') !== this.get('idleStats.length')) {
                return 0;
            }
            this.get('idleStats').forEach(function (value, i) {
                average += value.get('count') / (_this2.get('activeStats')[i].get('count') + value.get('count'));
            });
            return 100 * (average / this.get('idleStats.length'));
        }.property('idleStats.@each', 'activeStats.@each'),
        averageResizeProportion: function () {
            var _this3 = this;

            var average = 0;
            if (!this.get('activeStats.length') || !this.get('resizeStats.length')) {
                return 0;
            }
            if (this.get('activeStats.length') !== this.get('resizeStats.length')) {
                return 0;
            }
            this.get('resizeStats').forEach(function (value, i) {
                average += value.get('totalCost') / (_this3.get('activeStats')[i].get('totalCost') + value.get('totalCost'));
            });
            return 100 * (average / this.get('resizeStats.length'));
        }.property('resizeStats.@each', 'activeStats.@each'),
        averageResizeCountProportion: function () {
            var _this4 = this;

            var average = 0;
            if (!this.get('activeStats.length') || !this.get('resizeStats.length')) {
                return 0;
            }
            if (this.get('activeStats.length') !== this.get('resizeStats.length')) {
                return 0;
            }
            this.get('resizeStats').forEach(function (value, i) {
                average += value.get('count') / (_this4.get('activeStats')[i].get('count') + value.get('count'));
            });
            return 100 * (average / this.get('resizeStats.length'));
        }.property('resizeStats.@each', 'activeStats.@each'),
        costLabels: ['Active VM Cost', 'Resize Potential Savings', 'Idle VM Cost'],
        instanceCountLabels: ['Active VM Count', 'VMs to Resize', 'Idle VM Count'],
        idleStats: Ember.computed.alias('barchartSets[0]'),
        resizeStats: Ember.computed.alias('barChartSets[1]'),
        actions: {
            showUpgradeModal: function showUpgradeModal() {
                this.get('modals').upgradeModal();
            }
        }
    });
});