define('vmpower/components/ax-google-signin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Component.extend({
        actions: {
            connect: function connect() {
                getOwner(this).lookup('route:dashboard.subscriptions').addGcpAccount(this.get('gcpProjectId'));
            }
        }
    });
});