define('vmpower/components/ax-signin-form', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        clientId: function () {
            return _environment.default.AzureAcitveDirectoryV2ClientId;
        }.property(),
        redirectUri: function () {
            return encodeURIComponent(_environment.default.AzureActiveDirectoryLoginRedirectUri);
        }.property(),
        nonce: function () {
            return lil.uuid();
        }.property(),
        rememberMe: false,
        _rememberMeChanged: Ember.observer('rememberMe', function () {
            var expirationTime = this.get('rememberMe') ? 14 * 24 * 60 * 60 * 1000 : null;
            this.set('session.store.cookieExpirationTime', new Date().getTime() + expirationTime);
        }),
        init: function init() {
            var _this = this;

            this._super();
            Ember.TextSupport.reopen({
                attributeBindings: ['data-parsley-required', 'data-parsley-minlength', 'data-parsley-type', 'data-parsley-equalto']
            });
            var that = this;
            Ember.run.scheduleOnce('afterRender', function () {
                // Form Validation
                Ember.$('#signin-form').parsley().on('form:submit', function () {
                    try {
                        that.set('pending', true);
                        _this.set('errors', []);
                        getOwner(_this).lookup('route:signin').send('signin', {
                            email: _this.get('email'),
                            password: _this.get('password')
                        });
                    } catch (e) {
                        Ember.debug(e);
                    } finally {
                        that.set('pending', false);
                        return false;
                    }
                });
            });
        },
        actions: {
            signup: function signup() {
                this.set('errors', []);
                Ember.getOwner(this).lookup('route:signin').transitionTo('signup');
            }
        }
    });
});