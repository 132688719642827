define('vmpower/models/notification', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        session: Ember.inject.service(),
        timestamp: _emberData.default.attr('date'),
        text: _emberData.default.attr('string'),
        readBy: _emberData.default.hasMany('user'),
        displaySource: function () {
            if (this.get('source') === 'scheduler') {
                return 'VMPower Automate';
            } else if (this.get('source') === 'analyzer') {
                return 'VMPower Detect';
            } else {
                return 'VMPower Updates';
            }
        }.property('source'),
        unread: function () {
            var _this = this;

            var unread = true;
            this.get('readBy').forEach(function (user) {
                if (user.id === _this.get('session.data.authenticated.user.id')) {
                    unread = false;
                }
            });
            return unread;
        }.property('readBy.length'),
        frontEndLink: function () {
            switch (this.get('notificationType')) {
                case 'unsued-volumes':
                    return 'dashboard.unused-volumes';
                case 'idle-vms':
                    return 'dashboard.idle-vms';
                case 'idle-vms':
                    return '';
                case 'schedule-execution':
                case 'schedule-warning':
                    return 'dashboard.vm-schedules.all';
                case 'auto-off':
                case 'auto-off-warning':
                    return 'dashboard.auto-off';
                case 'resizable-vms':
                    return 'dashboard.vm-righsizing';
                case 'savings-update':
                case 'savings-monthly':
                    return 'dashboard.savings.charts';
                default:
                    return 'dashboard.home';
            }
        }.property('notificationType'),
        source: _emberData.default.attr('string'),
        owner: _emberData.default.attr('string'),
        notificationType: _emberData.default.attr('string')
    });
});