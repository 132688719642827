define('vmpower/components/ax-vm-group-create-modal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		modals: Ember.inject.service(),
		emberNotificationCenter: Ember.inject.service(),
		targetGroup: null,
		init: function init() {
			this._super();
			this.set('targetGroup', this.get('groupListing')[0]);
		},
		groupListing: function () {
			var groupListing = [];
			groupListing.push({
				name: 'Create New Group',
				createPlaceholder: true
			});
			if (this.get('vmGroups')) {
				this.get('vmGroups').forEach(function (group) {
					groupListing.push(group);
				});
			}
			return groupListing;
		}.property('vmGroups'),
		actions: {
			closeModal: function closeModal() {
				this.get('modals').closeModal('#vm-create-modal');
				this.set('modalSuccess', false);
				this.set('newGroupName', '');
			},
			addOrCreate: function addOrCreate(create) {
				var _this = this;

				var account = this.get('account');
				var promise = null;
				if (create) {
					if (!account.vmGroups) {
						account.vmGroups = [];
					}
					var record = account.store.createRecord('vmGroup', {
						name: this.get('newGroupName'),
						vms: []
					});
					var vmPromises = [];
					this.get('selectedVms').forEach(function (vm) {
						record.get('vms').pushObject(vm);
						vmPromises.push(vm.save());
					});
					promise = Ember.RSVP.all(vmPromises).then(function () {
						return record.save();
					}).then(function () {
						account.get('vmGroups').pushObject(record);
						return account.save();
					});
				} else {
					var targetGroup = null;
					this.get('vmGroups').every(function (group) {
						if (group.id === _this.get('targetGroup.id')) {
							_this.get('selectedVms').forEach(function (vm) {
								var found = false;

								group.get('vms').forEach(function (groupVm) {
									if (vm.id === groupVm.id) {
										// skip existing vms in the group
										found = true;
										return;
									}
								});
								if (!found) {
									group.get('vms').pushObject(vm);
									targetGroup = group;
								}
							});
						} else {
							// continue
							return true;
						}
						promise = group.save().then(function (group) {
							group.get('vms').forEach(function (vm) {
								return vm.set('group', group);
							});
						});
						return false;
					});
				}
				this.set('updating');
				promise.finally(function () {
					_this.set('updating', false);
					_this.send('closeModal');
					_this.sendAction('clearSelectedVms');
				});
				if (create) {
					return this.get('emberNotificationCenter').pushNotification({
						title: 'Creating VM Group',
						description: 'Creating VM Group "' + this.get('newGroupName') + '" with ' + this.get('selectedVms.length') + ' VMs'
					}, promise);
				} else {
					return this.get('emberNotificationCenter').pushNotification({
						title: 'Adding VMs to VM Group',
						description: 'Adding ' + this.get('selectedVms.length') + ' to VM Group ' + this.get('targetGroup.name')
					}, promise);
				}
			}
		}
	});
});