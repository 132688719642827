define('vmpower/routes/dashboard', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service(),
		modals: Ember.inject.service(),
		stripe: Ember.inject.service('stripe'),
		accounts: Ember.inject.service(),
		localSettings: Ember.inject.service(),
		emberNotificationCenter: Ember.inject.service(),
		routeObserver: Ember.observer('router.currentPath', function () {
			var user = this.get('session.data.authenticated.user');
			if (this.get('router.currentPath').indexOf('dashboard') <= -1) {
				return;
			}
			if (!this.get('controller')) {
				return;
			}
			if (!user || !user.id) {
				if (this.get('controller.token')) {
					this.transitionTo('dashboard.change-password', {
						queryParams: { authToken: this.get('controller.token') }
					});
					return;
				} else {
					return this.transitionTo('signin');
				}
			}
			var router = this.get('router');
			if (!this.get('controller.user.emailConfirmed') && router.currentPath !== 'dashboard.account-details' && router.currentPath !== 'dashboard.account-details.index' && router.currentPath !== 'dashboard.account-details.account' && router.currentPath !== 'confirm') {
				return this.transitionTo('confirm');
			}
			// remove tooltips from DOM
			Ember.run.scheduleOnce('afterRender', function () {
				Ember.$('.material-tooltip').remove();
				Ember.$('.tooltipped').tooltip({ delay: 0 });
			});
		}),
		// recursive fetch of stats
		_fetchRecords: function _fetchRecords(scroll, array) {
			var _this = this;

			var startMoment = moment();
			// reset current time to 1st of month at midnight
			startMoment.subtract(startMoment.date() - 1, 'days');
			startMoment.subtract(startMoment.hours(), 'hours');
			startMoment.subtract(startMoment.minutes(), 'minutes');
			startMoment.subtract(startMoment.seconds(), 'seconds');
			startMoment.subtract(startMoment.milliseconds(), 'milliseconds');
			return this.store.query('savingsRecord', {
				'start-date': startMoment.toDate().toISOString(),
				scroll: scroll
			}).then(function (records) {
				records.forEach(function (record) {
					array.push(record);
				});
				if (array.length !== records.get('meta.total')) {
					return _this._fetchRecords(records.get('meta.next'), array);
				}
				return array;
			});
		},
		_calculateSavings: function _calculateSavings(savingsRecords) {
			var vmDict = {};
			savingsRecords.forEach(function (record) {
				if (!vmDict[record.get('instanceId')] && record.get('instanceId')) {
					vmDict[record.get('instanceId')] = {
						pricePerHour: record.get('pricePerHour'),
						total: 0
					};
					vmDict[record.get('instanceId')].total += record.get('savings');
				} else if (!vmDict[record.get('volumeId')] && record.get('volumeId')) {
					vmDict[record.get('volumeId')] = {
						pricePerHour: record.get('pricePerHour'),
						total: 0
					};
					vmDict[record.get('volumeId')].total += record.get('savings');
				}
				if (!record.get('savings')) {
					return;
				}
			});
			var totalSavings = 0;
			Object.keys(vmDict).forEach(function (key) {
				if (!vmDict[key].pricePerHour) {
					return;
				}
				if (vmDict[key].total < 0) {
					var monthCost = moment().utc().daysInMonth() * 24 * vmDict[key].pricePerHour;
					totalSavings += vmDict[key].total + monthCost;
				} else {
					totalSavings += vmDict[key].total;
				}
			});
			return totalSavings;
		},
		afterModel: function afterModel(model) {
			var _this2 = this;

			if (!model) {
				return;
			}
			if (!this.get('account')) {
				// there's no account associated with this user
				// so look for team invites
				return this.store.findAll('teamInvite').then(function (invites) {
					if (!invites) {
						return;
					}
					if (invites && invites.get('length') > 0) {
						// user has some team invites -- take them there
						_this2.transitionTo('dashboard.teams.invites');
						return;
					}
					// no account associated wtih user show account modal
					Ember.run.scheduleOnce('afterRender', function () {
						_this2.get('modals').openModal(Ember.$('#account-modal'));
					});
				});
			} else if (this.get('account.cloudSubscriptions.length') <= 0 && model.get('emailConfirmed')) {
				// user has no cloud subscriptions, team invites or teams. take them to the wizard
				this.router.one('didTransition', function () {
					return _this2.transitionTo('dashboard.subscriptions', {
						queryParams: { showWizard: true }
					});
				});
			} else {
				return this.store.findAll('team').then(function (teams) {
					model.set('teams', teams);
					if (teams.get('length') > 0) {
						// user belongs to some teams hopefully with vms
						return;
					}
					return _this2.store.findAll('teamInvite');
				});
			}
		},

		_autoActivateTrial: function _autoActivateTrial() {
			var _this3 = this;

			var atLeastOneDisabled = false;
			if (!this.get('controller.vms')) {
				return;
			}
			this.get('controller.vms').every(function (vm) {
				if (!vm['plan-enabled']) {
					atLeastOneDisabled = true;
					return false;
				}
				return true;
			});
			var user = this.get('user');
			if (!user.get('trialUsed') && atLeastOneDisabled && user.get('cloudSubscriptions.length') > 0) {
				Ember.run.scheduleOnce('afterRender', function () {
					appInsights.trackEvent('routes:dashboard:trial-activation', {
						email: _this3.get('session.data.authenticated.user.email'),
						plan: 2
					});
					_this3.set('user.plan', 2);
					_this3.get('emberNotificationCenter').pushNotification({
						title: 'Activating VMPower Professional trial.',
						description: "Nice! You're getting 14 days of VMPower Professional"
					}, _this3.get('user').save().then(function () {
						_this3.updateIntercom();
						return _this3.get('user').reload();
					}));
					_this3.get('modals').confirmAction('VMPower Professional 14 day Trial Started!', 'No credit card required. You have some VMs which require a paid plan. After 14 days your account will convert to VMPower free.').then(function () {});
				});
			}
		},
		routeNameObserver: Ember.observer('router.currentRouteName', function (target) {
			if (target.get('user') && (!target.get('user.firstName') || !target.get('user.lastName')) && !target.get('controller.token')) {
				// user needs to update personal info
				Ember.run.scheduleOnce('afterRender', function () {
					target.get('modals').openModal(Ember.$('#user-info-modal'));
				});
			}
			target._autoActivateTrial();
		}),
		updateIntercom: function updateIntercom() {
			var action = '';
			var user = this.get('user');
			var account = this.get('accounts').activeAccountSync();
			if (window.Intercom.booted) {
				action = 'update';
			} else {
				action = 'boot';
			}
			if (user && account) {
				window.Intercom(action, {
					app_id: 'nl6tgybo',
					name: user.get('firstName') + ' ' + user.get('lastName'),
					email: user.get('email'),
					createdAt: user.get('createdAt'),
					plan: account.get('plan'),
					plan_expiring: account.get('planExpiring'),
					plan_expire_time: account.get('planExpireTime'),
					trialing: account.get('trialing'),
					trial_used: account.get('trialUsed'),
					user_id: user.id
				});
			}
		},
		didTransition: function didTransition() {
			Ember.debug('transition!');
			return true;
		},
		setupController: function setupController(controller, model) {
			var _this4 = this;

			var user = this.get('session.data.authenticated.user');
			controller.set('account', this.get('account'));
			controller.set('accounts', this.get('loadedAccounts'));
			this.set('router', Ember.getOwner(this).get('router'));
			controller.set('router', this.get('router'));
			controller.set('stripe', this.get('stripe'));
			if (!user || !user.id) {
				console.log('NO USER');
				if (controller.get('token')) {
					return;
				} else {
					return this.transitionTo('signin');
				}
			}
			this.set('controller', controller);
			this.set('controller.user', model);
			this.set('user', model);
			var currentMonthSavingsRecords = new Ember.A([]);
			this._fetchRecords(null, currentMonthSavingsRecords).then(function () {
				var savings = _this4._calculateSavings(currentMonthSavingsRecords);
				_this4.set('controller.savings', savings);
			});
			if (this.get('controller.awsMarketplaceToken')) {
				// trigger AWS marketplace activation proccess for already signed in user
				model.set('awsMarketplaceToken', this.get('controller.awsMarketplaceToken'));
				this.get('emberNotificationCenter').pushNotification({
					title: 'AWS Marketplace Purchase: VMPower Plus',
					description: 'Upgrading to VMPower Plus via AWS Marketplace'
				}, model.save().then(function () {
					// refresh model, since it will have a plan and cap change
					return model.reload();
				}).then(function () {
					_this4.set('controller.awsMarketplaceToken', null);
				}));
			}
			// if (this.get('router.currentRouteName') === 'das')

			return this.store.findAll('notification').then(function (results) {
				var notifications = results;
				// TODO why the hell is findAll return ing a wierd array of
				// [undefined, recordArray]
				_this4.set('controller.notifications', notifications);
			});
		},
		model: function model() {
			var _this5 = this;

			var user = this.get('session.data.authenticated.user');
			if (!user || !user.id) {
				return;
			}
			var id = this.get('session.data.authenticated.user.id');
			return this.get('accounts').activeAccount().then(function (account) {
				_this5.set('account', account);
				_this5.set('loadedAccounts', _this5.get('accounts.accounts'));
			}).then(function () {
				return _this5.store.find('user', id);
			});
		},
		actions: {
			createAccount: function createAccount(targetAccountName) {
				var _this6 = this;

				var newAccount = this.store.createRecord('account', {
					organization: targetAccountName
				});
				newAccount.save().then(function (account) {
					_this6.get('accounts').setPinnedAccount(account.id);
					location.reload();
				});
			},
			selectAccount: function selectAccount(targetAccount) {
				this.get('accounts').setPinnedAccount(targetAccount.id);
				location.reload();
			}
		}
	});
});