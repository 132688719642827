define("vmpower/templates/components/ax-account-details-form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 8
            },
            "end": {
              "line": 8,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-account-details-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["class", "white-text", "value", ["subexpr", "@mut", [["get", "user.firstName", ["loc", [null, [7, 41], [7, 55]]]]], [], []], "id", "first_name", "type", "text"], ["loc", [null, [7, 8], [7, 85]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 8
            },
            "end": {
              "line": 12,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-account-details-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tooltipped");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element4, 'data-tooltip');
          morphs[1] = dom.createMorphAt(element4, 1, 1);
          return morphs;
        },
        statements: [["attribute", "data-tooltip", ["concat", ["Modify your first name from your ", ["get", "user.accountType", ["loc", [null, [9, 81], [9, 97]]]], " account."]]], ["inline", "input", [], ["disabled", true, "class", "white-text tooltipped", "value", ["subexpr", "@mut", [["get", "user.firstName", ["loc", [null, [10, 68], [10, 82]]]]], [], []], "id", "first_name", "type", "text"], ["loc", [null, [10, 10], [10, 112]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 8
            },
            "end": {
              "line": 20,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-account-details-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["class", "white-text", "value", ["subexpr", "@mut", [["get", "user.lastName", ["loc", [null, [19, 41], [19, 54]]]]], [], []], "id", "last_name", "type", "text"], ["loc", [null, [19, 8], [19, 83]]]]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 8
            },
            "end": {
              "line": 24,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-account-details-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tooltipped");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element3, 'data-tooltip');
          morphs[1] = dom.createMorphAt(element3, 1, 1);
          return morphs;
        },
        statements: [["attribute", "data-tooltip", ["concat", ["Modify your last name from your ", ["get", "user.accountType", ["loc", [null, [21, 80], [21, 96]]]], " account."]]], ["inline", "input", [], ["class", "white-text", "disabled", true, "value", ["subexpr", "@mut", [["get", "user.lastName", ["loc", [null, [22, 57], [22, 70]]]]], [], []], "id", "last_name", "type", "text"], ["loc", [null, [22, 10], [22, 99]]]]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 8
            },
            "end": {
              "line": 32,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-account-details-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["class", "white-text", "value", ["subexpr", "@mut", [["get", "user.email", ["loc", [null, [31, 41], [31, 51]]]]], [], []], "id", "account-email", "type", "email"], ["loc", [null, [31, 8], [31, 85]]]]],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 8
            },
            "end": {
              "line": 36,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-account-details-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tooltipped");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element2, 'data-tooltip');
          morphs[1] = dom.createMorphAt(element2, 1, 1);
          return morphs;
        },
        statements: [["attribute", "data-tooltip", ["concat", ["Modify your email from your ", ["get", "user.accountType", ["loc", [null, [33, 76], [33, 92]]]], " account."]]], ["inline", "input", [], ["class", "white-text", "disabled", true, "value", ["subexpr", "@mut", [["get", "user.email", ["loc", [null, [34, 57], [34, 67]]]]], [], []], "id", "account-email", "type", "email"], ["loc", [null, [34, 10], [34, 101]]]]],
        locals: [],
        templates: []
      };
    }();
    var child6 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 10
            },
            "end": {
              "line": 56,
              "column": 10
            }
          },
          "moduleName": "vmpower/templates/components/ax-account-details-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn action-button-color waves-effect waves-light center-align");
          dom.setAttribute(el1, "type", "submit");
          dom.setAttribute(el1, "name", "action");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "material-icons left");
          var el3 = dom.createTextNode("check_box");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Configure 2FA\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [["element", "action", ["open2FAModal"], [], ["loc", [null, [52, 18], [52, 43]]]]],
        locals: [],
        templates: []
      };
    }();
    var child7 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 10
            },
            "end": {
              "line": 61,
              "column": 10
            }
          },
          "moduleName": "vmpower/templates/components/ax-account-details-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn disabled action-button-color waves-effect waves-light center-align tooltipped");
          dom.setAttribute(el1, "name", "action");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "material-icons left");
          var el3 = dom.createTextNode("check_box");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Configure 2FA\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'data-tooltip');
          return morphs;
        },
        statements: [["attribute", "data-tooltip", ["concat", ["You must configure 2FA through your ", ["get", "user.accountType", ["loc", [null, [58, 64], [58, 80]]]], " account."]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 86,
            "column": 33
          }
        },
        "moduleName": "vmpower/templates/components/ax-account-details-form.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h4");
        dom.setAttribute(el1, "class", "center-align white-text");
        var el2 = dom.createTextNode("Account Details");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2, "id", "account-details-form");
        dom.setAttribute(el2, "class", "col s12 white-text");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "input-field col s12");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "for", "first_name");
        dom.setAttribute(el5, "class", "active white-text");
        var el6 = dom.createTextNode("First Name");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "input-field col s12");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "for", "last_name");
        dom.setAttribute(el5, "class", "active white-text");
        var el6 = dom.createTextNode("Last Name");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "input-field col s12");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "for", "account-email");
        dom.setAttribute(el5, "class", "active white-text");
        var el6 = dom.createTextNode("Email");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "input-field col s12");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "for", "account-org");
        dom.setAttribute(el5, "class", "active white-text");
        var el6 = dom.createTextNode("Company/Organization");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h5");
        dom.setAttribute(el4, "class", "white-text center");
        var el5 = dom.createTextNode("2-Factor Authentication");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h6");
        dom.setAttribute(el4, "class", "white-text center");
        var el5 = dom.createTextNode("Secure your VM access with 2-Factor Authentication");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col s12 center");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "input-field col s12 center tooltipped");
        dom.setAttribute(el4, "type", "text");
        dom.setAttribute(el4, "data-position", "top");
        dom.setAttribute(el4, "data-tooltip", "Automatically enables VMs up to your VM limit. Helps auto-detect new VMs added.");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h5");
        dom.setAttribute(el4, "class", "white-text center");
        var el5 = dom.createTextNode("Developers");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h6");
        dom.setAttribute(el4, "class", "white-text center");
        var el5 = dom.createTextNode("Activate your developer credentials and use the VMPower API (private beta only)");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col s12 center");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "class", "btn action-button-color waves-effect waves-light center-align");
        dom.setAttribute(el6, "type", "submit");
        dom.setAttribute(el6, "name", "action");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("i");
        dom.setAttribute(el7, "class", "material-icons left");
        var el8 = dom.createTextNode("check_box");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("Configure API Access\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [2, 1]);
        var element6 = dom.childAt(element5, [13, 5, 1, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element5, [3, 1]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element5, [5, 1]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [7, 1]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element5, [9, 5, 1]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element5, [11, 1]), 1, 1);
        morphs[6] = dom.createElementMorph(element6);
        morphs[7] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[8] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "not", [["get", "user.accountType", ["loc", [null, [6, 19], [6, 35]]]]], [], ["loc", [null, [6, 14], [6, 36]]]]], [], 0, 1, ["loc", [null, [6, 8], [12, 15]]]], ["block", "if", [["subexpr", "not", [["get", "user.accountType", ["loc", [null, [18, 19], [18, 35]]]]], [], ["loc", [null, [18, 14], [18, 36]]]]], [], 2, 3, ["loc", [null, [18, 8], [24, 15]]]], ["block", "if", [["subexpr", "not", [["get", "user.accountType", ["loc", [null, [30, 19], [30, 35]]]]], [], ["loc", [null, [30, 14], [30, 36]]]]], [], 4, 5, ["loc", [null, [30, 8], [36, 15]]]], ["inline", "input", [], ["class", "white-text", "value", ["subexpr", "@mut", [["get", "account.organization", ["loc", [null, [42, 41], [42, 61]]]]], [], []], "id", "account-org", "type", "text"], ["loc", [null, [42, 8], [42, 92]]]], ["block", "if", [["subexpr", "not", [["get", "user.accountType", ["loc", [null, [51, 21], [51, 37]]]]], [], ["loc", [null, [51, 16], [51, 38]]]]], [], 6, 7, ["loc", [null, [51, 10], [61, 17]]]], ["inline", "md-check", [], ["name", "Automatically Use All VM Slots", "class", "white-text", "checked", ["subexpr", "@mut", [["get", "account.autoEnableVms", ["loc", [null, [68, 84], [68, 105]]]]], [], []], "id", "account-auto-enable"], ["loc", [null, [68, 8], [68, 132]]]], ["element", "action", ["openApiModal"], [], ["loc", [null, [76, 13], [76, 38]]]], ["inline", "ax-two-factor-auth-modal", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [85, 32], [85, 36]]]]], [], []]], ["loc", [null, [85, 0], [85, 38]]]], ["inline", "ax-api-config-modal", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [86, 27], [86, 31]]]]], [], []]], ["loc", [null, [86, 0], [86, 33]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }());
});