define('vmpower/routes/dashboard/vm-rightsizing/vm', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        setupController: function setupController(controller, vm) {
            var user = this.get('session.data.authenticated.user');
            controller.set('vm', vm);
            controller.set('user', this.store.findRecord('user', user.id));
        },
        model: function model(params) {
            return this.store.findRecord('virtualMachine', params.virtualMachine_id);
        }
    });
});