define('vmpower/routes/signup', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        errors: Ember.computed.alias('controller.errors'),
        setupController: function setupController(controller) {
            var _this = this;

            this.set('controller', controller);
            if (this.get('controller.utmSource') || this.get('controller.afmc')) {
                this.set('controller.state', JSON.stringify({
                    utmSource: this.get('controller.utmSource'),
                    afmc: this.get('controller.afmc')
                }));
            }
            // hande oauth response from azure
            if (controller.get('id_token') && controller.get('code')) {
                var state = {};
                if (this.get('controller.state')) {
                    state = JSON.parse(this.get('controller.state'));
                    this.set('controller.afmc', state.afmc);
                    this.set('controller.source', state.source);
                }
                this.set('controller.signingUp', true);
                // these will be filled by the api from the jwt
                var newUser = this.store.createRecord('user', {
                    'first-name': 'placeholder',
                    'last-name': 'placeholder',
                    email: 'placeholder',
                    source: state.source,
                    affiliate: state.affiliate,
                    accountType: 'microsoft',
                    idToken: controller.get('id_token'),
                    code: controller.get('code')
                });
                newUser.set('source', newUser.get('source') || document.referrer);
                newUser.save().then(function (result) {
                    fbq('track', 'CompleteRegistration');
                    _this.set('controller.signingUp', false);
                    Ember.debug(result);
                    Ember.debug('created user: ' + result.email);
                    _this.set('errors', []);
                    var action;
                    if (window.Intercom.booted) {
                        action = 'update';
                    } else {
                        action = 'boot';
                    }
                    window.Intercom(action, {
                        app_id: 'nl6tgybo',
                        name: result.get('firstName') + ' ' + result.get('lastName'),
                        trialing: result.get('trialing'),
                        trial_used: result.get('trialUsed'),
                        plan: result.get('plan'),
                        plan_expiring: result.get('planExpiring'),
                        plan_expire_time: result.get('planExpireTime'),
                        email: result.get('email'),
                        source: _this.get('controller.utmSource'),
                        affiliate: _this.get('controller.afmc'),
                        createdAt: result.get('createdAt'),
                        user_id: result.id
                    });
                    return _this.transitionTo('signin', {
                        queryParams: {
                            id_token: controller.get('id_token'),
                            code: controller.get('code'),
                            provider: 'azure'
                        }
                    });
                }).catch(function (err) {
                    Ember.debug(err);
                    _this.set('controller.signingUp', false);
                    _this.set('controller.code', null);
                    _this.set('controller.id_token', null);
                    _this.set('errors', err.errors);
                });
            }
        },
        actions: {
            signup: function signup(user) {
                var _this2 = this;

                this.set('controller.signingUp', true);
                var newUser = this.store.createRecord('user', user);
                newUser.set('source', user.source || document.referrer);
                newUser.save().then(function (result) {
                    fbq('track', 'CompleteRegistration');
                    _this2.set('controller.signingUp', false);
                    Ember.debug(result);
                    Ember.debug('created user: ' + result.email);
                    _this2.set('errors', []);
                    var action;
                    if (window.Intercom.booted) {
                        action = 'update';
                    } else {
                        action = 'boot';
                    }
                    window.Intercom(action, {
                        app_id: 'nl6tgybo',
                        name: result.get('firstName') + ' ' + result.get('lastName'),
                        email: result.get('email'),
                        source: result.get('source'),
                        createdAt: result.get('createdAt'),
                        user_id: result.id
                    });
                    return _this2.transitionTo('signin', {
                        queryParams: {
                            newUser: true
                        }
                    });
                }).catch(function (err) {
                    Ember.debug(err);
                    _this2.set('errors', err.errors);
                });
                gtag('event', 'sign_up');
                Ember.debug(user);
            }
        }
    });
});