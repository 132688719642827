define("vmpower/templates/components/ax-team-list", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 12
            },
            "end": {
              "line": 7,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-team-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          dom.setAttribute(el1, "class", "center header");
          var el2 = dom.createTextNode("Teams");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h6");
          dom.setAttribute(el1, "class", "center header");
          var el2 = dom.createTextNode("Teams allow you to easily delegate VM access to others and share VM event schedules.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 12
              },
              "end": {
                "line": 10,
                "column": 12
              }
            },
            "moduleName": "vmpower/templates/components/ax-team-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h3");
            dom.setAttribute(el1, "class", "center header");
            var el2 = dom.createTextNode("Invites");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h6");
            dom.setAttribute(el1, "class", "center header");
            var el2 = dom.createTextNode("These are the team invites you currently have to share VMs with other VMPower users.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 12
            },
            "end": {
              "line": 10,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-team-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "showInvites", ["loc", [null, [7, 22], [7, 33]]]]], [], 0, null, ["loc", [null, [7, 12], [10, 12]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 16
              },
              "end": {
                "line": 16,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-team-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "waves-effect tooltipped waves-light btn action-button-color right disabled");
            dom.setAttribute(el1, "data-position", "top");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "material-icons left");
            var el3 = dom.createTextNode("library_add");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("New Team");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element7, 'data-tooltip');
            return morphs;
          },
          statements: [["attribute", "data-tooltip", ["concat", ["You can only add ", ["get", "account.teamCap", ["loc", [null, [15, 159], [15, 174]]]], " team(s) on your plan"]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 16
              },
              "end": {
                "line": 18,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-team-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "waves-effect waves-light btn action-button-color right");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "material-icons left");
            var el3 = dom.createTextNode("library_add");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("New Team");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element6);
            return morphs;
          },
          statements: [["element", "action", ["addNewTeam"], [], ["loc", [null, [17, 23], [17, 46]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 12
            },
            "end": {
              "line": 19,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-team-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "gte", [["get", "teams.length", ["loc", [null, [14, 27], [14, 39]]]], ["get", "account.teamCap", ["loc", [null, [14, 40], [14, 55]]]]], [], ["loc", [null, [14, 22], [14, 56]]]]], [], 0, 1, ["loc", [null, [14, 16], [18, 23]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 77
            },
            "end": {
              "line": 25,
              "column": 141
            }
          },
          "moduleName": "vmpower/templates/components/ax-team-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("My Teams");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 79
            },
            "end": {
              "line": 27,
              "column": 146
            }
          },
          "moduleName": "vmpower/templates/components/ax-team-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("My Invites");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 48
                },
                "end": {
                  "line": 36,
                  "column": 107
                }
              },
              "moduleName": "vmpower/templates/components/ax-team-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Team: ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "team.name", ["loc", [null, [36, 94], [36, 107]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 48
                },
                "end": {
                  "line": 39,
                  "column": 48
                }
              },
              "moduleName": "vmpower/templates/components/ax-team-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                   ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "btn-floating red right tooltipped");
              dom.setAttribute(el1, "data-tooltip", "Delete Team");
              dom.setAttribute(el1, "data-position", "Top");
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "material-icons");
              var el3 = dom.createTextNode("delete");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element3);
              return morphs;
            },
            statements: [["element", "action", ["deleteTeam", ["get", "team", ["loc", [null, [38, 165], [38, 169]]]]], [], ["loc", [null, [38, 143], [38, 171]]]]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 64
                  },
                  "end": {
                    "line": 50,
                    "column": 64
                  }
                },
                "moduleName": "vmpower/templates/components/ax-team-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "task-cat blue");
                var el2 = dom.createElement("strong");
                var el3 = dom.createTextNode(" Team Owner");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 64
                  },
                  "end": {
                    "line": 53,
                    "column": 64
                  }
                },
                "moduleName": "vmpower/templates/components/ax-team-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "task-cat green");
                var el2 = dom.createElement("strong");
                var el3 = dom.createTextNode(" 2FA Enabled");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          var child2 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 53,
                    "column": 64
                  },
                  "end": {
                    "line": 55,
                    "column": 64
                  }
                },
                "moduleName": "vmpower/templates/components/ax-team-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "task-cat red");
                var el2 = dom.createElement("strong");
                var el3 = dom.createTextNode(" 2FA Disabled");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 52
                },
                "end": {
                  "line": 58,
                  "column": 52
                }
              },
              "moduleName": "vmpower/templates/components/ax-team-list.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                        \n                                                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "collection-item dismissable");
              dom.setAttribute(el1, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);");
              var el2 = dom.createTextNode("\n                                                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "for", "task1");
              dom.setAttribute(el2, "style", "text-decoration: none; vm-name");
              var el3 = dom.createTextNode("\n                                                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                                                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element2, 1, 1);
              morphs[1] = dom.createMorphAt(element2, 3, 3);
              morphs[2] = dom.createMorphAt(element2, 5, 5);
              morphs[3] = dom.createMorphAt(element2, 7, 7);
              morphs[4] = dom.createMorphAt(element2, 8, 8);
              return morphs;
            },
            statements: [["content", "member.firstName", ["loc", [null, [47, 64], [47, 84]]]], ["content", "member.lastName", ["loc", [null, [47, 85], [47, 104]]]], ["content", "member.email", ["loc", [null, [47, 105], [47, 121]]]], ["block", "if", [["subexpr", "is-equal", [["get", "account.primaryUser.id", ["loc", [null, [48, 80], [48, 102]]]], ["get", "member.id", ["loc", [null, [48, 103], [48, 112]]]]], [], ["loc", [null, [48, 70], [48, 113]]]]], [], 0, null, ["loc", [null, [48, 64], [50, 71]]]], ["block", "if", [["get", "member.enableTwoFactor", ["loc", [null, [51, 70], [51, 92]]]]], [], 1, 2, ["loc", [null, [51, 64], [55, 71]]]]],
            locals: ["member"],
            templates: [child0, child1, child2]
          };
        }();
        var child3 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 130
                  },
                  "end": {
                    "line": 62,
                    "column": 186
                  }
                },
                "moduleName": "vmpower/templates/components/ax-team-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("inviting somone.");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 52
                },
                "end": {
                  "line": 66,
                  "column": 52
                }
              },
              "moduleName": "vmpower/templates/components/ax-team-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "collection-item");
              var el2 = dom.createTextNode("\n                                                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "row");
              var el3 = dom.createTextNode("\n                                                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "col s12 center");
              var el4 = dom.createTextNode("\n                                                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("h5");
              var el5 = dom.createElement("i");
              dom.setAttribute(el5, "class", "mdi-action-info");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("No users in this Team. Try ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element1, 'style');
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1, 1]), 2, 2);
              return morphs;
            },
            statements: [["attribute", "style", ["concat", ["touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); ", ["subexpr", "if", [["subexpr", "not", [["get", "vm.planEnabled", ["loc", [null, [59, 194], [59, 208]]]]], [], ["loc", [null, [59, 189], [59, 209]]]], "background:gray;opacity:0.6;filter:alpha(opacity=60);"], [], ["loc", [null, [59, 184], [59, 267]]]]]]], ["block", "link-to", ["dashboard.teams.team", ["get", "team", ["loc", [null, [62, 164], [62, 168]]]]], [], 0, null, ["loc", [null, [62, 130], [62, 198]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 32
              },
              "end": {
                "line": 70,
                "column": 32
              }
            },
            "moduleName": "vmpower/templates/components/ax-team-list.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "active");
            var el2 = dom.createTextNode("\n                                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "collapsible-header darken-1 white-text active");
            var el3 = dom.createTextNode("\n                                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "collapsible-body orange lighten-5");
            dom.setAttribute(el2, "style", "display: block;");
            var el3 = dom.createTextNode("\n                                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("ul");
            dom.setAttribute(el3, "class", "collection");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                                                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var element5 = dom.childAt(element4, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element5, 1, 1);
            morphs[1] = dom.createMorphAt(element5, 3, 3);
            morphs[2] = dom.createMorphAt(dom.childAt(element4, [3, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["dashboard.teams.team", ["get", "team", ["loc", [null, [36, 82], [36, 86]]]]], [], 0, null, ["loc", [null, [36, 48], [36, 119]]]], ["block", "if", [["subexpr", "is-equal", [["get", "account.primaryUser.id", ["loc", [null, [37, 64], [37, 86]]]], ["get", "user.id", ["loc", [null, [37, 87], [37, 94]]]]], [], ["loc", [null, [37, 54], [37, 95]]]]], [], 1, null, ["loc", [null, [37, 48], [39, 55]]]], ["block", "each", [["get", "team.users", ["loc", [null, [43, 60], [43, 70]]]]], ["key", "@index"], 2, 3, ["loc", [null, [43, 52], [66, 61]]]]],
          locals: ["team"],
          templates: [child0, child1, child2, child3]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 32
              },
              "end": {
                "line": 81,
                "column": 32
              }
            },
            "moduleName": "vmpower/templates/components/ax-team-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n                                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col s12 center");
            var el3 = dom.createTextNode("\n                                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row");
            var el4 = dom.createTextNode("\n                                                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "rainy-icon");
            var el5 = dom.createTextNode("\n                                                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("canvas");
            dom.setAttribute(el5, "id", "vms-placeholder");
            dom.setAttribute(el5, "width", "120");
            dom.setAttribute(el5, "height", "120");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h5");
            dom.setAttribute(el3, "class", "valign");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "mdi-action-info");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("No teams found. Try creating one now.");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 24
            },
            "end": {
              "line": 84,
              "column": 24
            }
          },
          "moduleName": "vmpower/templates/components/ax-team-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "collapsible vm-list");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "each", [["get", "teams", ["loc", [null, [33, 40], [33, 45]]]]], ["key", "@index"], 0, 1, ["loc", [null, [33, 32], [81, 41]]]], ["inline", "ax-team-create-modal", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [83, 56], [83, 60]]]]], [], []], "account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [83, 69], [83, 76]]]]], [], []]], ["loc", [null, [83, 28], [83, 78]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child6 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 36
                },
                "end": {
                  "line": 91,
                  "column": 36
                }
              },
              "moduleName": "vmpower/templates/components/ax-team-list.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "ax-invite-list-item", [], ["invite", ["subexpr", "@mut", [["get", "invite", ["loc", [null, [90, 69], [90, 75]]]]], [], []], "user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [90, 81], [90, 85]]]]], [], []]], ["loc", [null, [90, 40], [90, 87]]]]],
            locals: ["invite"],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 91,
                  "column": 36
                },
                "end": {
                  "line": 102,
                  "column": 36
                }
              },
              "moduleName": "vmpower/templates/components/ax-team-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col s12 center");
              var el3 = dom.createTextNode("\n                                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "row");
              var el4 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "rainy-icon");
              var el5 = dom.createTextNode("\n                                                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("canvas");
              dom.setAttribute(el5, "id", "vms-placeholder");
              dom.setAttribute(el5, "width", "120");
              dom.setAttribute(el5, "height", "120");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                                                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              dom.setAttribute(el3, "class", "valign");
              var el4 = dom.createElement("i");
              dom.setAttribute(el4, "class", "mdi-action-info");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("No invites found. Ask your VMPower Team owner to invite you.");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 84,
                "column": 24
              },
              "end": {
                "line": 106,
                "column": 24
              }
            },
            "moduleName": "vmpower/templates/components/ax-team-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row center");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("ul");
            dom.setAttribute(el2, "class", "collapsible collection vm-list action-button-color");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                        ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["block", "each", [["get", "invites", ["loc", [null, [89, 44], [89, 51]]]]], ["key", "@index"], 0, 1, ["loc", [null, [89, 36], [102, 45]]]], ["inline", "ax-team-create-modal", [], ["account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [104, 63], [104, 70]]]]], [], []], "user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [104, 76], [104, 80]]]]], [], []]], ["loc", [null, [104, 32], [104, 82]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 24
            },
            "end": {
              "line": 106,
              "column": 24
            }
          },
          "moduleName": "vmpower/templates/components/ax-team-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "showInvites", ["loc", [null, [84, 34], [84, 45]]]]], [], 0, null, ["loc", [null, [84, 24], [106, 24]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 113,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-team-list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col s12 m8 l8 offset-m2 offset-l2 center");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row center");
        dom.setAttribute(el3, "style", "height: 60px;");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col s12 l12 m12");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6, "class", "tabs tab-demo-active z-depth-1");
        dom.setAttribute(el6, "style", "width: 100%;");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "card-panel");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" \n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0, 1]);
        var element9 = dom.childAt(element8, [5, 1, 1]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element10, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
        morphs[2] = dom.createAttrMorph(element11, 'class');
        morphs[3] = dom.createMorphAt(element11, 0, 0);
        morphs[4] = dom.createAttrMorph(element12, 'class');
        morphs[5] = dom.createMorphAt(element12, 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "showTeams", ["loc", [null, [4, 18], [4, 27]]]]], [], 0, 1, ["loc", [null, [4, 12], [10, 19]]]], ["block", "if", [["get", "showTeams", ["loc", [null, [13, 18], [13, 27]]]]], [], 2, null, ["loc", [null, [13, 12], [19, 19]]]], ["attribute", "class", ["concat", ["tab ", ["subexpr", "if", [["get", "showTeams", ["loc", [null, [25, 44], [25, 53]]]], "tab-active"], [], ["loc", [null, [25, 39], [25, 68]]]], " col s6"]]], ["block", "link-to", ["dashboard.teams"], ["class", "white-text active"], 3, null, ["loc", [null, [25, 77], [25, 153]]]], ["attribute", "class", ["concat", ["tab ", ["subexpr", "if", [["get", "showInvites", ["loc", [null, [27, 44], [27, 55]]]], "tab-active"], [], ["loc", [null, [27, 39], [27, 70]]]], " col s6"]]], ["block", "link-to", ["dashboard.teams.invites"], ["class", "white-text"], 4, null, ["loc", [null, [27, 79], [27, 158]]]], ["block", "if", [["get", "showTeams", ["loc", [null, [31, 30], [31, 39]]]]], [], 5, 6, ["loc", [null, [31, 24], [106, 31]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  }());
});