define('vmpower/routes/dashboard/teams/team', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        accounts: Ember.inject.service(),
        model: function model(params) {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            return this.store.findRecord('team', params.team_id);
        },
        setupController: function setupController(controller, model) {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            var account = this.get('accounts').activeAccountSync();
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            controller.set('user', user);
            controller.set('account', account);
            this.store.query('teamInvite', { account: this.get('accounts').activeAccountSync() }).then(function (invites) {
                controller.set('invites', invites);
            });
            return controller.set('team', model);
        }
    });
});