define('vmpower/routes/dashboard/account-details/billing/invoices', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        setupController: function setupController(controller, user) {
            var _this = this;

            appInsights.trackEvent('route:account-details:billing:invoices', {
                email: this.get('session.data.authenticated.user.email')
            });
            controller.set('user', user);
            controller.set('loadingInvoices', true);
            return this.store.findAll('invoice').then(function (invoices) {
                debugger;
                var sortedInvoices = invoices.sortBy('date').reverse();
                _this.controller.set('invoices', sortedInvoices);
                controller.set('loadingInvoices', false);
            });
        },
        model: function model() {
            var user = this.get('session.data.authenticated.user');
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            var id = this.get('session.data.authenticated.user.id');
            return this.store.find('user', id);
        }
    });
});