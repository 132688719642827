define('vmpower/components/ax-unused-disk-overview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        unusedVolumes: Ember.computed.filterBy('volumes', 'unused', true),
        emberNotificationCenter: Ember.inject.service(),
        session: Ember.inject.service(),
        modals: Ember.inject.service(),
        hasMoreData: true,
        store: Ember.inject.service(),
        columns: [100],
        unusedCost: function () {
            var cost = 0;
            this.get('unusedVolumes').forEach(function (v) {
                cost += v.get('monthlyCost');
            });
            return parseFloat(cost.toFixed(2));
        }.property('unusedVolumes.@each'),
        disableAlertsObserver: Ember.observer('volumes.@each.unusedVolumeDetection', function (target) {
            target.get('volumes').forEach(function (volume) {
                if (volume.get('hasDirtyAttributes')) {
                    if (volume.get('unusedVolumeDetection')) {
                        appInsights.trackEvent('ax-unused-disk-overview', { email: target.get('session.data.authenticated.user.email'), volume: volume.get('volumeId') });
                        target.get('emberNotificationCenter').pushNotification({
                            title: 'Enabling Unused Volume Alerts',
                            description: 'Unused VM alert notifications will be sent for ' + volume.get('volumeId')
                        }, volume.save());
                    } else {
                        appInsights.trackEvent('ax-unused-disk-overview', { email: target.get('session.data.authenticated.user.email'), volume: volume.get('volumeId') });
                        target.get('emberNotificationCenter').pushNotification({
                            title: 'Disabling Unused Volume Alerts',
                            description: 'Unused VM alert notifications will not be sent for ' + volume.get('volumeId')
                        }, volume.save());
                    }
                }
            });
        }),
        actions: {
            showUpgradeModal: function showUpgradeModal() {
                this.get('modals').upgradeModal();
            },
            scrolled: function scrolled(scrollLeft, scrollTop, lastObject) {
                var _this = this;

                if (lastObject.index + 15 > this.get('volumes.length') && this.get('hasMoreData') && !this.get('loadingMore')) {
                    this.set('loadingMore', true);
                    this.get('store').query('volume', { limit: 15, next: lastObject.item.id }).then(function (results) {
                        results.forEach(function (volume) {
                            if (!_this.get('volumes').contains(volume)) {
                                _this.get('volumes').pushObject(volume);
                            }
                        });
                        if (results.get('length') === 0) {
                            _this.set('hasMoreData', false);
                            Ember.$('#volume-overview-preload').hide();
                        }
                        _this.set('loadingMore', false);
                    });
                }
            }
        }
    });
});