define('vmpower/components/ax-blog-beacon', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		posts: Ember.A([]),
		hidden: true,
		init: function init() {
			var _this = this;

			this._super();
			Ember.$.browser = {};
			Ember.$.browser.msie = false;
			Ember.$.browser.version = 0;

			if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
				Ember.$.browser.msie = true;
				Ember.$.browser.version = RegExp.$1;
			}
			Ember.$.getFeed({
				url: _environment.default.apiHost + '/rss',
				success: function success(feed) {
					var latestTime = moment(feed.items[0].updated);
					var delta = moment(Date.now()).diff(latestTime, 'days');

					if (delta > 30) {
						_this.set('hidden', true);
					} else {
						_this.set('hidden', false);
					}
					_this.set('posts', feed.items);
				}
			});
		}
	});
});