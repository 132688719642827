define('vmpower/components/ax-navbar', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        session: Ember.inject.service(),
        accounts: Ember.inject.service(),
        init: function init() {
            this._super();
            Ember.run.scheduleOnce('afterRender', function () {
                Ember.$('.account-button').dropdown({
                    inDuration: 300,
                    outDuration: 225,
                    constrain_width: false, // Does not change width of dropdown to that of the activator
                    hover: true, // Activate on hover
                    gutter: 0, // Spacing from edge
                    belowOrigin: true, // Displays dropdown below the button
                    alignment: 'left' // Displays dropdown with edge aligned to the left of button 
                });
                Ember.$('.notifications-button').dropdown({
                    inDuration: 300,
                    outDuration: 225,
                    constrain_width: false, // Does not change width of dropdown to that of the activator
                    hover: false, // Activate on hover
                    gutter: 0, // Spacing from edge
                    belowOrigin: true, // Displays dropdown below the button
                    alignment: 'left' // Displays dropdown with edge aligned to the left of button 
                });
            });
        },
        timestampDesc: ['timestamp:desc'],
        sortedNotifications: Ember.computed.sort('notifications', 'timestampDesc'),
        unreadNotifications: function () {
            var filtered = new Ember.A([]);
            if (!this.get('notifications')) {
                return filtered;
            }
            this.get('notifications').forEach(function (notif) {
                if (!notif.get('unread')) {
                    return;
                }
                Ember.debug('pushing unread notification:');
                Ember.debug(notif);
                filtered.pushObject(notif);
            });
            return filtered;
        }.property('notifications.@each.unread'),
        userId: function () {
            return this.get('session.data.authenticated.user.id');
        }.property('userId'),
        account: function () {
            return this.get('accounts').activeAccountSync();
        }.property('account'),
        environment: function () {
            return _environment.default.environment;
        }.property(),
        _clearUnread: function _clearUnread() {
            var _this = this;

            this.get('notifications').forEach(function (notification) {
                if (!notification.get('readBy')) {
                    notification.set('readBy', new Ember([]));
                }
                var containsCurrentUser = false;
                var userId = _this.get('session.data.authenticated.user.id');
                notification.get('readBy').forEach(function (user) {
                    if (user && user.id === userId) {
                        containsCurrentUser = true;
                    }
                });
                if (!containsCurrentUser) {
                    notification.get('readBy').pushObject(_this.get('store').peekRecord('user', userId));
                    notification.save();
                }
            });
        },
        actions: {
            clearNotifications: function clearNotifications() {
                if (!this.get('notificationsOpen')) {
                    return this.set('notificationsOpen', true);
                }
                this._clearUnread();
                this.set('notificationsOpen', false);
            },
            goToNotification: function goToNotification(notification) {
                var route = notification.get('frontEndLink');
                if (!notification.get('readBy')) {
                    notification.set('readBy', new Ember([]));
                }
                this._clearUnread();
                var appRouter = this.container.lookup('controller:application');
                appRouter.transitionToRoute(route);
            }
        }
    });
});