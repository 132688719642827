define('vmpower/routes/dashboard/vm-schedules/all', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return this.store.findAll('vm-schedule');
        },
        setupController: function setupController(controller, schedules) {
            controller.set('schedules', schedules);
        }
    });
});