define('vmpower/components/ax-vmgroup-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        modals: Ember.inject.service(),
        powerManager: Ember.inject.service(),
        poweringOn: false,
        poweringOff: false,
        init: function init() {
            var _this = this;

            this._super();
            Ember.run.scheduleOnce('afterRender', function () {
                Ember.$('#' + _this.elementId + ' .tooltipped').tooltip({ delay: 0 });
            });
        },
        groupState: function () {
            var isOn = true;
            this.get('group.vms').forEach(function (vm) {
                isOn = isOn && vm.get('powerState') === 'on';
            });
            return isOn;
        }.property('group.vms.@each'),
        actions: {
            openOneClickModal: function openOneClickModal(vm) {
                this.get('modals').openOneClickModal(vm);
            },
            lookupOrCreateSchedule: function lookupOrCreateSchedule(group) {
                var _this2 = this;

                var targetSchedule = false;
                group.store.findAll('vm-schedule').then(function (schedules) {
                    schedules.forEach(function (sched) {
                        if (sched.get('vmGroup.id') === group.id) {
                            targetSchedule = sched;
                        }
                    });
                    if (targetSchedule) {
                        getOwner(_this2).lookup('route:dashboard.vm-groups').transitionTo('dashboard.vm-schedules.edit', targetSchedule);
                    } else {
                        getOwner(_this2).lookup('route:dashboard.vm-groups').transitionTo('dashboard.vm-schedules.edit', 'new', {
                            queryParams: {
                                group: group.id
                            }
                        });
                    }
                });
            },
            removeVmFromGroup: function removeVmFromGroup(vm) {
                var _this3 = this;

                this.get('modals').confirmAction('Removing VM From Group?', 'Removing the VM "' + vm.get('instanceId') + '" from the VM Group "' + this.get('group.name') + '"?').then(function () {
                    _this3.get('group.vms').removeObject(vm);
                    _this3.get('emberNotificationCenter').pushNotification({
                        title: 'Removing VM From Group',
                        description: 'Removing VM ' + vm.get('instanceId') + ' from the VM Group ' + _this3.get('group.name')
                    }, _this3.get('group').save().catch(function (err) {
                        _this3.get('group').reload();
                        throw err;
                    }));
                });
            },
            deleteGroup: function deleteGroup() {
                var _this4 = this;

                this.get('modals').confirmAction('Delete VM Group?', 'Delete the VM Group "' + this.get('group.name') + '"? This will delete any associated Power Schedule').then(function () {
                    var promises = [];
                    _this4.get('account.vmGroups').forEach(function (group) {
                        if (!group) {
                            return;
                        }
                        if (group.get('id') === _this4.get('group.id')) {
                            _this4.get('account.vmGroups').removeObject(group);
                        }
                    });
                    _this4.get('group').deleteRecord();
                    _this4.get('schedules').forEach(function (schedule) {
                        if (schedule.get('vmGroup.id') === _this4.get('group.id')) {
                            schedule.deleteRecord();
                            var schedulePromise = schedule.save();
                            promises.push(schedulePromise);
                            _this4.get('emberNotificationCenter').pushNotification({
                                title: 'Deleting Power Schedule',
                                description: 'Deleting Power Schedule "' + schedule.get('name') + '"'
                            }, schedulePromise);
                        }
                    });
                    promises.push(_this4.get('group').save());
                    promises.push(_this4.get('account').save());
                    _this4.get('emberNotificationCenter').pushNotification({
                        title: 'Deleting VM Group',
                        description: 'Deleting VM Group "' + _this4.get('group.name') + '"'
                    }, Ember.RSVP.all(promises));
                });
            },
            powerOffGroup: function powerOffGroup() {
                var _this5 = this;

                this.get('modals').confirmAction('Power off ' + this.get('group.vms.length') + ' VMs?', 'Epehemeral storage for this VM may be lost. Your VMs attached disks won\'t be effected but they will stop executing any apps and tasks').then(function () {
                    var promises = [];
                    _this5.set('poweringOff', true);
                    _this5.get('group.vms').forEach(function (vm) {
                        promises.push(_this5.get('powerManager').powerOnOffInstance(vm, 'off'));
                    });
                    Ember.RSVP.all(promises).then(function () {
                        _this5.set('poweringOff', false);
                    });
                });
            },
            powerOnGroup: function powerOnGroup() {
                var _this6 = this;

                var totalHourlyCost = 0;
                this.get('group.vms').forEach(function (vm) {
                    totalHourlyCost += vm.get('pricePerHour');
                });
                this.get('modals').confirmAction('Power on ' + this.get('group.vms.length') + ' VMs?', 'Your VMs will allocate and start incuring compute charges at $' + totalHourlyCost + 'USD' + ' per hour for all ' + this.get('group.vms.length') + ' VMs').then(function () {
                    var promises = [];
                    _this6.set('poweringOn', true);
                    _this6.get('group.vms').forEach(function (vm) {
                        promises.push(_this6.get('powerManager').powerOnOffInstance(vm, 'on'));
                    });
                    Ember.RSVP.all(promises).finally(function () {
                        _this6.set('poweringOn', false);
                    });
                });
            },
            moveVmUp: function moveVmUp(targetVm) {
                var vms = this.get('group.vms');
                var index = 0;
                vms.forEach(function (vm, i) {
                    if (vm.id === targetVm.id) {
                        index = i;
                    }
                });
                if (index > 0) {
                    var temp = vms.objectAt(index);
                    vms.removeAt(index);
                    vms.insertAt(index - 1, temp);
                    this.get('emberNotificationCenter').pushNotification({
                        title: 'Updating VM Order',
                        description: 'Modifying the order of VMs in this group. If sequential execution is on for this VM Group\'s Schedule, automation will execute in this group in the order they appear.'
                    }, this.get('group').save());
                }
            },
            moveVmDown: function moveVmDown(targetVm) {
                var vms = this.get('group.vms');
                var index = 0;
                vms.forEach(function (vm, i) {
                    if (vm.id === targetVm.id) {
                        index = i;
                    }
                });
                if (index + 1 < vms.get('length')) {
                    var temp = vms.objectAt(index);
                    vms.removeAt(index);
                    vms.insertAt(index + 1, temp);
                    this.get('emberNotificationCenter').pushNotification({
                        title: 'Updating VM Order',
                        description: 'Modifying the order of VMs in this group. If sequential execution is on for this VM Group\'s Schedule, automation will execute in this group in the order they appear.'
                    }, this.get('group').save());
                }
            }
        }
    });
});