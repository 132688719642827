define('vmpower/components/ax-auto-off-settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        modals: Ember.inject.service(),
        emberNotificationCenter: Ember.inject.service(),
        showAutoOffSettings: true,
        showIdleSettings: false,
        unstoppableVms: function () {
            var retVal = Ember.A([]);
            if (!this.get('targetGroup.vms')) {
                return retVal;
            }
            this.get('targetGroup.vms').forEach(function (vm) {
                if (vm.get('unstoppable')) {
                    retVal.pushObject(vm);
                }
            });
            return retVal;
        }.property('targetGroup.vms.@each.unstoppable'),
        autoOffHourOptions: new Ember.A([{
            name: '1 Hours of inactivity',
            hours: 1
        }, {
            name: '2 Hours of inactivity',
            hours: 2
        }, {
            name: '3 Hours of inactivity',
            hours: 3
        }, {
            name: '6 Hours of inactivity',
            hours: 6
        }, {
            name: '9 Hours of inactivity',
            hours: 9
        }, {
            name: '12 Hours of inactivity',
            hours: 12
        }, {
            name: '15 Hours of inactivity',
            hours: 15
        }, {
            name: '18 Hours of inactivity',
            hours: 18
        }, {
            name: '21 Hours of inactivity',
            hours: 21
        }, {
            name: '1 Day of inactivity',
            hours: 24
        }, {
            name: '36 Hours of inactivity',
            hours: 36
        }, {
            name: '2 Days of inactivity',
            hours: 48
        }, {
            name: '3 Days of inactivity',
            hours: 72
        }, {
            name: '4 Days of inactivity',
            hours: 96
        }]),
        warningHoursOptions: new Ember.A([{
            name: '1 Hour Before Shutdown',
            hours: 1
        }, {
            name: '2 Hours Before Shutdown',
            hours: 2
        }, {
            name: '3 Hours Before Shutdown',
            hours: 3
        }, {
            name: '4 Hours Before Shutdown',
            hours: 4
        }, {
            name: '8 Hours Before Shutdown',
            hours: 8
        }, {
            name: 'Do not notify me before Shutdown',
            hours: 0
        }]),
        ignoreHoursStart: new Ember.A([{
            name: '00:00',
            hours: 0
        }, {
            name: '01:00',
            hours: 1
        }, {
            name: '02:00',
            hours: 2
        }, {
            name: '03:00',
            hours: 3
        }, {
            name: '04:00',
            hours: 4
        }, {
            name: '05:00',
            hours: 5
        }, {
            name: '06:00',
            hours: 6
        }, {
            name: '07:00',
            hours: 7
        }, {
            name: '08:00',
            hours: 8
        }, {
            name: '09:00',
            hours: 9
        }, {
            name: '10:00',
            hours: 10
        }, {
            name: '11:00',
            hours: 11
        }, {
            name: '12:00',
            hours: 12
        }, {
            name: '13:00',
            hours: 13
        }, {
            name: '14:00',
            hours: 14
        }, {
            name: '15:00',
            hours: 15
        }, {
            name: '16:00',
            hours: 16
        }, {
            name: '17:00',
            hours: 17
        }, {
            name: '18:00',
            hours: 18
        }, {
            name: '19:00',
            hours: 19
        }, {
            name: '20:00',
            hours: 20
        }, {
            name: '21:00',
            hours: 21
        }, {
            name: '22:00',
            hours: 22
        }, {
            name: '23:00',
            hours: 23
        }]),
        ignoreHoursEnd: new Ember.A([{
            name: '00:00',
            hours: 0
        }, {
            name: '01:00',
            hours: 1
        }, {
            name: '02:00',
            hours: 2
        }, {
            name: '03:00',
            hours: 3
        }, {
            name: '04:00',
            hours: 4
        }, {
            name: '05:00',
            hours: 5
        }, {
            name: '06:00',
            hours: 6
        }, {
            name: '07:00',
            hours: 7
        }, {
            name: '08:00',
            hours: 8
        }, {
            name: '09:00',
            hours: 9
        }, {
            name: '10:00',
            hours: 10
        }, {
            name: '11:00',
            hours: 11
        }, {
            name: '12:00',
            hours: 12
        }, {
            name: '13:00',
            hours: 13
        }, {
            name: '14:00',
            hours: 14
        }, {
            name: '15:00',
            hours: 15
        }, {
            name: '16:00',
            hours: 16
        }, {
            name: '17:00',
            hours: 17
        }, {
            name: '18:00',
            hours: 18
        }, {
            name: '19:00',
            hours: 19
        }, {
            name: '20:00',
            hours: 20
        }, {
            name: '21:00',
            hours: 21
        }, {
            name: '22:00',
            hours: 22
        }, {
            name: '23:00',
            hours: 23
        }]),
        /*
        * Returns a moment Zone object for a timezone name
        * @param name String - The name of the timezone
        **/
        _timezoneForName: function _timezoneForName(name) {
            var zone = moment.tz.zone(name);
            zone.abbriv = zone.abbr(Date.now());
            zone.offsetHours = (zone.offset(Date.now()) / 60).toFixed(2);
            if (zone.offsetHours > 0) {
                zone.offsetHours = '+' + zone.offsetHours;
            } else {
                zone.offsetHours = zone.offsetHours.toString();
            }
            zone.code = name;
            if (zone.set) {
                zone.set('name', zone.get('name') + ' ' + zone.abbriv + ' (' + zone.offsetHours + ' UTC)');
            } else {
                zone.name += ' ' + zone.abbriv + ' (' + zone.offsetHours + ' UTC)';
            }
            return zone;
        },

        // the timezones for the timezone select
        timezones: function () {
            var _this = this;

            var timezones = [];
            moment.tz.names().forEach(function (name) {
                timezones.unshift(_this._timezoneForName(name));
            });
            return timezones;
        }.property(),
        targetGroupObserver: Ember.observer('targetGroup', function (component) {
            component.set('autoOffWarningHours', component.get('targetGroup.autoOffWarningHours'));
            component.set('autoOffHours', component.get('targetGroup.autoOffHours'));
            component.set('ignoreHoursStartValue', component.get('targetGroup.ignoreHoursStart'));
            component.set('ignoreHoursEndValue', component.get('targetGroup.ignoreHoursEnd'));
            component.set('autoOffTimezone', component.get('targetGroup.autoOffTimezone'));
        }),
        actions: {
            updateAutoOffSettings: function updateAutoOffSettings() {
                var _this2 = this;

                if (this.get('targetGroup').changedAttributes().autoOffEnabled && this.get('targetGroup.autoOffEnabled')) {
                    // the user switched on the enable flag
                    return this.get('modals').confirmAction('Enable Auto-off for ' + this.get('targetGroup.vms.length') + ' VMS?', 'This requires write-permission to your subscription and will automatically deallocate VMs after a ' + this.get('targetGroup.autoOffHours') + ' hours of inactivity.', ['- Any Idle Tags on these VMs will be reset']).then(function () {
                        appInsights.trackEvent('route:virtual-machines', {
                            email: _this2.get('session.data.authenticated.user.email'),
                            targetGroup: _this2.get('targetGroup.id'),
                            autoOffEnabled: _this2.get('targetGroup.autoOffEnabled'),
                            autoOffHours: _this2.get('targetGroup.autoOffHours'),
                            autoOffWarningHours: _this2.get('targetGroup.autoOffWarningHours')
                        });
                        _this2.get('emberNotificationCenter').pushNotification({
                            title: 'Updating Auto-off Settings',
                            description: 'Updating auto-off settings for the VM Group "' + _this2.get('targetGroup.name') + '"'
                        }, _this2.get('targetGroup').save().then(function () {
                            // refresh vms, since they are expected to be untagged server side
                            _this2.get('targetGroup.vms').forEach(function (vm) {
                                var promises = [];
                                if (vm.get('isIdle')) {
                                    promises.push(vm.reload());
                                }
                                return Ember.RSVP.all(promises);
                            });
                        }));
                    });
                } else {
                    this.get('emberNotificationCenter').pushNotification({
                        title: 'Updating Auto-off Settings',
                        description: 'Updating auto-off settings for the VM Group "' + this.get('targetGroup.name') + '"'
                    }, this.get('targetGroup').save());
                }
            },
            showAutoOffSettings: function showAutoOffSettings() {
                this.set('showIdleSettings', false);
                this.set('showAutoOffSettings', true);
            },
            showIdleSettings: function showIdleSettings() {
                this.set('showIdleSettings', true);
                this.set('showAutoOffSettings', false);
            },
            openOneClick: function openOneClick(vm) {
                this.get('modals').openOneClickModal(vm);
            }
        }
    });
});