define('vmpower/models/team', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        users: _emberData.default.hasMany('user'),
        vmGroups: _emberData.default.hasMany('vmGroup'),
        owner: _emberData.default.belongsTo('account'),
        permissions: _emberData.default.attr('array')
    });
});