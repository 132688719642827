define('vmpower/components/ax-backup-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        powerManager: Ember.inject.service(),
        inputObserver: Ember.observer('backupRetentionDays', function (target) {
            if (!target.get('timespan.backupRetentionDays')) {
                target.set('timespan.backupRetentionDays', 7);
            }
        }),
        init: function init() {
            this._super();
            this.set('backupRetentionDays', this.get('timespan.backupRetentionDays') || 7);
        },
        actions: {
            closeBackupModal: function closeBackupModal() {
                if (this.get('timespan') && this.get('timespan').rollbackAttributes && this.get('timespan.hasDirtyAttirbutes')) {
                    this.get('timespan').rollbackAttributes();
                }
                this.get('closeBackupModal')();
            },
            scheduleBackups: function scheduleBackups() {
                this.set('timespan.backupRetentionDays', parseInt(this.get('backupRetentionDays')));
                this.get('closeBackupModal')();
            },
            takeBackups: function takeBackups() {
                var _this = this;

                this.get('selectedVms').forEach(function (vm) {
                    _this.get('powerManager').backupInstance(vm, parseInt(_this.get('backupRetentionDays')));
                });
                this.get('closeBackupModal')();
            }
        }
    });
});