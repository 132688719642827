define('vmpower/routes/dashboard/backups/volume', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        model: function model(params) {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            return this.store.findRecord('volume', params.volume_id);
        },
        setupController: function setupController(controller, model) {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            this.store.query('snapshot', { limit: 15, volume: model.id }).then(function (snapshots) {
                if (!model.get('snapshots')) {
                    model.set('snapshots', new Ember.A([]));
                }
                snapshots.forEach(function (snapshot) {
                    model.get('snapshots').pushObject(snapshot);
                });
            });
            controller.set('user', user);
            return controller.set('volume', model);
        }
    });
});