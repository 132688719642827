define('vmpower/controllers/dashboard', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['token', 'showPlans', 'awsMarketplaceToken'],
        baseAssetPath: _environment.default.STATIC_ASSET_PATH,
        modals: Ember.inject.service(),
        oneClickVm: Ember.computed.alias('modals.oneClickVm'),
        copyrightYear: function () {
            return moment().year();
        }.property(),
        actions: {
            onCollapse: function onCollapse() {
                if (this.get('collapsed')) {
                    this.set('collapsed', false);
                } else {
                    this.set('collapsed', true);
                }
            }
        }
    });
});