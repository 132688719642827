define('vmpower/components/ax-team-settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Component.extend({
        modals: Ember.inject.service(),
        emberNotificationCenter: Ember.inject.service(),
        permissionChanged: Ember.observer('permissions.@each', function (target) {
            console.log('permission changed!');
        }),
        init: function init() {
            this._super();
            this._renderPermissions();
        },
        _renderPermissions: function _renderPermissions() {
            var permissions = new Ember.Object({
                'virtual-machines': {},
                'vm-groups': {},
                'vm-schedules': {},
                volumes: {},
                snapshots: {},
                'cloud-subscriptions': {},
                'aws-credentials': {},
                'azure-credentials': {},
                'azureasm-credentials': {},
                'gcp-credentials': {},
                timespans: {},
                'savings-records': {},
                invoices: {},
                'weekly-schedules': {},
                teams: _defineProperty({}, this.get('team.id'), {
                    update: false
                }),
                account: {},
                power: {},
                backup: {},
                resize: {}
            });
            this.get('team.permissions').forEach(function (p) {
                var parts = p.split('.');
                if (parts.length === 2) {
                    var permType = parts[0];
                    var permLevel = parts[1];
                    if (!permissions.get(permType)) {
                        return;
                    }
                    permissions.set(permType + '.' + permLevel, true);
                } else if (parts.length === 3) {
                    var _permType = parts[0];
                    var id = parts[1];
                    var _permLevel = parts[2];
                    if (!permissions.get(_permType)) {
                        return;
                    }
                    permissions.set(_permType + '.' + id + '.' + _permLevel, true);
                }
            });
            this.set('permissions', permissions);
        },

        pendingInvites: function () {
            var _this = this;

            var results = new Ember.A([]);
            if (!this.get('invites')) {
                return results;
            }
            this.get('invites').forEach(function (invite) {
                if (invite.get('team.id') === _this.get('team.id') && !invite.get('accepted')) {
                    results.pushObject(invite);
                }
            });
            return results;
        }.property('invites.@each'),
        actions: {
            applyPermissions: function applyPermissions() {
                var _this2 = this;

                var permissionTypes = Object.keys(this.get('permissions'));
                var permissionLevels = ['create', 'read', 'update', 'delete', 'off', 'on', this.get('team.id')];
                var permissions = this.get('permissions');

                var selectedPermissions = [];

                permissionTypes.forEach(function (permType) {
                    var typeSpecificPermissions = permissions[permType];
                    var appliedPermissions = [];
                    permissionLevels.forEach(function (level) {
                        if (typeof typeSpecificPermissions[level] === 'boolean' && typeSpecificPermissions[level]) {
                            return appliedPermissions.push(permType + '.' + level);
                        } else if (_typeof(typeSpecificPermissions[level]) === 'object') {
                            appliedPermissions = appliedPermissions.concat(permissionLevels.map(function (idSpecificLevel) {
                                if (typeSpecificPermissions[level][idSpecificLevel]) {
                                    return permType + '.' + level + '.' + idSpecificLevel;
                                }
                            }).filter(function (p) {
                                return p !== undefined;
                            }));
                        }
                    });
                    appliedPermissions = appliedPermissions.filter(function (p) {
                        return p !== undefined;
                    });
                    selectedPermissions = selectedPermissions.concat(appliedPermissions);
                });

                this.set('team.permissions', new Ember.A(selectedPermissions));
                this.get('emberNotificationCenter').pushNotification({
                    title: 'Updating Team Permissions',
                    description: 'Users in this team will have their permissions updated for this account.'
                }, this.get('team').save().then(function () {
                    return _this2._renderPermissions();
                }).catch(function (err) {
                    _this2.get('team').reload();
                    throw err;
                }));
            },
            addGroup: function addGroup() {
                this.get('modals').openModal('#add-vmgroup-modal');
            },
            inviteUser: function inviteUser() {
                this.get('modals').openModal('#invite-user-modal');
            },
            removeFromTeam: function removeFromTeam(user) {
                var _this3 = this;

                this.get('modals').confirmAction('Remove ' + user.get('firstName') + ' ' + user.get('lastName') + ' from Team?', 'They will no longer be able to access the VM Groups on this team.').then(function () {
                    _this3.get('team.users').removeObject(user);

                    _this3.get('emberNotificationCenter').pushNotification({
                        title: 'Removing User from Team',
                        description: user.get('email') + ' will no longer have access to VM Groups in this team'
                    }, _this3.get('team').save().catch(function (err) {
                        _this3.get('team').reload();
                        throw err;
                    }));
                });
            },
            removeVMGroup: function removeVMGroup(group) {
                var _this4 = this;

                this.get('modals').confirmAction('Remove VM Group \"' + group.get('name') + '\"', 'Team members will no longer have access to the ' + group.get('vms.length') + ' VMs in this group').then(function () {
                    _this4.get('team.vmGroups').removeObject(group);

                    _this4.get('emberNotificationCenter').pushNotification({
                        title: 'Removing VM Group \"' + group.get('name') + '\" from Team',
                        description: 'Users in thei team will no longer have access to VMs in this group'
                    }, _this4.get('team').save().catch(function (err) {
                        _this4.get('team').reload();
                        throw err;
                    }));
                });
            }
        }
    });
});