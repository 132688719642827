define('vmpower/adapters/application', ['exports', 'ember-data/adapters/json-api', 'ember-simple-auth/mixins/data-adapter-mixin', 'vmpower/config/environment'], function (exports, _jsonApi, _dataAdapterMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _jsonApi.default.extend(_dataAdapterMixin.default, {
        authorizer: 'authorizer:application',
        headers: {
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/vnd.api+json'
        },
        host: _environment.default.apiHost,
        accounts: Ember.inject.service(),
        urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
            var url = this._super.apply(this, arguments);
            var query = Ember.get(snapshot, 'adapterOptions.query');
            if (modelName === 'account' || modelName === 'team-invite') {
                return url;
            }
            if (query && this.get('accounts').activeAccountSync()) {
                url += '?' + Ember.$.param(query); // assumes no query params are present already
                url += '&account=' + this.get('accounts').activeAccountSync().id;
            } else if (this.get('accounts').activeAccountSync()) {
                url = url + '?account=' + this.get('accounts').activeAccountSync().id;
            }
            return url;
        },
        urlForQuery: function urlForQuery(query) {
            var url = this._super.apply(this, arguments);
            if (this.get('accounts').activeAccountSync()) {
                query.account = this.get('accounts').activeAccountSync().id;
            }
            return url;
        },
        _appendAccount: function _appendAccount(modelName, url) {
            if (modelName === 'account' || modelName === 'team-invite') {
                return url;
            }
            // all resources require the account parameter
            if (this.get('accounts').activeAccountSync()) {
                url = url + '?account=' + this.get('accounts').activeAccountSync().id;
            }
            return url;
        },
        urlForFindAll: function urlForFindAll(modelName) {
            return this._appendAccount(modelName, this._super.apply(this, arguments));
        },
        urlForUpdateRecord: function urlForUpdateRecord(id, modelName) {
            return this._appendAccount(modelName, this._super.apply(this, arguments));
        },
        urlForCreateRecord: function urlForCreateRecord(id, modelName) {
            return this._appendAccount(modelName, this._super.apply(this, arguments));
        },
        urlForDeleteRecord: function urlForDeleteRecord(id, modelName) {
            return this._appendAccount(modelName, this._super.apply(this, arguments));
        }
    });
});