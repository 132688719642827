define("vmpower/templates/components/ax-volume-list", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col s12 l12 m12 center placeholder-title");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row center icons-title-wrapper");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "material-icons large");
          var el5 = dom.createTextNode("storage");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row center");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createTextNode("Unused Volumes");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("VMPower monitors your cloud account for unused Disk Volumes that are unattached. When we notice unused Volumes costing you money, you'll get an email notification.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 12
              },
              "end": {
                "line": 17,
                "column": 12
              }
            },
            "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h5");
            dom.setAttribute(el1, "class", "white-text");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "material-icons");
            var el3 = dom.createTextNode("storage");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" You have ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" disks which cost you a total of  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("strong");
            var el3 = dom.createTextNode("$");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("/month");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element7, 2, 2);
            morphs[1] = dom.createMorphAt(dom.childAt(element7, [4]), 1, 1);
            return morphs;
          },
          statements: [["content", "unusedVolumes.length", ["loc", [null, [16, 91], [16, 115]]]], ["content", "totalUnusedCost", ["loc", [null, [16, 158], [16, 177]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 18,
                    "column": 16
                  },
                  "end": {
                    "line": 20,
                    "column": 16
                  }
                },
                "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("h5");
                dom.setAttribute(el1, "class", "white-text");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "material-icons");
                var el3 = dom.createTextNode("error_outline");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" unused cost you a total of  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("strong");
                var el3 = dom.createTextNode("$");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("/month");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" (including attached disks)");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element6, 2, 2);
                morphs[1] = dom.createMorphAt(dom.childAt(element6, [4]), 1, 1);
                return morphs;
              },
              statements: [["content", "unusedVolumes.length", ["loc", [null, [19, 92], [19, 116]]]], ["content", "totalUnusedCost", ["loc", [null, [19, 154], [19, 173]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 20,
                    "column": 16
                  },
                  "end": {
                    "line": 22,
                    "column": 16
                  }
                },
                "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("h5");
                dom.setAttribute(el1, "class", "white-text");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "material-icons");
                var el3 = dom.createTextNode("error_outline");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" unused disks cost you a total of  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("strong");
                var el3 = dom.createTextNode("$");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("/month");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element5, 2, 2);
                morphs[1] = dom.createMorphAt(dom.childAt(element5, [4]), 1, 1);
                return morphs;
              },
              statements: [["content", "unusedVolumes.length", ["loc", [null, [21, 92], [21, 116]]]], ["content", "totalUnusedCost", ["loc", [null, [21, 160], [21, 179]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 12
                },
                "end": {
                  "line": 23,
                  "column": 12
                }
              },
              "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "showUnusedAttached", ["loc", [null, [18, 22], [18, 40]]]]], [], 0, 1, ["loc", [null, [18, 16], [22, 23]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 12
                },
                "end": {
                  "line": 25,
                  "column": 12
                }
              },
              "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h5");
              dom.setAttribute(el1, "class", "white-text");
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "material-icons");
              var el3 = dom.createTextNode("check_circle");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" You have no unused disks. Nice!");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 12
              },
              "end": {
                "line": 25,
                "column": 12
              }
            },
            "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "unusedVolumes.length", ["loc", [null, [17, 22], [17, 42]]]]], [], 0, 1, ["loc", [null, [17, 12], [25, 12]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 12
              },
              "end": {
                "line": 28,
                "column": 12
              }
            },
            "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "btn action-button-color");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "material-icons left");
            var el3 = dom.createTextNode("delete");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Delete Disks");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [["element", "action", ["deleteVolumes"], [], ["loc", [null, [27, 19], [27, 45]]]]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 12
              },
              "end": {
                "line": 30,
                "column": 12
              }
            },
            "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "btn action-button-color disabled");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "material-icons left");
            var el3 = dom.createTextNode("delete");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Delete Disks");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child4 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 24
                },
                "end": {
                  "line": 54,
                  "column": 24
                }
              },
              "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "tooltipped");
              dom.setAttribute(el1, "data-position", "right");
              dom.setAttribute(el1, "data-tooltip", "You cannot delete this disk since it is attached.");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "md-check", [], ["name", "", "checked", ["subexpr", "@mut", [["get", "volume.selected", ["loc", [null, [53, 167], [53, 182]]]]], [], []], "disabled", "true"], ["loc", [null, [53, 140], [53, 200]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 24
                },
                "end": {
                  "line": 56,
                  "column": 24
                }
              },
              "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "md-check", [], ["name", "", "checked", ["subexpr", "@mut", [["get", "volume.selected", ["loc", [null, [55, 55], [55, 70]]]]], [], []]], ["loc", [null, [55, 28], [55, 72]]]]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 60,
                    "column": 28
                  },
                  "end": {
                    "line": 64,
                    "column": 28
                  }
                },
                "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "unused tooltipped");
                dom.setAttribute(el1, "data-position", "top");
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element1, 'data-tooltip');
                morphs[1] = dom.createMorphAt(element1, 1, 1);
                return morphs;
              },
              statements: [["attribute", "data-tooltip", ["concat", ["This disk has been unused since ", ["subexpr", "moment-from-now", [["get", "volume.unusedSince", ["loc", [null, [61, 148], [61, 166]]]]], [], ["loc", [null, [61, 130], [61, 168]]]], ", but cannot be deleted since it is attached"]]], ["content", "volume.volumeId", ["loc", [null, [62, 36], [62, 55]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 28
                  },
                  "end": {
                    "line": 68,
                    "column": 28
                  }
                },
                "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "unused tooltipped");
                dom.setAttribute(el1, "data-position", "top");
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element0, 'data-tooltip');
                morphs[1] = dom.createMorphAt(element0, 1, 1);
                return morphs;
              },
              statements: [["attribute", "data-tooltip", ["concat", ["This disk has been unused since ", ["subexpr", "moment-from-now", [["get", "volume.unusedSince", ["loc", [null, [65, 148], [65, 166]]]]], [], ["loc", [null, [65, 130], [65, 168]]]], "."]]], ["content", "volume.volumeId", ["loc", [null, [66, 36], [66, 55]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 24
                },
                "end": {
                  "line": 69,
                  "column": 24
                }
              },
              "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "volume.attached", ["loc", [null, [60, 34], [60, 49]]]]], [], 0, 1, ["loc", [null, [60, 28], [68, 35]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 24
                },
                "end": {
                  "line": 73,
                  "column": 24
                }
              },
              "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["content", "volume.volumeId", ["loc", [null, [71, 32], [71, 51]]]]],
            locals: [],
            templates: []
          };
        }();
        var child4 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 79,
                  "column": 24
                },
                "end": {
                  "line": 81,
                  "column": 24
                }
              },
              "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "moment-from-now", [["get", "volume.unusedSince", ["loc", [null, [80, 46], [80, 64]]]]], [], ["loc", [null, [80, 28], [80, 66]]]]],
            locals: [],
            templates: []
          };
        }();
        var child5 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 24
                },
                "end": {
                  "line": 87,
                  "column": 122
                }
              },
              "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "volume.attachedTo.displayName", ["loc", [null, [87, 89], [87, 122]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 12
              },
              "end": {
                "line": 96,
                "column": 12
              }
            },
            "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("(");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(")\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("strong");
            var el4 = dom.createTextNode("$");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [13]);
            var morphs = new Array(10);
            morphs[0] = dom.createAttrMorph(element2, 'id');
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element2, [7]), 1, 1);
            morphs[5] = dom.createMorphAt(dom.childAt(element2, [9]), 1, 1);
            morphs[6] = dom.createMorphAt(dom.childAt(element2, [11]), 1, 1);
            morphs[7] = dom.createMorphAt(element3, 1, 1);
            morphs[8] = dom.createMorphAt(element3, 3, 3);
            morphs[9] = dom.createMorphAt(dom.childAt(element2, [15, 1]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "id", ["concat", [["get", "volume.id", ["loc", [null, [50, 26], [50, 35]]]]]]], ["block", "if", [["get", "volume.attached", ["loc", [null, [52, 30], [52, 45]]]]], [], 0, 1, ["loc", [null, [52, 24], [56, 31]]]], ["block", "if", [["get", "volume.unused", ["loc", [null, [59, 30], [59, 43]]]]], [], 2, 3, ["loc", [null, [59, 24], [73, 31]]]], ["content", "volume.unused", ["loc", [null, [76, 24], [76, 41]]]], ["block", "if", [["get", "volume.unused", ["loc", [null, [79, 30], [79, 43]]]]], [], 4, null, ["loc", [null, [79, 24], [81, 31]]]], ["content", "volume.provider", ["loc", [null, [84, 24], [84, 43]]]], ["block", "link-to", ["dashboard.virtual-machines.vm", ["get", "volume.attachedTo.id", ["loc", [null, [87, 67], [87, 87]]]]], [], 5, null, ["loc", [null, [87, 24], [87, 134]]]], ["content", "volume.storageType", ["loc", [null, [90, 24], [90, 46]]]], ["content", "volume.diskType", ["loc", [null, [90, 47], [90, 66]]]], ["content", "volume.monthlyCost", ["loc", [null, [93, 33], [93, 55]]]]],
          locals: ["volume"],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 0
            },
            "end": {
              "line": 101,
              "column": 0
            }
          },
          "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row table-wrapper");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row left");
          dom.setAttribute(el2, "style", "max-width: 50%");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col s12");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("table");
          dom.setAttribute(el2, "id", "volume-table");
          dom.setAttribute(el2, "class", "table");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("thead");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tr");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "volume-id");
          var el6 = dom.createTextNode("Volume ID");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "unused");
          var el6 = dom.createTextNode("Unused?");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "unused-since");
          var el6 = dom.createTextNode("Unused Since");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "provider");
          var el6 = dom.createTextNode("Provider");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "attached-to");
          var el6 = dom.createTextNode("Attached To");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "storage-type");
          var el6 = dom.createTextNode("Storage Type");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "monthly-cost");
          var el6 = dom.createTextNode("Monthly Cost");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tbody");
          dom.setAttribute(el3, "id", "volume-body");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [0]);
          var element9 = dom.childAt(element8, [1, 1]);
          var element10 = dom.childAt(element8, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(element9, 1, 1);
          morphs[1] = dom.createMorphAt(element9, 2, 2);
          morphs[2] = dom.createMorphAt(element9, 4, 4);
          morphs[3] = dom.createMorphAt(element9, 6, 6);
          morphs[4] = dom.createMorphAt(dom.childAt(element10, [1, 1, 1]), 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
          morphs[6] = dom.createMorphAt(element8, 5, 5);
          return morphs;
        },
        statements: [["block", "if", [["get", "showAll", ["loc", [null, [15, 18], [15, 25]]]]], [], 0, 1, ["loc", [null, [15, 12], [25, 19]]]], ["block", "if", [["get", "selectedVolumes.length", ["loc", [null, [26, 18], [26, 40]]]]], [], 2, 3, ["loc", [null, [26, 12], [30, 19]]]], ["inline", "md-check", [], ["name", "Show All Disks", "checked", ["subexpr", "@mut", [["get", "showAll", ["loc", [null, [31, 53], [31, 60]]]]], [], []]], ["loc", [null, [31, 12], [31, 62]]]], ["inline", "md-check", [], ["name", "Include Unused Attached Disks", "checked", ["subexpr", "@mut", [["get", "showUnusedAttached", ["loc", [null, [32, 68], [32, 86]]]]], [], []]], ["loc", [null, [32, 12], [32, 88]]]], ["inline", "md-check", [], ["name", "Select", "checked", ["subexpr", "@mut", [["get", "allSelected", ["loc", [null, [38, 53], [38, 64]]]]], [], []]], ["loc", [null, [38, 20], [38, 66]]]], ["block", "each", [["get", "unusedVolumes", ["loc", [null, [49, 20], [49, 33]]]]], ["key", "@index"], 4, null, ["loc", [null, [49, 12], [96, 21]]]], ["inline", "ax-error", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [99, 22], [99, 28]]]]], [], []]], ["loc", [null, [99, 4], [99, 30]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 101,
            "column": 7
          }
        },
        "moduleName": "vmpower/templates/components/ax-volume-list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "is-equal", [["get", "unusedVolumes.length", ["loc", [null, [1, 16], [1, 36]]]], 0], [], ["loc", [null, [1, 6], [1, 39]]]]], [], 0, 1, ["loc", [null, [1, 0], [101, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});