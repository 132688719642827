define('vmpower/components/ax-idle-vms-table', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        modals: Ember.inject.service(),
        powerManager: Ember.inject.service(),
        init: function init() {
            var _this = this;

            this._super();
            Ember.run.scheduleOnce('afterRender', function () {
                var table = Ember.$('#idle-table').DataTable({
                    scrollY: '40vh',
                    bLengthChange: false
                });
                _this.set('table', table);
            });
        },
        allSelectedObserver: Ember.observer('allSelected', function (target) {
            target.get('vms').forEach(function (vm) {
                if (!vm.get('isIdle')) {
                    return;
                }
                if (target.get('allSelected')) {
                    vm.set('idleSelected', true);
                } else {
                    vm.set('idleSelected', false);
                }
            });
        }),
        modifyingVms: false,
        enablePowerOffButton: function () {
            if (this.get('modifyingVms')) {
                return false;
            }
            var enable = false;
            this.get('vms').forEach(function (vm) {
                if (vm.get('idleSelected')) {
                    enable = true;
                }
            });
            return enable;
        }.property('vms.@each.idleSelected', 'modifyingVms'),
        idleVms: Ember.computed.filterBy('vms', 'isIdle', true),
        powerOffVms: Ember.computed.filterBy('vms', 'idleSelected', true),
        selectedIdleCost: function () {
            var sum = 0;
            this.get('powerOffVms').forEach(function (vm) {
                if (isNaN(vm.get('monthlyCost'))) {
                    return;
                }
                sum += vm.get('monthlyCost');
            });
            return sum;
        }.property('powerOffVms.length'),
        totalIdleCost: function () {
            var total = 0;
            this.get('vms').forEach(function (vm) {
                if (isNaN(vm.get('monthlyCost'))) {
                    return;
                }
                if (!vm.get('isIdle')) {
                    return;
                }
                Ember.debug('adding: ' + parseFloat(vm.get('monthlyCost')));
                total += parseFloat(vm.get('monthlyCost'));
            });
            return total.toFixed(2);
        }.property('vms.@each.monthlyCost'),
        actions: {
            showUpgradeModal: function showUpgradeModal() {
                this.get('modals').upgradeModal();
            },
            thumbDownRec: function thumbDownRec(vm) {
                vm.toggleProperty('showDialog', true);
                vm.set('idleFeedback', 'thumbdown');
                vm.set('idleFeedbackText', '');
            },
            thumbUpRec: function thumbUpRec(vm) {
                vm.toggleProperty('showDialog', true);
                vm.set('idleFeedback', 'thumbup');
                vm.set('idleFeedbackText', '');
            },
            submitFeedback: function submitFeedback(vm) {
                var notifications = this.get('emberNotificationCenter');
                notifications.pushNotification({
                    title: 'Submitting VM Recommendation Feedback',
                    description: 'Thanks for helping improve VMPower!'
                }, vm.save());
                vm.set('showDialog', false);
            },
            closeFeedback: function closeFeedback(vm) {
                vm.set('showDialog', false);
                vm.rollbackAttributes();
            },
            powerOffVms: function powerOffVms() {
                var _this2 = this;

                if (!this.get('enablePowerOffButton')) {
                    return;
                }
                this.get('modals').confirmAction('Power off ' + this.get('powerOffVms.length') + ' VMs?', 'Careful! Ensure these VMs are not running any critical apps first. Data on data/OS disks will be safe. Data on ephemeral disks/paritions will be lost. ' + 'This will save approximatley $' + this.get('selectedIdleCost').toFixed(2) + ' per month. VMPower can only do this if you provided write access to your VMs').then(function () {
                    var powerManager = _this2.get('powerManager');
                    _this2.get('powerOffVms').forEach(function (vm) {
                        powerManager.powerOnOffInstance(vm, 'off').then(function () {
                            _this2.get('table').row('#' + vm.id).remove();
                            Ember.$('#' + vm.id).remove();
                        });
                    });
                }).catch(function () {
                    // user said no
                });
            }
        }
    });
});