define("vmpower/components/ax-vmgroup-list-item", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      var _this = this;

      this._super();
      Ember.run.scheduleOnce("afterRender", function () {
        var element = Ember.$("#" + _this.elementId + " .tooltipped");
        element.tooltip({ delay: 0 });
      });
    },
    actions: {
      removeVMGroup: function removeVMGroup() {
        this.get('removeVMGroup')(this.get('group'));
      }
    }
  });
});