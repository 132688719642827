define('vmpower/components/ax-invite-list-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        accounts: Ember.inject.service(),
        modals: Ember.inject.service(),
        actions: {
            cancelInvite: function cancelInvite() {
                var _this = this;

                this.get('modals').confirmAction('Cancel Team Invite?', this.get('invite.firstName') + ' ' + this.get('invite.lastName') + ' will not be able to join the team "' + this.get('invite.teamName') + '"').then(function () {
                    _this.get('emberNotificationCenter').pushNotification({
                        title: 'Canceling Team Invite',
                        description: _this.get('invite.firstName') + ' ' + _this.get('invite.lastName') + ' will not be able to join the team ' + _this.get('team.name')
                    }, _this.get('invite').destroyRecord());
                });
            },
            acceptInvite: function acceptInvite() {
                var _this2 = this;

                this.get('modals').confirmAction('Accept Team Invite?', 'You will be able to access the VMs in the team "' + this.get('invite.teamName') + '"').then(function () {
                    _this2.set('invite.accepted', true);
                    _this2.get('accounts').setPinnedAccount(_this2.get('invite.owner.id'));
                    _this2.get('emberNotificationCenter').pushNotification({
                        title: 'Accepting Team Invite',
                        description: _this2.get('invite.firstName') + ' ' + _this2.get('invite.lastName') + ' will be able to access the VMs in the team ' + _this2.get('invite.teamName')
                    }, _this2.get('invite').save().then(function () {
                        return _this2.get('invite').destroyRecord();
                    }).then(function () {
                        location.reload();
                    }));
                });
            }
        }
    });
});