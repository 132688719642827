define('vmpower/components/ax-confirm-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        modals: Ember.inject.service(),
        title: Ember.computed.alias('modals.confirmTitle'),
        description: Ember.computed.alias('modals.confirmDescription'),
        bullets: Ember.computed.alias('modals.bullets'),
        options: Ember.computed.alias('modals.options'),
        checkOptions: Ember.computed.alias('modals.checkOptions'),
        selectedOption: Ember.computed.alias('modals.selectedOption'),
        inputTextPlaceHolder: Ember.computed.alias('modals.inputTextPlaceHolder'),
        actions: {
            cancel: function cancel() {
                this.get('modals.modalReject')();
                this.get('modals').closeModal('#confirm-modal');
                if (this.get('modals.options')) {
                    this.get('modals.options').clear();
                }
            },
            confirm: function confirm() {
                var result = new Ember.Object({
                    selectedOption: this.get('selectedOption.value') || this.get('selectedOption')
                });
                if (this.get('inputText')) {
                    result.set('inputText', this.get('inputText'));
                }
                if (this.get('checkOptions')) {
                    this.get('checkOptions').forEach(function (o) {
                        result.set(o.name, o.value);
                    });
                }
                this.get('modals.modalConfirm')(result);
                this.get('modals').closeModal('#confirm-modal');
                if (this.get('modals.options.clear')) {
                    this.get('modals.options').clear();
                }
                if (this.get('modals.checkOptions.clear')) {
                    this.get('modals.checkOptions').clear();
                }
            }
        }
    });
});