define('vmpower/routes/actions/vm', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    modals: Ember.inject.service(),
    powerManager: Ember.inject.service(),
    parseJwt: function parseJwt(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
    setupController: function setupController(controller) {
      var _this = this;

      appInsights.trackEvent('route:actions:vm', {
        vmId: controller.get('vm-id'),
        actionName: controller.get('action-name')
      });
      var token = controller.get('token');
      var tokenParsed = this.parseJwt(token);
      controller.set("expiry", new Date(tokenParsed.exp));

      var action = controller.get('action-name');
      var instance = controller.get('vm-id');
      var vm;
      var p;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: 'GET',
          url: _environment.default.apiHost + '/virtual-machines/' + controller.get('vm-id'),
          json: true,
          success: function success(result) {
            vm = result.data.attributes;
            vm.id = result.data.id;
            controller.set('instanceId', vm['instance-id']);
            resolve(vm);
          },
          headers: {
            'x-access-token': controller.get('token')
          },
          contentType: 'application/vnd.api+json'
        }).fail(function (err) {
          controller.set('errors', err.responseJSON.errors);
          reject(err.responseJSON);
        });
      }).then(function () {
        if (!vm['plan-enabled']) {
          _this.get('modals').confirmAction(vm['instance-id'] + ' is not VMPower Enabled :(', 'Enable this VM on the VMPower Dashboard.').then(function () {});
        } else if (action === 'on') {
          controller.set('actionText', 'Powering on');
          // captialize first letter in provider name
          var providerName = vm.provider[0].toUpperCase() + vm.provider.substring(1, vm.provider.length);
          p = _this.get('modals').confirmAction('Power on ' + vm['instance-id'] + '?', 'Compute charges will begin incuring on ' + providerName + ' after this is complete.', ['- Ensure VMPower has write access to this VM to complete this action.', '- This link expires on ' + controller.get("expiry")]).then(function () {
            controller.set('pending', true);
            controller.set('message', 'Powering On VM ' + instance);
            return _this.get('powerManager').powerOnOffInstance(new Ember.Object({
              instanceId: vm['instance-id'],
              id: vm.id
            }), 'on', token);
          }).then(function () {
            controller.set('pending', false);
            controller.set('success', true);
          }).catch(function (err) {
            controller.set('pending', false);
            controller.set('failed', true);
            controller.set('errors', err.responseJSON ? err.responseJSON.errors : err.errors);
          });
        } else if (action === 'off') {
          controller.set('actionText', 'Powering off');
          p = _this.get('modals').confirmAction('Power off ' + vm['instance-id'] + '?', 'Running applications on this VM will stop immediatley.', ['- Ensure VMPower has write access to this VM to complete this action.', '- This link expires on ' + controller.get("expiry")]).then(function () {
            controller.set('pending', true);
            return _this.get('powerManager').powerOnOffInstance(new Ember.Object({
              instanceId: vm['instance-id'],
              id: vm.id
            }), 'off', token);
          }).then(function () {
            controller.set('pending', false);
            controller.set('success', true);
            controller.set('message', 'Powered off VM ' + instance);
          }).catch(function (err) {
            controller.set('pending', false);
            controller.set('failed', true);
            controller.set('errors', err.responseJSON ? err.responseJSON.errors : err.errors);
          });
        } else if (action === 'untag') {
          var id = vm.id;
          delete vm.id;
          vm['is-idle'] = false;
          controller.set('actionText', 'Untagging as Idle');
          p = _this.get('modals').confirmAction('Untag ' + vm['instance-id'] + ' as not idle?', 'Any auto-off timer on this VM will be reset and delay auto-off', ['- VMPower will tag this machine as idle again if it remains inactive.', '- This link expires on ' + controller.get("expiry")]).then(function () {
            return new Ember.RSVP.Promise(function (resolve, reject) {
              Ember.$.ajax({
                type: 'PATCH',
                url: _environment.default.apiHost + '/virtual-machines/' + controller.get('vm-id'),
                json: true,
                data: JSON.stringify({
                  data: {
                    type: 'virtual-machines',
                    id: id,
                    attributes: vm
                  }
                }),
                success: function success() {
                  resolve();
                },
                headers: {
                  'x-access-token': controller.get('token')
                },
                contentType: 'application/vnd.api+json'
              }).fail(function (data) {
                reject(data.responseJSON);
              });
            }).then(function () {
              controller.set('pending', false);
              controller.set('success', true);
            }).catch(function (err) {
              controller.set('failed', true);
              controller.set('errors', err.errors);
            });
          });
        }
      });
    }
  });
});