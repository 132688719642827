define("vmpower/templates/components/ax-idle-vms-table", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 24
              },
              "end": {
                "line": 29,
                "column": 88
              }
            },
            "moduleName": "vmpower/templates/components/ax-idle-vms-table.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "vm.instanceId", ["loc", [null, [29, 71], [29, 88]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 24
              },
              "end": {
                "line": 43,
                "column": 24
              }
            },
            "moduleName": "vmpower/templates/components/ax-idle-vms-table.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "material-icons red-text");
            var el2 = dom.createTextNode("thumb_down");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element8);
            return morphs;
          },
          statements: [["element", "action", ["thumbDownRec", ["get", "vm", ["loc", [null, [42, 55], [42, 57]]]]], [], ["loc", [null, [42, 31], [42, 59]]]]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 24
              },
              "end": {
                "line": 45,
                "column": 24
              }
            },
            "moduleName": "vmpower/templates/components/ax-idle-vms-table.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "material-icons");
            var el2 = dom.createTextNode("thumb_down");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element7);
            return morphs;
          },
          statements: [["element", "action", ["thumbDownRec", ["get", "vm", ["loc", [null, [44, 55], [44, 57]]]]], [], ["loc", [null, [44, 31], [44, 59]]]]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 24
              },
              "end": {
                "line": 48,
                "column": 24
              }
            },
            "moduleName": "vmpower/templates/components/ax-idle-vms-table.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "material-icons thumb-up-icon green-text");
            var el2 = dom.createTextNode("thumb_up");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element6);
            return morphs;
          },
          statements: [["element", "action", ["thumbUpRec", ["get", "vm", ["loc", [null, [47, 53], [47, 55]]]]], [], ["loc", [null, [47, 31], [47, 57]]]]],
          locals: [],
          templates: []
        };
      }();
      var child4 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 24
              },
              "end": {
                "line": 50,
                "column": 24
              }
            },
            "moduleName": "vmpower/templates/components/ax-idle-vms-table.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "material-icons thumb-up-icon");
            var el2 = dom.createTextNode("thumb_up");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element5);
            return morphs;
          },
          statements: [["element", "action", ["thumbUpRec", ["get", "vm", ["loc", [null, [49, 53], [49, 55]]]]], [], ["loc", [null, [49, 31], [49, 57]]]]],
          locals: [],
          templates: []
        };
      }();
      var child5 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 44
                },
                "end": {
                  "line": 59,
                  "column": 44
                }
              },
              "moduleName": "vmpower/templates/components/ax-idle-vms-table.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                Oh no! How can we improve this recommendation?\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 44
                },
                "end": {
                  "line": 61,
                  "column": 44
                }
              },
              "moduleName": "vmpower/templates/components/ax-idle-vms-table.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                                Glad to see you agree! Anything else you'd like us to know?\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 24
              },
              "end": {
                "line": 73,
                "column": 24
              }
            },
            "moduleName": "vmpower/templates/components/ax-idle-vms-table.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "resize");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "resize-card card-panel blog-beacon white-text");
            dom.setAttribute(el2, "style", "top: 50px; right: 0px; height: 100%; width: 300px");
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "card-content");
            var el4 = dom.createTextNode("\n                                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "material-icons beacon-icon");
            var el5 = dom.createTextNode("feedback");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h5");
            var el5 = dom.createElement("a");
            dom.setAttribute(el5, "class", "white-text");
            dom.setAttribute(el5, "target", "_blank");
            var el6 = dom.createElement("strong");
            var el7 = dom.createTextNode("Feedback");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h5");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("                                                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5, "class", "input-field feedback-button col s12");
            var el6 = dom.createTextNode("\n                                                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("a");
            dom.setAttribute(el6, "class", "btn waves-effect waves-light action-button-color submit-button");
            var el7 = dom.createTextNode("Tell us!");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5, "class", "input-field close-button col s12");
            var el6 = dom.createTextNode("\n                                                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("a");
            dom.setAttribute(el6, "class", "btn waves-effect waves-light red submit-button");
            var el7 = dom.createTextNode("Close");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                                        ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1, 1]);
            var element1 = dom.childAt(element0, [2, 0]);
            var element2 = dom.childAt(element0, [4]);
            var element3 = dom.childAt(element2, [5, 1]);
            var element4 = dom.childAt(element2, [7, 1]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element1, 'href');
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            morphs[2] = dom.createMorphAt(element2, 3, 3);
            morphs[3] = dom.createElementMorph(element3);
            morphs[4] = dom.createElementMorph(element4);
            return morphs;
          },
          statements: [["attribute", "href", ["concat", [["get", "posts.firstObject.link", ["loc", [null, [55, 124], [55, 146]]]]]]], ["block", "if", [["subexpr", "is-equal", [["get", "vm.idleFeedback", ["loc", [null, [57, 60], [57, 75]]]], "thumbdown"], [], ["loc", [null, [57, 50], [57, 88]]]]], [], 0, 1, ["loc", [null, [57, 44], [61, 51]]]], ["inline", "input", [], ["class", "white black-text", "type", "text", "value", ["subexpr", "@mut", [["get", "vm.idleFeedbackText", ["loc", [null, [62, 99], [62, 118]]]]], [], []]], ["loc", [null, [62, 48], [62, 120]]]], ["element", "action", ["submitFeedback", ["get", "vm", ["loc", [null, [64, 77], [64, 79]]]]], [], ["loc", [null, [64, 51], [64, 81]]]], ["element", "action", ["closeFeedback", ["get", "vm", ["loc", [null, [67, 76], [67, 78]]]]], [], ["loc", [null, [67, 51], [67, 80]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 12
            },
            "end": {
              "line": 79,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-idle-vms-table.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" vCPUs, ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" GiB, ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" IOps\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "recommendation");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "style", "position:relative;");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("strong");
          var el4 = dom.createTextNode("$");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("/mo");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var element10 = dom.childAt(element9, [9]);
          var element11 = dom.childAt(element9, [11]);
          var element12 = dom.childAt(element9, [13]);
          var morphs = new Array(14);
          morphs[0] = dom.createAttrMorph(element9, 'id');
          morphs[1] = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
          morphs[3] = dom.createMorphAt(dom.childAt(element9, [5]), 1, 1);
          morphs[4] = dom.createMorphAt(dom.childAt(element9, [7]), 1, 1);
          morphs[5] = dom.createMorphAt(element10, 1, 1);
          morphs[6] = dom.createMorphAt(element10, 3, 3);
          morphs[7] = dom.createMorphAt(element10, 5, 5);
          morphs[8] = dom.createAttrMorph(element11, 'data-vm');
          morphs[9] = dom.createMorphAt(element11, 1, 1);
          morphs[10] = dom.createMorphAt(element12, 1, 1);
          morphs[11] = dom.createMorphAt(element12, 2, 2);
          morphs[12] = dom.createMorphAt(element12, 3, 3);
          morphs[13] = dom.createMorphAt(dom.childAt(element9, [15, 1]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "id", ["concat", [["get", "vm.id", ["loc", [null, [21, 26], [21, 31]]]]]]], ["inline", "md-check", [], ["name", "", "checked", ["subexpr", "@mut", [["get", "vm.idleSelected", ["loc", [null, [23, 51], [23, 66]]]]], [], []]], ["loc", [null, [23, 24], [23, 68]]]], ["content", "vm.displayName", ["loc", [null, [26, 24], [26, 42]]]], ["block", "link-to", ["dashboard.virtual-machines.vm", ["get", "vm", ["loc", [null, [29, 67], [29, 69]]]]], [], 0, null, ["loc", [null, [29, 24], [29, 100]]]], ["content", "vm.provider", ["loc", [null, [32, 24], [32, 39]]]], ["content", "vm.cpus", ["loc", [null, [35, 24], [35, 35]]]], ["content", "vm.memoryGiB", ["loc", [null, [35, 43], [35, 59]]]], ["content", "vm.iops", ["loc", [null, [35, 65], [35, 76]]]], ["attribute", "data-vm", ["concat", [["get", "vm.id", ["loc", [null, [37, 58], [37, 63]]]]]]], ["inline", "moment-from-now", [["get", "vm.sinceIdleTime", ["loc", [null, [38, 42], [38, 58]]]]], [], ["loc", [null, [38, 24], [38, 60]]]], ["block", "if", [["subexpr", "is-equal", [["get", "vm.idleFeedback", ["loc", [null, [41, 40], [41, 55]]]], "thumbdown"], [], ["loc", [null, [41, 30], [41, 68]]]]], [], 1, 2, ["loc", [null, [41, 24], [45, 31]]]], ["block", "if", [["subexpr", "is-equal", [["get", "vm.idleFeedback", ["loc", [null, [46, 40], [46, 55]]]], "thumbup"], [], ["loc", [null, [46, 30], [46, 66]]]]], [], 3, 4, ["loc", [null, [46, 24], [50, 31]]]], ["block", "if", [["get", "vm.showDialog", ["loc", [null, [51, 30], [51, 43]]]]], [], 5, null, ["loc", [null, [51, 24], [73, 31]]]], ["content", "vm.monthlyCost", ["loc", [null, [76, 33], [76, 51]]]]],
        locals: ["vm"],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 84,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-idle-vms-table.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row left");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        dom.setAttribute(el2, "class", "white-text");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "material-icons");
        var el4 = dom.createTextNode("error_outline");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("These idle VMs cost you a total of  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("strong");
        var el4 = dom.createTextNode("$");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("/month");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "material-icons left");
        var el4 = dom.createTextNode("power_settings_new");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Power off Idle VMs");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row table-wrapper");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("table");
        dom.setAttribute(el2, "id", "idle-table");
        dom.setAttribute(el2, "class", "table");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("thead");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        dom.setAttribute(el5, "data-field", "select");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        dom.setAttribute(el5, "data-field", "name");
        var el6 = dom.createTextNode("Name");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        dom.setAttribute(el5, "data-field", "instance");
        var el6 = dom.createTextNode("Instance ID");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        dom.setAttribute(el5, "data-field", "provider");
        var el6 = dom.createTextNode("Provider");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        dom.setAttribute(el5, "data-field", "specs");
        var el6 = dom.createTextNode("Specs");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        dom.setAttribute(el5, "data-field", "idle-since");
        var el6 = dom.createTextNode("Idle Since");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        dom.setAttribute(el5, "data-field", "feedback");
        var el6 = dom.createTextNode("Feedback");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        dom.setAttribute(el5, "data-field", "monthly-cost");
        var el6 = dom.createTextNode("Monthly Cost");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tbody");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element13 = dom.childAt(fragment, [0]);
        var element14 = dom.childAt(element13, [3]);
        var element15 = dom.childAt(fragment, [2]);
        var element16 = dom.childAt(element15, [1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element13, [1, 2]), 1, 1);
        morphs[1] = dom.createAttrMorph(element14, 'class');
        morphs[2] = dom.createElementMorph(element14);
        morphs[3] = dom.createMorphAt(dom.childAt(element16, [1, 1, 1]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element16, [3]), 1, 1);
        morphs[5] = dom.createMorphAt(element15, 3, 3);
        return morphs;
      },
      statements: [["content", "totalIdleCost", ["loc", [null, [2, 115], [2, 132]]]], ["attribute", "class", ["concat", ["btn action-button-color ", ["subexpr", "if", [["get", "enablePowerOffButton", ["loc", [null, [3, 68], [3, 88]]]], "", "disabled"], [], ["loc", [null, [3, 63], [3, 104]]]]]]], ["element", "action", ["powerOffVms"], [], ["loc", [null, [3, 7], [3, 31]]]], ["inline", "md-check", [], ["name", "Select", "checked", ["subexpr", "@mut", [["get", "allSelected", ["loc", [null, [9, 73], [9, 84]]]]], [], []]], ["loc", [null, [9, 40], [9, 86]]]], ["block", "each", [["get", "idleVms", ["loc", [null, [20, 20], [20, 27]]]]], ["key", "@index"], 0, null, ["loc", [null, [20, 12], [79, 21]]]], ["inline", "ax-error", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [82, 22], [82, 28]]]]], [], []]], ["loc", [null, [82, 4], [82, 30]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});