define("vmpower/templates/components/ax-invite-list-item", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 8
            },
            "end": {
              "line": 10,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-invite-list-item.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "tooltipped");
          dom.setAttribute(el1, "data-position", "top");
          dom.setAttribute(el1, "data-tooltip", "Cancel Invite");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "btn-floating red");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "material-icons left activator");
          var el4 = dom.createTextNode("delete");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [["element", "action", ["cancelInvite", ["get", "invite", ["loc", [null, [9, 138], [9, 144]]]]], [], ["loc", [null, [9, 114], [9, 146]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 8
            },
            "end": {
              "line": 13,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/components/ax-invite-list-item.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "tooltipped");
          dom.setAttribute(el1, "data-position", "top");
          dom.setAttribute(el1, "data-tooltip", "Accept Invite");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "btn-floating action-button-color");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "material-icons left activator");
          var el4 = dom.createTextNode("done");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["acceptInvite", ["get", "invite", ["loc", [null, [12, 154], [12, 160]]]]], [], ["loc", [null, [12, 130], [12, 162]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-invite-list-item.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("li");
        dom.setAttribute(el1, "class", "collection-item dismissable left");
        dom.setAttribute(el1, "style", "width: 100%; touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "for", "task1");
        dom.setAttribute(el2, "class", "left");
        dom.setAttribute(el2, "style", "text-decoration: none; vm-name");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "tooltipped");
        dom.setAttribute(el3, "data-position", "top");
        dom.setAttribute(el3, "data-tooltip", "Sent by you");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "material-icons");
        var el5 = dom.createTextNode("send");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" (");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(")");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "task-cat blue");
        var el4 = dom.createElement("strong");
        var el5 = dom.createTextNode(" Pending Acceptance");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "task-cat green");
        var el4 = dom.createElement("strong");
        var el5 = dom.createTextNode(" Team: ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element2, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element4, 1, 1);
        morphs[1] = dom.createMorphAt(element4, 3, 3);
        morphs[2] = dom.createMorphAt(element4, 5, 5);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [5, 0]), 1, 1);
        morphs[4] = dom.createMorphAt(element5, 1, 1);
        morphs[5] = dom.createMorphAt(element5, 2, 2);
        return morphs;
      },
      statements: [["content", "invite.firstName", ["loc", [null, [3, 114], [3, 134]]]], ["content", "invite.lastName", ["loc", [null, [3, 135], [3, 154]]]], ["content", "invite.email", ["loc", [null, [3, 156], [3, 172]]]], ["inline", "nbsp", [["get", "invite.teamName", ["loc", [null, [5, 69], [5, 84]]]]], [], ["loc", [null, [5, 62], [5, 86]]]], ["block", "if", [["subexpr", "or", [["subexpr", "has-permission", ["team-invites.delete"], [], ["loc", [null, [8, 18], [8, 56]]]], ["subexpr", "has-permission", ["team-invites.{{invite.id}}.delete"], [], ["loc", [null, [8, 57], [8, 109]]]]], [], ["loc", [null, [8, 14], [8, 110]]]]], [], 0, null, ["loc", [null, [8, 8], [10, 15]]]], ["block", "if", [["subexpr", "is-equal", [["get", "invite.invitee.id", ["loc", [null, [11, 24], [11, 41]]]], ["get", "user.id", ["loc", [null, [11, 42], [11, 49]]]]], [], ["loc", [null, [11, 14], [11, 50]]]]], [], 1, null, ["loc", [null, [11, 8], [13, 15]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});