define('vmpower/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        emailConfirmed: _emberData.default.attr('boolean', {
            defaultValue: false
        }),
        affiliateId: _emberData.default.attr('string', {
            defaultValue: ''
        }),
        accountType: _emberData.default.attr('string'),
        source: _emberData.default.attr('string'),
        organization: _emberData.default.attr('string'),
        idToken: _emberData.default.attr('string'),
        code: _emberData.default.attr('string'),
        password: _emberData.default.attr('string'),
        sendInvoices: _emberData.default.attr('boolean', {
            defaultValue: false
        }),
        enableTwoFactor: _emberData.default.attr('boolean', {
            defaultValue: false
        }),
        notificationEmail: _emberData.default.attr('string'),
        notificationEmailConfirmed: _emberData.default.attr('boolean'),
        phoneCountryCode: _emberData.default.attr('number', {
            defaultValue: ''
        }),
        phoneNumber: _emberData.default.attr('number', {
            defaultValue: ''
        }),
        priceCap: _emberData.default.attr('number', {
            defaultValue: 0.139
        }),
        createdAt: _emberData.default.attr('date', {
            defaultValue: new Date(Date.now())
        }),
        slackAuthCode: _emberData.default.attr('string', {
            defaultValue: ''
        }),
        slackToken: _emberData.default.attr('string', {
            defaultValue: ''
        }),
        slackNotifications: _emberData.default.attr('boolean', {
            defaultValue: true
        }),
        emailNotifications: _emberData.default.attr('boolean', {
            defaultValue: true
        }),
        slackChannel: _emberData.default.attr('string', {
            defaultValue: ''
        })
    });
});