define('vmpower/components/ax-resize-table', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        modals: Ember.inject.service(),
        powerManager: Ember.inject.service(),
        resizing: false,
        init: function init() {
            var _this = this;

            this._super();
            Ember.run.scheduleOnce('afterRender', function () {
                var table = Ember.$('#resize-table').DataTable({
                    scrollY: 600,
                    bLengthChange: false
                });
                _this.set('table', table);
            });
        },
        selectedVms: function () {
            var selected = new Ember.A([]);
            this.get('vms').forEach(function (vm) {
                if (vm.get('recommendedResizeTypes.length') && vm.get('resizeSelected')) {
                    selected.push(vm);
                }
            });
            return selected;
        }.property('vms.@each.resizeSelected'),
        enableResizeButton: function () {
            var enable = false;
            this.get('vms').forEach(function (vm) {
                if (vm.get('resizeSelected')) {
                    enable = true;
                }
            });
            return enable && !this.get('resizing');
        }.property('vms.@each.resizeSelected', 'resizing'),
        hasResizeRecs: function () {
            var count = 0;
            this.get('vms').forEach(function (vm) {
                if (vm.get('recommendedResizeTypes.length')) {
                    count += 1;
                }
            });
            return count > 0;
        }.property('vms'),
        totalPossibleSavings: function () {
            var total = 0;
            this.get('vms').forEach(function (vm) {
                var recIsValid = false;
                if (vm.get('recommendedResizeTypes.firstObject') && vm.get('scaleDownDimensions.length') && vm.get('resizeMonthlySavings') > 0 || vm.get('scaleUpDimensions.length') && vm.get('resizeMonthlySavings') < 0) {
                    recIsValid = true;
                }
                if (recIsValid && vm.get('resizeMonthlySavings') && !isNaN(vm.get('resizeMonthlySavings'))) {
                    total += parseFloat(vm.get('resizeMonthlySavings'));
                }
            });
            return total.toFixed(2);
        }.property('vms.@each.resizeMonthlySavings'),
        totalUpscaleCost: function () {
            return this.get('totalPossibleSavings') * -1;
        }.property('totalPossibleSavings'),
        allSelectedObserver: Ember.observer('allSelected', function (target) {
            target.get('vms').forEach(function (vm) {
                if (!vm.get('recommendedResizeTypes.length')) {
                    return;
                }
                vm.set('resizeSelected', target.get('allSelected'));
            });
        }),
        selectedResizeSavings: function () {
            var total = 0;
            this.get('selectedVms').forEach(function (vm) {
                var delta = (vm.get('pricePerHour') - vm.get('selectedResizeType.pricePerHour')) * 720;
                total += delta;
            });
            return total;
        }.property('selectedVms.@each.selectedResizeType.pricePerHour'),
        selectedResizeCost: function () {
            return this.get('selectedResizeSavings') * -1;
        }.property('selectedResizeSavings'),
        recommendedVms: function () {
            var list = new Ember.A([]);
            this.get('vms').forEach(function (vm) {
                if (vm.get('recommendedResizeTypes.length')) {
                    list.pushObject(vm);
                }
            });
            return list;
        }.property('vms'),
        actions: {
            showUpgradeModal: function showUpgradeModal() {
                this.get('modals').upgradeModal();
            },
            showResizeModal: function showResizeModal() {
                if (!this.get('enableResizeButton')) {
                    return;
                }
                this.set('showResizeModal', true);
            },
            closeResizeModal: function closeResizeModal() {
                this.set('showResizeModal', false);
            },
            thumbDownRec: function thumbDownRec(vm) {
                vm.toggleProperty('showDialog', true);
                vm.set('recFeedback', 'thumbdown');
                vm.set('recFeedbackText', '');
            },
            thumbUpRec: function thumbUpRec(vm) {
                vm.toggleProperty('showDialog', true);
                vm.set('recFeedback', 'thumbup');
                vm.set('recFeedbackText', '');
            },
            submitFeedback: function submitFeedback(vm) {
                var notifications = this.get('emberNotificationCenter');
                notifications.pushNotification({
                    title: 'Submitting VM Recommendation Feedback',
                    description: 'Thanks for helping improve VMPower!'
                }, vm.save());
                vm.set('showDialog', false);
            },
            closeFeedback: function closeFeedback(vm) {
                vm.set('showDialog', false);
                vm.rollbackAttributes();
            }
        }
    });
});