define('vmpower/components/ax-vm-list', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var getOwner = Ember.getOwner;
	exports.default = Ember.Component.extend({
		modals: Ember.inject.service(),
		sort: new Ember.Object(),
		localSettings: Ember.inject.service(),
		emberNotificationCenter: Ember.inject.service(),
		supportedProviders: Ember.inject.service(),
		store: Ember.inject.service(),
		updating: false,
		schedules: [],
		hasMoreData: true,
		vmListColums: new Ember.A([100]),
		_refreshGroupTags: function _refreshGroupTags() {
			if (this.get('groups') && this.get('vms')) {
				this.get('vms').forEach(function (vm) {
					vm.set('group', null);
				});
				this.get('groups').forEach(function (group) {
					group.get('vms').forEach(function (vm) {
						vm.set('group', group);
					});
				});
			}
		},
		_deserializeSortObject: function _deserializeSortObject(serializedSort) {
			var parts = serializedSort.split(',');
			var sort = new Ember.Object();
			parts.forEach(function (part) {
				if (part.indexOf('-')) {
					sort.set(part.replace('-', ''), -1);
				} else {
					sort.set(part.replace('-', ''), 1);
				}
			});
			this.set('sort', sort);
		},
		_serializeSortObject: function _serializeSortObject() {
			var _this = this;

			var serialized = '';
			var keyCount = Object.keys(this.get('sort'));
			Object.keys(this.get('sort')).forEach(function (key, i) {
				if (_this.get('sort.' + key) === undefined) {
					return;
				}
				if (i > 0) {
					serialized += ',';
				}
				if (_this.get('sort.' + key) === 1) {
					serialized += key;
				} else if (_this.get('sort.' + key) === -1) {
					serialized += '-' + key;
				}
				if (i < keyCount - 1) {
					serialized += ',';
				}
			});
			return serialized;
		},
		sortObserver: Ember.observer('sort.instance-name', 'sort.cpus', 'sort.power-state', 'sort.memory-gi-b', 'sort.price-per-hour', function (target) {
			if (target.get('lastScheduledSearch')) {
				Ember.run.cancel(target.get('lastScheduledSearch'));
			}
			var scheduled = void 0;
			if (target.get('teams.length') && target.get('singleSub')) {
				scheduled = Ember.run.later(function () {
					target.get('hasMoreData', true);
					getOwner(target).lookup('route:dashboard:virtual-machines:cloud-subscription').send('doSortQuery', target._serializeSortObject());
				}, 500);
			} else {
				scheduled = Ember.run.later(function () {
					target.get('hasMoreData', true);
					getOwner(target).lookup('route:dashboard:virtual-machines:team').send('doSortQuery', target._serializeSortObject());
				}, 500);
			}
			target.set('lastScheduledSearch', scheduled);
		}),
		showTabularViewObserver: Ember.observer('showTabularView', function (target) {
			target.get('localSettings').getLocalSettings().then(function (settings) {
				if (!settings) {
					return;
				}
				// save sidebar state into local storage
				settings.set('showCompactVms', target.get('showTabularView'));
				return settings.save();
			});
		}),
		init: function init() {
			var _this2 = this;

			this._super();
			var router = getOwner(this).lookup('route:dashboard:virtual-machines:cloud-subscription');
			var sortSerialized = router.get('controller.sort');
			if (sortSerialized) {
				this.set('sort', this._deserializeSortObject(sortSerialized));
			}
			this.get('localSettings').getLocalSettings().then(function (settings) {
				if (!settings) {
					return;
				}
				_this2.set('showTabularView', settings.get('showCompactVms'));
			});
			this.get('store').query('vm-group', {}).then(function (groups) {
				_this2.set('vmGroups', groups);
			});
			Ember.run.scheduleOnce('afterRender', function () {
				Ember.$('.collapsible').collapsible('close');
				Ember.$('.material-tooltip').remove();
				Ember.$('.tooltipped').tooltip({ delay: 0 });
				// open initial
			});
		},

		searchTerm: null,
		filteredVmObserver: Ember.observer('searchTerm', function () {
			var _this3 = this;

			if (this.get('searchTerm') === null) {
				return;
			}
			if (this.get('lastScheduledSearch')) {
				Ember.run.cancel(this.get('lastScheduledSearch'));
			}
			var scheduled = Ember.run.later(function () {
				_this3.get('hasMoreData', true);
				getOwner(_this3).lookup('route:dashboard:virtual-machines:cloud-subscription').send('doQuery', _this3.get('searchTerm'));
			}, 500);
			this.set('lastScheduledSearch', scheduled);
		}),
		selectedVms: Ember.computed.filterBy('vms', 'selected', true),
		groupObserver: Ember.observer('groups.@each.vms', 'vms', function () {
			this._refreshGroupTags();
		}),
		actions: {
			openOneClick: function openOneClick(vm) {
				this.get('modals').openOneClickModal(vm);
			},
			selectVm: function selectVm(vm) {
				if (vm.get('selected')) {
					vm.set('selected', false);
				} else {
					vm.set('selected', true);
				}
			},
			scrolled: function scrolled(scrollLeft, scrollTop, lastObject) {
				var _this4 = this;

				var sub = lastObject.item.get('cloudSubscription');
				if (lastObject.index + 5 > this.get('vms.length') && this.get('hasMoreData') && !this.get('loadingVms')) {
					if (this.get('lastScheduledLoad')) {
						Ember.run.cancel(this.get('lastScheduledLoad'));
						this.set('lastScheduledLoad', null);
					}
					this.set('loadingVms', true);

					var query = {
						limit: 15,
						next: this.get('searchTerm') ? Math.trunc(this.get('vms.length') / 15) : lastObject.item.id,
						sort: this._serializeSortObject()
					};
					if (this.get('teams.length') && this.get('singleSub')) {
						// this is a teams view, scroll by team
						query.team = this.get('teams.lastObject.id');
					} else {
						query['cloud-subscription'] = sub.get('id');
					}
					if (this.get('searchTerm')) {
						query['search-query'] = this.get('searchTerm');
						delete query.limit;
					}
					this.get('store').query('virtual-machine', query).then(function (results) {
						results.forEach(function (vm) {
							if (!_this4.get('vms').contains(vm)) {
								_this4.get('vms').pushObject(vm);
							}
						});
						if (results.get('length') === 0) {
							_this4.set('hasMoreData', false);
						}
						_this4.set('loadingVms', false);
					});
				}
			},
			showCreate: function showCreate() {
				this.get('modals').openModal('#vm-create-modal');
			},
			showExportModal: function showExportModal() {
				this.get('modals').openModal('#export-create-modal');
			},
			refresh: function refresh() {
				var _this5 = this;

				var p = this.get('account').store.query('cloud-subscription', {
					reload: 'true',
					'cloud-subscription': this.get('subscriptions.lastObject.id')
				}).then(function () {
					_this5.get('hasMoreData', true);
					getOwner(_this5).lookup('route:dashboard:virtual-machines:cloud-subscription').send('doQuery', _this5.get('searchTerm'));
				});
				this.get('emberNotificationCenter').pushNotification({
					title: 'Updating Virtual Machines',
					description: 'Updating available virtual machines across cloud subscription " ' + this.get('subscriptions.lastObject.name') + '."'
				}, p);
			},
			clearSelectedVms: function clearSelectedVms() {
				this.get('vms').forEach(function (vm) {
					vm.set('selected', false);
				});
			},
			sort: function sort(field) {
				if (this.get('sort.' + field) === 1) {
					this.set('sort.' + field, -1);
				} else if (this.get('sort.' + field) === -1) {
					this.set('sort.' + field, undefined);
				} else if (this.get('sort.' + field) === undefined) {
					this.set('sort.' + field, 1);
				}
			},
			showTabularView: function showTabularView() {
				this.set('sort', new Ember.Object());
			}
		}
	});
});