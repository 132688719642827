define('vmpower/models/phone-validation', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        mobileNumber: _emberData.default.attr('string'),
        countryCode: _emberData.default.attr('string'),
        pending: _emberData.default.attr('boolean'),
        recoveryToken: _emberData.default.attr('string'),
        valid: _emberData.default.attr('boolean')
    });
});