define('vmpower/components/ax-account-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        modals: Ember.inject.service(),
        accountListing: function () {
            var accountListing = [];
            accountListing.push({
                name: 'Create New Organization',
                createPlaceholder: true
            });
            if (this.get('accounts')) {
                this.get('accounts').forEach(function (account) {
                    return accountListing.push({
                        value: account,
                        name: account.get('organization')
                    });
                });
            }
            return accountListing;
        }.property('accounts'),
        init: function init() {
            this._super();
            Ember.TextSupport.reopen({
                attributeBindings: ['data-parsley-required', 'data-parsley-minlength', 'data-parsley-type', 'data-parsley-equalto']
            });
            this.set('targetAccount', this.get('accountListing')[0]);
        },
        actions: {
            closeModal: function closeModal() {
                this.get('modals').closeModal(Ember.$('#account-modal'));
            },
            createAccount: function createAccount() {
                Ember.getOwner(this).lookup('route:dashboard').send('createAccount', this.get('newAccountName'));
            },
            selectAccount: function selectAccount() {
                Ember.getOwner(this).lookup('route:dashboard').send('selectAccount', this.get('targetAccount').value);
            }
        }
    });
});