define('vmpower/helpers/to-fixed', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.toFixed = toFixed;
    function toFixed(params /*, hash*/) {
        if (params.length < 2) {
            return 0;
        }
        if (!params[0]) {
            return 0;
        }
        if (params[0].toFixed) {
            return params[0].toFixed(params[1]);
        }
        return params[0];
    }

    exports.default = Ember.Helper.helper(toFixed);
});