define('vmpower/routes/forgot-password', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        errors: Ember.computed.alias('controller.errors'),
        setupController: function setupController(controller) {
            this.set('controller', controller);
            appInsights.trackEvent('forgot-password', { email: this.get('session.data.authenticated.user.email') });
        },
        actions: {
            sendResetLink: function sendResetLink(email) {
                var _this = this;

                Ember.$.ajax({
                    type: 'POST',
                    url: _environment.default.apiHost + '/reset-password/' + encodeURIComponent(email),
                    success: function success() {
                        _this.set('controller.sent', true);
                    },
                    contentType: 'application/vnd.api+json'
                }).fail(function (err) {
                    if (err && err.errors) {
                        _this.set('controller.errors', err);
                    }
                    Ember.debug(err);
                });
            }
        }
    });
});