define("vmpower/services/modals", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    accounts: Ember.inject.service(),
    confirmAction: function confirmAction(title, description, bullets, options, checkOptions, inputTextPlaceHolder) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.set("modalReject", reject);
        _this.set("modalConfirm", resolve);
        _this.set("confirmTitle", title);
        _this.set("confirmDescription", description);
        _this.set("bullets", bullets);
        _this.set("options", options);
        _this.set("checkOptions", checkOptions);
        _this.set("inputTextPlaceHolder", inputTextPlaceHolder);
        if (options && options.forEach) {
          options.forEach(function (o) {
            if (o.selected) {
              _this.set("selectedOption", o);
            }
          });
        }
        _this.openModal(Ember.$("#confirm-modal"), false);
      });
    },
    openUpsellPopover: function openUpsellPopover(cursorX, cursorY) {
      var _this2 = this;

      this.set("account", this.get("accounts").activeAccountSync());
      this.set("upsellPopoverX", cursorX);
      this.set("upsellPopoverY", cursorY);
      this.set("showUpsellPopover", true);
      if (this.get("upsellPopoverTimeout")) {
        clearTimeout(this.get("upsellPopoverTimeout"));
        this.set("upsellPopoverTimeout", null);
      }
      Ember.run.scheduleOnce("afterRender", function () {
        var offset = Ember.$("#upsell-popover").mouseenter(function () {
          if (_this2.get("upsellPopoverTimeout")) {
            clearTimeout(_this2.get("upsellPopoverTimeout"));
            _this2.set("upsellPopoverTimeout", null);
          }
        }).mouseleave(function () {
          var timeout = setTimeout(function () {
            _this2.closeUpsellPopover();
          }, 200);
        });
        _this2.set("upsellPopoverTimeout", offset);
      });
    },
    closeUpsellPopover: function closeUpsellPopover(cursorX, cursorY) {
      var _this3 = this;

      var timeout = setTimeout(function () {
        return _this3.set("showUpsellPopover", false);
      });
      this.set("upsellPopoverTimeout", timeout);
    },
    openOneClickModal: function openOneClickModal(vm) {
      this.openModal("#one-click-modal");
      this.set("oneClickVm", vm);
    },
    upgradeModal: function upgradeModal() {
      this.openModal(Ember.$("#upgrade-modal"), false);
    },
    closeUpgradeModal: function closeUpgradeModal() {
      this.closeModal(Ember.$("#upgrade-modal"));
    },
    openModal: function openModal(modal) {
      var dismissible = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      Ember.$(modal).openModal({
        dismissible: dismissible
      });

      // Ugh: https://github.com/Dogfalo/materialize/issues/1532
      var overlay = Ember.$(".lean-overlay");
      overlay.detach();
      Ember.$(".container").after(overlay);
    },
    closeModal: function closeModal(modal) {
      Ember.$(modal).closeModal();
      var overlay = Ember.$(".lean-overlay");
      overlay.detach();
    }
  });
});