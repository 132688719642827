define('vmpower/serializers/volume', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONAPISerializer.extend({
        keyForAttribute: function keyForAttribute(attr, method) {
            if (attr === 'pricePerGiBHour') {
                return 'price-per-gi-b-hour';
            } else {
                return this._super(attr, method);
            }
        }
    });
});