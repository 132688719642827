define('vmpower/components/ax-user-info-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        modals: Ember.inject.service(),
        init: function init() {
            this._super();
            Ember.TextSupport.reopen({
                attributeBindings: ['data-parsley-required', 'data-parsley-minlength', 'data-parsley-type', 'data-parsley-equalto']
            });
        },
        didRender: function didRender() {
            var _this = this;

            // Form Validation
            Ember.$('#user-update-form').parsley().on('form:submit', function () {
                _this.set('user.firstName', _this.get('firstName'));
                _this.set('user.lastName', _this.get('lastName'));
                _this.set('account.organization', _this.get('organization'));
                _this.get('emberNotificationCenter').pushNotification({
                    title: 'Updating your personal information',
                    description: 'This will help personalize your experience on VMPower'
                }, _this.get('user').save().then(function () {
                    return _this.get('account').save();
                }).then(function () {
                    return _this.send('closeModal');
                }));
                return false;
            });
        },

        actions: {
            closeModal: function closeModal() {
                this.get('modals').closeModal(Ember.$('#user-info-modal'));
            }
        }
    });
});