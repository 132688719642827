define('vmpower/helpers/lookup', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.lookup = lookup;
    function lookup(params) {
        if (params[0]) {
            return params[0][params[1]];
        }
        return null;
    }

    exports.default = Ember.Helper.helper(lookup);
});