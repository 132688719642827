define('vmpower/components/ax-vmgroup-overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
    },

    groupSchedules: function () {
      var _this = this;

      var retVal = Ember.A([]);
      if (this.get('groups') && this.get('schedules')) {
        this.get('groups').forEach(function (group) {
          var schedule = _this.get('schedules').find(function (schedule) {
            return schedule.get('vmGroup.id') === group.id;
          });
          if (schedule) {
            group.set('schedule', schedule);
          }
          retVal.push(group);
        });
      }
      return retVal;
    }.property('groups', 'schedules')
  });
});