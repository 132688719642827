define("vmpower/templates/components/ax-notification-center", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 16
            },
            "end": {
              "line": 21,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "preloader-wrapper tiny-spinner active left");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "spinner-layer spinner-green-only");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "circle-clipper left");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "circle");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "gap-patch");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "circle");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "circle-clipper right");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "circle");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Pending Tasks\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["content", "notifications.inProgress", ["loc", [null, [20, 20], [20, 48]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 20
              },
              "end": {
                "line": 26,
                "column": 20
              }
            },
            "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "truncate");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(": ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element4, 1, 1);
            morphs[1] = dom.createMorphAt(element4, 3, 3);
            return morphs;
          },
          statements: [["content", "lastNotification.status", ["loc", [null, [24, 24], [24, 51]]]], ["content", "lastNotification.description", ["loc", [null, [24, 53], [24, 85]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 20
              },
              "end": {
                "line": 28,
                "column": 20
              }
            },
            "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    Notifications\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 16
            },
            "end": {
              "line": 29,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "lastNotification", ["loc", [null, [22, 26], [22, 42]]]]], [], 0, 1, ["loc", [null, [22, 20], [28, 27]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 20
              },
              "end": {
                "line": 52,
                "column": 20
              }
            },
            "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "notification.description", ["loc", [null, [51, 27], [51, 55]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 20
                },
                "end": {
                  "line": 54,
                  "column": 20
                }
              },
              "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "notification.description", ["loc", [null, [53, 27], [53, 55]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 55,
                      "column": 24
                    },
                    "end": {
                      "line": 57,
                      "column": 24
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  var el2 = dom.createElement("strong");
                  dom.setAttribute(el2, "class", "red-text");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode(":");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
                  morphs[1] = dom.createMorphAt(element1, 2, 2);
                  return morphs;
                },
                statements: [["content", "error.code", ["loc", [null, [56, 56], [56, 70]]]], ["content", "error.title", ["loc", [null, [56, 81], [56, 96]]]]],
                locals: ["error"],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 54,
                    "column": 20
                  },
                  "end": {
                    "line": 58,
                    "column": 20
                  }
                },
                "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "each", [["get", "notification.errors", ["loc", [null, [55, 32], [55, 51]]]]], ["key", "@index"], 0, null, ["loc", [null, [55, 24], [57, 33]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 20
                },
                "end": {
                  "line": 58,
                  "column": 20
                }
              },
              "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "is-equal", [["get", "notification.status", ["loc", [null, [54, 40], [54, 59]]]], "Failed"], [], ["loc", [null, [54, 30], [54, 69]]]]], [], 0, null, ["loc", [null, [54, 20], [58, 20]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 20
              },
              "end": {
                "line": 58,
                "column": 20
              }
            },
            "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "is-equal", [["get", "notification.status", ["loc", [null, [52, 40], [52, 59]]]], "Success"], [], ["loc", [null, [52, 30], [52, 70]]]]], [], 0, 1, ["loc", [null, [52, 20], [58, 20]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 12
            },
            "end": {
              "line": 60,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "for", "task1");
          dom.setAttribute(el2, "style", "text-decoration: none; left-align");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("strong");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          dom.setAttribute(el3, "class", "secondary-content");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "ultra-small");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [3, 1]), 0, 0);
          morphs[3] = dom.createMorphAt(element2, 3, 3);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["collection-item ", ["subexpr", "if", [["subexpr", "is-equal", [["get", "notification.status", ["loc", [null, [43, 159], [43, 178]]]], "Pending"], [], ["loc", [null, [43, 149], [43, 189]]]], "glow-pending"], [], ["loc", [null, [43, 144], [43, 206]]]]]]], ["content", "notification.title", ["loc", [null, [45, 32], [45, 54]]]], ["content", "notification.status", ["loc", [null, [47, 54], [47, 77]]]], ["block", "if", [["subexpr", "is-equal", [["get", "notification.status", ["loc", [null, [50, 36], [50, 55]]]], "Pending"], [], ["loc", [null, [50, 26], [50, 66]]]]], [], 0, 1, ["loc", [null, [50, 20], [58, 27]]]]],
        locals: ["notification"],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 12
            },
            "end": {
              "line": 68,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);");
          dom.setAttribute(el1, "class", "collection-item");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "for", "task1");
          dom.setAttribute(el2, "style", "text-decoration: none; center-align");
          var el3 = dom.createTextNode("\n                        Clear All\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["clearAll"], [], ["loc", [null, [62, 20], [62, 41]]]], ["content", "notification.description", ["loc", [null, [66, 23], [66, 51]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 72,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-notification-center.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n        ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n         ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col s12 l12 m12");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "left valign valign-wrapper notification-title");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "material-icons tiny notification-open");
        var el6 = dom.createTextNode("more_vert");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "right valign notification-indicator");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "material-icons indicator");
        var el6 = dom.createTextNode("done");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "right valign notification-indicator");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "material-icons indicator");
        var el6 = dom.createTextNode("error_outline");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n     ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n     ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "collapsible pullout");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "class", "collection");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [1]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element5, [3, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createAttrMorph(element6, 'class');
        morphs[2] = dom.createElementMorph(element6);
        morphs[3] = dom.createMorphAt(dom.childAt(element7, [1]), 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element7, [3]), 2, 2);
        morphs[5] = dom.createMorphAt(dom.childAt(element7, [5]), 2, 2);
        morphs[6] = dom.createMorphAt(element8, 1, 1);
        morphs[7] = dom.createMorphAt(element8, 2, 2);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "isPulloutVisible", ["loc", [null, [1, 19], [1, 35]]]], "notifications-active", "notifications-inactive"], [], ["loc", [null, [1, 14], [1, 85]]]], " notifications"]]], ["attribute", "class", ["concat", ["handle ", ["subexpr", "if", [["get", "notifications.inProgress", ["loc", [null, [2, 59], [2, 83]]]], "green"], [], ["loc", [null, [2, 54], [2, 93]]]], " ", ["subexpr", "unless", [["subexpr", "is-equal", [["get", "lastNotification.status", ["loc", [null, [2, 113], [2, 136]]]], "Failed"], [], ["loc", [null, [2, 103], [2, 146]]]], "action-button-color", "red"], [], ["loc", [null, [2, 94], [2, 176]]]], " white-text valign-wrapper"]]], ["element", "action", ["togglePullout"], [], ["loc", [null, [2, 13], [2, 39]]]], ["block", "if", [["get", "notifications.inProgress", ["loc", [null, [6, 22], [6, 46]]]]], [], 0, 1, ["loc", [null, [6, 16], [29, 23]]]], ["content", "notifications.succeeded", ["loc", [null, [33, 60], [33, 87]]]], ["content", "notifications.failed", ["loc", [null, [36, 69], [36, 93]]]], ["block", "each", [["get", "notifications.notifications", ["loc", [null, [42, 20], [42, 47]]]]], ["key", "@index"], 2, null, ["loc", [null, [42, 12], [60, 21]]]], ["block", "if", [["get", "notifications.notifications.length", ["loc", [null, [61, 18], [61, 52]]]]], [], 3, null, ["loc", [null, [61, 12], [68, 19]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});