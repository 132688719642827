define('vmpower/helpers/ember-notification-is-equal', ['exports', 'ember-notif-hub/helpers/ember-notification-is-equal'], function (exports, _emberNotificationIsEqual) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    Object.defineProperty(exports, 'default', {
        enumerable: true,
        get: function () {
            return _emberNotificationIsEqual.default;
        }
    });
    Object.defineProperty(exports, 'emberNotificationIsEqual', {
        enumerable: true,
        get: function () {
            return _emberNotificationIsEqual.emberNotificationIsEqual;
        }
    });
});