define("vmpower/components/ax-resize-popover", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      if (window.Event) {
        document.captureEvents(Event.MOUSEMOVE);
      }
      // Ember.run.scheduleOnce("afterRender", () => {
      //   Ember.$("#resize-popover").mouseleave(() =>
      //     Ember.$("#resize-popover").hide()
      //   );
      // });
    }
  });
});