define("vmpower/templates/components/ax-cloud-wizard", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 16
              },
              "end": {
                "line": 17,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col s8 offset-s2");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3, "class", "cloud-logo");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element31 = dom.childAt(fragment, [1, 1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element31, 'src');
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["subexpr", "cdn-path", [["get", "currentStep.image", ["loc", [null, [14, 68], [14, 85]]]]], [], ["loc", [null, [14, 57], [14, 87]]]]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 16
              },
              "end": {
                "line": 25,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col s4 offset-s4 input-field modal-input");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element29 = dom.childAt(fragment, [1, 1]);
            var element30 = dom.childAt(element29, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element29, 1, 1);
            morphs[1] = dom.createAttrMorph(element30, 'for');
            morphs[2] = dom.createMorphAt(element30, 0, 0);
            return morphs;
          },
          statements: [["inline", "input", [], ["type", "text", "id", ["subexpr", "@mut", [["get", "button.name", ["loc", [null, [21, 51], [21, 62]]]]], [], []], "value", ["subexpr", "@mut", [["get", "input.value", ["loc", [null, [21, 69], [21, 80]]]]], [], []]], ["loc", [null, [21, 28], [21, 82]]]], ["attribute", "for", ["concat", [["get", "button.name", ["loc", [null, [22, 42], [22, 53]]]]]]], ["content", "input.title", ["loc", [null, [22, 57], [22, 72]]]]],
          locals: ["input"],
          templates: []
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 28
                },
                "end": {
                  "line": 31,
                  "column": 28
                }
              },
              "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center disabled");
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "material-icons left");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element28 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createElementMorph(element28);
              morphs[1] = dom.createMorphAt(dom.childAt(element28, [0]), 0, 0);
              morphs[2] = dom.createMorphAt(element28, 2, 2);
              return morphs;
            },
            statements: [["element", "action", [["get", "button.action", ["loc", [null, [30, 44], [30, 57]]]]], [], ["loc", [null, [30, 35], [30, 59]]]], ["content", "button.icon", ["loc", [null, [30, 161], [30, 176]]]], ["content", "button.title", ["loc", [null, [30, 182], [30, 198]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 28
                  },
                  "end": {
                    "line": 33,
                    "column": 28
                  }
                },
                "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center disabled");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "material-icons left");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element27 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element27);
                morphs[1] = dom.createMorphAt(dom.childAt(element27, [0]), 0, 0);
                morphs[2] = dom.createMorphAt(element27, 2, 2);
                return morphs;
              },
              statements: [["element", "action", [["get", "button.action", ["loc", [null, [32, 44], [32, 57]]]]], [], ["loc", [null, [32, 35], [32, 59]]]], ["content", "button.icon", ["loc", [null, [32, 161], [32, 176]]]], ["content", "button.title", ["loc", [null, [32, 182], [32, 198]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 28
                  },
                  "end": {
                    "line": 35,
                    "column": 28
                  }
                },
                "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "material-icons left");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                            ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element26 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element26);
                morphs[1] = dom.createMorphAt(dom.childAt(element26, [0]), 0, 0);
                morphs[2] = dom.createMorphAt(element26, 2, 2);
                return morphs;
              },
              statements: [["element", "action", [["get", "button.action", ["loc", [null, [34, 44], [34, 57]]]]], [], ["loc", [null, [34, 35], [34, 59]]]], ["content", "button.icon", ["loc", [null, [34, 152], [34, 167]]]], ["content", "button.title", ["loc", [null, [34, 173], [34, 189]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 28
                },
                "end": {
                  "line": 35,
                  "column": 28
                }
              },
              "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "modalSuccess", ["loc", [null, [31, 38], [31, 50]]]]], [], 0, 1, ["loc", [null, [31, 28], [35, 28]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 16
              },
              "end": {
                "line": 38,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col s12 center");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "updating", ["loc", [null, [29, 34], [29, 42]]]]], [], 0, 1, ["loc", [null, [29, 28], [35, 35]]]]],
          locals: ["button"],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 12
            },
            "end": {
              "line": 42,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row center");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "currentStep.image", ["loc", [null, [11, 22], [11, 39]]]]], [], 0, null, ["loc", [null, [11, 16], [17, 23]]]], ["block", "each", [["get", "currentStep.inputs", ["loc", [null, [18, 24], [18, 42]]]]], ["key", "@index"], 1, null, ["loc", [null, [18, 16], [25, 25]]]], ["block", "each", [["get", "currentStep.buttons", ["loc", [null, [26, 24], [26, 43]]]]], ["key", "@index"], 2, null, ["loc", [null, [26, 16], [38, 25]]]], ["content", "currentStep.title", ["loc", [null, [40, 20], [40, 41]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 16
                },
                "end": {
                  "line": 49,
                  "column": 16
                }
              },
              "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col s8 offset-s2");
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("img");
              dom.setAttribute(el3, "class", "cloud-logo");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element25 = dom.childAt(fragment, [1, 1, 1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element25, 'src');
              return morphs;
            },
            statements: [["attribute", "src", ["concat", [["subexpr", "cdn-path", [["get", "currentStep.image", ["loc", [null, [46, 68], [46, 85]]]]], [], ["loc", [null, [46, 57], [46, 87]]]]]]]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 16
                },
                "end": {
                  "line": 57,
                  "column": 16
                }
              },
              "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col s4 offset-s4 input-field modal-input");
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element23 = dom.childAt(fragment, [1, 1]);
              var element24 = dom.childAt(element23, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element23, 1, 1);
              morphs[1] = dom.createAttrMorph(element24, 'for');
              morphs[2] = dom.createMorphAt(element24, 0, 0);
              return morphs;
            },
            statements: [["inline", "input", [], ["type", "text", "id", ["subexpr", "@mut", [["get", "button.name", ["loc", [null, [53, 51], [53, 62]]]]], [], []], "value", ["subexpr", "@mut", [["get", "input.value", ["loc", [null, [53, 69], [53, 80]]]]], [], []]], ["loc", [null, [53, 28], [53, 82]]]], ["attribute", "for", ["concat", [["get", "button.name", ["loc", [null, [54, 42], [54, 53]]]]]]], ["content", "input.title", ["loc", [null, [54, 57], [54, 72]]]]],
            locals: ["input"],
            templates: []
          };
        }();
        var child2 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 28
                  },
                  "end": {
                    "line": 63,
                    "column": 28
                  }
                },
                "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center disabled");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "material-icons left");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element22 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element22);
                morphs[1] = dom.createMorphAt(dom.childAt(element22, [0]), 0, 0);
                morphs[2] = dom.createMorphAt(element22, 2, 2);
                return morphs;
              },
              statements: [["element", "action", [["get", "button.action", ["loc", [null, [62, 44], [62, 57]]]]], [], ["loc", [null, [62, 35], [62, 59]]]], ["content", "button.icon", ["loc", [null, [62, 161], [62, 176]]]], ["content", "button.title", ["loc", [null, [62, 182], [62, 198]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 63,
                      "column": 28
                    },
                    "end": {
                      "line": 65,
                      "column": 28
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center disabled");
                  var el2 = dom.createElement("i");
                  dom.setAttribute(el2, "class", "material-icons left");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element21 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element21);
                  morphs[1] = dom.createMorphAt(dom.childAt(element21, [0]), 0, 0);
                  morphs[2] = dom.createMorphAt(element21, 2, 2);
                  return morphs;
                },
                statements: [["element", "action", [["get", "button.action", ["loc", [null, [64, 44], [64, 57]]]]], [], ["loc", [null, [64, 35], [64, 59]]]], ["content", "button.icon", ["loc", [null, [64, 161], [64, 176]]]], ["content", "button.title", ["loc", [null, [64, 182], [64, 198]]]]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 65,
                      "column": 28
                    },
                    "end": {
                      "line": 67,
                      "column": 28
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center");
                  var el2 = dom.createElement("i");
                  dom.setAttribute(el2, "class", "material-icons left");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                            ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element20 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element20);
                  morphs[1] = dom.createMorphAt(dom.childAt(element20, [0]), 0, 0);
                  morphs[2] = dom.createMorphAt(element20, 2, 2);
                  return morphs;
                },
                statements: [["element", "action", [["get", "button.action", ["loc", [null, [66, 44], [66, 57]]]]], [], ["loc", [null, [66, 35], [66, 59]]]], ["content", "button.icon", ["loc", [null, [66, 152], [66, 167]]]], ["content", "button.title", ["loc", [null, [66, 173], [66, 189]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 28
                  },
                  "end": {
                    "line": 67,
                    "column": 28
                  }
                },
                "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "modalSuccess", ["loc", [null, [63, 38], [63, 50]]]]], [], 0, 1, ["loc", [null, [63, 28], [67, 28]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 16
                },
                "end": {
                  "line": 70,
                  "column": 16
                }
              },
              "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col s12 center");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
              return morphs;
            },
            statements: [["block", "if", [["get", "updating", ["loc", [null, [61, 34], [61, 42]]]]], [], 0, 1, ["loc", [null, [61, 28], [67, 35]]]]],
            locals: ["button"],
            templates: [child0, child1]
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 71,
                  "column": 16
                },
                "end": {
                  "line": 81,
                  "column": 16
                }
              },
              "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col s6 offset-s3 input-field modal-input center");
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              dom.setAttribute(el3, "class", "dark-text");
              dom.setAttribute(el3, "for", "aad-domain-textbox");
              var el4 = dom.createTextNode("Enter your Azure subscription domain and click 'Sign in to Azure'");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col s6 offset-s3");
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element19 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element19, [1]), 3, 3);
              morphs[1] = dom.createMorphAt(dom.childAt(element19, [3]), 1, 1);
              return morphs;
            },
            statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "aadDirectory", ["loc", [null, [75, 54], [75, 66]]]]], [], []], "placeholder", "emailmydomain.onmicrosoft.com"], ["loc", [null, [75, 28], [75, 112]]]], ["inline", "ax-azure-signin", [], ["type", "msa", "directory", ["subexpr", "@mut", [["get", "aadDirectory", ["loc", [null, [78, 67], [78, 79]]]]], [], []], "collectDw", ["subexpr", "@mut", [["get", "collectDw", ["loc", [null, [78, 90], [78, 99]]]]], [], []], "account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [78, 108], [78, 115]]]]], [], []]], ["loc", [null, [78, 28], [78, 117]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 12
              },
              "end": {
                "line": 85,
                "column": 12
              }
            },
            "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row center");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[4] = dom.createMorphAt(dom.childAt(fragment, [5]), 1, 1);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "currentStep.image", ["loc", [null, [43, 22], [43, 39]]]]], [], 0, null, ["loc", [null, [43, 16], [49, 23]]]], ["block", "each", [["get", "currentStep.inputs", ["loc", [null, [50, 24], [50, 42]]]]], ["key", "@index"], 1, null, ["loc", [null, [50, 16], [57, 25]]]], ["block", "each", [["get", "currentStep.buttons", ["loc", [null, [58, 24], [58, 43]]]]], ["key", "@index"], 2, null, ["loc", [null, [58, 16], [70, 25]]]], ["block", "if", [["subexpr", "is-equal", [["get", "currentStep.component", ["loc", [null, [71, 32], [71, 53]]]], "ax-azure-signin"], [], ["loc", [null, [71, 22], [71, 72]]]]], [], 3, null, ["loc", [null, [71, 16], [81, 23]]]], ["content", "currentStep.title", ["loc", [null, [83, 20], [83, 41]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 86,
                    "column": 16
                  },
                  "end": {
                    "line": 92,
                    "column": 16
                  }
                },
                "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col s8 offset-s2");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("img");
                dom.setAttribute(el3, "class", "cloud-logo");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element18 = dom.childAt(fragment, [1, 1, 1]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element18, 'src');
                return morphs;
              },
              statements: [["attribute", "src", ["concat", [["subexpr", "cdn-path", [["get", "currentStep.image", ["loc", [null, [89, 68], [89, 85]]]]], [], ["loc", [null, [89, 57], [89, 87]]]]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 93,
                    "column": 16
                  },
                  "end": {
                    "line": 100,
                    "column": 16
                  }
                },
                "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col s4 offset-s4 input-field modal-input");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("label");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element16 = dom.childAt(fragment, [1, 1]);
                var element17 = dom.childAt(element16, [3]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(element16, 1, 1);
                morphs[1] = dom.createAttrMorph(element17, 'for');
                morphs[2] = dom.createMorphAt(element17, 0, 0);
                return morphs;
              },
              statements: [["inline", "input", [], ["type", "text", "id", ["subexpr", "@mut", [["get", "button.name", ["loc", [null, [96, 51], [96, 62]]]]], [], []], "value", ["subexpr", "@mut", [["get", "input.value", ["loc", [null, [96, 69], [96, 80]]]]], [], []]], ["loc", [null, [96, 28], [96, 82]]]], ["attribute", "for", ["concat", [["get", "button.name", ["loc", [null, [97, 42], [97, 53]]]]]]], ["content", "input.title", ["loc", [null, [97, 57], [97, 72]]]]],
              locals: ["input"],
              templates: []
            };
          }();
          var child2 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 104,
                      "column": 28
                    },
                    "end": {
                      "line": 106,
                      "column": 28
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center disabled");
                  var el2 = dom.createElement("i");
                  dom.setAttribute(el2, "class", "material-icons left");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element15 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element15);
                  morphs[1] = dom.createMorphAt(dom.childAt(element15, [0]), 0, 0);
                  morphs[2] = dom.createMorphAt(element15, 2, 2);
                  return morphs;
                },
                statements: [["element", "action", [["get", "button.action", ["loc", [null, [105, 44], [105, 57]]]]], [], ["loc", [null, [105, 35], [105, 59]]]], ["content", "button.icon", ["loc", [null, [105, 161], [105, 176]]]], ["content", "button.title", ["loc", [null, [105, 182], [105, 198]]]]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 106,
                        "column": 28
                      },
                      "end": {
                        "line": 108,
                        "column": 28
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center disabled");
                    var el2 = dom.createElement("i");
                    dom.setAttribute(el2, "class", "material-icons left");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element14 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element14);
                    morphs[1] = dom.createMorphAt(dom.childAt(element14, [0]), 0, 0);
                    morphs[2] = dom.createMorphAt(element14, 2, 2);
                    return morphs;
                  },
                  statements: [["element", "action", [["get", "button.action", ["loc", [null, [107, 44], [107, 57]]]]], [], ["loc", [null, [107, 35], [107, 59]]]], ["content", "button.icon", ["loc", [null, [107, 161], [107, 176]]]], ["content", "button.title", ["loc", [null, [107, 182], [107, 198]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 108,
                        "column": 28
                      },
                      "end": {
                        "line": 110,
                        "column": 28
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center");
                    var el2 = dom.createElement("i");
                    dom.setAttribute(el2, "class", "material-icons left");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                            ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element13 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element13);
                    morphs[1] = dom.createMorphAt(dom.childAt(element13, [0]), 0, 0);
                    morphs[2] = dom.createMorphAt(element13, 2, 2);
                    return morphs;
                  },
                  statements: [["element", "action", [["get", "button.action", ["loc", [null, [109, 44], [109, 57]]]]], [], ["loc", [null, [109, 35], [109, 59]]]], ["content", "button.icon", ["loc", [null, [109, 152], [109, 167]]]], ["content", "button.title", ["loc", [null, [109, 173], [109, 189]]]]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 106,
                      "column": 28
                    },
                    "end": {
                      "line": 110,
                      "column": 28
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "modalSuccess", ["loc", [null, [106, 38], [106, 50]]]]], [], 0, 1, ["loc", [null, [106, 28], [110, 28]]]]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 101,
                    "column": 16
                  },
                  "end": {
                    "line": 113,
                    "column": 16
                  }
                },
                "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col s12 center");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                return morphs;
              },
              statements: [["block", "if", [["get", "updating", ["loc", [null, [104, 34], [104, 42]]]]], [], 0, 1, ["loc", [null, [104, 28], [110, 35]]]]],
              locals: ["button"],
              templates: [child0, child1]
            };
          }();
          var child3 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 114,
                    "column": 16
                  },
                  "end": {
                    "line": 120,
                    "column": 16
                  }
                },
                "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col s6 offset-s3");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                return morphs;
              },
              statements: [["content", "ax-google-signin", ["loc", [null, [117, 28], [117, 48]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 85,
                  "column": 12
                },
                "end": {
                  "line": 124,
                  "column": 12
                }
              },
              "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row center");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[4] = dom.createMorphAt(dom.childAt(fragment, [5]), 1, 1);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "if", [["get", "currentStep.image", ["loc", [null, [86, 22], [86, 39]]]]], [], 0, null, ["loc", [null, [86, 16], [92, 23]]]], ["block", "each", [["get", "currentStep.inputs", ["loc", [null, [93, 24], [93, 42]]]]], ["key", "@index"], 1, null, ["loc", [null, [93, 16], [100, 25]]]], ["block", "each", [["get", "currentStep.buttons", ["loc", [null, [101, 24], [101, 43]]]]], ["key", "@index"], 2, null, ["loc", [null, [101, 16], [113, 25]]]], ["block", "if", [["subexpr", "is-equal", [["get", "currentStep.component", ["loc", [null, [114, 32], [114, 53]]]], "ax-google-signin"], [], ["loc", [null, [114, 22], [114, 73]]]]], [], 3, null, ["loc", [null, [114, 16], [120, 23]]]], ["content", "currentStep.title", ["loc", [null, [122, 20], [122, 41]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 125,
                      "column": 16
                    },
                    "end": {
                      "line": 131,
                      "column": 16
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col s8 offset-s2");
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("img");
                  dom.setAttribute(el3, "class", "cloud-logo");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element10 = dom.childAt(fragment, [1, 1, 1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element10, 'src');
                  return morphs;
                },
                statements: [["attribute", "src", ["concat", [["subexpr", "cdn-path", [["get", "currentStep.image", ["loc", [null, [128, 68], [128, 85]]]]], [], ["loc", [null, [128, 57], [128, 87]]]]]]]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 132,
                      "column": 16
                    },
                    "end": {
                      "line": 139,
                      "column": 16
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col s4 offset-s4 input-field modal-input");
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("label");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element8 = dom.childAt(fragment, [1, 1]);
                  var element9 = dom.childAt(element8, [3]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(element8, 1, 1);
                  morphs[1] = dom.createAttrMorph(element9, 'for');
                  morphs[2] = dom.createMorphAt(element9, 0, 0);
                  return morphs;
                },
                statements: [["inline", "input", [], ["type", "text", "id", ["subexpr", "@mut", [["get", "button.name", ["loc", [null, [135, 51], [135, 62]]]]], [], []], "value", ["subexpr", "@mut", [["get", "input.value", ["loc", [null, [135, 69], [135, 80]]]]], [], []]], ["loc", [null, [135, 28], [135, 82]]]], ["attribute", "for", ["concat", [["get", "button.name", ["loc", [null, [136, 42], [136, 53]]]]]]], ["content", "input.title", ["loc", [null, [136, 57], [136, 72]]]]],
                locals: ["input"],
                templates: []
              };
            }();
            var child2 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 143,
                        "column": 28
                      },
                      "end": {
                        "line": 145,
                        "column": 28
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center disabled");
                    var el2 = dom.createElement("i");
                    dom.setAttribute(el2, "class", "material-icons left");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element7 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createElementMorph(element7);
                    morphs[1] = dom.createMorphAt(dom.childAt(element7, [0]), 0, 0);
                    morphs[2] = dom.createMorphAt(element7, 2, 2);
                    return morphs;
                  },
                  statements: [["element", "action", [["get", "button.action", ["loc", [null, [144, 44], [144, 57]]]]], [], ["loc", [null, [144, 35], [144, 59]]]], ["content", "button.icon", ["loc", [null, [144, 161], [144, 176]]]], ["content", "button.title", ["loc", [null, [144, 182], [144, 198]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 145,
                          "column": 28
                        },
                        "end": {
                          "line": 147,
                          "column": 28
                        }
                      },
                      "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center disabled");
                      var el2 = dom.createElement("i");
                      dom.setAttribute(el2, "class", "material-icons left");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element6 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createElementMorph(element6);
                      morphs[1] = dom.createMorphAt(dom.childAt(element6, [0]), 0, 0);
                      morphs[2] = dom.createMorphAt(element6, 2, 2);
                      return morphs;
                    },
                    statements: [["element", "action", [["get", "button.action", ["loc", [null, [146, 44], [146, 57]]]]], [], ["loc", [null, [146, 35], [146, 59]]]], ["content", "button.icon", ["loc", [null, [146, 161], [146, 176]]]], ["content", "button.title", ["loc", [null, [146, 182], [146, 198]]]]],
                    locals: [],
                    templates: []
                  };
                }();
                var child1 = function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 147,
                          "column": 28
                        },
                        "end": {
                          "line": 149,
                          "column": 28
                        }
                      },
                      "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      dom.setAttribute(el1, "class", "waves-effect blue waves-blue btn modal-action center");
                      var el2 = dom.createElement("i");
                      dom.setAttribute(el2, "class", "material-icons left");
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                            ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element5 = dom.childAt(fragment, [1]);
                      var morphs = new Array(3);
                      morphs[0] = dom.createElementMorph(element5);
                      morphs[1] = dom.createMorphAt(dom.childAt(element5, [0]), 0, 0);
                      morphs[2] = dom.createMorphAt(element5, 2, 2);
                      return morphs;
                    },
                    statements: [["element", "action", [["get", "button.action", ["loc", [null, [148, 44], [148, 57]]]]], [], ["loc", [null, [148, 35], [148, 59]]]], ["content", "button.icon", ["loc", [null, [148, 152], [148, 167]]]], ["content", "button.title", ["loc", [null, [148, 173], [148, 189]]]]],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 145,
                        "column": 28
                      },
                      "end": {
                        "line": 149,
                        "column": 28
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "modalSuccess", ["loc", [null, [145, 38], [145, 50]]]]], [], 0, 1, ["loc", [null, [145, 28], [149, 28]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              }();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 140,
                      "column": 16
                    },
                    "end": {
                      "line": 152,
                      "column": 16
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col s12 center");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "if", [["get", "updating", ["loc", [null, [143, 34], [143, 42]]]]], [], 0, 1, ["loc", [null, [143, 28], [149, 35]]]]],
                locals: ["button"],
                templates: [child0, child1]
              };
            }();
            var child3 = function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 153,
                      "column": 16
                    },
                    "end": {
                      "line": 163,
                      "column": 16
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col s6 offset-s3 input-field modal-input center");
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("h5");
                  dom.setAttribute(el3, "class", "dark-text");
                  dom.setAttribute(el3, "for", "aad-domain-textbox");
                  var el4 = dom.createTextNode("Enter your Azure subscrpition ID and click 'Upload' to upload your .pem certificate file.");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col s6 offset-s3");
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 3, 3);
                  morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "subscriptionId", ["loc", [null, [157, 54], [157, 68]]]]], [], []], "placeholder", "0000-0000-0000-0000"], ["loc", [null, [157, 28], [157, 104]]]], ["inline", "ax-azureasm-upload", [], ["account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [160, 57], [160, 64]]]]], [], []], "subscriptionId", ["subexpr", "@mut", [["get", "subscriptionId", ["loc", [null, [160, 80], [160, 94]]]]], [], []], "directory", ["subexpr", "@mut", [["get", "aadDirectory", ["loc", [null, [160, 105], [160, 117]]]]], [], []], "closeModal", ["subexpr", "action", ["closeModal"], [], ["loc", [null, [160, 129], [160, 150]]]]], ["loc", [null, [160, 28], [160, 152]]]]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 124,
                    "column": 12
                  },
                  "end": {
                    "line": 167,
                    "column": 12
                  }
                },
                "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row center");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "target", "_blank");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element11 = dom.childAt(fragment, [5]);
                var element12 = dom.childAt(element11, [1]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                morphs[4] = dom.createAttrMorph(element12, 'href');
                morphs[5] = dom.createMorphAt(element12, 0, 0);
                morphs[6] = dom.createMorphAt(element11, 2, 2);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "if", [["get", "currentStep.image", ["loc", [null, [125, 22], [125, 39]]]]], [], 0, null, ["loc", [null, [125, 16], [131, 23]]]], ["block", "each", [["get", "currentStep.inputs", ["loc", [null, [132, 24], [132, 42]]]]], ["key", "@index"], 1, null, ["loc", [null, [132, 16], [139, 25]]]], ["block", "each", [["get", "currentStep.buttons", ["loc", [null, [140, 24], [140, 43]]]]], ["key", "@index"], 2, null, ["loc", [null, [140, 16], [152, 25]]]], ["block", "if", [["subexpr", "is-equal", [["get", "currentStep.component", ["loc", [null, [153, 32], [153, 53]]]], "ax-azureasm-upload"], [], ["loc", [null, [153, 22], [153, 75]]]]], [], 3, null, ["loc", [null, [153, 16], [163, 23]]]], ["attribute", "href", ["concat", [["get", "currentStep.link", ["loc", [null, [165, 31], [165, 47]]]]]]], ["content", "currentStep.linkText", ["loc", [null, [165, 67], [165, 91]]]], ["content", "currentStep.title", ["loc", [null, [165, 95], [165, 116]]]]],
              locals: [],
              templates: [child0, child1, child2, child3]
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 167,
                    "column": 12
                  },
                  "end": {
                    "line": 179,
                    "column": 12
                  }
                },
                "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col s12");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "valign-wrapper");
                var el4 = dom.createTextNode("\n                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("i");
                dom.setAttribute(el4, "class", "material-icons large valign");
                var el5 = dom.createTextNode("arrow_upward");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("h5");
                dom.setAttribute(el4, "class", "valign dark-text");
                var el5 = dom.createElement("strong");
                var el6 = dom.createTextNode("Hi there!:");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode(" Click on the dropdown list and follow the instructions for your cloud provider!");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row center");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("iframe");
                dom.setAttribute(el2, "width", "90%");
                dom.setAttribute(el2, "height", "500");
                dom.setAttribute(el2, "src", "https://www.youtube.com/embed/e4MZJUyl1jo");
                dom.setAttribute(el2, "frameborder", "0");
                dom.setAttribute(el2, "allowfullscreen", "");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 124,
                  "column": 12
                },
                "end": {
                  "line": 179,
                  "column": 12
                }
              },
              "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "is-equal", ["azureasm", ["get", "provider.shortName", ["loc", [null, [124, 43], [124, 61]]]]], [], ["loc", [null, [124, 22], [124, 62]]]]], [], 0, 1, ["loc", [null, [124, 12], [179, 12]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 12
              },
              "end": {
                "line": 179,
                "column": 12
              }
            },
            "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "is-equal", ["gcp", ["get", "provider.shortName", ["loc", [null, [85, 38], [85, 56]]]]], [], ["loc", [null, [85, 22], [85, 57]]]]], [], 0, 1, ["loc", [null, [85, 12], [179, 12]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 12
            },
            "end": {
              "line": 179,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "is-equal", ["azure", ["get", "provider.shortName", ["loc", [null, [42, 40], [42, 58]]]]], [], ["loc", [null, [42, 22], [42, 59]]]]], [], 0, 1, ["loc", [null, [42, 12], [179, 12]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 181,
              "column": 12
            },
            "end": {
              "line": 191,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col s12");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "id", "card-alert");
          dom.setAttribute(el3, "class", "card purple lighten-1");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "card-content dark-text");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createElement("i");
          dom.setAttribute(el6, "class", "mdi-action-info");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("strong");
          var el7 = dom.createTextNode("Success!:");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" We successfully imported your cloud subscription!");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 196,
              "column": 12
            },
            "end": {
              "line": 198,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "waves-effect waves-red red btn modal-action modal-close left");
          var el2 = dom.createTextNode(" Close");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [["element", "action", ["closeModal"], [], ["loc", [null, [197, 19], [197, 42]]]]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 198,
              "column": 12
            },
            "end": {
              "line": 200,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "waves-effect waves-red red btn modal-action modal-close left");
          var el2 = dom.createTextNode(" Cancel");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [["element", "action", ["closeModal"], [], ["loc", [null, [199, 19], [199, 42]]]]],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 202,
                "column": 16
              },
              "end": {
                "line": 204,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "waves-effect waves-green btn modal-action right");
            var el2 = dom.createTextNode("Next >");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["nextStep"], [], ["loc", [null, [203, 23], [203, 44]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 204,
                "column": 16
              },
              "end": {
                "line": 206,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "waves-effect waves-green btn modal-action right disabled");
            var el2 = dom.createTextNode("Next >");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 207,
                "column": 16
              },
              "end": {
                "line": 209,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "waves-effect waves-green btn modal-action right");
            var el2 = dom.createTextNode(" < Back");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["previousStep"], [], ["loc", [null, [208, 23], [208, 48]]]]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 209,
                "column": 16
              },
              "end": {
                "line": 211,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "waves-effect waves-green btn modal-action right disabled");
            var el2 = dom.createTextNode(" < Back");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 201,
              "column": 12
            },
            "end": {
              "line": 212,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasNext", ["loc", [null, [202, 22], [202, 29]]]]], [], 0, 1, ["loc", [null, [202, 16], [206, 23]]]], ["block", "if", [["get", "hasPrev", ["loc", [null, [207, 22], [207, 29]]]]], [], 2, 3, ["loc", [null, [207, 16], [211, 23]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 216,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-cloud-wizard.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "cloud-wizard");
        dom.setAttribute(el1, "class", "modal modal-fixed-footer white dark-text");
        dom.setAttribute(el1, "style", "z-index: 1003; opacity: 1; display: block; transform: scaleX(1); top: 10%;");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "modal-content");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col s12 center-align");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        dom.setAttribute(el5, "class", "header dark-text");
        var el6 = dom.createTextNode("Select Your Cloud Provider");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "modal-footer");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element32 = dom.childAt(fragment, [0]);
        var element33 = dom.childAt(element32, [1, 3]);
        var element34 = dom.childAt(element32, [3, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element33, 1, 1);
        morphs[1] = dom.createMorphAt(element33, 3, 3);
        morphs[2] = dom.createMorphAt(element33, 5, 5);
        morphs[3] = dom.createMorphAt(element33, 7, 7);
        morphs[4] = dom.createMorphAt(element34, 1, 1);
        morphs[5] = dom.createMorphAt(element34, 2, 2);
        return morphs;
      },
      statements: [["inline", "ax-select", [], ["content", ["subexpr", "@mut", [["get", "providers", ["loc", [null, [9, 32], [9, 41]]]]], [], []], "valuePath", "shortName", "displayPath", "longName", "name", "range-selector-small", "action", "selectProvider", "target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [9, 146], [9, 150]]]]], [], []], "value", ["subexpr", "@mut", [["get", "provider", ["loc", [null, [9, 157], [9, 165]]]]], [], []], "label", "Cloud Providers"], ["loc", [null, [9, 12], [9, 191]]]], ["block", "if", [["subexpr", "is-equal", ["aws", ["get", "provider.shortName", ["loc", [null, [10, 34], [10, 52]]]]], [], ["loc", [null, [10, 18], [10, 53]]]]], [], 0, 1, ["loc", [null, [10, 12], [179, 19]]]], ["inline", "ax-error", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [180, 30], [180, 36]]]]], [], []]], ["loc", [null, [180, 12], [180, 38]]]], ["block", "if", [["get", "modalSuccess", ["loc", [null, [181, 18], [181, 30]]]]], [], 2, null, ["loc", [null, [181, 12], [191, 19]]]], ["block", "if", [["get", "modalSuccess", ["loc", [null, [196, 18], [196, 30]]]]], [], 3, 4, ["loc", [null, [196, 12], [200, 19]]]], ["block", "if", [["get", "provider.shortName", ["loc", [null, [201, 18], [201, 36]]]]], [], 5, null, ["loc", [null, [201, 12], [212, 19]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }());
});