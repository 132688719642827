define('vmpower/components/ax-schedule-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        session: Ember.inject.service('session'),
        savings: 0 .toFixed(2),
        averageCostPerHour: 0.00,
        scheduledDownTime: 0.00,
        accounts: Ember.inject.service(),
        scheduledUpTime: 0.00,
        modals: Ember.inject.service(),
        monthlyDowntime: 0 .toFixed(2),
        scheduleCalc: Ember.inject.service(),
        init: function init() {
            this._super();
            this._calcMonthlyUptime();
            this.set('account', this.get('accounts').activeAccountSync());
        },
        _calcMonthlyUptime: function _calcMonthlyUptime() {
            var _this = this;

            var totalTime = 0;
            var promises = [];
            moment.weekdays().forEach(function (weekday) {
                if (!_this.get('schedule.weeklySchedule.' + weekday.toLowerCase())) {
                    return;
                }
                var day = weekday.toLowerCase();
                var p = _this.get('schedule.weeklySchedule.' + day).then(function (timespans) {
                    timespans.forEach(function (timespan) {
                        if (timespan.get('endMilis') < timespan.get('startMilis')) {
                            // span spreads days, add 24 hours to end time
                            timespan.set('endMilis', 864000000);
                        }
                        var elapsedTime = timespan.get('endMilis') - timespan.get('startMilis');
                        totalTime += elapsedTime;
                    });
                });
                promises.push(p);
            });
            Ember.RSVP.all(promises).then(function () {
                // 4 weeks per month assuming
                totalTime *= 4;
                // return in hours
                var monthlyActiveHours = totalTime / 3600000;
                if (_this.get('schedule.normally') === 'on') {
                    _this.set('monthlyDowntime', monthlyActiveHours.toFixed(2));
                } else {
                    // during active times, the vm is on
                    // return # of hours in month - monthlyActiveHours
                    _this.set('monthlyDowntime', (720 - monthlyActiveHours).toFixed(2));
                }
            });
        },
        monthlyDowntimeObserver: Ember.observer('schedule.weeklySchedule.sunday.@each', 'schedule.weeklySchedule.monday.@each', 'schedule.weeklySchedule.tuesday.@each', 'schedule.weeklySchedule.wednesday.@each', 'schedule.weeklySchedule.thursday.@each', 'schedule.weeklySchedule.friday.@each', 'schedule.weeklySchedule.saturday.@each', 'schedule.normally', function () {
            this._calcMonthlyUptime();
        }),
        monthlyUptime: function () {
            if (!this.get('monthlyDowntime')) {
                return 0;
            }
            // just return # of hours in momth - monthylDowntime
            return (720 - parseFloat(this.get('monthlyDowntime'))).toFixed(2);
        }.property('monthlyDowntime'),
        actions: {
            deleteSchedule: function deleteSchedule() {
                var _this2 = this;

                var schedule = this.get('schedule');
                this.get('modals').confirmAction('Delete Power Schedule?', 'Delete the Power Schedule "' + this.get('schedule.name') + '"? This will stop any further power changes to the Virtual Machines in the VM Group "' + this.get('schedule.vmGroup.name') + '".').then(function () {
                    schedule.deleteRecord();
                    appInsights.trackEvent('ax-schedule-card:deleteSchedule', {
                        email: _this2.get('session.data.authenticated.user.email'),
                        provider: _this2.get('provider')
                    });
                    _this2.get('emberNotificationCenter').pushNotification({
                        title: 'Deleting Power Schedule',
                        description: 'Deleting Power Schedule "' + _this2.get('schedule.name') + '"'
                    }, schedule.save());
                }).catch(function () {});
            }
        }
    });
});