define('vmpower/components/ax-notification-center', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        isPulloutVisible: false,
        showLastNotification: false,
        lastNotification: Ember.computed.alias('notifications.notifications.firstObject'),
        actions: {
            retryFailedAction: function retryFailedAction(notification) {
                notification.get('retryTarget').send('retryAction', notification.get('retryArgs'));
            },
            togglePullout: function togglePullout() {

                // This hack is required since Chrome isn't smart enough
                // to trigger our animations right away. We're just
                // redrawing those elements.
                this.set('showLastNotification', false);
                this.toggleProperty('isPulloutVisible');
            },
            clearAll: function clearAll() {
                this.get('emberNotificationCenter').clearAll();
            }
        }
    });
});