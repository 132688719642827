define('vmpower/routes/dashboard/backups/volumes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        setupController: function setupController(controller, user) {
            appInsights.trackEvent('route:backups', {
                email: this.get('session.data.authenticated.user.email')
            });
            controller.set('user', user);
            this.store.findAll('vm-group').then(function (groups) {
                controller.set('groups', groups);
            });
            controller.set('router', Ember.getOwner(this).lookup('router:main'));
            var query = { limit: 15 };
            if (controller.get('searchQuery')) {
                query['search-query'] = controller.get('searchQuery');
            }
            return this.store.query('volume', query).then(function (volumes) {
                var holder = new Ember.A([]);
                volumes.forEach(function (volume) {
                    holder.pushObject(volume);
                });
                controller.set('volumes', holder);
            });
        },
        model: function model() {
            var user = this.get('session.data.authenticated.user');
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            var id = this.get('session.data.authenticated.user.id');
            return this.store.find('user', id);
        },
        actions: {
            doQuery: function doQuery(searchTerm) {
                var controller = this.get('controller');
                controller.set('loading', true);
                return this.store.query('volume', {
                    limit: 10,
                    'search-query': searchTerm
                }).then(function (volumes) {
                    var holder = new Ember.A([]);
                    volumes.forEach(function (volume) {
                        holder.pushObject(volume);
                    });
                    controller.set('loading', false);
                    controller.set('volumes', holder);
                });
            }
        }
    });
});