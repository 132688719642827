define('vmpower/models/analytics-stat', ['exports', 'ember-data/model', 'ember-data'], function (exports, _model, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        allTimeSavings: _emberData.default.attr('number', {
            defaultValue: 0
        })
    });
});