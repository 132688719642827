define('vmpower/services/datapoint-calc', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        computeMean: function computeMean(datapoints, property) {
            if (datapoints.get('length') === 0) {
                // prevent div by 0
                return 0 .toFixed(2);
            }
            var average = 0;
            datapoints.forEach(function (dp) {
                average += dp.get(property);
            });
            Ember.debug('datapoint count: ' + datapoints.get('length'));

            average = average / datapoints.get('length');
            return parseFloat(average.toFixed(2));
        },
        computeMaxima: function computeMaxima(datapoints, property) {
            var maxima = 0;
            datapoints.forEach(function (dp) {
                if (dp.get(property) > maxima) {
                    maxima = dp.get(property);
                }
            });
            return parseFloat(maxima.toFixed(2));
        },
        computeMinima: function computeMinima(datapoints, property) {
            var minima = 0;
            datapoints.forEach(function (dp) {
                if (!minima && dp.get(property)) {
                    minima = dp.get(property);
                }
                if (dp.get(property) < minima && dp.get(property)) {
                    minima = dp.get(property);
                }
            });
            return parseFloat(minima.toFixed(2));
        }
    });
});