define('vmpower/router', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Router = Ember.Router.extend({
		location: _environment.default.locationType
	});

	Router.map(function () {
		this.route('signup');
		this.route('signin', {
			path: '/signin'
		});
		this.route('confirm');
		this.route('dashboard', function () {
			this.route('account');
			this.route('home');
			this.route('subscriptions');
			this.route('virtual-machines', function () {
				this.route('vm', { path: '/vm/:virtualMachine_id' });
				this.route('cloud-subscription', { path: '/sub/:cloudSubscription_id' });
				this.route('team', { path: '/team/:team_id' });
			});
			this.route('vm-groups');
			this.route('vm-schedules', {
				path: '/vm-schedules'
			}, function () {
				this.route('edit', {
					path: '/edit/:vm-schedule_id'
				});
				this.route('all');
			});
			this.route('one-click-links');
			this.route('account-details', function () {
				this.route('account', function () {});
				this.route('notifications');
				this.route('billing', function () {
					this.route('invoices');
				});
			});
			this.route('change-password');
			this.route('billing');
			this.route('plans');
			this.route('vm-rightsizing', function () {
				this.route('vm', { path: '/vm/:virtualMachine_id' });
			});
			this.route('idle-vms');
			this.route('historical');
			this.route('savings', function () {
				this.route('charts');
				this.route('logs');
			});
			this.route('auto-off');
			// This route doesn't exist. It was used to advertise a potential feature to turn on VMs lazily as users
			// attempted to SSH into them.
			this.route('ssh-power-on');
			// This route exist but it is hidden because the unused volume feature is not maintained and hidden
			this.route('unused-volumes');
			this.route('teams', function () {
				this.route('team', { path: '/:team_id' });
				this.route('invites');
			});
			this.route('backups', function () {
				this.route('backup', { path: '/:vm_id' });
				this.route('volume', { path: '/volume/:volume_id' });
				this.route('volumes');
				this.route('vms');
			});
			// This isn't used. It used to be a placeholder to advertise a feature where users could see the
			// users of their VMs in one view.
			this.route('vm-users');
			// This isn't used. It used to be a a route where it was planned to display data warehouses a user managed.
			this.route('data-warehouses');
		});
		// We don't actually render a landing page with Ember anymore. This is because at the time
		this.route('landing', {
			// Ember didn't support server-side rendering which is the optimal format for SEO.
			path: '/'
		});
		this.route('signout');
		this.route('notfound');
		this.route('privacy');
		this.route('forgot-password');
		this.route('actions', function () {
			this.route('vm');
			this.route('timespan');
		});
		this.route('unused-volumes');
		// Appsource is a cross-sell path from Microsoft App Source which was a way for users to purchase
		// VMPower through a Microsoft marketplace (but payment isn't facilitated by them). It never got
		// much leads so it hasn't been maintained for quite some time..
		this.route('appsource');
		this.route('termsofservice');
		this.route('terms');
	});

	exports.default = Router;
});