define('vmpower/helpers/api-url', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.dashboardUrl = dashboardUrl;
    function dashboardUrl() {
        return _environment.default.apiHost;
    }

    exports.default = Ember.Helper.helper(dashboardUrl);
});