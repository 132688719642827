define('vmpower/services/random-colors', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        colorArray: new Ember.A([]),
        colors: function () {
            if (this.get('colorArray.length')) {
                return this.get('colorArray');
            }
            for (var i = 0; i < 100; i += 1) {
                this.get('colorArray').push(randomColor({
                    luminosity: 'bright',
                    format: 'hex'
                }));
            }
            return this.get('colorArray');
        }.property()
    });
});