define('vmpower/routes/dashboard/teams/invites', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        accounts: Ember.inject.service(),
        model: function model() {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            return this.store.findAll('teamInvite');
        },
        setupController: function setupController(controller, model) {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            if (this.get('accounts').activeAccountSync()) {
                this.set('account', this.get('accounts').activeAccountSync());
            }
            controller.set('user', user);
            controller.set('invites', model);
        }
    });
});