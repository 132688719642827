define('vmpower/models/invoice', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        date: _emberData.default.attr('date'),
        stripeId: _emberData.default.attr('string'),
        amount: _emberData.default.attr('number'),
        pdfLink: _emberData.default.attr('string'),
        amountDollars: function () {
            return this.get('amount') / 100;
        }.property('amount')
    });
});