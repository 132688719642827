define('vmpower/routes/dashboard/virtual-machines/cloud-subscription', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service(),
		accounts: Ember.inject.service(),
		model: function model(params) {
			var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
			if (!user || !user.id) {
				return this.transitionTo('signin');
			}
			return this.store.findRecord('cloud-subscription', params.cloudSubscription_id);
		},
		setupController: function setupController(controller, model) {
			var loadedCount = 0;
			controller.set('loading', true);
			var account = this.get('accounts').activeAccountSync();
			loadedCount += 1;
			if (loadedCount >= 3) {
				controller.set('loading', false);
			}
			controller.set('account', account);
			controller.set('subscriptions', new Ember.A([model]));
			this.store.findAll('vm-group').then(function (vmGroups) {
				loadedCount += 1;
				if (loadedCount >= 3) {
					controller.set('loading', false);
				}
				controller.set('groups', vmGroups);
			});
			var query = {
				'cloud-subscription': model.id,
				limit: 20,
				'search-query': controller.get('searchQuery'),
				sort: controller.get('sort')
			};
			if (controller.get('searchQuery')) {
				delete query.limit;
			}
			this.store.query('virtual-machine', query).then(function (vms) {
				var holder = new Ember.A([]);
				vms.map(function (vm) {
					return holder.push(vm);
				});
				loadedCount += 1;
				if (loadedCount >= 3) {
					controller.set('loading', false);
				}
				controller.set('vms', holder);
			});
		},

		actions: {
			doQuery: function doQuery(queryTerm) {
				var controller = this.get('controller');
				if (controller.get('searchQuery') === '') {
					controller.set('searchQuery', undefined);
				}
				controller.set('loading', true);
				this.store.query('virtual-machine', {
					'cloud-subscription': controller.get('subscriptions.firstObject.id'),
					'search-query': queryTerm
				}).then(function (vms) {
					controller.set('loading', false);
					var holder = new Ember.A([]);
					vms.map(function (vm) {
						return holder.push(vm);
					});
					controller.set('vms', holder);
				});
			},
			doSortQuery: function doSortQuery(sort) {
				var controller = this.get('controller');
				if (controller.get('searchQuery') === '') {
					controller.set('searchQuery', undefined);
				}
				controller.set('sort', sort);
				this.store.query('virtual-machine', {
					'cloud-subscription': controller.get('subscriptions.firstObject.id'),
					sort: sort,
					'search-query': this.get('controller.searchQuery')
				}).then(function (vms) {
					controller.set('loading', false);
					var holder = new Ember.A([]);
					vms.map(function (vm) {
						return holder.push(vm);
					});
					controller.set('vms', holder);
				});
			}
		}
	});
});