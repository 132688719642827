define('vmpower/routes/privacy', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        setupController: function setupController() {
            appInsights.trackEvent('route:privacy', { email: this.get('session.data.authenticated.user.email') });
        }
    });
});