define('vmpower/models/local-notification', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        // status should be 'succcess' 'pending' failed'
        status: _emberData.default.attr('string'),
        errors: _emberData.default.hasMany('local-error')
    });
});