define('vmpower/components/ax-one-click-links-list-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            goToLink: function goToLink(link) {
                window.open(link, '_blank');
            }
        },
        onLink: Ember.computed('link.token', function () {
            return 'https://app.vmpower.com/actions/vm?action-name=on&vm-id=' + this.get('link.vm') + '&token=' + this.get('link.token');
        }),
        offLink: Ember.computed('link.token', function () {
            return 'https://app.vmpower.com/actions/vm?action-name=off&vm-id=' + this.get('link.vm') + '&token=' + this.get('link.token');
        }),
        untagLink: Ember.computed('link.token', function () {
            return 'https://app.vmpower.com/actions/vm?action-name=untag&vm-id=' + this.get('link.vm') + '&token=' + this.get('link.token');
        })
    });
});