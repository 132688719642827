define('vmpower/helpers/contains', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.contains = contains;
    function contains(params) {
        if (!params[0] || !params[1]) {
            return false;
        }
        return params[0].indexOf(params[1]) > -1;
    }

    exports.default = Ember.Helper.helper(contains);
});