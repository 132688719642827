define('vmpower/routes/signin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        errors: Ember.computed.alias('controller.errors'),
        accounts: Ember.inject.service(),
        model: function model() {
            var user = this.get('session.data.authenticated.user');
            if (user && user.id) {
                return this.store.find('user', user.id);
            }
        },

        setupController: function setupController(controller, user) {
            var _this = this;

            this.set('controller', controller);
            var account = this.get('accounts').activeAccountSync();
            if (controller.get('newUser')) {
                controller.set('newUser', true);
            }
            // hande oauth response from azure
            if (controller.get('id_token') && controller.get('code')) {
                var state = {};
                if (this.get('controller.state')) {
                    try {
                        state = JSON.parse(this.get('controller.state'));
                        this.set('controller.afmc', state.afmc);
                        this.set('controller.source', state.utmSource);
                    } catch (err) {
                        console.error(err);
                    }
                }
                this.set('controller.pending', true);
                return this.get('session').authenticate('authenticator:application', {
                    accountType: 'microsoft',
                    idToken: controller.get('id_token'),
                    source: state.utmSource,
                    affiliate: state.afmc,
                    code: controller.get('code')
                }).then(function () {
                    controller.set('id_token', null);
                    controller.set('code', null);
                    controller.set('pending', false);
                    var user = _this.get('session.data.authenticated.user');
                    _this.set('controller.changedPassword', undefined);
                    appInsights.trackEvent('route:signin:signin', { email: user.email });
                    return _this.transitionTo('dashboard.home');
                });
            }
            if (user && account) {
                if (!user.get('emailConfirmed')) {
                    this.transitionTo('confirm');
                } else {
                    this.router.one('didTransition', function () {
                        return _this.transitionTo('dashboard.home', {
                            queryParams: {
                                awsMarketplaceToken: _this.get('controller.awsMarketplaceToken')
                            }
                        });
                    });
                }
                var action;
                if (window.Intercom.booted) {
                    action = 'update';
                } else {
                    action = 'boot';
                }
                window.Intercom(action, {
                    app_id: 'nl6tgybo',
                    name: user.get('firstName') + ' ' + user.get('lastName'),
                    email: user.get('email'),
                    createdAt: user.get('createdAt'),
                    plan: account.get('plan'),
                    vmCap: account.get('vmCap'),
                    teamCap: account.get('teamCap'),
                    teamMemberCap: account.get('teamMemberCap'),
                    snapshotCap: account.get('snapshotCap'),
                    enableTwoFactor: user.get('enableTwoFactor'),
                    plan_expiring: account.get('planExpiring'),
                    plan_expire_time: account.get('planExpireTime'),
                    trialing: account.get('trialing'),
                    trial_used: account.get('trialUsed'),
                    user_id: user.id
                });
            }
        },
        actions: {
            signin: function signin(creds) {
                var _this2 = this;

                this.get('session').authenticate('authenticator:application', creds).then(function () {
                    var user = _this2.get('session.data.authenticated.user');

                    if (!user.emailConfirmed) {
                        _this2.transitionTo('confirm', {
                            queryParams: {
                                code: _this2.get('controller.code')
                            }
                        });
                        _this2.set('controller.code', undefined);
                        return;
                    }
                    _this2.set('controller.changedPassword', undefined);
                    _this2.set('controller.code', undefined);
                    appInsights.trackEvent('route:signin:signin', { email: user.email });
                    _this2.transitionTo('dashboard.home', {
                        queryParams: {
                            awsMarketplaceToken: _this2.get('controller.awsMarketplaceToken')
                        }
                    });
                }).then(function () {
                    _this2.set('controller.twoFactor', false);
                    _this2.set('controller.showRecovery', false);
                    _this2.set('controller.email', null);
                    _this2.set('controller.password', null);
                    _this2.set('controller.pending', false);
                }).catch(function (err) {
                    _this2.set('controller.pending', false);
                    if (err && err.name && err.name === 'TwoFactorAuthException') {
                        // display two factor auth dialog to user
                        _this2.set('controller.twoFactor', true);
                        _this2.set('controller.email', creds.email);
                        _this2.set('controller.password', creds.password);
                        _this2.set('controller.maskedNumber', err.maskedNumber);
                        return;
                    }
                    if (err && err.errors) {
                        _this2.set('errors', err.errors);
                    }
                    Ember.debug(err);
                });
            }
        }
    });
});