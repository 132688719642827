define('vmpower/components/ax-schedule-designer', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var getOwner = Ember.getOwner;
	exports.default = Ember.Component.extend({
		// the actual rendered events on the calendar
		// they map to Timepsan models but are *not* timespan
		// models. They are for jquery.DataTables
		events: Ember.A(),
		startVisibleTime: Date.now(),
		pusher: Ember.inject.service(),
		store: Ember.inject.service(),
		session: Ember.inject.service(),
		modals: Ember.inject.service(),
		emberNotificationCenter: Ember.inject.service(),
		normally: 'on',
		// the timezones for the timezone select
		environment: function () {
			return _environment.default.environment;
		}.property(),
		timezones: function () {
			var _this = this;

			var timezones = [];
			moment.tz.names().forEach(function (name) {
				timezones.unshift(_this._timezoneForName(name));
			});
			return timezones;
		}.property(),
		unstoppableVms: function () {
			var retVal = Ember.A([]);
			if (!this.get('group') | !this.get('group.vms')) {
				return;
			}
			this.get('group.vms').forEach(function (vm) {
				if (vm.get('unstoppable')) {
					retVal.pushObject(vm);
				}
			});
			return retVal;
		}.property('group.vms.@each.unstoppable'),
		/*
     Observes timezone changes and moves event according to how many hours
     the new timezone offset is compared ot the current
     */
		timezoneObserver: Ember.observer('schedule.timezone', function () {
			var _this2 = this;

			var timezone = moment.tz.zone(this.get('schedule.timezone'));
			this.get('events').forEach(function (event) {
				event.start.tz(timezone.code);
				event.end.tz(timezone.code);
				_this2._updateEvent(event);
			});
		}),
		// used by calendar to show red current time mark
		now: function () {
			Ember.$('#fc-view-calendar').fullCalendar('gotoDate', moment.tz(Date.now(), this.get('schedule.timezone')));
			var retVal = moment.tz(Date.now(), this.get('schedule.timezone'));
			return retVal;
		}.property('schedule.timezone'),
		warningTimes: [{
			name: 'Notify me 30 minutes before',
			seconds: 1800
		}, {
			name: 'Notify me 60 minutes before',
			seconds: 3600
		}, {
			name: 'Do not notify me',
			seconds: 0
		}],
		normallyOnOff: [{
			name: 'During these times...',
			normally: null
		}, {
			name: 'Turn these VMS On',
			normally: 'off'
		}, {
			name: 'Turn these VMs Off',
			normally: 'on'
		}],
		frequencyOptions: [{
			name: 'Run this Schedule...',
			frequency: null
		}, {
			name: 'Every week',
			frequency: 1
		}, {
			name: 'Every 2 weeks',
			frequency: 2
		}, {
			name: 'Every 3 weeks',
			frequency: 3
		}, {
			name: 'Every 4 weeks',
			frequency: 4
		}],
		/*
   * Returns a moment Zone object for a timezone name
   * @param name String - The name of the timezone
   **/
		_timezoneForName: function _timezoneForName(name) {
			var zone = moment.tz.zone(name);
			zone.abbriv = zone.abbr(Date.now());
			zone.offsetHours = parseInt((zone.offset(Date.now()) / 60).toFixed(2)) * -1;
			if (zone.offsetHours > 0) {
				zone.offsetHours = '+' + zone.offsetHours;
			}
			zone.code = name;
			if (zone.set) {
				zone.set('name', zone.get('name') + ' ' + zone.abbriv + ' (' + zone.offsetHours + ' UTC)');
			} else {
				zone.name += ' ' + zone.abbriv + ' (' + zone.offsetHours + ' UTC)';
			}
			return zone;
		},

		_updateCalendarWithModelData: function _updateCalendarWithModelData() {
			var _this3 = this;

			if (!this.get('schedule')) {
				this.set('schedule', this.get('store').createRecord('schedule'));
			}
			this.get('normallyOnOff').forEach(function (onOff) {
				if (onOff.normally === _this3.get('schedule.normally')) {
					_this3.set('normallySelected', onOff);
				}
			});
			this.set('timezoneSelected', moment.tz.zone(this.get('schedule.timezone')));
			this.get('warningTimes').forEach(function (time) {
				if (time.seconds === _this3.get('schedule.warningSecs')) {
					_this3.set('warningTimeSelected', time);
				}
			});
			this.get('frequencyOptions').forEach(function (freq) {
				if (freq.frequency === _this3.get('schedule.frequency')) {
					_this3.set('frequencySelected', freq);
				}
			});
			if (!this.get('weeklySchedule')) {
				this.set('weeklySchedule', this.get('store').createRecord('weeklySchedule', {
					sunday: [],
					monday: [],
					tuesday: [],
					wednesday: [],
					thursday: [],
					friday: [],
					saturday: []
				}));
			}
			var weeklySchedule = this.get('weeklySchedule');
			var promises = [];
			this.get('_dayMap').forEach(function (day, i) {
				var p = weeklySchedule.get(day).then(function (timespans) {
					timespans.forEach(function (timespan) {
						_this3._buildEventForTimespan(timespan, i);
					});
				});
				promises.push(p);
				_this3.get('events').arrayContentDidChange();
			});
			return Ember.RSVP.all(promises);
		},
		_buildEventForTimespan: function _buildEventForTimespan(timespan, day) {
			var absoluteStart = this._computeTimeForDay(timespan.get('startMilis'), day, timespan.get('timespanType') === 'absolute' ? timespan.get('date') : null);
			var absoluteEnd = this._computeTimeForDay(timespan.get('endMilis'), day, timespan.get('timespanType') === 'absolute' ? timespan.get('date') : null);
			if (absoluteEnd < absoluteStart) {
				absoluteEnd.add('day', 1);
			}
			var event = {
				title: 'VM ' + timespan.get('actionType'),
				actionType: timespan.get('actionType'),
				start: absoluteStart,
				color: timespan.get('displayColor'),
				end: absoluteEnd,
				model: timespan,
				id: timespan.id,
				timespanType: timespan.get('timespanType')
			};
			this.get('events').pushObject(event);
		},
		init: function init() {
			var _this4 = this;

			this._super();
			if (window.Event) {
				document.captureEvents(Event.MOUSEMOVE);
			}
			document.onmousemove = function (e) {
				var cursorX = window.Event ? e.pageX : event.clientX + (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft);
				var cursorY = window.Event ? e.pageY : event.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop);
				_this4.set('cursorX', cursorX);
				_this4.set('cursorY', cursorY);
			};
			this.set('showResizePopover', false);
			// clear all the time spans rendered on the calendar
			this.get('events').clear();
			this.set('loading', true);
			if (!this.get('schedule.weeklySchedule.id')) {
				this._updateCalendarWithModelData().finally(function () {
					return _this4.set('loading', false);
				});
				return;
			}
			this.get('schedule').store.find('weeklySchedule', this.get('schedule.weeklySchedule.id')).then(function (weeklySchedule) {
				_this4.set('weeklySchedule', weeklySchedule);
				return _this4._updateCalendarWithModelData();
			}).finally(function () {
				return _this4.set('loading', false);
			});
			Ember.run.scheduleOnce('afterRender', function () {
				Ember.$('#calendar').fullCalendar('option', 'aspectRatio', 1.5);
			});
			// subscribe to timespan updates & deletes
			if (this.get('pusher') && this.get('pusher').on) {
				this.get('pusher').on('update', this);
				this.get('pusher').on('delete', this);
			}
		},
		editable: true,
		selectable: true,
		slotEventOverlap: false,
		selectOverlap: false,
		eventOverlap: false,
		/**
   * @param milis {Number} milis - number of miliseconds into the day.
   */
		_computeTimeForDay: function _computeTimeForDay(milis, day, absoluteDate) {
			// first take the current time, then rewind it to most recent past sunday
			var now = moment.tz(this.get('startVisibleTime'), this.get('schedule.timezone'));
			var nowDST = now.isDST();
			/// 86400000 = 24 hours
			if (milis > 86400000) {
				milis = milis - 86400000;
			} else if (milis < 0) {
				milis = milis + 86400000;
			}

			if (absoluteDate) {
				var date = moment.tz(absoluteDate, this.get('schedule.timezone')).clone();
				date = date.subtract(date.milliseconds(), 'milliseconds');
				date = date.subtract(date.seconds(), 'seconds');
				date = date.subtract(date.minutes(), 'minutes');
				date = date.subtract(date.hours(), 'hours');
				date.add(milis, 'milliseconds');
				return date;
			} else {
				var mostRecentPastSunday = now.subtract(now.days(), 'days');
				mostRecentPastSunday = mostRecentPastSunday.subtract(mostRecentPastSunday.milliseconds(), 'miliseconds');
				mostRecentPastSunday = mostRecentPastSunday.subtract(mostRecentPastSunday.seconds(), 'seconds');
				mostRecentPastSunday = mostRecentPastSunday.subtract(mostRecentPastSunday.minutes(), 'minutes');
				var sundayAtMidnight = mostRecentPastSunday.subtract(mostRecentPastSunday.hours(), 'hours');

				if (nowDST && !sundayAtMidnight.isDST()) {
					// spring forward week
					sundayAtMidnight = sundayAtMidnight.add(1, 'hours');
				} else if (!nowDST && sundayAtMidnight.isDST()) {
					// fall back week
					sundayAtMidnight = sundayAtMidnight.subtract(1, 'hours');
				}

				// now add the number of days, then the number of milis, and return that
				sundayAtMidnight = sundayAtMidnight.add(day, 'days');
				var computedTime = sundayAtMidnight.add(milis, 'milliseconds');
				return computedTime;
			}
		},
		/**
   * Handy map for interating through days of the week
   */
		_dayMap: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
		/**
   * @params m Moment The Moment object
   */
		_computeMilisForDay: function _computeMilisForDay(m) {
			// the utc() function will set moment in utc mode
			// which messes with the display of the timespan
			m = m.clone();
			var result = m.milliseconds() + m.seconds() * 1000 + m.minutes() * 60 * 1000 + m.hours() * 60 * 60 * 1000;
			return result;
		},
		_updateScheduleModel: function _updateScheduleModel() {
			var _this5 = this;

			var weeklySchedule = this.get('weeklySchedule');
			var dayPromises = [];
			this.get('_dayMap').forEach(function (day) {
				dayPromises.push(weeklySchedule.get(day).then(function (timespans) {
					var timespanPromises = [];
					timespans.forEach(function (timespan) {
						if (timespan.get('isDeleted')) {
							var model = _this5.get('schedule').store.peekRecord('timespan', event.id);
							_this5.get('schedule.weeklySchedule.' + day).removeObject(model);
							timespanPromises.push(timespan.save());
						} else if (timespan.get('hasDirtyAttributes')) {
							timespanPromises.push(timespan.save());
						}
					});
					return Ember.RSVP.all(timespanPromises);
				}));
			});

			return Ember.RSVP.all(dayPromises).then(function () {
				return weeklySchedule.save();
			}).then(function () {
				return _this5.get('schedule').save();
			});
		},
		_updateEvent: function _updateEvent(event) {
			var _this6 = this;

			var model = this.get('schedule').store.peekRecord('timespan', event.id);
			this.get('events').forEach(function (e) {
				if (e.id === event.id) {
					e.start = event.start;
					e.end = event.end;
				}
			});
			if (model) {
				// clears execution flag
				model.set('lastExecutionTime', null);
			} else {
				return;
			}
			var weeklySchedule = this.get('weeklySchedule');
			if (weeklySchedule) {
				this.get('_dayMap').forEach(function (day) {
					weeklySchedule.get(day).then(function (timespans) {
						timespans.forEach(function (timespan) {
							if (timespan.id === event.id && _this6.get('_dayMap')[event.start.day()] !== day) {
								// this event has moved days of week...
								_this6.get('schedule.weeklySchedule.' + day).removeObject(model);
								model.set('startMilis', _this6._computeMilisForDay(event.start));
								model.set('endMilis', _this6._computeMilisForDay(event.end));
								_this6.get('schedule.weeklySchedule.' + _this6.get('_dayMap')[event.start.day()]).pushObject(model);
							}
						});
					});
				});
			}
			if (model) {
				model.set('startMilis', this._computeMilisForDay(event.start));
				model.set('endMilis', this._computeMilisForDay(event.end));
			}
			if (this.get('weeklySchedule')) {
				this.get('weeklySchedule').send('becomeDirty');
			}
			this.get('schedule').send('becomeDirty');
		},
		_buildScheduleModel: function _buildScheduleModel(store) {
			var _this7 = this;

			var events = this.get('events');
			var weeklySchedule = this.get('weeklySchedule') ? this.get('weeklySchedule') : store.createRecord('weeklySchedule', {
				sunday: [],
				monday: [],
				tuesday: [],
				wednesday: [],
				thursday: [],
				friday: [],
				saturday: []
			});
			var timespanPromises = [];
			events.forEach(function (event) {
				var dayName = _this7.get('_dayMap')[event.start.day()];
				var timespan;
				if (!event.model) {
					timespan = store.createRecord('timespan', {
						timespanType: event.timespanType,
						actionType: event.actionType,
						startMilis: _this7._computeMilisForDay(event.start),
						endMilis: _this7._computeMilisForDay(event.end),
						resizeActions: event.resizeActions || new Ember.A([])
					});
				} else {
					timespan = event.model;
				}
				timespanPromises.push(timespan.save().then(function () {
					weeklySchedule.get(dayName).pushObject(timespan);
				}));
			});
			return Ember.RSVP.all(timespanPromises).then(function () {
				return weeklySchedule.save();
			}).then(function () {
				_this7.set('schedule.vmGroup', _this7.get('group'));
				_this7.set('schedule.scheduleType', 'weekly');
				_this7.set('schedule.vmGroup', _this7.get('group'));
				_this7.set('schedule.weeklySchedule', weeklySchedule);
				_this7.set('schedule.startTime', new Date(Date.now()));
				return _this7.get('schedule').save().then(function () {
					getOwner(_this7).lookup('route:dashboard.vm-schedules').transitionTo('dashboard.vm-schedules.all');
				});
			});
		},
		_showActionTypeModal: function _showActionTypeModal(event) {
			var _this8 = this;

			var resizeName = 'Resize VMs';
			var backupName = 'Backup VMs (Snapshot)';
			this.get('modals').confirmAction('Select VM Action Type', 'Select the action VMPower should take for this time block. This action will happen at the beginning of this event timespan.', null, [{
				name: 'Power off VMs',
				value: 'POWER OFF',
				selected: event.actionType === 'POWER OFF'
			}, {
				name: 'Power on VMs',
				value: 'POWER ON',
				selected: event.actionType === 'POWER ON'
			}, {
				name: 'Restart VMs',
				value: 'RESTART',
				selected: event.actionType === 'RESTART'
			}, {
				name: resizeName,
				value: 'RESIZE',
				selected: event.actionType === 'POWER RESIZE'
			}, {
				name: backupName,
				value: 'BACKUP',
				selected: event.actionType === 'BACKUP'
			}], [{
				displayName: 'Repeat Weekly',
				name: 'timespanType',
				value: event.timespanType === 'daily'
			}, {
				displayName: 'Skip action for VMs with active RDP/SSH session (must install VMPower agent)',
				name: 'currentUserSessionSkip',
				value: event.currentUserSessionSkip
			}]).then(function (action) {
				var model = _this8.get('schedule').store.peekRecord('timespan', event.id);
				if (action.currentUserSessionSkip && !_this8.get('group.vms').find(function (vm) {
					return vm.get('lastLoginUpdate');
				})) {
					// error because user has not installed agent
					return _this8.get('emberNotificationCenter').pushNotification({
						title: 'Creating VM action',
						description: 'Creating VM action for automation on the VM group "' + _this8.get('group.name') + '"'
					}, Promise.reject({
						errors: [{
							code: 'No VM agents detected',
							title: 'You must install the VMPower agent on at least one VM in the VM group "' + _this8.get('group.name') + '" in order skip VM actions based on currently logged in users.'
						}]
					}));
				}
				if (!model) {
					event.actionType = action.get('selectedOption');
					event.timespanType = action.timespanType ? 'daily' : 'absolute';
					event.currentUserSessionSkip = action.currentUserSessionSkip;
				} else {
					model.set('actionType', action.get('selectedOption'));
					model.set('timespanType', action.timespanType ? 'daily' : 'absolute');
					model.set('timespanType', action.currentUserSessionSkip);
				}
				var actionName = action.get('selectedOption');
				if (!event.model) {
					var timespan = _this8.get('schedule').store.createRecord('timespan', {
						timespanType: action.timespanType ? 'daily' : 'absolute',
						currentUserSessionSkip: action.currentUserSessionSkip,
						actionType: actionName,
						startMilis: _this8._computeMilisForDay(event.start),
						endMilis: _this8._computeMilisForDay(event.end),
						date: event.start.toDate()
					});
					event.model = timespan;
					if (_this8.get('weeklySchedule')) {
						var weeklySchedule = _this8.get('weeklySchedule');
						weeklySchedule.get(_this8.get('_dayMap')[event.start.days()]).pushObject(timespan);
					}
				}
				event.title = 'VM ' + actionName;
				if (actionName === 'POWER ON') {
					event.color = '#33e22d';
				} else if (actionName === 'POWER OFF') {
					event.title = 'VM ' + actionName;
					event.color = 'red';
				} else if (actionName === 'RESTART') {
					event.color = 'blue';
				} else if (actionName === 'RESIZE') {
					appInsights.trackEvent('ax-schedule-designer:createResizeEvent', {
						email: _this8.get('session.data.authenticated.user.email')
					});
					_this8.set('selectedTimespan', model || event.model);
					_this8.set('showResizeModal', true);
					event.color = 'orange';
				} else if (actionName === 'BACKUP') {
					appInsights.trackEvent('ax-schedule-designer:createBackupEvent', {
						email: _this8.get('session.data.authenticated.user.email')
					});
					_this8.set('selectedTimespan', model || event.model);
					_this8.send('showBackupModal');
					event.color = 'purple';
				}
				Ember.$('.full-calendar').fullCalendar('renderEvent', event);
				_this8.get('events').forEach(function (e) {
					if (event.id === e.id) {
						event.actionType = actionName;
						e.actionType = actionName;
						e.title = 'VM ' + actionName;
						event.title = 'VM ' + actionName;
						e.timespanType = action.timespanType ? 'daily' : 'absolute';
						e.currentUserSessionSkip = action.currentUserSessionSkip;
						event.timespanType = action.timespanType ? 'daily' : 'absolute';
						event.currentUserSessionSkip = action.currentUserSessionSkip;
						if (actionName === 'POWER ON') {
							e.color = '#33e22d';
						} else if (actionName === 'POWER OFF') {
							e.color = 'red';
						} else if (actionName === 'RESIZE') {
							e.color = 'orange';
						} else if (actionName === 'BACKUP') {
							e.color = 'purple';
						} else if (actionName === 'RESTART') {
							e.color = 'blue';
						}
					}
					if (event.model) {
						event.model.set('actionType', actionName);
						event.model.set('timespanType', action.timespanType ? 'daily' : 'absolute');
						event.model.set('currentUserSessionSkip', action.currentUserSessionSkip);
					}
				});
				var alreadyOnCalendar = false;
				_this8.get('events').forEach(function (e) {
					if (e.id === event.id) {
						alreadyOnCalendar = true;
					}
				});
				if (!alreadyOnCalendar) {
					_this8.get('events').pushObject(event);
				}
				_this8.get('schedule').send('becomeDirty');
			});
		},

		actions: {
			showResizeModal: function showResizeModal() {
				this.set('showResizeModal', true);
			},

			closeResizeModal: function closeResizeModal() {
				this.set('showResizeModal', false);
			},
			showBackupModal: function showBackupModal() {
				this.set('showBackupModal', true);
			},
			closeBackupModal: function closeBackupModal() {
				this.set('showBackupModal', false);
			},
			select: function select(start, end) {
				var event = {
					title: 'VM POWER OFF',
					id: lil.uuid(),
					start: start,
					end: end,
					editable: true,
					color: 'red',
					actionType: 'POWER OFF',
					timespanType: 'daily',
					currentUserSessionSkip: false
				};
				this._showActionTypeModal(event);
			},
			selectWarningTime: function selectWarningTime(seconds) {
				var _this9 = this;

				this.get('warningTimes').forEach(function (time) {
					if (time.seconds === seconds) {
						_this9.set('warningTimeSelected', time);
						_this9.set('schedule.warningSecs', seconds);
					}
				});
			},
			selectAllow: function selectAllow(droppedLocation) {
				var duration = moment.duration(droppedLocation.end.diff(droppedLocation.start));
				if (duration.asHours() > 1) {
					return false;
				}
				return true;
			},
			eventClick: function eventClick(event) {
				Ember.debug('event click');
				this._showActionTypeModal(event);
			},
			eventRender: function eventRender(event, element) {
				var _this10 = this;

				Ember.debug('Event Render');
				var time = element.find('.fc-time').find('span');
				var closeButton = Ember.$('<span class="right tiny-button"><i class="material-icons cal-close">close</i></span>');
				closeButton.click(function () {
					_this10.send('deleteEvent', event);
					return false;
				});
				closeButton.insertAfter(time);

				if (event.currentUserSessionSkip) {
					var symbol = Ember.$('<span id=timespan-current-user-' + event.id + ' class="right tiny-button round tooltipped" data-tooltip="Skips for VMs that have logged in users" data-position="top"><i class="material-icons cal-close">person</i></span>');
					symbol.insertAfter(closeButton);
					Ember.$('.material-tooltip').remove();
					Ember.$('.tooltipped').tooltip({ delay: 0 });
				}
				if (event.timespanType === 'daily') {
					var _symbol = Ember.$('<span id=timespan-' + event.id + ' class="right tiny-button round tooltipped" data-tooltip="Repeats Weekly" data-position="top"><i class="material-icons cal-close">repeat</i></span>');
					_symbol.insertAfter(closeButton);
					Ember.$('.material-tooltip').remove();
					Ember.$('.tooltipped').tooltip({ delay: 0 });
				} else {
					Ember.$('#timespan-' + event.id).remove();
				}
				if (event.actionType === 'RESIZE') {
					var timeout = void 0;
					var resizeButton = Ember.$('<span class="right tiny-button"><i class="material-icons cal-close">photo_size_select_small</i></span>').mouseenter(function () {
						if (timeout) {
							clearTimeout(timeout);
						}
						var model = _this10.get('schedule').store.peekRecord('timespan', event.id);
						_this10.set('selectedTimespan', model || event.model);
						Ember.$('#resize-popover').show();
						if (!_this10.get('showResizePopover')) {
							var offset = resizeButton.offset();

							if (offset.left / window.innerWidth < 0.8) {
								// render on top right of button
								_this10.set('resizePopoverX', offset.left + 15);
							} else {
								_this10.set('resizePopoverX', offset.left - 297);
							}
							_this10.set('resizePopoverY', offset.top - 7);
						}
						_this10.set('showResizePopover', true);
						Ember.run.scheduleOnce('afterRender', function () {
							Ember.$('#resize-popover').mouseenter(function () {
								if (timeout) {
									clearTimeout(timeout);
								}
							}).mouseleave(function () {
								timeout = setTimeout(function () {
									return _this10.set('showResizePopover', false);
								}, 500);
							});
						});
					}).mouseleave(function () {
						timeout = setTimeout(function () {
							return _this10.set('showResizePopover', false);
						}, 500);
					});
					resizeButton.insertAfter(closeButton);
				}
			},
			deleteEvent: function deleteEvent(event) {
				var _this11 = this;

				this.get('events').forEach(function (e, i) {
					if (event.id === e.id) {
						if (e.model) {
							e.model.deleteRecord();
							_this11.get('_dayMap').forEach(function (day) {
								if (!_this11.get('schedule.weeklySchedule.' + day)) {
									return;
								}
								_this11.get('schedule.weeklySchedule.' + day).forEach(function (timespan) {
									if (timespan.id === event.id) {
										_this11.get('schedule').send('becomeDirty');
									}
								});
							});
						}
						_this11.get('events').splice(i, 1);
						_this11.get('events').arrayContentDidChange(i, 1, 0);
					}
				});
			},
			eventResize: function eventResize(event) {
				var _this12 = this;

				this._updateEvent(event);
				this.get('events').forEach(function (e, i) {
					if (e.id === event.id) {
						_this12.get('events')[i] = event;
					}
				});
			},
			eventDragStart: function eventDragStart(event) {
				// var d = new Date();
				// var offset = d.getTimezoneOffset();
				// event.start.add('minutes', offset);
				Ember.debug(event);
			},
			eventDrop: function eventDrop(event) {
				this._updateEvent(event);
			},
			selectNormallyOnOff: function selectNormallyOnOff(onOff) {
				this.set('normally', onOff);
				this._updateCaledarItemsForPowerSetting();
			},
			createSchedule: function createSchedule() {
				var _this13 = this;

				var store = this.get('store');
				var _createSchedlue = function _createSchedlue() {
					this.get('emberNotificationCenter').pushNotification({
						title: 'Creating New Schedule',
						description: 'Creating new VM Power Schedule for VM Group "' + this.get('group.name') + '"'
					}, this._buildScheduleModel(store));
					appInsights.trackEvent('ax-schedule-designer:createSchedule', {
						email: this.get('session.data.authenticated.user.email'),
						normally: this.get('normally')
					});
				};
				if (!this.get('schedule.enabled')) {
					return this.get('modals').confirmAction('Schedule Not Enabled', "You're creating a new schedule, but didn't enable it. Click OK to continue saving the schedule. Click Cancel to enable it before creating.").then(function () {
						_createSchedlue.call(_this13);
					});
				}
				return _createSchedlue.call(this);
			},
			updateSchedule: function updateSchedule() {
				this.get('emberNotificationCenter').pushNotification({
					title: 'Updating Schedule',
					description: 'Updating VM Schedule "' + this.get('schedule.name') + '"'
				}, this._updateScheduleModel());
			},
			update: function update(e) {
				var _this14 = this;

				if (e.type === 'vm-schedule' && e.id === this.get('schedule.id')) {
					this.get('weeklySchedule').reload().then(function () {
						_this14.get('events').clear();
						Ember.run.scheduleOnce('afterRender', function () {
							_this14._updateCalendarWithModelData();
						});
					});
				}
			},
			delete: function _delete() {},
			viewRender: function viewRender(view) {
				var _this15 = this;

				this.set('startVisibleTime', view.intervalEnd.toDate().getTime());
				if (this.get('firstRender')) {
					Ember.run.scheduleOnce('afterRender', function () {
						_this15.get('events').clear();
						_this15._updateCalendarWithModelData();
					});
				} else {
					this.set('firstRender', true);
				}
			}
		}
	});
});