define('vmpower/components/ax-account-details-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        modals: Ember.inject.service(),
        init: function init() {
            this._super();
            Ember.TextSupport.reopen({
                attributeBindings: ['data-parsley-required', 'data-parsley-minlength', 'data-parsley-type', 'data-parsley-equalto']
            });
            this.initParsley();
        },
        initParsley: function initParsley() {
            var _this = this;

            Ember.run.scheduleOnce('afterRender', function () {
                // Form Validation
                Ember.$('#account-details-form').parsley().on('form:submit', function () {
                    try {
                        Ember.getOwner(_this).lookup('route:dashboard.account-details').send('updateAccountDetails');
                    } catch (e) {
                        Ember.debug(e);
                    } finally {
                        return false;
                    }
                });
            });
        },
        userObserver: Ember.observer('user.hasDirtyAttributes', function () {
            this.initParsley();
        }),
        actions: {
            open2FAModal: function open2FAModal() {
                this.get('modals').openModal(Ember.$('#two-factor-auth-modal'));
            },
            openApiModal: function openApiModal() {
                this.get('modals').openModal(Ember.$('#api-config-modal'));
            }
        }
    });
});