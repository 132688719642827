define('vmpower/components/ax-notifications-form', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        modals: Ember.inject.service(),
        slackLink: function () {
            return _environment.default.SlackAuthUrl;
        }.property(),
        frequencyTimes: [{
            name: 'Notify me every day',
            days: 1
        }, {
            name: 'Notify me every 3 days',
            days: 3
        }, {
            name: 'Notify me every week',
            days: 7
        }, {
            name: 'Notify me every month',
            days: 30
        }],
        init: function init() {
            var _this = this;

            this._super();
            this.get('frequencyTimes').forEach(function (time) {
                if (time.days === _this.get('user.detectionFrequencyDays')) {
                    _this.set('frequencySelected', time);
                }
            });
        },
        actions: {
            removeSlack: function removeSlack() {
                var _this2 = this;

                this.get('modals').confirmAction('Are you sure you want disconnect us from Slack?', 'You won\'t be able to inteact with us and we will be very sad :-(.').then(function () {
                    var notifications = _this2.get('emberNotificationCenter');
                    _this2.set('controller.user.slackAuthCode', '');
                    _this2.set('controller.user.slackToken', '');
                    _this2.set('controller.user.slackChannel', '');
                    notifications.pushNotification({
                        title: 'Removing Slack Integration',
                        description: 'You will no longer be able to interact with us via Slack!'
                    }, _this2.get('controller.user').save());
                });
            }
        }
    });
});