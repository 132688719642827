define('vmpower/routes/confirm', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        errors: Ember.computed.alias('controller.errors'),
        user: Ember.computed.alias('controller.user'),
        confirmed: Ember.computed.alias('controller.confirmed'),
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        model: function model() {
            var sessionUser = this.get('session.data.authenticated.user');
            if (!sessionUser) {
                this.transitionTo('signin');
            }
            return this.get('store').findRecord('user', sessionUser.id);
        },

        setupController: function setupController(controller, user) {
            var _this = this;

            this.set('controller', controller);
            this.set('user', user);

            return new Ember.RSVP.Promise(function (resolve, reject) {

                if (!_this.get('controller.code')) {
                    return resolve();
                }
                var accessToken = _this.get('session.data.authenticated.accessToken');
                Ember.$.ajax({
                    url: _environment.default.apiHost + '/confirm/' + _this.get('controller.code'),
                    method: 'POST',
                    headers: {
                        'x-access-token': accessToken
                    },
                    dataType: 'json',
                    success: function success() {
                        _this.set('confirmed', true);
                        if (_this.get('session.data.authenticated.user')) {
                            _this.set('session.data.authenticated.user.emailConfirmed', true);
                        }
                        Ember.debug('sucessfully confirmed user: ' + _this.get('user.email'));
                    },
                    error: function error(jqXHR, textStatus, errorThrown) {
                        Ember.debug(textStatus);
                        Ember.debug(errorThrown);
                        _this.set('errors', [{
                            title: 'Confirmation Error',
                            message: textStatus
                        }]);
                        reject(errorThrown);
                    }
                });
            });
        }
    });
});