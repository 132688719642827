define('vmpower/components/ax-two-factor-signin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        init: function init() {
            this._super();
            this.set('errors', []);
            Ember.TextSupport.reopen({
                attributeBindings: ['data-parsley-required', 'data-parsley-minlength', 'data-parsley-type', 'data-parsley-equalto']
            });
        },
        actions: {
            confirmCode: function confirmCode() {
                this.set('errors', []);
                getOwner(this).lookup('route:signin').send('signin', {
                    email: this.get('email'),
                    password: this.get('password'),
                    code: this.get('code')
                });
            },
            resend: function resend() {
                this.set('errors', []);
                getOwner(this).lookup('route:signin').send('signin', {
                    email: this.get('email'),
                    password: this.get('password')
                });
            },
            showRecovery: function showRecovery() {
                this.get('showRecoveryDialog').send();
            }
        }
    });
});