define('vmpower/routes/dashboard/virtual-machines/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service(),
		accounts: Ember.inject.service(),
		setupController: function setupController(controller, account) {
			appInsights.trackEvent('route:virtual-machines', {
				email: this.get('session.data.authenticated.user.email')
			});
			this.store.findAll('cloudSubscription').then(function (subs) {
				controller.set('subscriptions', subs);
			}).catch(function (err) {
				console.error(err);
			});
			controller.set('teams', account.get('teams'));
			this.store.findAll('vm-group').then(function (groups) {
				controller.set('groups', groups);
			});
			controller.set('router', Ember.getOwner(this).lookup('router:main'));
		},
		model: function model() {
			var user = this.get('session.data.authenticated.user');
			if (!user || !user.id) {
				return this.transitionTo('signin');
			}
			return this.get('accounts').activeAccountSync();
		}
	});
});