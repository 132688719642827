define('vmpower/helpers/lt', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.lt = lt;
    function lt(params) {
        return params[0] < params[1];
    }

    exports.default = Ember.Helper.helper(lt);
});