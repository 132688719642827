define('vmpower/components/ax-one-click-modal', ['exports', 'vmpower/config/environment', 'npm:clipboard'], function (exports, _environment, _npmClipboard) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		session: Ember.inject.service(),
		accounts: Ember.inject.service(),
		modals: Ember.inject.service('modals'),
		init: function init() {
			this._super();
			if (!this.get('vm')) {
				return;
			}
		},
		oneClickExpiryOptions: function () {
			var groupListing = new Ember.A([new Ember.Object({
				name: '60 Day Expiration',
				value: 60 * 24 * 60 * 60 * 1000
			}), new Ember.Object({
				name: '90 Day Expiration',
				value: 90 * 24 * 60 * 60 * 1000
			}), new Ember.Object({
				name: '120 Day Expiration',
				value: 120 * 24 * 60 * 60 * 1000
			}), new Ember.Object({
				name: 'One Year Expiration',
				value: 365 * 24 * 60 * 60 * 1000
			})]);
			return groupListing;
		}.property('oneClickExpiryOptions'),
		vmObserver: Ember.observer('vm.id', function (target) {
			return target.generateLinks(target);
		}),
		expiryObserver: Ember.observer('targetExpiry', function (target) {
			return target.generateLinks(target);
		}),
		generateLinks: function generateLinks(target) {
			target.set('loading', true);
			target.set('oneClickUntagLink', '');
			target.set('oneClickOnLink', '');
			target.set('oneClickOffLink', '');
			if (!target.get('targetExpiry.value')) {
				target.set('targetExpiry', new Ember.Object({ value: 60 * 24 * 60 * 60 * 1000 }));
			}
			var accessToken = target.get('session.data.authenticated.accessToken');
			var onLinkRequest = new Ember.RSVP.Promise(function (resolve, reject) {
				Ember.$.ajax({
					type: 'GET',
					url: _environment.default.apiHost + '/one-click?vm-id=' + target.get('vm.id') + ('&action=on&expiry=' + target.get('targetExpiry.value')),
					json: true,
					success: function success(data) {
						resolve(data.accessToken);
					},
					headers: {
						'x-access-token': accessToken
					},
					contentType: 'application/vnd.api+json'
				}).fail(function (data) {
					reject(data.responseJSON);
				});
			}).then(function (limitedAccessToken) {
				var link = _environment.default.dashboardUrl + '/actions/vm?action-name=on&vm-id=' + target.get('vm.id') + '&token=' + limitedAccessToken;
				target.set('oneClickOnLink', link);
			});
			var offLinkRequest = new Ember.RSVP.Promise(function (resolve, reject) {
				Ember.$.ajax({
					type: 'GET',
					url: _environment.default.apiHost + '/one-click?vm-id=' + target.get('vm.id') + '&action=off&account=' + target.get('accounts').activeAccountSync().id + '&expiry=' + target.get('targetExpiry.value'),
					json: true,
					success: function success(data) {
						resolve(data.accessToken);
					},
					headers: {
						'x-access-token': accessToken
					},
					contentType: 'application/vnd.api+json'
				}).fail(function (data) {
					reject(data.responseJSON);
				});
			}).then(function (limitedAccessToken) {
				var link = _environment.default.dashboardUrl + '/actions/vm?action-name=off&vm-id=' + target.get('vm.id') + '&token=' + limitedAccessToken;
				target.set('oneClickOffLink', link);
			});
			var untagLinkRequest = new Ember.RSVP.Promise(function (resolve, reject) {
				Ember.$.ajax({
					type: 'GET',
					url: _environment.default.apiHost + '/one-click?vm-id=' + target.get('vm.id') + '&action=untag&account=' + target.get('accounts').activeAccountSync().id + '&expiry=' + target.get('targetExpiry.value'),
					json: true,
					success: function success(data) {
						resolve(data.accessToken);
					},
					headers: {
						'x-access-token': accessToken
					},
					contentType: 'application/vnd.api+json'
				}).fail(function (data) {
					reject(data.responseJSON);
				});
			}).then(function (limitedAccessToken) {
				var link = _environment.default.dashboardUrl + '/actions/vm?action-name=untag&vm-id=' + target.get('vm.id') + '&token=' + limitedAccessToken + ('&expiry=' + target.get('targetExpiry.value'));
				target.set('oneClickUntagLink', link);
			});
			Ember.RSVP.all([onLinkRequest, offLinkRequest, untagLinkRequest]).then(function () {
				target.set('loading', false);
			}).catch(function () {
				target.set('loading', false);
			});
		},
		didRender: function didRender() {
			Ember.run.scheduleOnce('afterRender', function () {
				Ember.TextSupport.reopen({
					attributeBindings: ['data-clipboard-target']
				});
				new _npmClipboard.default('#on-link-btn');
				new _npmClipboard.default('#off-link-btn');
				new _npmClipboard.default('#untag-link-btn');
				Ember.$('.one-click-card .tooltipped').tooltip({ delay: 0 });
			});
		},
		actions: {
			closeModal: function closeModal() {
				this.get('modals').closeModal('#one-click-modal');
			}
		}
	});
});