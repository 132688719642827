define('vmpower/controllers/dashboard/subscriptions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        modals: Ember.inject.service(),
        updating: false,
        queryParams: ['code', 'state', 'showWizard', 'access_token'],
        actions: {
            showWizard: function showWizard() {
                this.set('showWizard', true);
                this.get('modals').openModal('#cloud-wizard');
            }
        }
    });
});