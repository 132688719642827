define('vmpower/components/ax-subscription-table', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        modals: Ember.inject.service(),
        store: Ember.inject.service(),
        table: null,
        init: function init() {
            var _this = this;

            this._super();
            Ember.run.scheduleOnce('afterRender', function () {
                var table = Ember.$('#sub-table').DataTable({
                    scrollY: 300,
                    bSort: false,
                    bFilter: false,
                    bLengthChange: false
                });
                _this.set('table', table);
            });
        },
        allSelectedChanged: Ember.observer('allSelected', function () {
            var _this2 = this;

            var cloudSubscriptions = this.get('account.cloudSubscriptions');
            cloudSubscriptions.forEach(function (sub) {
                sub.set('selected', _this2.get('allSelected'));
            });
        }),
        allLoaded: function () {
            if (!this.get('account.cloudSubscriptions')) {
                return false;
            }
            var loaded = true;
            this.get('account.cloudSubscriptions').forEach(function (sub) {
                if (!sub.get('isLoaded')) {
                    loaded = false;
                    return;
                }
            });
            return loaded;
        }.property('account.cloudSubscriptions.@each', 'account.cloudSubscriptions.@each.aws.isLoaded', 'account.cloudSubscriptions.@each.azure.isLoaded', 'account.cloudSubscriptions.@each.gcp.isLoaded'),
        checkedSubs: Ember.computed.filterBy('account.cloudSubscriptions', 'selected', true),
        actions: {
            removeSelected: function removeSelected() {
                var _this3 = this;

                this.get('modals').confirmAction('Remove Selected Credentials?', 'Remove the selected Cloud Credentials? This will remove any associated Virtual Machines').then(function () {
                    var user = _this3.get('account');
                    var table = _this3.get('table');
                    var promises = [];
                    var count = 0;
                    _this3.set('updating', true);
                    _this3.get('checkedSubs').forEach(function (sub) {
                        sub.deleteRecord();
                        table.row('#' + sub.id).remove();
                        count += 1;
                        if (sub.get('provider') === 'aws') {
                            promises.push(sub.store.findRecord('awsCredential', sub.get('aws.id')).then(function (aws) {
                                aws.deleteRecord();
                                return aws.save();
                            }));
                        } else if (sub.get('provider') === 'azure') {
                            promises.push(sub.store.findRecord('azureCredential', sub.get('azure.id')).then(function (azure) {
                                azure.deleteRecord();
                                return azure.save();
                            }));
                        } else if (sub.get('provider') === 'gcp') {
                            promises.push(sub.store.findRecord('gcpCredential', sub.get('gcp.id')).then(function (gcp) {
                                gcp.deleteRecord();
                                return gcp.save();
                            }));
                        }
                        promises.push(sub.save());
                        promises.push(sub.store.findAll('cloud-subscription').then(function () {
                            return sub.store.findAll('virtual-machine');
                        }).then(function (vms) {
                            vms.forEach(function (vm) {
                                if (vm.get('cloudSubscription.id') === sub.id) {
                                    vm.deleteRecord();
                                    promises.push(vm.save());
                                }
                            });
                        }).then(function () {
                            return sub.store.findAll('volume');
                        }).then(function (volumes) {
                            volumes.forEach(function (volume) {
                                if (volume.get('cloudSubscription.id') === sub.id) {
                                    promises.push(volume.destroyRecord());
                                }
                            });
                        }));
                    });
                    _this3.get('emberNotificationCenter').pushNotification({
                        title: 'Deleting Cloud Credentials',
                        description: 'Deleting ' + count + ' cloud credentials and removing associated VMs'
                    }, Ember.RSVP.all(promises).then(function () {
                        return user.save();
                    }).then(function () {
                        _this3.set('deletedRecords', count);
                    }).then(function () {
                        Ember.RSVP.all([_this3.get('store').query('cloud-subscription', { reload: true }).then(function () {
                            return Ember.RSVP.all([_this3.get('store').findAll('virtual-machine'), _this3.get('store').findAll('volume')]);
                        })]);
                        _this3.set('updating', false);
                        _this3.rerender();
                    }));
                });
            },
            clearRemoveNotification: function clearRemoveNotification() {
                this.set('deletedRecords', 0);
            },
            loadSecret: function loadSecret(sub) {
                if (sub.get('aws')) {
                    var creds = sub.get('aws');
                    sub.store.query('aws-credential', {
                        id: creds.get('id'),
                        secret: 'true'
                    }).then(function (subWithSecret) {
                        sub.iamSecret = subWithSecret.iamSecret;
                        sub.set('showSecret', true);
                    });
                }
            },
            hideSecret: function hideSecret(sub) {
                if (sub.get('aws')) {
                    var creds = sub.get('aws');
                    sub.store.query('aws-credential', {
                        id: creds.get('id')
                    }).then(function (subWithoutSecret) {
                        sub.iamSecret = subWithoutSecret.iamSecret;
                        sub.set('showSecret', false);
                    });
                }
            }
        }
    });
});