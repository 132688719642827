define("vmpower/controllers/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modals: Ember.inject.service(),
    showUpsellPopover: Ember.computed.alias("modals.showUpsellPopover")
  });
});