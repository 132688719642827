define('vmpower/components/ax-savings-table', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            var _this = this;

            this._super();
            Ember.run.scheduleOnce('afterRender', function () {
                var table = Ember.$('#savings-table').DataTable({
                    scrollY: '30vh',
                    bLengthChange: false
                });
                _this.set('table', table);
            });
        },
        pieChartResourceBreakdown: function () {
            var _this2 = this;

            var resourceDict = {};
            this.get('savingsRecords.records').forEach(function (record) {
                if (record.get('savingsType') === 'Volume Delete' && !resourceDict[record.get('volumeId')]) {
                    if (!resourceDict[record.get('volumeId')]) {
                        resourceDict[record.get('volumeId')] = {
                            value: record.get('savings'),
                            pricePerHour: record.get('pricePerHour')
                        };
                        return;
                    }
                    resourceDict[record.get('volumeId')].value += record.get('savings');
                } else if (record.get('savingsType') === 'Power-off Savings' || record.get('savingsType') === 'resizeSavings') {
                    if (!resourceDict[record.get('instanceId')]) {
                        resourceDict[record.get('instanceId')] = {
                            value: record.get('savings'),
                            pricePerHour: record.get('pricePerHour')
                        };
                        return;
                    }
                    resourceDict[record.get('instanceId')].value += record.get('savings');
                } else if (record.get('savingsType') === 'Resize Savings' || record.get('savingsType') === 'resizeSavings') {
                    if (!resourceDict[record.get('instanceId')]) {
                        resourceDict[record.get('instanceId')] = {
                            value: record.get('savings'),
                            pricePerHour: record.get('pricePerHour')
                        };
                        return;
                    }
                    resourceDict[record.get('instanceId')].value += record.get('savings');
                }
            });
            var resourceArray = [];
            Object.keys(resourceDict).forEach(function (key) {
                // subtract the value of the resources monthly cost
                // by the negative savings if savings is negative
                if (resourceDict[key].value < 0) {
                    var monthNumber = moment().month(_this2.get('savingsRecords.month')).format('M');
                    var daysInMonth = moment(moment().year() + '-' + monthNumber, 'YYYY-MM').daysInMonth();
                    resourceDict[key].value = resourceDict[key].value + daysInMonth * 24 * resourceDict[key].pricePerHour;
                }
                resourceDict[key].value = parseFloat(resourceDict[key].value.toFixed(2));
                resourceArray.push({
                    key: key,
                    value: resourceDict[key]
                });
            });
            // filter to the top 15 largest savings resources
            var instanceCap = 8;
            resourceArray = stable(resourceArray, function (a, b) {
                return a.value.value < b.value.value;
            });
            var filteredDict = {};
            resourceArray.forEach(function (item, i) {
                if (i < instanceCap) {
                    filteredDict[item.key] = item.value;
                }
            });
            return filteredDict;
        }.property('savingsRecords.records.@each'),
        pieChartTypeBreakdown: function () {
            var resourceDict = {};
            this.get('savingsRecords.records').forEach(function (record) {
                if (record.get('source') === 'Scheduled Resize') {
                    resourceDict['Scheduled Resize'] = {
                        value: 1
                    };
                    resourceDict['Scheduled Resize'].value += 1;
                } else if (record.get('source') === 'Scheduled Power-off' || record.get('source') === 'Scheduled Power-on') {
                    if (!resourceDict['Scheduled Power On/Off']) {
                        resourceDict['Scheduled Power On/Off'] = {
                            value: 1
                        };
                        return;
                    }
                    resourceDict['Scheduled Power On/Off'].value += 1;
                } else if (record.get('source') === 'VMPower Auto-off') {
                    if (!resourceDict['VMPower Auto-off']) {
                        resourceDict['VMPower Auto-off'] = {
                            value: 1
                        };
                        return;
                    }
                    resourceDict['VMPower Auto-off'].value += 1;
                } else if ((record.get('source') === 'Gcp Dashboard' || record.get('source') === 'Azure Dashboard' || record.get('source') === 'Aws Dashboard') && record.get('savingsType') === 'Power-off Savings') {
                    if (!resourceDict['Manual Power On/off (External)']) {
                        resourceDict['Manual Power On/off (External)'] = {
                            value: 1
                        };
                        return;
                    }
                    resourceDict['Manual Power On/off (External)'].value += 1;
                } else if (record.get('source') === 'VMPower Dashboard/API' && record.get('savingsType') === 'Power-off Savings') {
                    if (!resourceDict['Manual Power On/off (via VMPower)']) {
                        resourceDict['Manual Power On/off (via VMPower)'] = {
                            value: 1
                        };
                        return;
                    }
                    resourceDict['Manual Power On/off (via VMPower)'].value += 1;
                } else if (record.get('source') === 'VMPower Dashboard/API' && record.get('savingsType') === 'Resize Savings') {
                    if (!resourceDict['Manual Resize (via VMPower)']) {
                        resourceDict['Manual Resize (via VMPower)'] = {
                            value: 1
                        };
                        return;
                    }
                    resourceDict['Manual Resize (via VMPower)'].value += 1;
                } else if ((record.get('source') === 'Gcp Dashboard' || record.get('source') === 'Azure Dashboard' || record.get('source') === 'Aws Dashboard') && record.get('savingsType') === 'Resize Savings') {
                    if (!resourceDict['Manual Resize (External)']) {
                        resourceDict['Manual Resize (External)'] = {
                            value: 1
                        };
                        return;
                    }
                    resourceDict['Manual Resize (External)'].value += 1;
                } else if ((record.get('source') === 'Gcp Dashboard' || record.get('source') === 'Azure Dashboard' || record.get('source') === 'Aws Dashboard') && record.get('savingsType') === 'Volume Delete') {
                    if (!resourceDict['Volume Delete (External)']) {
                        resourceDict['Volmume Delete (External)'] = {
                            value: 1
                        };
                        return;
                    }
                    resourceDict['Volume Delete (External)'].value += 1;
                } else if (record.get('savingsType') === 'Volume Delete' && record.get('source') === 'VMPower Dashboard/API') {
                    if (!resourceDict['Volume Delete (via VMPower)']) {
                        resourceDict['Volume Delete (via VMPower)'] = {
                            value: 1
                        };
                        return;
                    }
                    resourceDict['Volume Delete (via VMPower)'].value += 1;
                }
            });
            return resourceDict;
        }.property('savingsRecords.records.@each'),
        actions: {
            selectMonth: function selectMonth(entry) {
                var _this3 = this;

                this.get('table').clear();
                this.set('savingsRecords.selected', false);
                this.set('savingsRecords', this.get('savingsDict.' + entry.month));
                this.set('savingsRecords.selected', true);
                this.get('savingsRecords.records').forEach(function (record) {
                    _this3.get('table').row.add([moment(record.get('timestamp')).fromNow(), record.get('savingsType'), record.get('instanceId') || record.get('volumeId'), record.get('source'), record.get('pricePerHour'), record.get('hours').toFixed(2), record.get('savings').toFixed(2)]).draw(false);
                });
                this.get('table').draw(true);
            },
            showCharts: function showCharts() {
                this.set('showLogs', false);
                this.set('showCharts', true);
            },
            showLogs: function showLogs() {
                var _this4 = this;

                if (this.get('showLogs')) {
                    return;
                }
                this.set('showLogs', true);
                this.set('showCharts', false);
                Ember.run.scheduleOnce('afterRender', function () {
                    var table = Ember.$('#savings-table').DataTable({
                        scrollY: '30vh',
                        bLengthChange: false
                    });
                    _this4.set('table', table);
                });
            }
        }
    });
});