define('vmpower/components/ax-azureasm-upload', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        accounts: Ember.inject.service(),
        store: Ember.inject.service(),
        modals: Ember.inject.service(),
        emberNotificationCenter: Ember.inject.service(),
        init: function init() {
            var _this = this;

            this._super();
            var accessToken = this.get('session.data.authenticated.accessToken');
            Ember.run.scheduleOnce('afterRender', function () {
                Ember.$('#azureasm-file-input').on('change', function (e) {
                    var file = e.target.files[0];
                    if (!file) {
                        return;
                    }
                    var formData = new FormData();
                    console.log('uri:');
                    console.log(_environment.default.apiHost + '/azureasm-cert-upload?subscription-id=' + _this.get('subscriptionId'));
                    console.log('appending file:');
                    console.log(file);
                    formData.append('certificate', file, 'certificate.pem');
                    _this.set('loading', true);
                    var p = new Ember.RSVP.Promise(function (resolve, reject) {
                        Ember.$.ajax({
                            type: 'POST',
                            url: _environment.default.apiHost + '/azureasm-cert-upload?subscription-id=' + _this.get('subscriptionId') + '&account=' + _this.get('accounts').activeAccountSync().id,
                            data: file,
                            processData: false,
                            json: true,
                            contentType: 'text/plain',
                            success: function success(data) {
                                resolve(data);
                            },
                            headers: {
                                'x-access-token': accessToken
                            }
                        }).fail(function (data) {
                            console.log('errors');
                            console.log(data.responseJSON);
                            reject(data.responseJSON);
                        });
                    });
                    p = p.then(function (subscription) {
                        return _this.get('store').query('cloud-subscription', { 'subscription-id': subscription.id, reload: true });
                    }).then(function () {
                        return _this.get('user').reload();
                    });
                    _this.get('emberNotificationCenter').pushNotification({
                        title: 'Connecting to Azure (Classic) Subscription ' + _this.get('subscriptionId'),
                        description: 'Your Azure (Classic) VMs and Volumes will soon be available to manage.'
                    }, p.finally(function () {
                        _this.closeModal();
                        _this.set('loading', false);
                    }));
                });
            });
        },

        actions: {
            selectFile: function selectFile() {
                if (this.get('loading')) {
                    return;
                }
                if (!this.get('subscriptionId')) {
                    return;
                }
                Ember.$('#azureasm-file-input').trigger('click');
            }
        }
    });
});