define('vmpower/routes/dashboard/historical', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        // time => moment obejct
        session: Ember.inject.service('session'),
        fetchStats: function fetchStats(type, time, scroll) {
            var _this = this;

            var controller = this.get('controller');
            // if we have a scroll id, use that
            var startDate = moment().subtract(1, 'month').toDate().toISOString();
            return this.store.query('historical-stat', {
                'start-date': startDate,
                scroll: scroll,
                type: type + '-analysis'
            }).then(function (stats) {
                stats.forEach(function (point) {
                    controller.get(type + 'Stats').pushObject(point);
                });
                if (controller.get(type + 'Stats.length') < stats.get('meta.total')) {
                    // page results
                    return _this.fetchStats(type, time, stats.get('meta.next'));
                }
            });
        },
        setupController: function setupController(controller, user) {
            var _this2 = this;

            appInsights.trackEvent('route:virtual-machines:vm', {
                email: this.get('session.data.authenticated.user.email')
            });
            controller.set('user', user);
            this.set('controller', controller);
            this.set('controller.loading', true);
            var initialTime = moment().subtract(2, 'hours');
            controller.set('selectedTime', initialTime);
            if (controller.get('barChartSets.length')) {
                // we have already loaded stats
                return;
            }
            controller.set('idleStats', new Ember.A([]));
            controller.set('resizeStats', new Ember.A([]));
            controller.set('activeStats', new Ember.A([]));
            return Ember.RSVP.all([this.fetchStats('resize', initialTime), this.fetchStats('idle', initialTime), this.fetchStats('active', initialTime)]).then(function () {
                controller.get('barChartSets').unshiftObject(controller.get('idleStats'));
                controller.get('barChartSets').unshiftObject(controller.get('resizeStats'));
                controller.get('barChartSets').unshiftObject(controller.get('activeStats'));
                _this2.set('controller.loading', false);
            }).finally(function () {
                _this2.set('controller.loading', false);
            });
        },
        model: function model() {
            return this.store.findRecord('user', this.get('session.data.authenticated.user.id'));
        }
    });
});