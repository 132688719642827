define('vmpower/components/ember-notification-pull-out', ['exports', 'ember-notif-hub/components/ember-notification-pull-out'], function (exports, _emberNotificationPullOut) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    Object.defineProperty(exports, 'default', {
        enumerable: true,
        get: function () {
            return _emberNotificationPullOut.default;
        }
    });
});