define("vmpower/helpers/date-diff", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateDiff = dateDiff;
  function dateDiff(params /*, hash*/) {
    if (!(params[0] instanceof Date)) {
      return;
    }
    var diff = moment(params[1] || Date.now()).diff(params[0], "seconds");
    return diff;
  }

  exports.default = Ember.Helper.helper(dateDiff);
});