define('vmpower/authenticators/application', ['exports', 'ember-simple-auth/authenticators/base', 'vmpower/config/environment'], function (exports, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        restore: function restore(data) {
            return new Ember.RSVP.Promise(function (resolve, reject) {
                if (data.user.admin) {
                    // disable telemetry for test user
                    appInsights.trackEvent = function () {};
                    appInsights.trackMetric = function () {};
                }
                if (data.expiresIn > Date.now()) {
                    resolve(data);
                } else {
                    reject('Session expired');
                }
            });
        },

        makeAuthCall: function makeAuthCall(options) {
            return new Ember.RSVP.Promise(function (resolve, reject) {
                var url = _environment.default.apiHost + '/authenticate';
                if (options.accountType === 'microsoft') {
                    url += '?azure-auth=true&id-token=' + encodeURIComponent(options.idToken) + '&code=' + encodeURIComponent(options.code) + '&source=' + encodeURIComponent(options.source) + '&affiliate=' + encodeURIComponent(options.afmc);
                }
                Ember.$.ajax({
                    type: 'POST',
                    url: url,
                    data: JSON.stringify({
                        email: options.email || 'placeholder',
                        password: options.password || 'placeholder',
                        code: options.code,
                        'recovery-token': options.recoveryToken
                    }),
                    success: function success(data) {
                        if (data['needs-two-factor']) {
                            var e = new Error('Two Factor Auth Required');
                            e.name = 'TwoFactorAuthException';
                            e.maskedNumber = data['masked-number'];
                            return reject(e);
                        }
                        var authData = {
                            accessToken: data.accessToken,
                            expiresIn: data.expiresIn,
                            user: {
                                firstName: data.data.attributes['first-name'],
                                lastName: data.data.attributes['last-name'],
                                email: data.data.attributes.email,
                                emailConfirmed: data.data.attributes['email-confirmed'],
                                id: data.data.id,
                                admin: data.data.attributes.admin
                            }
                        };
                        resolve(authData);
                    },
                    dataType: 'json',
                    contentType: 'application/vnd.api+json'
                }).fail(function (data) {
                    reject(data.responseJSON);
                });
            }).then(function (data) {
                if (data.user.admin) {
                    // disable telemetry for test user
                    appInsights.trackEvent = function () {};
                    appInsights.trackMetric = function () {};
                }
                return data;
            });
        },
        authenticate: function authenticate(options) {
            return this.makeAuthCall(options);
        },
        invalidate: function invalidate() {
            // no server side things need to be done.
            // the jwt token will be destroyed by now
            return new Ember.RSVP.Promise(function (resolve) {
                return resolve();
            });
        }
    });
});