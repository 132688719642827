define('vmpower/routes/dashboard/account-details/billing/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		accounts: Ember.inject.service(),
		stripe: Ember.inject.service('stripe'),
		setupController: function setupController(controller, user) {
			controller.set('user', user);
			controller.set('account', this.get('accounts').activeAccountSync());
			controller.set('stripe', this.get('stripe'));
		},
		session: Ember.inject.service('session'),
		model: function model() {
			var user = this.get('session.data.authenticated.user');
			if (!user || !user.id) {
				return this.transitionTo('signin');
			}
			var id = this.get('session.data.authenticated.user.id');
			return this.store.find('user', id);
		}
	});
});