define('vmpower/routes/dashboard/account-details/notifications', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        emberNotificationCenter: Ember.inject.service(),
        setupController: function setupController(controller, user) {
            var _this = this;

            this.set('controller', controller);
            controller.set('user', user);

            if (this.get('controller.code')) {
                appInsights.trackEvent('route:notiifcations:slack', {
                    email: this.get('session.data.authenticated.user.email')
                });
                var notifications = this.get('emberNotificationCenter');
                user.slackAuthCode = this.get('controller.code');
                var promise = this.get('controller.user').save();
                promise.then(function () {
                    _this.set('controller.code', undefined);
                    user.slackAuthCode = '';
                    return user.save();
                });
                notifications.pushNotification({
                    title: 'Setting up Slack Integration',
                    description: 'When this is done you can use the VMPower bot in your Slack channel'
                }, promise);
                return promise;
            }
        },
        model: function model() {
            var id = this.get('session.data.authenticated.user.id');
            return this.store.find('user', id);
        }
    });
});