define('vmpower/routes/dashboard/vm-groups', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        accounts: Ember.inject.service(),
        setupController: function setupController(controller, vmGroups) {
            var user = this.get('session.data.authenticated.user');
            controller.set('vmGroups', vmGroups);
            controller.set('account', this.get('accounts').activeAccountSync());
            this.store.findRecord('user', user.id).then(function (user) {
                controller.set('user', user);
            });
            this.store.findAll('vm-schedule').then(function (schedules) {
                controller.set('schedules', schedules);
            });
        },
        session: Ember.inject.service('session'),
        model: function model() {
            var user = this.get('session.data.authenticated.user');
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            var id = this.get('session.data.authenticated.user.id');
            return this.store.findAll('vm-group');
        }
    });
});