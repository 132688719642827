define('vmpower/components/ax-two-factor-auth-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        modals: Ember.inject.service(),
        init: function init() {
            var _this = this;

            this._super();
            this.initParsley();
            this.get('store').findAll('phone-validation').then(function (validations) {
                _this.set('validation', validations.get('firstObject'));
                if (_this.get('validation')) {
                    _this.set('countryCode', _this.get('validation.countryCode'));
                    _this.set('mobileNumber', _this.get('validation.mobileNumber'));
                } else {
                    _this.set('validation', _this.get('store').createRecord('phone-validation'));
                }
            });
        },
        emberNotificationCenter: Ember.inject.service(),
        initParsley: function initParsley() {
            var _this2 = this;

            window.Parsley.addValidator('phone', {
                requirementType: 'string',
                validateString: function validateString(value) {
                    var isValid = true;
                    var _iteratorNormalCompletion = true;
                    var _didIteratorError = false;
                    var _iteratorError = undefined;

                    try {
                        for (var _iterator = value[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                            var i = _step.value;

                            if (!i.match(/[0-9]|-/)) {
                                isValid = false;
                                break;
                            }
                        }
                    } catch (err) {
                        _didIteratorError = true;
                        _iteratorError = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion && _iterator.return) {
                                _iterator.return();
                            }
                        } finally {
                            if (_didIteratorError) {
                                throw _iteratorError;
                            }
                        }
                    }

                    return isValid;
                },
                messages: {
                    en: 'This value should only contain numbers and dashes',
                    pt: 'Esse valor deve conter somente número e traços',
                    es: 'Este valor sólo debe contener números y guiones'
                }
            });
            window.Parsley.addValidator('countryCode', {
                requirementType: 'string',
                validateString: function validateString(value) {
                    var isValid = true;
                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = value[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var i = _step2.value;

                            if (!i.match(/[0-9]|\+/)) {
                                isValid = false;
                                break;
                            }
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                _iterator2.return();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }

                    return isValid;
                },
                messages: {
                    en: 'This value should only contain numbers, dashes and the \'+\' sign',
                    pt: 'Este valor deve conter apenas números, traços e o sinal \'+\'',
                    es: 'Este valor sólo debe contener números, guiones y el signo \'+\''
                }
            });
            Ember.run.scheduleOnce('afterRender', function () {
                // Form Validation
                Ember.$('#two-factor-auth-form').parsley().on('form:submit', function () {
                    try {
                        var validationRecord;
                        if (!_this2.get('validation')) {
                            validationRecord = _this2.get('store').createRecord('phone-validation', {
                                mobileNumber: _this2.get('mobileNumber'),
                                countryCode: _this2.get('countryCode')
                            });
                        } else {
                            validationRecord = _this2.get('validation');
                            validationRecord.send('becomeDirty');
                        }
                        _this2.get('emberNotificationCenter').pushNotification({
                            title: 'Sending Verification SMS',
                            description: 'Check your phone for the verification code'
                        }, validationRecord.save());
                    } catch (err) {
                        Ember.debug(err);
                    } finally {
                        return false;
                    }
                });
            });
        },
        userObserver: Ember.observer('user.enableTwoFactor', function () {
            if (this.get('user.enableTwoFactor')) {
                this.get('emberNotificationCenter').pushNotification({
                    title: 'Enabling Two-Factor Authentication',
                    description: 'You will now be required to login with your password and mobile phone'
                }, this.get('user').save());
            } else {
                this.get('emberNotificationCenter').pushNotification({
                    title: 'Disabling Two-Factor Authentication',
                    description: 'You will no longer be required to login with your password and mobile phone'
                }, this.get('user').save());
            }
        }),
        actions: {
            closeModal: function closeModal() {
                this.get('modals').closeModal(Ember.$('#two-factor-auth-modal'));
                if (this.get('validation')) {
                    this.get('validation').rollbackAttributes();
                }
            },
            confirmCode: function confirmCode() {
                var _this3 = this;

                this.get('emberNotificationCenter').pushNotification({
                    title: 'Validating Mobile Number',
                    description: 'You will now be able to enable two factor authentication'
                }, this.get('store').query('phone-validation', { code: this.get('confirmationCode') }).then(function (validations) {
                    _this3.set('validation', validations.get('firstObject'));
                }));
            }
        }
    });
});