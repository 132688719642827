define('vmpower/components/ax-invite-user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        modals: Ember.inject.service(),
        session: Ember.inject.service(),
        accounts: Ember.inject.service(),
        emberNotificationCenter: Ember.inject.service(),
        init: function init() {
            var _this = this;

            this._super();
            Ember.TextSupport.reopen({
                attributeBindings: ['data-parsley-required', 'data-parsley-minlength', 'data-parsley-type', 'data-parsley-equalto']
            });
            Ember.run.scheduleOnce('afterRender', function () {
                // Form Validation
                Ember.$('#invite-form').parsley().on('form:submit', function () {
                    var invite;
                    try {
                        invite = _this.get('user.store').createRecord('teamInvite', {
                            firstName: _this.get('newTeammateFirst'),
                            lastName: _this.get('newTeammateLast'),
                            team: _this.get('team'),
                            teamName: _this.get('team.name'),
                            email: _this.get('newTeammateEmail'),
                            owner: _this.get('accounts').activeAccountSync()
                        });
                        appInsights.trackEvent('ax-invite-user:invite', { email: _this.get('session.data.authenticated.user.email'), inviteEmail: _this.get('newTeammateEmail'), team: _this.get('team.name') });
                        // send invite
                        _this.get('emberNotificationCenter').pushNotification({
                            title: 'Inviting User to VMPower Team "' + _this.get('team.name') + '"',
                            description: 'The user, ' + invite.get('email') + ' will be invited to join your group and have access to VMs in the assocaited VM groups'
                        }, invite.save().catch(function (err) {
                            Ember.debug(err);
                            if (invite.get('isNew')) {
                                invite.deleteRecord();
                            }
                            throw err;
                        }));
                        _this.send('closeModal');
                    } catch (e) {
                        Ember.debug(e);
                    } finally {
                        _this.send('closeModal');
                        return false;
                    }
                });
            });
        },
        nameTaken: function () {
            var _this2 = this;

            var taken = false;
            if (!this.get('user.teams')) {
                return false;
            }
            this.get('user.teams').forEach(function (team) {
                if (team.get('name') === _this2.get('newTeamName')) {
                    taken = true;
                }
            });
            return taken;
        }.property('user.@teams.each', 'newTeamName'),
        actions: {
            closeModal: function closeModal() {
                this.get('modals').closeModal('#invite-user-modal');
                this.set('newTeammateFirst', '');
                this.set('newTeammateLast', '');
                this.set('newTeammateEmail', '');
            }
        }
    });
});