define('vmpower/components/ax-signup-form', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Component.extend({
        clientId: function () {
            return _environment.default.AzureAcitveDirectoryV2ClientId;
        }.property(),
        redirectUri: function () {
            return encodeURIComponent(_environment.default.AzureActiveDirectoryLoginRedirectUri);
        }.property(),
        nonce: function () {
            return lil.uuid();
        }.property(),
        init: function init() {
            var _this = this;

            this._super();
            Ember.TextSupport.reopen({
                attributeBindings: ['data-parsley-required', 'data-parsley-minlength', 'data-parsley-type', 'data-parsley-equalto']
            });
            Ember.run.scheduleOnce('afterRender', function () {
                // Form Validation
                Ember.$('#signup-form').parsley().on('form:submit', function () {
                    Ember.debug('handing form submit!');
                    try {
                        getOwner(_this).lookup('route:signup').send('signup', {
                            firstName: _this.get('firstName'),
                            lastName: _this.get('lastName'),
                            source: _this.get('utmSource') || _this.get('afmc') || _this.get('promoCode') || 'Unknown',
                            email: _this.get('email'),
                            password: _this.get('password'),
                            organization: 'My Org',
                            affiliateId: _this.get('afmc') || _this.get('promoCode')
                        });
                    } catch (e) {
                        Ember.debug(e);
                        _this.set('signingUp', false);
                        if (e.errors) {
                            _this.set('errors', e.errors);
                        }
                    } finally {
                        return false;
                    }
                });
            });
        },
        actions: {
            togglePasswordVisibility: function togglePasswordVisibility() {

                if ($('#password')[0].type === 'password') {
                    $('#toggle-password-icon').css({ "color": "grey" });
                    $('#password')[0].type = 'text';
                } else {
                    $('#password')[0].type = 'password';
                    $('#toggle-password-icon').css({ "color": "black" });
                }
            }
        }
    });
});