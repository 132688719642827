define('vmpower/controllers/dashboard/auto-off', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['group', 'resetIdleVm'],
        emberNotificationCenter: Ember.inject.service(),
        init: function init() {
            this._super();
        },
        targetGroupObserver: Ember.observer('targetGroup', function (component) {
            component.set('group', this.get('targetGroup.id'));
        })
    });
});