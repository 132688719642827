define('vmpower/components/ax-volume-list', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		init: function init() {
			this._super();
			this.set('showUnusedAttached', true);
			Ember.$.fn.dataTable.ext.errMode = 'none';
			this._showUnusedAttached();
		},
		accounts: Ember.inject.service(),
		modals: Ember.inject.service(),
		powerManager: Ember.inject.service(),
		_renderTable: function _renderTable() {
			var _this = this;

			Ember.run.scheduleOnce('afterRender', function () {
				var _iteratorNormalCompletion = true;
				var _didIteratorError = false;
				var _iteratorError = undefined;

				try {
					for (var _iterator = Ember.$('tr')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
						var elem = _step.value;

						if (elem.innerHTML.indexOf('input') === -1) {
							elem.remove();
						}
					}
				} catch (err) {
					_didIteratorError = true;
					_iteratorError = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion && _iterator.return) {
							_iterator.return();
						}
					} finally {
						if (_didIteratorError) {
							throw _iteratorError;
						}
					}
				}

				var table = Ember.$('#volume-table').DataTable({
					scrollY: '80%',
					bLengthChange: false
				});
				_this.set('table', table);
				Ember.run.scheduleOnce('afterRender', function () {
					Ember.$('.material-tooltip').remove();
					Ember.$('.tooltipped').tooltip({ delay: 0 });
				});
			});
		},
		_destoryTable: function _destoryTable() {
			if (this.get('table')) {
				this.get('table').destroy();
				this.set('table', null);
				Ember.$('tr').removeAttr('class');
				Ember.$('tr').removeAttr('role');
				Ember.$('td').removeAttr('class');
				Ember.$('table').removeAttr('role');
				Ember.$('table').removeAttr('aria-describedby');
			}
		},
		_showAll: function _showAll() {
			var _this2 = this;

			if (this.get('showUnusedAttached')) {
				return this.set('showUnusedAttached', false);
			}
			this._destoryTable();
			this._renderTable();
			this.get('unusedVolumes').clear();
			var account = this.get('accounts').activeAccountSync();
			var volumeCap = account.get('volumeCap');
			this.get('volumes').forEach(function (v, i) {
				if (i > volumeCap) {
					// hide volumes past the plan-limited cap
					return;
				}
				if (_this2.get('showAll')) {
					return _this2.get('unusedVolumes').pushObject(v);
				}
				if (_this2.get('showUnusedAttached') && v.get('unused') && v.get('attached')) {
					return _this2.get('unusedVolumes').pushObject(v);
				}
				if (v.get('unused') && !v.get('attached')) {
					_this2.get('unusedVolumes').pushObject(v);
				}
			});
		},
		showAllObserver: Ember.observer('showAll', function (target) {
			target._showAll();
		}),
		_showUnusedAttached: function _showUnusedAttached() {
			var _this3 = this;

			if (this.get('showAll')) {
				return this.set('showAll', false);
			}
			this._destoryTable();
			this._renderTable();
			this.get('unusedVolumes').clear();
			var account = this.get('accounts').activeAccountSync();
			var volumeCap = account.get('volumeCap');
			this.get('volumes').forEach(function (v, i) {
				if (i > volumeCap) {
					// hide volumes past the plan-limited cap
					return;
				}
				if (_this3.get('showAll')) {
					return _this3.get('unusedVolumes').pushObject(v);
				}
				if (_this3.get('showUnusedAttached') && v.get('unused')) {
					return _this3.get('unusedVolumes').pushObject(v);
				}
			});
		},
		showUnusedAttachedObserver: Ember.observer('showUnusedAttached', function (target) {
			target._showUnusedAttached();
		}),
		volumesObserver: Ember.observer('volumes.@each', function (target) {
			if (target.get('showAll')) {
				target._showAll();
			} else if (target.get('showUnusedAttached')) {
				target._showUnusedAttached();
			} else {
				target._showAll();
			}
		}),
		selectAllObserver: Ember.observer('allSelected', function (target) {
			target.get('unusedVolumes').forEach(function (v) {
				if (target.get('allSelected')) {
					if (!v.get('attached')) {
						v.set('selected', true);
					}
				} else {
					if (!v.get('attached')) {
						v.set('selected', true);
					}
				}
			});
		}),
		selectedVolumes: Ember.computed.filterBy('unusedVolumes', 'selected', true),
		unusedVolumes: new Ember.A([]),
		totalUnusedCost: function () {
			var _this4 = this;

			var total = 0;
			this.get('volumes').forEach(function (v) {
				if (_this4.get('showUnusedAttached')) {
					if (v.get('unused')) {
						total += v.get('monthlyCost');
					}
				} else {
					total += v.get('monthlyCost');
				}
			});
			return total.toFixed(2);
		}.property('volumes.@each.monthlyCost', 'showUnusedAttached', 'showAll'),
		actions: {
			showUpgradeModal: function showUpgradeModal() {
				this.get('modals').upgradeModal();
			},
			deleteVolumes: function deleteVolumes() {
				var _this5 = this;

				var diskIdList = [];
				var totalSavings = 0;
				this.get('selectedVolumes').forEach(function (v) {
					diskIdList.push(v.get('volumeId'));
					totalSavings += v.get('monthlyCost');
				});
				totalSavings = parseFloat(totalSavings.toFixed(2));
				appInsights.trackEvent('ax-volume-list:deleteVolumes', {
					email: this.get('session.data.authenticated.user.email'),
					volumeCount: this.get('selectedVolumes.length'),
					savings: totalSavings
				});
				this.get('modals').confirmAction('Delete ' + this.get('selectedVolumes.length') + ' Volumes? The following disks will be permenantley deleted, saving $' + totalSavings.toFixed(2) + '/month:', diskIdList).then(function () {
					_this5.get('selectedVolumes').forEach(function (v) {
						_this5.get('powerManager').deleteVolume(v);
					});
				});
			}
		}
	});
});