define('vmpower/components/ax-footer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        copyrightYear: function () {
            return moment().year();
        }.property()
    });
});