define('vmpower/routes/dashboard/vm-schedules', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Route.extend({
        init: function init() {
            this._super();
            var path = getOwner(this).lookup('router:main').get('url');
            Ember.debug(path);
        }
    });
});