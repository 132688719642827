define('vmpower/components/ax-sidebar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        modals: Ember.inject.service(),
        localSettings: Ember.inject.service(),
        showSidebar: true,
        didRender: function didRender() {
            Ember.$('.dropdown-button').dropdown({
                inDuration: 300,
                outDuration: 225,
                constrain_width: false, // Does not change width of dropdown to that of the activator
                hover: false, // Activate on hover
                gutter: 0, // Spacing from edge
                belowOrigin: true, // Displays dropdown below the button
                alignment: 'right' // Displays dropdown with edge aligned to the left of button
            });
            Ember.run.scheduleOnce('afterRender', function () {
                Ember.$('.material-tooltip').remove();
                Ember.$('.tooltipped').tooltip({ delay: 0 });
            });
        },
        routeNameObserver: Ember.observer('router.currentRouteName', function (target) {
            Ember.debug('route name:');
            Ember.debug(target.get('router.currentRouteName'));
        }),
        planEnabledVms: function () {
            var result = new Ember.A([]);
            if (!this.get('vms')) {
                return result;
            }
            this.get('vms').forEach(function (vm) {
                if (!vm.get('shared') && vm.get('planEnabled')) {
                    result.pushObject(vm);
                }
            });
            return result;
        }.property('vms.@each.planEnabled', 'vms.@each.shared'),
        idleVms: Ember.computed.filterBy('vms', 'isIdle', true),
        unusedVolumes: Ember.computed.filterBy('volumes', 'unused', true),
        init: function init() {
            var _this = this;

            this._super();
            if (this.get('showPlans')) {
                Ember.run.scheduleOnce('afterRender', function () {
                    _this.send('openUpgradeModal');
                    Ember.$('.material-tooltip').remove();
                });
            }
            this.get('localSettings').getLocalSettings().then(function (settings) {
                if (!settings) {
                    return;
                }
                _this.set('collapse', settings.get('collapse'));
                if (_this.get('collapse')) {
                    _this.sendAction('onCollapse');
                }
            });
        },
        rightsizingRecCount: function () {
            var count = 0;
            if (!this.get('vms')) {
                return count;
            }
            this.get('vms').forEach(function (vm) {
                if (vm.get('recommendedResizeTypes.length')) {
                    count += 1;
                }
            });
            return count;
        }.property('vms'),
        actions: {
            openUpgradeModal: function openUpgradeModal() {
                this.get('modals').upgradeModal();
            },
            collapse: function collapse() {
                var _this2 = this;

                if (this.get('collapse')) {
                    this.set('collapse', false);
                } else {
                    this.set('collapse', true);
                }
                this.get('localSettings').getLocalSettings().then(function (settings) {
                    if (!settings) {
                        return;
                    }
                    // save sidebar state into local storage
                    settings.set('collapse', _this2.get('collapse'));
                    return settings.save();
                });
                // remove tooltips from DOM
                Ember.run.scheduleOnce('afterRender', function () {
                    Ember.$('.material-tooltip').remove();
                    Ember.$('.tooltipped').tooltip({ delay: 0 });
                });
                this.sendAction('onCollapse');
            },
            openAccountModal: function openAccountModal() {
                this.get('modals').openModal(Ember.$('#account-modal'));
            }
        }
    });
});