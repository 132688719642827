define("vmpower/helpers/subtract", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.subtract = subtract;
  function subtract(params /*, hash*/) {
    return params[0] - params[1];
  }

  exports.default = Ember.Helper.helper(subtract);
});