define("vmpower/models/virtual-machine", ["exports", "ember-data/model", "ember-data"], function (exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    provider: _emberData.default.attr("string"),
    specs: function () {
      return this.get("cpus") + " CPUs " + this.get("memoryGiB") + " GiB " + this.get("iops") + " IOps";
    }.property("cpus", "memoryGiB", "iops"),
    displayName: function () {
      if (this.get("pubIp") && this.get("instanceName")) {
        return this.get("instanceName") + "/" + this.get("pubIp");
      } else if (this.get("instanceName")) {
        // azure instance ids include resource groups
        // which is better ux
        if (this.get("provider") === "azure") {
          return this.get("instanceId");
        } else {
          return this.get("instanceName");
        }
      } else if (this.get("pubIp")) {
        return this.get("instanceId") + "/" + this.get("pubIp");
      } else {
        return this.get("instanceId");
      }
    }.property("pubIp", "instanceName", "instanceId"),
    instanceType: _emberData.default.attr("string"),
    latestAggregateDatapointTimestamp: _emberData.default.attr('date'),
    instanceId: _emberData.default.attr("string"),
    shared: _emberData.default.attr("boolean"),
    powerState: _emberData.default.attr("string"),
    osType: _emberData.default.attr("string"),
    unstoppable: _emberData.default.attr("boolean", {
      defaultValue: false
    }),
    isIdle: _emberData.default.attr("boolean"),
    idleReason: _emberData.default.attr("string", {
      defaultValue: "Disk Write Average > 100KB/s"
    }),
    autoOffVms: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    iops: _emberData.default.attr("number"),
    sinceIdleTime: _emberData.default.attr("date"),
    cpus: _emberData.default.attr("number"),
    kind: _emberData.default.attr("string"),
    memoryGiB: _emberData.default.attr("number"),
    pricePerHour: _emberData.default.attr("number"),
    recFeedback: _emberData.default.attr("string"),
    recFeedbackText: _emberData.default.attr("string"),
    idleFeedback: _emberData.default.attr("string"),
    cloudNativeLink: _emberData.default.attr("string"),
    cloudNativeDiagnosticsLink: _emberData.default.attr("string"),
    idleFeedbackText: _emberData.default.attr("string"),
    cloudSubscription: _emberData.default.belongsTo("cloud-subscription"),
    datapoints: _emberData.default.hasMany("datapoint"),
    diskReadAverage: _emberData.default.attr("number"),
    diskWriteAverage: _emberData.default.attr("number"),
    networkInMax: _emberData.default.attr("number"),
    networkInAverage: _emberData.default.attr('number'),
    networkOutMax: _emberData.default.attr("number"),
    networkOutAverage: _emberData.default.attr('number'),
    diskReadMax: _emberData.default.attr("number"),
    instanceName: _emberData.default.attr("string"),
    planEnabled: _emberData.default.attr("boolean"),
    // enable or disalbe idle vm detection
    idleVmDetection: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    commonIdleHourStreak: _emberData.default.attr('array', { defaultValue: [] }),
    commonIdleHourStreakUpdated: _emberData.default.attr('date'),
    // enable or disable resize recommendations
    resizeRecommendations: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    rdpPort: function () {
      if (this.get("provider") === "azureasm") {
        return "62653";
      } else {
        return "3389";
      }
    }.property(),
    pubIp: _emberData.default.attr("string"),
    memoryRec: _emberData.default.attr("string"),
    iopsRec: _emberData.default.attr("string"),
    cpuRec: _emberData.default.attr("number"),
    diskWriteMax: _emberData.default.attr("number"),
    memoryGiBUtilizedAverage: function () {
      if (isNaN(this.get("memoryUtilizedAverage"))) {
        return 0;
      }
      return this.get("memoryUtilizedAverage") / 100 * this.get("memoryGiB");
    }.property("memoryUtilizedAverage"),
    memoryGiBUtilizedMax: function () {
      if (isNaN(this.get("memoryUtilizedMax"))) {
        return 0;
      }
      return this.get("memoryUtilizedMax") / 100 * this.get("memoryGiB");
    }.property("memoryUtilizedMax"),
    memoryUtilizedAverage: _emberData.default.attr("number"),
    memoryUtilizedMax: _emberData.default.attr("number"),
    cpuAverage: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    cpuMax: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    iopsMax: _emberData.default.attr("number"),
    iopsAverage: _emberData.default.attr("number"),
    scaleDownDimensions: function () {
      var dimensions = [];
      if (this.get("cpuRec") < this.get("cpus")) {
        dimensions.push("CPU");
      }
      if (this.get("memoryRec") === "scaledown") {
        dimensions.push("Memory");
      }
      if (this.get("iopsRec") === "scaledown") {
        dimensions.push("IOps");
      }
      return dimensions;
    }.property("cpuRec", "memoryRec", "iopsRec"),
    agentConnected: function () {
      this.reload();
      if (!this.get("lastLoginUpdate")) {
        return false;
      }
      var seconds = moment().diff(moment(this.get("lastLoginUpdate")), "seconds");
      return seconds < 120;
    }.property("lastLoginUpdate"),
    lastLoginUpdate: _emberData.default.attr("date"),
    lastLoginTime: _emberData.default.attr("date"),
    scaleUpDimensions: function () {
      var dimensions = [];
      if (this.get("cpuRec") > this.get("cpus")) {
        dimensions.push("CPU");
      }
      if (this.get("memoryRec") === "scaleup") {
        dimensions.push("Memory");
      }
      if (this.get("iopsRec") === "scaleup") {
        dimensions.push("IOps");
      }
      return dimensions;
    }.property("cpuRec", "memoryRec", "iopsRec"),
    // returns all resizable types and adds custom field to vm type
    // to indicate that it is recommended
    resizeList: function () {
      var _this = this;

      var list = new Ember.A([]);
      this.get("resizableTypes").forEach(function (type) {
        var monthlyDelta = type.get("pricePerHour") * 720;
        type.set("name", type.get("instanceType") + " $" + monthlyDelta.toFixed(2) + "/mo");
        type.set("recommended", true);
        _this.get("recommendedResizeTypes").forEach(function (recType) {
          if (type.id === recType.id) {
            type.set("name", type.get("instanceType") + " $" + monthlyDelta.toFixed(2) + "/mo (recommended)");
          }
        });
        list.pushObject(type);
      });
      // put recommended sizes on top of list
      this.get("recommendedResizeTypes").forEach(function (type) {
        list.removeObject(type);
        list.unshiftObject(type);
      });
      return list;
    }.property("resizableTypes.@each.instanceType", "recommendedResizeTypes.@each"),
    resizeMonthlySavings: function () {
      var firstRecType = this.get("recommendedResizeTypes.firstObject");
      if (!firstRecType) {
        return 0;
      }
      // 720 hours in a month
      var monthlySavings = 720 * (this.get("pricePerHour") - this.get("recommendedResizeTypes.firstObject").get("pricePerHour"));
      return parseFloat(monthlySavings.toFixed(2));
    }.property("pricePerHour", "recommendedResizeTypes.@each.pricePerHour"),
    resizeMonthlyCost: function () {
      var firstRecType = this.get("recommendedResizeTypes.firstObject");
      if (!firstRecType) {
        return 0;
      }
      // 720 hours in a month
      var monthlySavings = -720 * (this.get("pricePerHour") - this.get("recommendedResizeTypes.firstObject").get("pricePerHour"));
      return parseFloat(monthlySavings.toFixed(2));
    }.property("pricePerHour", "recommendedResizeTypes.@each.pricePerHour"),
    resizableTypes: _emberData.default.hasMany("vm-type"),
    recommendedResizeTypes: _emberData.default.hasMany("vm-type"),
    diskReadMaxKb: function () {
      return (this.get("diskReadMax") / 1024).toFixed(2);
    }.property("diskReadMax"),
    diskWriteMaxKb: function () {
      return (this.get("diskWriteMax") / 1024).toFixed(2);
    }.property("diskWriteMax"),
    diskWriteAverageKb: function () {
      return (this.get("diskReadAverage") / 1024).toFixed(2);
    }.property("diskWriteAverage"),
    diskReadAverageKb: function () {
      return (this.get("diskWriteAverage") / 1024).toFixed(2);
    }.property("diskReadAverage"),
    poweringOff: _emberData.default.attr("boolean"),
    poweringOn: _emberData.default.attr("boolean"),
    monthlyCost: function () {
      // 720 hours per month
      return parseFloat((this.get("pricePerHour") * 720).toFixed(2));
    }.property("pricePerHour")
  });
});