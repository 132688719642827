define("vmpower/templates/dashboard", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "vmpower/templates/dashboard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col m2 l2");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 1]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "ax-navbar", [], ["notifications", ["subexpr", "@mut", [["get", "notifications", ["loc", [null, [5, 26], [5, 39]]]]], [], []]], ["loc", [null, [5, 0], [5, 41]]]], ["inline", "ax-sidebar", [], ["onCollapse", ["subexpr", "action", ["onCollapse"], [], ["loc", [null, [8, 36], [8, 57]]]], "user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [8, 63], [8, 67]]]]], [], []], "account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [8, 76], [8, 83]]]]], [], []], "vms", ["subexpr", "@mut", [["get", "vms", ["loc", [null, [8, 88], [8, 91]]]]], [], []], "records", ["subexpr", "@mut", [["get", "currentMonthSavingsRecords", ["loc", [null, [8, 100], [8, 126]]]]], [], []], "monthlySavings", ["subexpr", "@mut", [["get", "savings", ["loc", [null, [8, 142], [8, 149]]]]], [], []], "router", ["subexpr", "@mut", [["get", "router", ["loc", [null, [8, 157], [8, 163]]]]], [], []], "volumes", ["subexpr", "@mut", [["get", "volumes", ["loc", [null, [8, 172], [8, 179]]]]], [], []], "showPlans", ["subexpr", "@mut", [["get", "showPlans", ["loc", [null, [8, 190], [8, 199]]]]], [], []]], ["loc", [null, [8, 12], [8, 201]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 12
            },
            "end": {
              "line": 18,
              "column": 12
            }
          },
          "moduleName": "vmpower/templates/dashboard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "ax-breadcrumbs", ["loc", [null, [17, 16], [17, 34]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 8
            },
            "end": {
              "line": 33,
              "column": 8
            }
          },
          "moduleName": "vmpower/templates/dashboard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ember-notification-center", [], ["bottom", "-2px", "openBottom", "-16px", "left", "20%", "width", "60%", "pullDown", false, "baseAssetPath", ["subexpr", "@mut", [["get", "baseAssetPath", ["loc", [null, [32, 125], [32, 138]]]]], [], []]], ["loc", [null, [32, 12], [32, 140]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 37,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/dashboard.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" Start Page Loading ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" End Page Loading ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col s12 m6 l6 offset-m4 offset-l4");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("footer");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col s12 m8 l8 offset-m3 offset-l3");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [5, 1]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[5] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 9, 9, contextualElement);
        morphs[7] = dom.createMorphAt(fragment, 11, 11, contextualElement);
        morphs[8] = dom.createMorphAt(dom.childAt(fragment, [13, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "account", ["loc", [null, [4, 6], [4, 13]]]]], [], 0, null, ["loc", [null, [4, 0], [12, 7]]]], ["attribute", "class", ["concat", ["col s12 ", ["subexpr", "if", [["get", "collapsed", ["loc", [null, [15, 33], [15, 42]]]], "m11 l11 offset-m1 offset-l1", "m10 l10 offset-m2 offset-l2"], [], ["loc", [null, [15, 28], [15, 104]]]], " dashboard-container"]]], ["block", "if", [["get", "account", ["loc", [null, [16, 18], [16, 25]]]]], [], 1, null, ["loc", [null, [16, 12], [18, 19]]]], ["content", "outlet", ["loc", [null, [19, 12], [19, 22]]]], ["inline", "ax-upgrade-modal", [], ["account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [22, 39], [22, 46]]]]], [], []], "user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [22, 52], [22, 56]]]]], [], []], "stripe", ["subexpr", "@mut", [["get", "stripe", ["loc", [null, [22, 64], [22, 70]]]]], [], []]], ["loc", [null, [22, 12], [22, 72]]]], ["inline", "ax-one-click-modal", [], ["vm", ["subexpr", "@mut", [["get", "oneClickVm", ["loc", [null, [26, 24], [26, 34]]]]], [], []]], ["loc", [null, [26, 0], [26, 36]]]], ["inline", "ax-user-info-modal", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [27, 26], [27, 30]]]]], [], []], "account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [27, 39], [27, 46]]]]], [], []]], ["loc", [null, [27, 0], [27, 48]]]], ["inline", "ax-account-modal", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [28, 24], [28, 28]]]]], [], []], "accounts", ["subexpr", "@mut", [["get", "accounts", ["loc", [null, [28, 38], [28, 46]]]]], [], []], "account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [28, 55], [28, 62]]]]], [], []]], ["loc", [null, [28, 0], [28, 64]]]], ["block", "if", [["get", "account", ["loc", [null, [31, 14], [31, 21]]]]], [], 2, null, ["loc", [null, [31, 8], [33, 15]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});