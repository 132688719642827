define('vmpower/components/ax-line-chart', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        timeChartData: function () {
            var _this = this;

            var chartData = {
                fillColor: this.get('fillColor'),
                strokeColor: this.get('strokeColor'),
                pointColor: this.get('pointColor'),
                // this chart just shows one dataset at a time
                datasets: [{
                    backgroundColor: '#12130F',
                    label: this.get('chartName'),
                    borderColor: '#CCDDE2',
                    color: '#CCDDE2',
                    data: [],
                    xAxisID: 'xScale0',
                    yAxisID: 'yScale0'
                }]
            };
            if (!this.get('datapoints').forEach) {
                return chartData;
            }
            var skipMod = 1;
            if (this.get('datapoints.length') > 100) {
                skipMod = 2;
            } else if (this.get('datapoints.length') > 300) {
                skipMod = 3;
            } else if (this.get('datapoints.length') > 600) {
                skipMod = 6;
            }
            var count = 0;
            this.get('datapoints').forEach(function (dp) {
                if (count % skipMod !== 0) {
                    count += 1;
                    return;
                }
                if (dp.get(_this.get('pointName')) === undefined || dp.get(_this.get('pointName')) === null) {
                    return;
                }
                chartData.datasets[0].data.unshift({
                    y: dp.get(_this.get('pointName')),
                    x: new Date(dp.get('timestamp'))
                });
                count += 1;
            });
            return chartData;
        }.property('datapoints.length'),
        chartOptions: function () {
            var opts = {
                color: 'black',
                scaleFontSize: 16,
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    xAxes: [{
                        id: 'xScale0',
                        type: 'time',
                        position: 'bottom',
                        ticks: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        id: 'yScale0',
                        ticks: {
                            min: 0
                        },
                        type: 'linear'
                    }]
                },
                // Boolean - Show a backdrop to the scale label
                scaleShowLabelBackdrop: true,

                // Boolean - Whether the scale should begin at zero
                scaleBeginAtZero: true,

                // Number - The backdrop padding above & below the label in pixels
                scaleBackdropPaddingY: 2,

                // Number - The backdrop padding to the side of the label in pixels
                scaleBackdropPaddingX: 2,

                // Boolean - Show line for each value in the scale
                scaleShowLine: true,

                // Boolean - Stroke a line around each segment in the chart
                segmentShowStroke: true,

                // String - The colour of the stroke on each segement.
                segmentStrokeColor: '#fff',

                // Number - The width of the stroke value in pixels
                segmentStrokeWidth: 2,

                datasetStroke: true,

                datasetFill: false,

                // Number - Pixel width of dataset stroke
                datasetStrokeWidth: 5,

                // Number - Amount of animation steps
                animationSteps: 0,

                // String - Animation easing effect.
                animationEasing: 'easeOutBounce',

                // Boolean - Whether to animate the rotation of the chart
                animateRotate: false,

                // Boolean - Whether to animate scaling the chart from the centre
                animateScale: false,
                legendTemplate: '<div class="collection timechart-legend"><% for (var i=0; i<datasets.length; i++){%><%if(datasets[i].label.htmlUrl){%><a target="_blank" href="<%=datasets[i].label.htmlUrl%>" class="collection-item legend-selecter"><%} else{%><a class="collection-item legend-selecter"><%}%><span style="background-color:<%=datasets[i].strokeColor%>" class="circle"></span> <%if(datasets[i].label.name){%><%=datasets[i].label.name%><%}%></a><%}%></div>'
            };
            return opts;
        }.property()
    });
});