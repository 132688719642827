define("vmpower/templates/dashboard/virtual-machines/team", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/dashboard/virtual-machines/team.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        dom.setAttribute(el1, "style", "margin-top:-50px");
        var el2 = dom.createTextNode("\n        ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col s12 m10 offset-m1 l12");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        return morphs;
      },
      statements: [["inline", "ax-vm-list", [], ["vms", ["subexpr", "@mut", [["get", "vms", ["loc", [null, [4, 16], [4, 19]]]]], [], []], "user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [5, 17], [5, 21]]]]], [], []], "team", ["subexpr", "@mut", [["get", "team", ["loc", [null, [6, 17], [6, 21]]]]], [], []], "groups", ["subexpr", "@mut", [["get", "groups", ["loc", [null, [7, 19], [7, 25]]]]], [], []], "teams", ["subexpr", "@mut", [["get", "teams", ["loc", [null, [8, 18], [8, 23]]]]], [], []], "singleSub", true, "searchTerm", ["subexpr", "@mut", [["get", "searchQuery", ["loc", [null, [10, 23], [10, 34]]]]], [], []], "loading", ["subexpr", "@mut", [["get", "loading", ["loc", [null, [11, 20], [11, 27]]]]], [], []]], ["loc", [null, [3, 8], [11, 29]]]]],
      locals: [],
      templates: []
    };
  }());
});