define('vmpower/routes/dashboard/vm-rightsizing', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        setupController: function setupController(controller, vms) {
            var user = this.get('session.data.authenticated.user');
            controller.set('router', Ember.getOwner(this).lookup('router:main'));
            return this.store.find('user', user.id).then(function (user) {
                controller.set('user', user);
                controller.set('vms', vms);
            });
        },
        model: function model() {
            return this.store.findAll('virtual-machine');
        }
    });
});