define('vmpower/services/ember-notification-center', ['exports', 'ember-notif-hub/services/ember-notification-center'], function (exports, _emberNotificationCenter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    Object.defineProperty(exports, 'default', {
        enumerable: true,
        get: function () {
            return _emberNotificationCenter.default;
        }
    });
});