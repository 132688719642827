define("vmpower/controllers/dashboard/virtual-machines/vm", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    emberNotificationCenter: Ember.inject.service(),
    datapointCalc: Ember.inject.service(),
    sinceTimes: Ember.A([]),
    accounts: Ember.inject.service(),
    init: function init() {
      this._super();
      this.set("account", this.get("accounts").activeAccountSync());
      this.get("sinceTimes").pushObject({
        name: "Data since " + moment().subtract(2, "hours").fromNow(),
        value: moment().subtract(2, "hours")
      });
      this.get("sinceTimes").pushObject({
        name: "Data since " + moment().subtract(8, "hours").fromNow(),
        value: moment().subtract(8, "hours")
      });
      this.get("sinceTimes").pushObject({
        name: "Data since " + moment().subtract(1, "day").fromNow(),
        value: moment().subtract(1, "day")
      });
      this.get("sinceTimes").pushObject({
        name: "Data since " + moment().subtract(2, "days").fromNow(),
        value: moment().subtract(2, "days")
      });
      this.get("sinceTimes").pushObject({
        name: "Data since " + moment().subtract(3, "days").fromNow(),
        value: moment().subtract(3, "days")
      });
      this.get("sinceTimes").pushObject({
        name: "Data since " + moment().subtract(7, "days").fromNow(),
        value: moment().subtract(7, "days")
      });
      this.get("sinceTimes").pushObject({
        name: "Data since " + moment().subtract(12, "days").fromNow(),
        value: moment().subtract(12, "days")
      });
      this.get("sinceTimes").pushObject({
        name: "Data since " + moment().subtract(24, "days").fromNow(),
        value: moment().subtract(24, "days")
      });
      this.get("sinceTimes").pushObject({
        name: "Data since " + moment().subtract(30, "days").fromNow(),
        value: moment().subtract(30, "days")
      });
    },
    cpuAverage: function () {
      var calc = this.get("datapointCalc");
      return (calc.computeMean(this.get("cpuPoints"), "cpuPercentageTime") / 1024).toFixed(2);
    }.property("cpuPoints.length"),
    cpuMaxima: function () {
      var calc = this.get("datapointCalc");
      return (calc.computeMaxima(this.get("cpuPoints"), "cpuPercentageTime") / 1024).toFixed(2);
    }.property("cpuPoints.length"),
    diskReadAverage: function () {
      var calc = this.get("datapointCalc");
      return (calc.computeMean(this.get("diskPoints"), "diskReadAverage") / 1024).toFixed(2);
    }.property("diskPoints.length"),
    diskWriteAverage: function () {
      var calc = this.get("datapointCalc");
      return (calc.computeMean(this.get("diskPoints"), "diskWriteAverage") / 1024).toFixed(2);
    }.property("diskPoints.length"),
    diskWriteMaxima: function () {
      var calc = this.get("datapointCalc");
      return (calc.computeMaxima(this.get("diskPoints"), "diskWriteAverage") / 1024).toFixed(2);
    }.property("diskPoints.length"),
    diskReadMaxima: function () {
      var calc = this.get("datapointCalc");
      return (calc.computeMaxima(this.get("diskPoints"), "diskReadAverage") / 1024).toFixed(2);
    }.property("diskPoints.length"),
    memoryUsageAverage: function () {
      var calc = this.get("datapointCalc");
      return (100 - parseFloat(calc.computeMean(this.get("memoryPoints"), "memoryPercentAvailable"))).toFixed(2);
    }.property("memoryPoints.length"),
    memoryUsageMax: function () {
      var calc = this.get("datapointCalc");
      if (this.get("diskPoints.length") <= 0) {
        return 0;
      }
      return (100 - parseFloat(calc.computeMinima(this.get("diskPoints"), "memoryPercentAvailable"))).toFixed(2);
    }.property("memoryPoints.length"),
    idleTrackingLimited: function () {
      if (this.get("account.hasDirtyAttributes")) {
        return true;
      }
      return this.get("account.idleTrackingCount") >= this.get("account.idleTrackingCap");
    }.property("account.idleTrackingCount", "account.idleTrackingCap", "account.hasDirtyAttributes"),
    idleCheckboxObserver: Ember.observer("vm.idleVmDetection", function (target) {
      var description;
      var title;
      if (!target.get("vm.hasDirtyAttributes")) {
        return;
      }
      if (target.get("vm.idleVmDetection")) {
        description = "Enabling Idle Detection for " + target.get("vm.displayName");
        title = "Enabling Idle Detection";
        appInsights.trackEvent("ax-cloud-wizard:enable-idle-tracking", {
          email: target.get("session.data.authenticated.account.email")
        });
      } else {
        description = "Disabling Idle Detection for " + target.get("vm.displayName");
        title = "Disabling Idle Detection";
        // clear current idle status then
        target.set("vm.isIdle", false);
        appInsights.trackEvent("ax-cloud-wizard:disable-idle-tracking", {
          email: target.get("session.data.authenticated.account.email")
        });
      }
      target.get("emberNotificationCenter").pushNotification({
        title: title,
        description: description
      }, target.get("vm").save().then(function () {
        return target.get("account").reload();
      }).catch(function (e) {
        target.get("vm").rollbackAttributes();
        throw e;
      }));
    })
  });
});