define('vmpower/components/ax-team-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        modals: Ember.inject.service(),
        emberNotificationCenter: Ember.inject.service(),
        session: Ember.inject.service(),
        showTeams: true,
        actions: {
            deleteTeam: function deleteTeam(team) {
                var _this = this;

                this.get('modals').confirmAction('Delete Team ' + team.get('name') + '?', 'You will prevent ' + (team.get('users.length') - 1) + ' members from accessing VMs').then(function () {
                    appInsights.trackEvent('ax-team-list:deleteTeam', { email: _this.get('session.data.authenticated.user.email'), team: team.get('name'), memberCount: team.get('team.users.length'), vmGroupCount: team.get('team.vmGroups.length') });
                    _this.get('emberNotificationCenter').pushNotification({
                        title: 'Deleting Team',
                        description: 'Deleting team "' + team.get('name') + '". ' + team.get('users.length') + ' members will no longer be able to access ' + team.get('vmGroups.length') + ' VM Groups.'
                    }, team.destroyRecord().catch(function (err) {
                        team.reload();
                        throw err;
                    }));
                });
            },
            closeModal: function closeModal() {
                this.get('modals').closeModal('#team-create-modal');
                this.set('modalSuccess', false);
            },
            addNewTeam: function addNewTeam() {
                this.get('modals').openModal('#team-create-modal');
            },
            showTeams: function showTeams() {
                this.set('showTeams', true);
                this.set('showInvites', false);
            },
            showInvites: function showInvites() {
                this.set('showTeams', false);
                this.set('showInvites', true);
            }
        }
    });
});