define('vmpower/helpers/not', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.not = not;
    function not(params) {
        return !params[0];
    }

    exports.default = Ember.Helper.helper(not);
});