define('vmpower/models/savings-record', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        timestamp: _emberData.default.attr('date'),
        savings: _emberData.default.attr('number'),
        savingsType: _emberData.default.attr('string'),
        instanceType: _emberData.default.attr('string'),
        hours: _emberData.default.attr('number'),
        instanceId: _emberData.default.attr('string'),
        targetInstanceType: _emberData.default.attr('string'),
        storageType: _emberData.default.attr('string'),
        volumeId: _emberData.default.attr('string'),
        pricePerHour: _emberData.default.attr('number'),
        source: _emberData.default.attr('string')
    });
});