define('vmpower/adapters/ember-notification-local-notification', ['exports', 'ember-notif-hub/adapters/ember-notification-local-notification'], function (exports, _emberNotificationLocalNotification) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    Object.defineProperty(exports, 'default', {
        enumerable: true,
        get: function () {
            return _emberNotificationLocalNotification.default;
        }
    });
});