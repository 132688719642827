define('vmpower/services/supported-providers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        names: function () {
            return ['aws', 'azure'];
        }.property()
    });
});