define('vmpower/components/ax-vm-tabular-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        checkedObserver: Ember.observer('selectAll', function (target) {
            if (target.get('selectAll')) {
                target.get('vms').forEach(function (vm) {
                    if (!vm.get('group') && vm.get('planEnabled')) {
                        vm.set('selected', true);
                    }
                });
            } else {
                target.get('vms').forEach(function (vm) {
                    if (!vm.get('group')) {
                        vm.set('selected', false);
                    }
                });
            }
        }),
        actions: {
            sort: function sort(field) {
                this.get('sort')(field);
            }
        }
    });
});