define('vmpower/components/ax-invoice-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
            Ember.run.scheduleOnce('afterRender', function () {
                Ember.$('.tooltipped').tooltip({ delay: 0 });
            });
        }
    });
});