define('vmpower/initializers/ember-stripe-elements', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];

    application.register('config:ember-stripe-elements', _environment.default, { instantiate: false });

    if (!_environment.default.stripe && !_environment.default.stripe.publishableKey) {
      throw new Ember.Error('StripeService: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js');
    }
  }

  exports.default = {
    name: 'ember-stripe-elements',
    initialize: initialize
  };
});