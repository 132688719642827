define("vmpower/templates/dashboard/auto-off", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 25
          }
        },
        "moduleName": "vmpower/templates/dashboard/auto-off.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "ax-auto-off-settings", [], ["account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [2, 12], [2, 19]]]]], [], []], "groups", ["subexpr", "@mut", [["get", "vmGroups", ["loc", [null, [3, 11], [3, 19]]]]], [], []], "targetGroup", ["subexpr", "@mut", [["get", "targetGroup", ["loc", [null, [4, 16], [4, 27]]]]], [], []], "autoOffWarningHours", ["subexpr", "@mut", [["get", "autoOffWarningHours", ["loc", [null, [5, 24], [5, 43]]]]], [], []], "autoOffHours", ["subexpr", "@mut", [["get", "autoOffHours", ["loc", [null, [6, 17], [6, 29]]]]], [], []], "ignoreHoursStartValue", ["subexpr", "@mut", [["get", "ignoreHoursStartValue", ["loc", [null, [7, 26], [7, 47]]]]], [], []], "ignoreHoursEndValue", ["subexpr", "@mut", [["get", "ignoreHoursEndValue", ["loc", [null, [8, 24], [8, 43]]]]], [], []], "autoOffTimezone", ["subexpr", "@mut", [["get", "autoOffTimezone", ["loc", [null, [9, 20], [9, 35]]]]], [], []], "schedules", ["subexpr", "@mut", [["get", "schedules", ["loc", [null, [10, 14], [10, 23]]]]], [], []]], ["loc", [null, [1, 0], [10, 25]]]]],
      locals: [],
      templates: []
    };
  }());
});