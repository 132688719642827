define('vmpower/routes/dashboard/vm-schedules/edit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        group: null,
        vmSchedule: null,
        modals: Ember.inject.service(),
        session: Ember.inject.service(),
        model: function model(params) {
            var _this = this;

            if (params['vm-schedule_id'] && params['vm-schedule_id'] !== 'new') {
                return this.store.find('vmSchedule', params['vm-schedule_id']).then(function (schedule) {
                    _this.set('group', schedule.get('vmGroup.id'));
                    return schedule;
                });
            } else {
                this.set('group', params.group);
            }
            return null;
        },
        setupController: function setupController(controller, model) {
            var user = this.get('session.data.authenticated.user');
            controller.set('user', this.store.findRecord('user', user.id));
            this.set('controller', controller);
            if (!model) {
                var newSchedule = this.store.createRecord('vmSchedule', {
                    name: 'New VM Schedule',
                    frequency: 1,
                    notifications: true,
                    warningSecs: 1800,
                    normally: 'on',
                    enabled: false
                });
                this.set('newSchedule', newSchedule);
                controller.set('schedule', newSchedule);
                this.store.find('vmGroup', this.get('group')).then(function (group) {
                    controller.set('groupModel', group);
                    newSchedule.set('name', group.get('name'));
                });
            } else {
                controller.set('schedule', model);
                controller.set('groupModel', model.get('vmGroup'));
            }
        },
        deactivate: function deactivate() {
            if (this.get('newSchedule.isNew')) {
                // the user left this route without saving the schedule
                // so trash it.
                this.get('newSchedule').destroyRecord();
            }
        },
        actions: {
            willTransition: function willTransition(transition) {
                var _this2 = this;

                if (!this.get('tempTransition') && this.get('controller.schedule.hasDirtyAttributes')) {
                    this.set('tempTransition', transition);
                    // temporarily abort the transition
                    transition.abort();
                } else {
                    this.set('tempTransition', undefined);
                    // allow higher level routers to decide if transition should be cancelled
                    return true;
                }
                return this.get('modals').confirmAction('Are you sure you want to abandon your changes?', 'Your schedule changes will be lost.').then(function () {
                    // clear schedule back to unmodified state
                    // including nested things
                    var weeklySchedule = _this2.store.peekRecord('weeklySchedule', _this2.get('controller.schedule.weeklySchedule.id'));
                    _this2.get('controller.schedule').rollbackAttributes();
                    weeklySchedule.rollbackAttributes();
                    weeklySchedule.eachRelationship(function (key, relationship) {
                        var day = weeklySchedule.get(key);
                        if (relationship.kind === 'hasMany') {
                            day.clear();
                        }
                    });
                    weeklySchedule.reload().then(function () {
                        moment.weekdays().forEach(function (day) {
                            day = day.toLowerCase();
                            _this2.get('controller.schedule.weeklySchedule.' + day).forEach(function (timespan) {
                                _this2.store.peekRecord('timespan', timespan.get('id')).rollbackAttributes();
                            });
                        });
                    });

                    return transition.retry();
                }).catch(function (err) {
                    Ember.debug(err);
                    _this2.set('tempTransition', undefined);
                    return transition.abort();
                });
            }
        }
    });
});