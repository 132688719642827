define('vmpower/routes/dashboard/teams/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        accounts: Ember.inject.service(),
        model: function model() {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            return this.store.findAll('team');
        },
        afterModel: function afterModel(model) {
            return Ember.RSVP.all(model.getEach('users'));
        },
        setupController: function setupController(controller, model) {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            controller.set('user', user);
            controller.set('teams', model);
            if (this.get('accounts').activeAccountSync()) {
                controller.set('account', this.get('accounts').activeAccountSync());
            }
            model.forEach(function (team) {
                team.get('users');
            });
            controller.set('router', this);
            this.set('controller', controller);
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            this.store.findAll('teamInvite').then(function (invites) {
                controller.set('invites', invites);
            });
        }
    });
});