define('vmpower/components/ax-export-create-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    accounts: Ember.inject.service(),
    modals: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      closeModal: function closeModal() {
        this.get('modals').closeModal('#export-create-modal');
        this.set('job', null);
      },
      makeExport: function makeExport() {
        var job = this.get('store').createRecord('export-job', {
          kind: 'VIRTUAL_MACHINE',
          executionState: 'SUBMITTED'
        });
        this.set('loading');
        this.set('job', job);
        job.save();
        var currentTime = Date.now();
        var that = this;
        var reloadJob = function reloadJob() {
          Ember.run.later(function () {
            that.get('job').reload();
            console.log('STATE', that.get('job.executionState'));
            if (that.get('job.executionState') !== 'COMPLETED' && that.get('job.executionState') !== 'ERROR') {
              reloadJob();
            }
          }, 1000);
        };

        reloadJob();
      }
    }
  });
});