define("vmpower/models/account", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    betaUser: _emberData.default.attr("boolean"),
    primaryUser: _emberData.default.belongsTo("user"),
    monthlyVmCost: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    affiliateId: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    couponCode: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    annualBilling: _emberData.default.attr("boolean", {
      defaultValue: false
    }),
    monthlyVolumeCost: _emberData.default.attr("number"),
    planExpireTime: _emberData.default.attr("date"),
    planExpiring: _emberData.default.attr("boolean", {
      defaultValue: false
    }),
    snapshotBalance: _emberData.default.attr("number", {
      defaultValue: false
    }),
    snapshotCap: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    nextSubscriptionBilling: _emberData.default.attr("date"),
    permissions: _emberData.default.attr("strings"),
    detectionFrequencyDays: _emberData.default.attr("number", {
      defaultValue: 1
    }),
    paymentMethod: _emberData.default.attr("string", {
      defaultValue: "card"
    }),
    payViaInvoice: function () {
      return this.get("paymentMethod") === "invoice";
    }.property("paymentMethod"),
    lastDetectionTime: _emberData.default.attr("date"),
    createdAt: _emberData.default.attr("date"),
    monthlySavings: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    /**
     * 0 - free
     * 0.5 - Basic
     * 1 - plus
     * 2 - pro
     */
    plan: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    autoEnableVms: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    // where did this user hear about us?
    source: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    planPercentage: _emberData.default.attr("number"),
    vmCount: _emberData.default.attr("number"),
    dbCount: _emberData.default.attr("number"),
    idleTrackingCount: _emberData.default.attr("number"),
    vmCap: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    dbCap: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    volumeCap: function () {
      return this.get("vmCap") * 2;
    }.property("vmCap"),
    pricePlan: _emberData.default.belongsTo("pricePlan"),
    priceCap: _emberData.default.attr("number", {
      defaultValue: 0.139
    }),
    cancellationReason: _emberData.default.attr("string"),
    hasPriceCap: function () {
      return false;
    }.property(),
    monthlyPriceCap: function () {
      return Math.round(this.get("priceCap") * 720 / 10) * 10;
    }.property("priceCap"),
    stripeSubId: _emberData.default.attr("string"),
    slackToken: _emberData.default.attr("string"),
    stripeId: _emberData.default.attr("string"),
    stripeToken: _emberData.default.attr("string"),
    teamCap: _emberData.default.attr("number"),
    teamMemberCap: _emberData.default.attr("number"),
    idleTrackingCap: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    organization: _emberData.default.attr("string"),
    trialUsed: _emberData.default.attr("boolean"),
    trialing: _emberData.default.attr("boolean"),
    billingName: _emberData.default.attr("string"),
    cardId: _emberData.default.attr("string"),
    cardBrand: _emberData.default.attr("string"),
    cardLast4: _emberData.default.attr("string"),
    cardZip: _emberData.default.attr("string"),
    invoiceDetails: _emberData.default.attr("string"),
    cardMonth: _emberData.default.attr("string"),
    cardYear: _emberData.default.attr("number"),
    vmGroups: _emberData.default.hasMany("vmGroup"),
    cloudSubscriptions: _emberData.default.hasMany("cloudSubscription"),
    credit: _emberData.default.attr("number"),
    monthlySavingsRounded: function () {
      return this.get("monthlySavings").toFixed(2);
    }.property("monthlySavings"),
    planName: function () {
      if (this.get("plan") === 0) {
        return "Free";
      } else if (this.get("plan") === 0.5) {
        return "Basic";
      } else if (this.get("plan") === 1) {
        return "Plus";
      } else if (this.get("plan") === 2) {
        return "Premium";
      } else {
        return "Special";
      }
    }.property("plan")
  });
});