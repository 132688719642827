define('vmpower/models/vm-schedule', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        vmGroup: _emberData.default.belongsTo('vmGroup'),
        notifications: _emberData.default.attr('boolean'),
        scheduleType: _emberData.default.attr('string'),
        frequency: _emberData.default.attr('number'),
        warningSecs: _emberData.default.attr('number'),
        startTime: _emberData.default.attr('date'),
        normally: _emberData.default.attr('string'),
        name: _emberData.default.attr('string'),
        enabled: _emberData.default.attr('boolean'),
        sequential: _emberData.default.attr('boolean'),
        timezone: _emberData.default.attr('string', {
            defaultValue: 'US/Pacific'
        }),
        engineWatchdog: _emberData.default.attr('boolean', {
            defaultValue: false
        }),
        weeklySchedule: _emberData.default.belongsTo('weeklySchedule')
    });
});