define('vmpower/services/accounts', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        store: Ember.inject.service(),
        localSettings: Ember.inject.service(),
        activeAccount: function activeAccount() {
            var _this = this;

            var activeAccount = this.get('account');
            if (activeAccount) {
                new Ember.RSVP.Promise(function (resolve) {
                    return resolve(activeAccount);
                });
            }
            return this.get('store').findAll('account').then(function (accounts) {
                _this.set('accounts', accounts);
                return _this.get('localSettings').getLocalSettings().then(function (settings) {
                    if (settings.get('pinnedAccount')) {
                        activeAccount = accounts.find(function (a) {
                            return a.id === settings.get('pinnedAccount');
                        });
                    } else {
                        activeAccount = accounts.get('firstObject');
                    }
                    if (activeAccount) {
                        _this.set('account', activeAccount);
                        return _this.get('store').findAll('permission');
                    }
                });
            }).then(function (permissions) {
                _this.set('permissions', permissions);
                return _this.get('account');
            });
        },
        permissionsSync: function permissionsSync() {
            return this.get('permissions');
        },
        activeAccountSync: function activeAccountSync() {
            return this.get('account');
        },
        setPinnedAccount: function setPinnedAccount(id) {
            return this.get('localSettings').getLocalSettings().then(function (settings) {
                // save sidebar state into local storage
                settings.set('pinnedAccount', id);
                return settings.save();
            });
        }
    });
});