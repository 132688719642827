define("vmpower/routes/application", ["exports", "ember-pusher", "vmpower/config/environment"], function (exports, _emberPusher, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_emberPusher.default.Bindings, {
    session: Ember.inject.service(),
    accounts: Ember.inject.service(),
    modals: Ember.inject.service(),
    showUpsellPopover: Ember.computed.alias("modals.showUpsellPopover"),
    pusher: Ember.inject.service(),
    pusherEvents: ["update", "delete"],
    init: function init() {
      this._super();
    },
    _noActiveAccount: function _noActiveAccount() {
      var _this = this;

      // we don't have an account... the only case
      // a user was created without an account is due to
      // a team invite, or the user was removed from a team
      this.store.findAll("teamInvite").then(function (invites) {
        if (invites && invites.get("length") > 0) {
          // user has some team invites -- take them there
          _this.transitionTo("dashboard.teams.invites");
          return;
        } else {
          // no accounts, no invites.
          // TODO: here we would just forward user to modal to create a new
          // VMPower account they would be the primary-user of.
          _this.get("modals").openModal("account-modal");
        }
      });
    },

    model: function model() {
      var _this2 = this;

      var user = this.get("session.data.authenticated.user");
      if (user) {
        return this.get("accounts").activeAccount().then(function (account) {
          if (!account) {
            return _this2._noActiveAccount();
          }
          return _this2.store.findRecord("user", user.id);
        }).catch(function (err) {
          Ember.debug(err);
          var notFound = false;
          err.errors.forEach(function (error) {
            if (error.status === "404") {
              notFound = true;
            }
          });
          if (notFound) {
            return;
          }
          _this2._noActiveAccount();
        });
      }
      return null;
    },
    setupController: function setupController(controller, model) {
      var _this3 = this;

      var router = Ember.getOwner(this).get("router");
      if (router.location.getURL().indexOf("/actions/vm") > -1 || router.location.getURL().indexOf("/actions/timespan") > -1 || router.location.getURL().indexOf("/dashboard/subscriptions") > -1 || router.location.getURL().indexOf("/dashboard/change-password") > -1 || router.location.getURL().indexOf("/signup") > -1) {
        // this front-end route does not require signin
        return;
      }
      if (!model) {
        return this.transitionTo("signin");
      }
      if (!model.get("emailConfirmed")) {
        return this.transitionTo("confirm");
      }
      var user = this.get("session.data.authenticated.user");
      var accessToken = this.get("session.data.authenticated.accessToken");
      Ember.debug("pusher key: " + _environment.default.APP.PUSHER_OPTS.key);
      Ember.debug("auth: " + _environment.default.apiHost + "/pusher-auth");
      this.set("pusher.updateSubscribers", []);
      this.set("pusher.deleteSubscribers", []);
      this.get("pusher").on = function (eventName, target) {
        if (eventName === "update") {
          _this3.get("pusher.updateSubscribers").push(target);
        } else if (eventName === "delete") {
          _this3.get("pusher.updateSubscribers").push(target);
        }
      };
      this.get("pusher").setup(_environment.default.APP.PUSHER_OPTS.key, {
        wsHost: _environment.default.wsHost,
        wsPort: _environment.default.environment === "development" ? _environment.default.wsPort : undefined,
        wssPort: _environment.default.environment === "development" ? undefined : _environment.default.wsPort,
        encrypted: _environment.default.environment === "development" ? false : true,
        enabledTransports: ["ws", "flash"],
        authEndpoint: _environment.default.apiHost + "/pusher-auth",
        auth: {
          headers: {
            "x-access-token": accessToken
          }
        }
      });
      var pusher = this.get("pusher");
      var account = this.get("accounts").activeAccountSync();
      // Signature for wire is wire(target, channelName, events)
      pusher.wire(this, this.get("session.data.authenticated.user.id") + "-model", this.get("pusherEvents"));
      if (model && account) {
        // signed in user
        window.Intercom("boot", {
          app_id: _environment.default.intercomAppId,
          name: user.firstName + " " + user.lastName,
          email: user.email,
          createdAt: user.createdAt,
          plan: account.get("plan"),
          vmCap: account.get("vmCap"),
          teamCap: account.get("teamCap"),
          teamMemberCap: account.get("teamMemberCap"),
          snapshotCap: account.get("snapshotCap"),
          planExpiring: account.get("planExpiring"),
          planExpireTime: account.get("planExpireTime"),
          trialing: account.get("trialing"),
          trial_used: account.get("trialUsed"),
          user_id: user.id,
          "Subscriptions Added": model.get("cloudSubscriptions.length") > 0
        });
      } else {
        // visitor
        window.Intercom("boot", {
          app_id: _environment.default.intercomAppId
        });
      }
    },
    actions: {
      update: function update(e) {
        this.store.find(e.type, e.id);
        this.get("pusher.updateSubscribers").forEach(function (target) {
          target.send("update", e);
        });
      },
      delete: function _delete(e) {
        var localRecord = this.store.peekRecord(e.type, e.id);
        if (localRecord) {
          localRecord.deleteRecord();
        }
        this.get("pusher.deleteSubscribers").forEach(function (target) {
          target.send("delete", e);
        });
      }
    }
  });
});