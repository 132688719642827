define('vmpower/controllers/dashboard/vm-rightsizing', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        hasAwsInstances: function () {
            var hasAws = false;
            this.get('vms').forEach(function (vm) {
                if (vm.get('provider') === 'aws') {
                    hasAws = true;
                    return;
                }
            });
            return hasAws;
        }.property('vms.@each.provider'),
        showAwsFlag: true,
        os: new Ember.A([new Ember.Object({
            name: 'Ubuntu Linux',
            value: 'ubuntu'
        }), new Ember.Object({
            name: 'Amazon Linux',
            value: 'amazon'
        }), new Ember.Object({
            name: 'SuSe Linux',
            value: 'suse'
        }), new Ember.Object({
            name: 'Redhat Linux',
            value: 'redhat'
        })]),
        subscriptionObserver: Ember.observer('user.awsSubscriptions.firstObject', function (target) {
            if (target.get('subscription')) {
                return;
            }
            // set the default subscription
            target.set('subscription', target.get('user.awsSubscriptions.firstObject'));
            // generate the default script
            target._generateScript();
            target.set('selectedOs', target.get('os.firstObject'));
        }),
        _generateScript: function _generateScript() {
            if (!this.get('selectedOs') || !this.get('subscription')) {
                return;
            }
            var script = 'bash <(curl --header "x-access-token: ' + this.get('session.data.authenticated.accessToken') + '" ' + _environment.default.apiHost + '/aws-collection-script/' + this.get('subscription').id + '?os=' + this.get('selectedOs.value') + ')';
            this.set('script', script);
        },
        actions: {
            openModal: function openModal() {
                this.set('showScriptModal', true);
            },
            closeModal: function closeModal() {
                this.set('showScriptModal', false);
            },
            generateScript: function generateScript() {
                this._generateScript();
            },
            clearAwsFlag: function clearAwsFlag() {
                this.set('showAwsFlag', false);
            }
        }
    });
});