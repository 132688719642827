define('vmpower/routes/dashboard/backups/backup', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        accounts: Ember.inject.service(),
        model: function model(params) {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            return this.store.findRecord('virtual-machine', params.vm_id);
        },
        setupController: function setupController(controller, model) {
            var _this = this;

            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            model.set('snapshotsLoading', true);
            model.set('snapshots', new Ember.A([]));
            controller.set('vm', model);
            this.store.query('snapshot', { limit: 20, vm: model.id }).then(function (snapshots) {
                snapshots.forEach(function (snapshot) {
                    model.get('snapshots').pushObject(snapshot);
                });
                model.set('snapshotsLoading', false);
                controller.set('account', _this.get('accounts').activeAccountSync());
            }).catch(function () {
                model.set('snapshotsLoading', false);
            });
        }
    });
});