define('vmpower/components/ax-snapshot-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Component.extend({
        searchByVMs: true,
        searchByVolumes: false,
        modals: Ember.inject.service(),
        emberNotificationCenter: Ember.inject.service(),
        store: Ember.inject.service(),
        hasMoreData: true,
        hasMoreVolumeData: true,
        hasMoreSnapshotData: true,
        vmListColums: [100],
        init: function init() {
            this._super();
            this._initCollapsible();
            this.set('hasMoreData', true);
            this.set('hasMoreVolumeData', true);
            this.set('hasMoreSnapshotData', true);
        },
        _initCollapsible: function _initCollapsible() {
            if (!this.get('selectedVm')) {
                Ember.run.scheduleOnce('afterRender', function () {
                    Ember.$('.collapsible').collapsible('close');
                    Ember.$('.material-tooltip').remove();
                    // open initial
                });
            }
            if (this.get('vms')) {
                this.get('vms').forEach(function (vm) {
                    vm.set('snapshots', null);
                });
            }
        },
        snapshotObserver: Ember.observer('selectedVm.snapshots', function () {
            Ember.run.scheduleOnce('afterRender', function () {
                Ember.$('.tooltipped').tooltip({ delay: 0 });
            });
        }),
        volSnapshotObserver: Ember.observer('selectedVolume.snapshots', function () {
            Ember.run.scheduleOnce('afterRender', function () {
                Ember.$('.tooltipped').tooltip({ delay: 0 });
            });
        }),
        selectAllObserver: Ember.observer('selectAll', function (target) {
            target.get('vms').forEach(function (vm) {
                if (target.get('selectAll')) {
                    vm.set('selected', true);
                } else {
                    vm.set('selected', false);
                }
            });
        }),
        filteredVmObserver: Ember.observer('searchTerm', function () {
            var _this = this;

            if (this.get('searchTerm') === null) {
                return;
            }
            if (this.get('lastScheduledSearch')) {
                Ember.run.cancel(this.get('lastScheduledSearch'));
            }
            var scheduled = Ember.run.later(function () {
                _this.get('hasMoreData', true);
                var route = void 0;
                if (_this.get('searchByVm')) {
                    route = 'route:dashboard:backups:vms';
                } else {
                    route = 'route:dashboard:backups:volumes';
                }
                getOwner(_this).lookup(route).send('doQuery', _this.get('searchTerm'));
            }, 500);
            this.set('lastScheduledSearch', scheduled);
        }),
        selectedVms: Ember.computed.filterBy('vms', 'selected'),
        actions: {
            closeBackupModal: function closeBackupModal() {
                this.set('showBackupModal', false);
            },
            showBackupModal: function showBackupModal() {
                this.set('showBackupModal', true);
            },
            closeRestoreModal: function closeRestoreModal() {
                this.set('showRestoreModal', false);
            },
            setSearchByVMs: function setSearchByVMs() {
                this.set('searchByVMs', true);
                this.set('searchByVolumes', false);
            },
            setSearchByVolumes: function setSearchByVolumes() {
                this.set('searchByVMs', false);
                this.set('searchByVolumes', true);
            },
            changeSnapshotExpiry: function changeSnapshotExpiry(snapshot) {
                this.set('activeSnapshot', snapshot);
                this.set('showExpiryModal', true);
            },
            restoreSnapshot: function restoreSnapshot(snapshot) {
                this.set('activeSnapshot', snapshot);
                this.set('showRestoreModal', true);
            },
            closeExpiryModal: function closeExpiryModal() {
                this.set('showExpiryModal', false);
            },
            showUpgradeModal: function showUpgradeModal() {
                this.get('modals').upgradeModal();
            },
            deleteSnapshot: function deleteSnapshot(snapshot) {
                var _this2 = this;

                this.get('modals').confirmAction('Delete Snapshot?', 'The snapshot ' + snapshot.get('snapshotId') + ' will be deleted. This cannot be undone').then(function () {
                    _this2.get('emberNotificationCenter').pushNotification({
                        title: 'Deleting Snapshot',
                        description: 'Deleting snaphot ' + snapshot.get('snapshotId')
                    }, snapshot.destroyRecord());
                });
            },
            scrolled: function scrolled(scrollLeft, scrollTop, lastObject) {
                var _this3 = this;

                if (lastObject.index + 7 > this.get('vms.length') && this.get('hasMoreData') && !this.get('loadingVms')) {
                    this.set('loadingVms', true);
                    var next = lastObject.item.id;
                    if (this.get('searchTerm')) {
                        next = this.get('vms.length');
                    }
                    this.get('store').query('virtual-machine', { limit: 10, next: next }).then(function (results) {
                        results.forEach(function (vm) {
                            if (!_this3.get('vms').contains(vm)) {
                                _this3.get('vms').pushObject(vm);
                            }
                        });
                        if (results.get('length') === 0) {
                            _this3.set('hasMoreData', false);
                        }
                        _this3.set('loadingVms', false);
                    });
                }
            },
            scrolledVolumes: function scrolledVolumes(scrollLeft, scrollTop, lastObject) {
                var _this4 = this;

                if (lastObject.index + 15 > this.get('volumes.length') && this.get('hasMoreVolumeData')) {
                    if (this.get('loadingVms')) {
                        return;
                    }
                    var next = lastObject.item.id;
                    if (this.get('searchTerm')) {
                        next = this.get('vms.length');
                    }
                    this.set('loadingVms', true);
                    this.get('store').query('volume', { limit: 15, next: next }).then(function (results) {
                        results.forEach(function (volume) {
                            if (!_this4.get('volumes').contains(volume)) {
                                _this4.get('volumes').pushObject(volume);
                            }
                        });
                        if (results.get('length') === 0) {
                            _this4.set('hasMoreVolumeData', false);
                        }
                        _this4.set('loadingVms', false);
                    });
                }
            },
            scrolledSnapshot: function scrolledSnapshot(scrollLeft, scrollTop, lastObject) {
                var _this5 = this;

                if (lastObject.index + 7 > this.get('selectedVm.snapshots.length') && this.get('hasMoreSnapshotData') && !this.get('selectedVm.loadingSnapshots')) {
                    this.set('loadingVms', true);
                    this.get('store').query('snapshot', { limit: 20, vm: this.get('selectedVm.id'), next: lastObject.item.id }).then(function (results) {
                        results.forEach(function (snapshot) {
                            if (!_this5.get('selectedVm.snapshots').contains(snapshot)) {
                                _this5.get('selectedVm.snapshots').pushObject(snapshot);
                            }
                        });
                        if (results.get('length') === 0) {
                            _this5.set('hasMoreSnapshotData', false);
                        }
                        _this5.set('loadingVms', false);
                    });
                }
            },
            scrolledSnapshotVolume: function scrolledSnapshotVolume(scrollLeft, scrollTop, lastObject) {
                var _this6 = this;

                if (lastObject.index + 7 > this.get('selectedVolume.snapshots.length') && this.get('hasMoreSnapshotData') && !this.get('loadingVms')) {
                    this.set('loadingVms', true);
                    this.get('store').query('snapshot', { limit: 20, vm: this.get('selectedVolume.id'), next: lastObject.item.id }).then(function (results) {
                        results.forEach(function (snapshot) {
                            if (!_this6.get('selectedVolume.snapshots').contains(snapshot)) {
                                _this6.get('selectedVolume.snapshots').pushObject(snapshot);
                            }
                        });
                        if (results.get('length') === 0) {
                            _this6.set('hasMoreSnapshotData', false);
                        }
                        _this6.set('loadingVms', false);
                    });
                }
            }
        }
    });
});