define('vmpower/components/ax-add-vmgroup-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        modals: Ember.inject.service(),
        emberNotificationCenter: Ember.inject.service(),
        session: Ember.inject.service(),
        targetGroup: null,
        init: function init() {
            this._super();
            this.set('targetGroup', this.get('groupListing')[0]);
        },
        groupListing: function () {
            var groupListing = new Ember.A([]);
            if (this.get('account.vmGroups')) {
                this.get('account.vmGroups').forEach(function (group) {
                    groupListing.pushObject(group);
                });
            }
            return groupListing;
        }.property('vmGroups'),
        actions: {
            closeModal: function closeModal() {
                this.get('modals').closeModal('#add-vmgroup-modal');
                this.set('modalSuccess', false);
                this.set('newGroupName', '');
            },
            add: function add() {
                var team = this.get('team');
                if (!team.get('vmGroups')) {
                    team.set('vmGroups', new Ember.A([]));
                }
                team.set('owner', this.get('account'));
                team.get('vmGroups').pushObject(this.get('targetGroup'));
                this.send('closeModal');
                appInsights.trackEvent('ax-add-vmgroup-modal:addVmGroup', { email: this.get('session.data.authenticated.user.email'), group: this.get('targetGroup.name'), team: this.get('team.name'), vmCount: this.get('targetGroup.vms.length') });
                return this.get('emberNotificationCenter').pushNotification({
                    title: 'Adding VM Group "' + this.get('targetGroup.name') + '" to team "' + this.get('team.name') + '"',
                    description: 'Users in this team will now have access to ' + this.get('targetGroup.vms.length') + ' additional VMs'
                }, team.save().catch(function (err) {
                    Ember.debug(err);
                    if (team.get('isNew')) {
                        team.deleteRecord();
                    }
                    throw err;
                }));
            }
        }
    });
});