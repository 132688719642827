define('vmpower/routes/landing', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController: function setupController() {
            window.location.replace(_environment.default.landingUrl);
        }
    });
});