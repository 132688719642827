define('vmpower/components/ax-vm-list-item', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		session: Ember.inject.service('session'),
		modals: Ember.inject.service(),
		accounts: Ember.inject.service(),
		emberNotificationCenter: Ember.inject.service(),
		powerManager: Ember.inject.service(),
		init: function init() {
			var _this = this;

			this._super();
			this.set('account', this.get('accounts').activeAccountSync());
			// TODO: does this really violate datadown, action up?
			this.set('registerAs', this);
			this.set('vm.selected', false);
			var id = this.get('vm.id');
			Ember.run.scheduleOnce('afterRender', function () {
				Ember.$('#' + _this.elementId + ' .tooltipped').tooltip({ delay: 0 });
				Ember.$('#' + id + '-idletracking-upsell-popover').mouseenter(function (evt) {
					var offset = Ember.$('#' + id + '-idletracking-upsell-popover').offset();
					_this.get('modals').openUpsellPopover(evt.pageX, offset.top - 165);
				}).mouseleave(function () {
					return _this.get('modals').closeUpsellPopover();
				});
			});
		},
		showCreate: function showCreate() {
			this.get('modals').openModal('#vm-create-modal');
		},
		planEnabledVms: Ember.computed.filterBy('vms', 'planEnabled', true),
		limited: function () {
			return this.get('account.vmCount') >= this.get('account.vmCap');
		}.property('account.vmCount', 'account.vmCap'),
		idleTrackingLimited: function () {
			if (this.get('account.hasDirtyAttributes')) {
				return true;
			}
			return this.get('account.idleTrackingCount') >= this.get('account.idleTrackingCap');
		}.property('account.idleTrackingCount', 'account.idleTrackingCap', 'account.hasDirtyAttributes'),
		dbLimited: function dbLimited() {
			return this.get('account.dbCount') >= this.get('account.dbCap');
		},
		vmpowerEnabledCheckboxObserver: Ember.observer('vm.planEnabled', function (target) {
			if (!target.get('vm.hasDirtyAttributes') || target.get('vm.planEnabled')) {
				return;
			}

			return target.get('modals').confirmAction('Disable VMPower Management?', 'This will free up an addition VMPower management slot however cause ' + target.get('vm.displayName') + ' to not have any Idle Tracking, Resize Recommendations, VM Schedules or Auto-off.').then(function () {
				return target.get('emberNotificationCenter').pushNotification({
					description: 'Removing VMPower Management of ' + target.get('vm.displayName'),
					title: 'Disabling VMPower Management'
				}, target.get('vm').save().then(function () {
					return target.get('account').reload();
				}).catch(function (e) {
					target.get('vm').rollbackAttributes();
					throw e;
				}));
			}).catch(function () {
				return target.get('vm').rollbackAttributes();
			});
		}),
		resizeCheckboxObserver: Ember.observer('vm.resizeRecommendations', function (target) {
			var description;
			var title;
			if (!target.get('vm.hasDirtyAttributes')) {
				return;
			}
			if (target.get('vm.resizeRecommendations')) {
				description = 'Enabling VM Rightsizing Recommendations for ' + target.get('vm.displayName');
				title = 'Enabling VM Rightsizing Recommendations';
				appInsights.trackEvent('ax-cloud-wizard:enable-rightsizing', {
					email: target.get('session.data.authenticated.account.email')
				});
			} else {
				description = 'Disabling VM Resize Recommendations for ' + target.get('vm.displayName');
				title = 'Disabling VM Rightsizing Recommendations';
				// clear current idle status then
				target.set('vm.recommendedResizeTypes', []);
				appInsights.trackEvent('ax-cloud-wizard:disable-rightsizing', {
					email: target.get('session.data.authenticated.account.email')
				});
			}
			target.get('emberNotificationCenter').pushNotification({
				title: title,
				description: description
			}, target.get('vm').save());
		}),
		idleCheckboxObserver: Ember.observer('vm.idleVmDetection', function (target) {
			var description;
			var title;
			if (!target.get('vm.hasDirtyAttributes')) {
				return;
			}
			if (target.get('vm.idleVmDetection')) {
				description = 'Enabling Idle Detection for ' + target.get('vm.displayName');
				title = 'Enabling Idle Detection';
				appInsights.trackEvent('ax-cloud-wizard:enable-idle-tracking', {
					email: target.get('session.data.authenticated.account.email')
				});
			} else {
				description = 'Disabling Idle Detection for ' + target.get('vm.displayName');
				title = 'Disabling Idle Detection';
				// clear current idle status then
				target.set('vm.isIdle', false);
				appInsights.trackEvent('ax-cloud-wizard:disable-idle-tracking', {
					email: target.get('session.data.authenticated.account.email')
				});
			}
			target.get('emberNotificationCenter').pushNotification({
				title: title,
				description: description
			}, target.get('vm').save().then(function () {
				return target.get('account').reload();
			}).catch(function (e) {
				target.get('vm').rollbackAttributes();
				throw e;
			}));
		}),
		actions: {
			showUpgradeModal: function showUpgradeModal() {
				this.get('modals').upgradeModal();
			},
			oneClickModal: function oneClickModal() {
				this.get('oneClickModal').send(this.get('vm'));
			},
			enableMangement: function enableMangement() {
				var _this2 = this;

				var count = 0;
				var dbCount = 0;
				this.get('vms').forEach(function (vm) {
					if (vm.get('planEnabled') && vm.get('osType') !== 'sql') {
						count += 1;
					}
				});
				this.get('vms').forEach(function (vm) {
					if (vm.get('planEnabled') && vm.get('osType') === 'sql') {
						dbCount += 1;
					}
				});
				if (this.get('vm.osType') !== 'sql' && count < this.get('account.vmCap') || this.get('vm.osType') === 'sql' && dbCount < this.get('account.dbCap')) {
					this.set('vm.planEnabled', true);
					return this.get('emberNotificationCenter').pushNotification({
						description: this.get('vm.displayName') + ' will now be able to join VM Groups and have Idle Alerts & Resize Recommendations',
						title: 'Enabling VMPower Management'
					}, this.get('vm').save().then(function () {
						return _this2.get('account').reload();
					}).catch(function (err) {
						_this2.get('vm').rollbackAttributes();
						throw err;
					}));
				}

				var thisVms = [];
				var thisInstanceIds = [];
				this.get('vms').forEach(function (vm) {
					if (count <= _this2.get('account.vmCap')) {
						return;
					}
					if (vm.id !== _this2.get('vm.id')) {
						thisVms.push(vm);
						thisInstanceIds.push(vm.get('displayName'));
						count -= 1;
					}
				});
				return this.get('modals').confirmAction('Enable VMPower Management?', 'This will put you over your allocated VMPower management slots of ' + this.get('account.vmCap') + '. We will automatically disable Resize Recommendations, Idle VM tracking, Auto-off and VM Schedule for the following VM(s):', thisInstanceIds, function () {
					var promises = [];
					thisVms.forEach(function (vm) {
						var p = vm.save().then(function () {
							return _this2.get('account').reload();
						});
						_this2.get('emberNotificationCenter').pushNotification({
							description: 'Removing VMPower Management of ' + vm.get('displayName'),
							title: 'Disabling VMPower Management'
						}, p.catch(function (e) {
							vm.rollbackAttributes();
							throw e;
						}));
						promises.push(p);
					});
					return Ember.RSVP.all(promises);
				}).then(function () {
					return _this2.get('emberNotificationCenter').pushNotification({
						description: 'Enabling VMPower Management of ' + _this2.get('vm.displayName'),
						title: 'Disabling VMPower Management'
					}, _this2.get('vm').save().then(function () {
						return _this2.get('account').reload();
					}).catch(function (e) {
						_this2.get('vm').rollbackAttributes();
						throw e;
					}));
				});
			},
			powerOff: function powerOff() {
				var _this3 = this;

				if (!this.get('vm.planEnabled')) {
					return;
				}
				this.get('modals').confirmAction('Power off VM ' + this.get('vm.instanceId') + '?', 'This will save you $' + this.get('vm.pricePerHour') + ' per hour on your ' + this.get('vm.provider') + ' bill. However, your VM attached disk will be preserved but your ephemeral storage may be lost. It will stop running any apps/computations.').then(function () {
					_this3.get('powerManager').powerOnOffInstance(_this3.get('vm'), 'off');
				});
			},
			powerOn: function powerOn() {
				var _this4 = this;

				if (!this.get('vm.planEnabled')) {
					return;
				}
				this.get('modals').confirmAction('Power on VM ' + this.get('vm.instanceId') + '?', 'Your VM will allocate and start incurring compute charges at $' + this.get('vm.pricePerHour') + 'USD' + ' per hour').then(function () {
					return _this4.get('powerManager').powerOnOffInstance(_this4.get('vm'), 'on');
				});
			},
			goToLink: function goToLink(link, t) {
				window.open(link);
				return false;
			},
			openUpgradeModal: function openUpgradeModal() {
				this.get('modals').upgradeModal();
			},
			moveVmUp: function moveVmUp(vm) {
				this.get('vmGroup');
			}
		}
	});
});