define('vmpower/components/ax-breadcrumbs', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Component.extend({
        router: function () {
            return getOwner(this).lookup('router:main');
        }.property(),
        breadcrumbs: function () {
            var _this = this;

            var breadcrumbs = [];
            this.get('router.currentPath').split('.').forEach(function (part, i) {
                var path = '';
                _this.get('router.currentPath').split('.').forEach(function (segment, z) {
                    if (z < i) {
                        path += segment + '.';
                    } else if (z === i) {
                        path += segment;
                    }
                });
                path = path.replace('.index', '');
                // exceptions
                if (path === 'dashboard') {
                    path = 'dashboard.home';
                }
                if (path === 'dashboard.vm-schedules') {
                    path = 'dashboard.vm-schedules.all';
                }
                part = part[0].toUpperCase() + part.substring(1, part.length);

                while (part.indexOf('-') > -1) {
                    var index = part.indexOf('-');
                    part = part.substring(0, index) + ' ' + part[index + 1].toUpperCase() + part.substring(index + 2, part.length);
                }
                if (part === 'Index') {
                    return;
                }
                breadcrumbs.push({
                    name: part,
                    path: path
                });
            });
            return breadcrumbs;
        }.property('router.currentPath')
    });
});