define('vmpower/components/ax-resize-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        mouseEnter: function mouseEnter() {
            this.set('showCard', true);
        },
        mouseLeave: function mouseLeave() {
            this.set('showCard', false);
        }
    });
});