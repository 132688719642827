define('vmpower/authorizers/application', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        authorize: function authorize(sessionData, block) {
            if (sessionData.user.admin) {
                // disable telemetry for test user
                appInsights.trackEvent = function () {};
                appInsights.trackMetric = function () {};
            }
            return block('x-access-token', sessionData.accessToken);
        }
    });
});