define('vmpower/components/ax-landing-page', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        didRender: function didRender() {
            Ember.run.scheduleOnce('afterRender', function () {
                var App = {
                    init: function init() {
                        App.Reviews();
                        App.Nivo_Lightbox(); // lightbox
                        App.Elements_animation(); // animations
                    },
                    // Elements Animation
                    Elements_animation: function Elements_animation() {},
                    // Nivo Lightbox
                    Nivo_Lightbox: function Nivo_Lightbox() {
                        Ember.$('.screenshots_gallery a').nivoLightbox({
                            effect: 'slideDown'
                        });
                    },
                    // Reviews Carousel
                    Reviews: function Reviews() {
                        Ember.$('.owl-carousel').owlCarousel({
                            loop: true,
                            singleItem: true
                        });
                    }
                };
                App.init();
                Ember.$(window).resize();
            });
        },
        allTimeSavings: function () {
            var _this = this;

            this._super();
            if (!this.get('stat.allTimeSavings')) {
                return 0;
            }
            Ember.run.scheduleOnce('afterRender', function () {
                Ember.debug('All time: ' + _this.get('stat'));
                // how many decimal places allows
                var decimalPlaces = 2;
                var decimalFactor = decimalPlaces === 0 ? 1 : Math.pow(10, decimalPlaces);
                Ember.$('#all-time-savings').animateNumber({
                    number: _this.get('stat.allTimeSavings') * decimalFactor,

                    numberStep: function numberStep(now, tween) {
                        var flooredNumber = Math.floor(now) / decimalFactor,
                            target = Ember.$(tween.elem);

                        if (decimalPlaces > 0) {
                            // force decimal places even if they are 0
                            flooredNumber = flooredNumber.toFixed(decimalPlaces);

                            // replace '.' separator with ','
                            flooredNumber = flooredNumber.toString();
                        }
                        flooredNumber = flooredNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        target.text('$' + flooredNumber);
                    }
                }, 1000);
            });
            return 0;
        }.property('stat.allTimeSavings'),
        isSignedIn: function () {
            var user = this.get('session.data.authenticated.user');
            if (!user || !user.id) {
                return false;
            }
            return true;
        }.property('session.data.authenticated.user')
    });
});