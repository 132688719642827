define('vmpower/routes/dashboard/one-click-links', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        init: function init() {
            this._super();
        },
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),
        accounts: Ember.inject.service(),
        setupController: function setupController(controller, user) {
            controller.set('user', user);
            controller.set('account', this.get('accounts').activeAccountSync());
            controller.set('links', new Ember.A([]));

            // TODO remove this when we are ready to enable links feature
            controller.set('loadingLinks', false);

            // controller.set('loadingLinks', true);
            // this.store.query('one-click-link', {limit: 15})
            //     .then(links => {
            //         let holder = new Ember.A([]);
            //         links.map(link => holder.push(link));
            //         controller.set('links', holder);
            //         controller.set('loadingLinks', false);
            //     });
        },
        model: function model() {
            var user = this.get('session.data.authenticated.user');
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            var id = this.get('session.data.authenticated.user.id');
            return this.store.find('user', id);
        }
    });
});