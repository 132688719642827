define('vmpower/routes/actions/timespan', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        modals: Ember.inject.service(),
        powerManager: Ember.inject.service(),
        setupController: function setupController(controller) {
            var _this = this;

            appInsights.trackEvent('route:actions:timespan', {
                timespanId: controller.get('timespan-id'),
                actionName: controller.get('action-name')
            });
            var action = controller.get('action-name');
            var timespan;
            var schedule;
            var p;
            return new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.$.ajax({
                    type: 'GET',
                    url: _environment.default.apiHost + '/vm-schedules/' + controller.get('schedule-id') + '?account=' + controller.get('account'),
                    json: true,
                    success: function success(result) {
                        schedule = result.data.attributes;
                        schedule.id = result.data.id;
                        controller.set('schedule', schedule);
                        resolve(schedule);
                    },
                    headers: {
                        'x-access-token': controller.get('token')
                    },
                    contentType: 'application/vnd.api+json'
                }).fail(function (err) {
                    controller.set('errors', err.responseJSON.errors);
                    reject(err.responseJSON);
                });
            }).then(function () {
                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.$.ajax({
                        type: 'GET',
                        url: _environment.default.apiHost + '/timespans/' + controller.get('timespan-id') + '?account=' + controller.get('account'),
                        json: true,
                        success: function success(result) {
                            timespan = result.data.attributes;
                            timespan.id = result.data.id;
                            controller.set('timespan', timespan);
                            resolve(timespan);
                        },
                        headers: {
                            'x-access-token': controller.get('token')
                        },
                        contentType: 'application/vnd.api+json'
                    }).fail(function (err) {
                        controller.set('errors', err.responseJSON.errors);
                        reject(err.responseJSON);
                    });
                });
            }).then(function () {
                if (!timespan) {
                    _this.get('modals').confirmAction('Error', 'Cloud not find that VM Scheduled event').then(function () {});
                } else if (action === 'skip') {
                    controller.set('actionText', 'Skipping VM Schedued Event');
                    // captialize first letter in provider name
                    p = _this.get('modals').confirmAction('Skip Scheduled Event?', 'VMPower will skip this ' + timespan['action-type'] + ' event for this week').then(function () {
                        controller.set('pending', true);
                        controller.set('message', 'Skipping ' + timespan['action-type'] + ' event');
                        var timespanStartMoment = moment.tz(schedule.timezone);
                        timespanStartMoment.subtract('hours', timespanStartMoment.hours());
                        timespanStartMoment.subtract('minutes', timespanStartMoment.minutes());
                        timespanStartMoment.subtract('seconds', timespanStartMoment.seconds());
                        timespanStartMoment.subtract('milliseconds', timespanStartMoment.milliseconds());
                        // timespan start time plus 10 minutes as the last executed time
                        // the scheduler will not execute the event since it will be less than the
                        // required > 1 hour delta between last execution time and current time
                        timespanStartMoment.add('milliseconds', parseInt(timespan['start-milis']) + 600000);
                        timespan['last-execution-time'] = timespanStartMoment.toDate().toISOString();
                        return new Ember.RSVP.Promise(function (resolve, reject) {
                            delete timespan.id;
                            Ember.$.ajax({
                                type: 'PATCH',
                                url: _environment.default.apiHost + '/timespans/' + controller.get('timespan-id') + '?account=' + controller.get('account'),
                                json: true,
                                data: JSON.stringify({
                                    data: {
                                        type: 'timespans',
                                        id: controller.get('timespan-id'),
                                        attributes: timespan
                                    }
                                }),
                                success: function success() {
                                    controller.set('pending', false);
                                    controller.set('success', true);
                                    controller.set('message', 'Skipped ' + timespan['action-type'] + ' event.');
                                },
                                headers: {
                                    'x-access-token': controller.get('token')
                                },
                                contentType: 'application/vnd.api+json'
                            }).fail(function (err) {
                                controller.set('errors', err.responseJSON.errors);
                                controller.set('failed', true);
                                controller.set('pending', false);
                                reject(err.responseJSON);
                            });
                        });
                    });
                }
            });
        }
    });
});