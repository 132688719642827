define("vmpower/templates/components/ax-blog-beacon", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["empty-body"]
          },
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 2,
              "column": 0
            }
          },
          "moduleName": "vmpower/templates/components/ax-blog-beacon.hbs"
        },
        isEmpty: true,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "vmpower/templates/components/ax-blog-beacon.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "fixed-action-btn card-panel blog-beacon white-text hide-on-med-and-down");
          dom.setAttribute(el1, "style", "top: 60px; right: 19px; height: 80px; width: 300px");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "id", "beacon-icon");
          dom.setAttribute(el2, "class", "material-icons beacon-icon");
          var el3 = dom.createTextNode("settings_input_antenna");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h5");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "class", "white-text");
          dom.setAttribute(el3, "target", "_blank");
          var el4 = dom.createElement("strong");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "right");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "http://blog.vmpower.io");
          dom.setAttribute(el3, "target", "_blank");
          var el4 = dom.createElement("strong");
          dom.setAttribute(el4, "class", "white-text");
          var el5 = dom.createTextNode("Plus ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" more posts");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [2, 0]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element1, 'href');
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [4]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [6, 0, 0]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "href", ["concat", [["get", "posts.firstObject.link", ["loc", [null, [4, 123], [4, 145]]]]]]], ["content", "posts.firstObject.title", ["loc", [null, [4, 173], [4, 200]]]], ["content", "posts.firstObject.description", ["loc", [null, [5, 11], [5, 44]]]], ["content", "posts.length", ["loc", [null, [6, 106], [6, 122]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-blog-beacon.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "hidden", ["loc", [null, [1, 6], [1, 12]]]]], [], 0, 1, ["loc", [null, [1, 0], [8, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});