define('vmpower/models/timespan', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    timespanType: _emberData.default.attr('string'), // 'daily', 'absolute'
    lastExecutionTime: _emberData.default.attr('date'),
    date: _emberData.default.attr('date'),
    startMilis: _emberData.default.attr('number'),
    endMilis: _emberData.default.attr('number'),
    actionType: _emberData.default.attr('string'),
    currentUserSessionSkip: _emberData.default.attr('boolean'),
    backupRetentionDays: _emberData.default.attr('number', {
      defaultValue: 7
    }),
    displayColor: function () {
      var actionType = this.get('actionType');
      if (actionType === 'POWER ON') {
        return '#33e22d';
      } else if (actionType === 'POWER OFF') {
        return 'red';
      } else if (actionType === 'RESIZE') {
        return 'orange';
      } else if (actionType === 'BACKUP') {
        return 'purple';
      } else if (actionType === 'RESTART') {
        return 'blue';
      }
    }.property('actionType'),
    // http://stackoverflow.com/questions/10073699/pad-a-number-with-leading-zeros-in-javascript
    pad: function pad(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },
    resizeActions: _emberData.default.hasMany('resizeAction'),
    startTime: function () {
      var now = moment();
      now.subtract(now.milliseconds(), 'miliseconds');
      now.subtract(now.seconds(), 'seconds');
      now.subtract(now.minutes(), 'minutes');
      now.subtract(now.hours(), 'hours');
      now.add(this.get('startMilis', 'miliseconds'));
      return {
        day: moment.weekdays(now.days()),
        hour: this.pad(now.hours(), 2),
        minutes: this.pad(now.minutes(), 2)
      };
    }.property('startMilis'),
    endTime: function () {
      var now = moment();
      now.subtract(now.milliseconds(), 'miliseconds');
      now.subtract(now.seconds(), 'seconds');
      now.subtract(now.minutes(), 'minutes');
      now.subtract(now.hours(), 'hours');
      now.add(this.get('endMilis', 'miliseconds'));
      return {
        day: moment.weekdays(now.days()),
        hour: this.pad(now.hours(), 2),
        minutes: this.pad(now.minutes(), 2)
      };
    }.property('endMilis')
  });
});