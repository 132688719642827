define('vmpower/helpers/url-encode', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.urlEncode = urlEncode;
    function urlEncode(params) {
        var result = '';
        params.forEach(function (p) {
            result += encodeURIComponent(p);
        });
        return result;
    }

    exports.default = Ember.Helper.helper(urlEncode);
});