define('vmpower/components/ax-team-create-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Component.extend({
        modals: Ember.inject.service(),
        session: Ember.inject.service(),
        emberNotificationCenter: Ember.inject.service(),
        nameTaken: function () {
            var _this = this;

            var taken = false;
            if (!this.get('account.teams')) {
                return false;
            }
            this.get('account.teams').forEach(function (team) {
                if (team.get('name') === _this.get('newTeamName')) {
                    taken = true;
                }
            });
            return taken;
        }.property('user.@teams.each', 'newTeamName'),
        actions: {
            newTeam: function newTeam() {
                var _this2 = this;

                var team = this.get('user.store').createRecord('team', {
                    name: this.get('newTeamName'),
                    users: new Ember.A([this.get('user')]),
                    vmGroups: new Ember.A([]),
                    owner: this.get('account')
                });
                appInsights.trackEvent('ax-team-create-modal:newTeam', { email: this.get('session.data.authenticated.user.email'), teamName: this.get('newTeamName') });
                this.set('modalSuccess', true);
                this.get('emberNotificationCenter').pushNotification({
                    title: 'Creating new Team "' + this.get('newTeamName') + '"',
                    description: 'You will now be able to add users and acessible VM groups to this team'
                }, team.save().then(function () {
                    getOwner(_this2).lookup('route:dashboard.teams').transitionTo('dashboard.teams.team', team);
                }));
                this.send('closeModal');
            },
            closeModal: function closeModal() {
                this.get('modals').closeModal('#team-create-modal');
                this.set('modalSuccess', false);
                this.set('newTeamName', '');
            }
        }
    });
});