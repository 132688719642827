define('vmpower/models/resize-action', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        virtualMachine: _emberData.default.belongsTo('virtualMachine'),
        resizeTarget: _emberData.default.belongsTo('vmType')
    });
});