define('vmpower/services/local-settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        getLocalSettings: function getLocalSettings() {
            var _this = this;

            var user = this.get('session.data.authenticated.user');
            if (!user || !user.id) {
                return new Ember.RSVP.Promise(function (resolve) {
                    return resolve(null);
                });
            }
            return this.get('store').findAll('local-setting').catch(function () {
                return null;
            }).then(function (result) {
                var settings = result.find(function (setting) {
                    return setting.id === user.id;
                });
                if (!settings) {
                    settings = _this.get('store').createRecord('local-setting', { id: user.id });
                    return settings.save();
                }
                return settings;
            });
        }
    });
});