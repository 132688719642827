define('vmpower/services/notifications', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        init: function init() {
            var _this = this;

            this._super();
            this.store = Ember.getOwner(this).lookup('route:application').store;
            this.store.findAll('local-notification').then(function (notifications) {
                notifications.forEach(function (notification) {
                    if (notification.get('hasDirtyAttributes')) {
                        return;
                    }
                    _this.get('emberNotificationCenter').pushObject(notification);
                });
            });
            this.store.findAll('local-error');
        },
        notifications: Ember.A([]),
        clearAll: function clearAll() {
            var newArray = Ember.A([]);

            var _loop = function _loop() {
                if (notification.get('status') === 'Pending') {
                    newArray.push(notification);
                    return 'continue';
                }
                var promises = [];
                notification.get('errors').forEach(function (error) {
                    promises.push(error.destroyRecord());
                });
                notification.destroyRecord();
            };

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('emberNotificationCenter')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var notification = _step.value;

                    var _ret = _loop();

                    if (_ret === 'continue') continue;
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            this.set('notifications', newArray);
        },
        inProgress: function () {
            var count = 0;
            this.get('emberNotificationCenter').forEach(function (notif) {
                if (notif.get('status') === 'Pending') {
                    count += 1;
                }
            });
            Ember.debug('in progress: ');
            Ember.debug(count);
            return count;
        }.property('notifications.@each.status'),
        failed: function () {
            var count = 0;
            this.get('emberNotificationCenter').forEach(function (notif) {
                if (notif.get('status') === 'Failed') {
                    count += 1;
                }
            });

            return count;
        }.property('notifications.@each.status'),
        succeeded: function () {
            var count = 0;
            Ember.debug('counting succeeded...');
            this.get('emberNotificationCenter').forEach(function (notif) {
                Ember.debug(notif.status);
                if (notif.get('status') === 'Success') {
                    count += 1;
                }
            });
            Ember.debug('succeeded: ');
            Ember.debug(count);
            return count;
        }.property('notifications.@each.status'),
        notificationsObserver: function () {
            var _this2 = this;

            this.store.findAll('localNotification').then(function (notifications) {
                _this2.set('notifications', notifications);
            });
        }.property('notifications.@each', 'notifications'),
        pushNotification: function pushNotification(notification, promise) {
            var record = this.store.createRecord('local-notification', notification);
            if (!promise) {
                this._pushAlertNotification(record);
            } else if (promise.then) {
                record.promise = promise;
                this._pushTaskNotification(record);
            }
        },
        _pushAlertNotification: function _pushAlertNotification(notification) {
            notification.save();
            this.notifications.unshiftObject(notification);
        },
        _pushTaskNotification: function _pushTaskNotification(notification) {
            var _this3 = this;

            notification.set('status', 'Pending');
            this.get('emberNotificationCenter').unshiftObject(notification);
            notification.promise.then(function () {
                Ember.debug('setting succeeded');
                notification.set('status', 'Success');
                notification.save();
            }).catch(function (err) {
                var promise = new Ember.RSVP.Promise(function (resolve) {
                    resolve();
                });
                if (!err) {
                    return notification.set('status', 'Failed');
                }
                if (Array.isArray(err)) {
                    var errPromises = [];
                    err.forEach(function (error) {
                        var localError = _this3.store.createRecord('local-error', error);
                        notification.get('errors').pushObject(localError);
                        errPromises.push(localError.save());
                    });
                    promise = Ember.RSVP.all(errPromises).then(function () {
                        return notification.save();
                    });
                } else if (err.errors) {
                    var _errPromises = [];
                    err.errors.forEach(function (error) {
                        var localError = _this3.store.createRecord('local-error', error);
                        notification.get('errors').pushObject(localError);
                        _errPromises.push(localError.save());
                    });
                    promise = Ember.RSVP.all(_errPromises).then(function () {
                        return notification.save();
                    });
                } else if (err.message) {
                    var localError = _this3.store.createRecord('local-error', {
                        code: 'Unknown Error',
                        title: err.message
                    });
                    notification.get('errors').pushObject(localError);
                    promise = localError.save();
                }
                promise.then(function () {
                    notification.set('status', 'Failed');
                    notification.save().then(function () {
                        Ember.debug('saved local notification!');
                    }).catch(function (err) {
                        Ember.debug(err.message);
                    });
                });
            });
        }
    });
});