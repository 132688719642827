define('vmpower/models/datapoint', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        instanceId: _emberData.default.attr('string'),
        timestamp: _emberData.default.attr('date'),
        pointType: _emberData.default.attr('string'),
        cpuPercentageTime: _emberData.default.attr('number'),
        diskWriteAverageBytesPerSecond: _emberData.default.attr('number'),
        memoryPercentAvailable: _emberData.default.attr('number'),
        networkOutBytesPerSecond: _emberData.default.attr('number'),
        networkInBytesPerSecond: _emberData.default.attr('number'),
        memoryPercentUsed: function () {
            if (isNaN(this.get('memoryPercentAvailable'))) {
                return null;
            }
            return 100 - this.get('memoryPercentAvailable');
        }.property('memoryPercentAvailable'),
        diskReadAverageBytesPerSecond: _emberData.default.attr('number'),
        iops: _emberData.default.attr('number')
    });
});