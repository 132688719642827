define('vmpower/helpers/parse-int', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.parseInteger = parseInteger;
	function parseInteger(params /*, hash*/) {
		if (!Array.isArray(params)) {
			return 0;
		}
		if (params[0] && typeof params[0] === 'string') {
			return parseInt(params[0]);
		}
		return params[0];
	}

	exports.default = Ember.Helper.helper(parseInteger);
});