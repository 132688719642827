define("vmpower/templates/components/ax-resize-popover", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 19,
              "column": 4
            }
          },
          "moduleName": "vmpower/templates/components/ax-resize-popover.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "preloader-wrapper large active");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "spinner-layer spinner-yellow-only");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "circle-clipper left");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "circle");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "gap-patch");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "circle");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "circle-clipper right");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "circle");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 6
                  },
                  "end": {
                    "line": 32,
                    "column": 6
                  }
                },
                "moduleName": "vmpower/templates/components/ax-resize-popover.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "col s12");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "row");
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                var el4 = dom.createElement("span");
                dom.setAttribute(el4, "class", "truncate");
                var el5 = dom.createElement("i");
                dom.setAttribute(el5, "class", "material-icons left");
                var el6 = dom.createTextNode("computer");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" →\n            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n            ($");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("/mo)");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                dom.setAttribute(el3, "class", "subtitle");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" vCPUs, ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" GiB Memory");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1, 1]);
                var element1 = dom.childAt(element0, [1]);
                var element2 = dom.childAt(element0, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element1, [0]), 1, 1);
                morphs[1] = dom.createMorphAt(element1, 2, 2);
                morphs[2] = dom.createMorphAt(element1, 4, 4);
                morphs[3] = dom.createMorphAt(element2, 0, 0);
                morphs[4] = dom.createMorphAt(element2, 2, 2);
                return morphs;
              },
              statements: [["content", "vm.instanceName", ["loc", [null, [26, 79], [26, 98]]]], ["content", "action.resizeTarget.instanceType", ["loc", [null, [27, 12], [27, 48]]]], ["content", "action.resizeTarget.monthlyCost", ["loc", [null, [28, 14], [28, 49]]]], ["content", "action.resizeTarget.cpus", ["loc", [null, [29, 30], [29, 58]]]], ["content", "action.resizeTarget.memoryGiB", ["loc", [null, [29, 66], [29, 99]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 6
                },
                "end": {
                  "line": 33,
                  "column": 6
                }
              },
              "moduleName": "vmpower/templates/components/ax-resize-popover.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "is-equal", [["get", "action.virtualMachine.id", ["loc", [null, [23, 22], [23, 46]]]], ["get", "vm.id", ["loc", [null, [23, 47], [23, 52]]]]], [], ["loc", [null, [23, 12], [23, 53]]]]], [], 0, null, ["loc", [null, [23, 6], [32, 13]]]]],
            locals: ["action"],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 6
              },
              "end": {
                "line": 34,
                "column": 6
              }
            },
            "moduleName": "vmpower/templates/components/ax-resize-popover.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "timespan.resizeActions", ["loc", [null, [22, 14], [22, 36]]]]], [], 0, null, ["loc", [null, [22, 6], [33, 15]]]]],
          locals: ["vm"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 4
            },
            "end": {
              "line": 36,
              "column": 4
            }
          },
          "moduleName": "vmpower/templates/components/ax-resize-popover.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row resize-list");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "selectedVms", ["loc", [null, [21, 14], [21, 25]]]]], ["key", "@index"], 0, null, ["loc", [null, [21, 6], [34, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 38,
            "column": 6
          }
        },
        "moduleName": "vmpower/templates/components/ax-resize-popover.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "resize-popover");
        dom.setAttribute(el1, "class", "popover green white-text");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "title");
        var el4 = dom.createTextNode("Scheduled resize actions");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element3, 'style');
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]), 3, 3);
        return morphs;
      },
      statements: [["attribute", "style", ["concat", ["position: fixed; top: ", ["subexpr", "subtract", [["get", "cursorY", ["loc", [null, [2, 42], [2, 49]]]], 200], [], ["loc", [null, [2, 31], [2, 55]]]], "px; left: ", ["get", "cursorX", ["loc", [null, [2, 67], [2, 74]]]], "px"]]], ["block", "if", [["get", "timespan.resizeActions.firstObject.isLoading", ["loc", [null, [5, 10], [5, 54]]]]], [], 0, 1, ["loc", [null, [5, 4], [36, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});