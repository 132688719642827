define('vmpower/routes/dashboard/home', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        init: function init() {
            this._super();
        },
        accounts: Ember.inject.service(),
        setupController: function setupController(controller, user) {
            controller.set('loadingVms', true);
            this.store.query('virtual-machine', { limit: 15 }).then(function (vms) {
                var holder = new Ember.A([]);
                vms.map(function (vm) {
                    return holder.push(vm);
                });
                controller.set('vms', holder);
                controller.set('loadingVms', false);
            });
            this.store.query('virtual-machine', { 'is-idle': true }).then(function (vms) {
                var holder = new Ember.A([]);
                vms.map(function (vm) {
                    return holder.push(vm);
                });
                controller.set('idleVms', holder);
                controller.set('loadingVms', false);
            });
            this.store.findAll('vm-schedule').then(function (schedules) {
                controller.set('schedules', schedules);
            });
            this.store.findAll('vm-group').then(function (groups) {
                controller.set('groups', groups);
            });
            controller.set('loadingVolumes', true);
            this.store.query('volume', { limit: 15 }).then(function (volumes) {
                var holder = new Ember.A([]);
                volumes.map(function (vm) {
                    return holder.push(vm);
                });
                controller.set('volumes', holder);
                controller.set('loadingVolumes', false);
            });
            this.set('controller.user', user);
            this.set('controller.account', this.get('accounts').activeAccountSync());
        },
        session: Ember.inject.service('session'),
        model: function model() {
            var user = this.get('session.data.authenticated.user');
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            var id = this.get('session.data.authenticated.user.id');
            return this.store.find('user', id);
        }
    });
});