define('vmpower/services/power-manager', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        session: Ember.inject.service(),
        accounts: Ember.inject.service(),
        emberNotificationCenter: Ember.inject.service(),
        resizeInstance: function resizeInstance(vm, targetType) {
            var _this = this;

            var accessToken = this.get('session.data.authenticated.accessToken');
            vm.set('resizing', true);
            var promise = new Ember.RSVP.Promise(function (resolve) {
                return resolve();
            });
            var originalPowerstate = vm.get('powerState');
            if (vm.get('powerState') !== 'off') {
                // power off vm
                promise = promise.then(function () {
                    return _this.powerOnOffInstance(vm, 'off');
                });
            }
            promise = promise.then(function () {
                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.$.ajax({
                        type: 'POST',
                        url: _environment.default.apiHost + '/resize?vm-id=' + vm.get('instanceId') + '&target-type=' + targetType,
                        json: true,
                        success: function success(data) {
                            if (data.status === 'success') {
                                Ember.debug('successfully resized ' + vm.get('instanceId'));
                                vm.reload().then(function () {
                                    resolve();
                                }).catch(function (err) {
                                    reject(err);
                                });
                            } else {
                                reject(data.errors);
                            }
                        },
                        headers: {
                            'x-access-token': accessToken
                        },
                        contentType: 'application/vnd.api+json'
                    }).fail(function (data) {
                        reject(data.responseJSON);
                    });
                });
            });
            var notification;
            notification = {
                title: 'Resizing VM ' + vm.get('displayName'),
                description: 'Resizing ' + vm.get('displayName') + ' from ' + vm.get('instanceType') + ' to ' + targetType
            };
            this.get('emberNotificationCenter').pushNotification(notification, promise.then(function () {
                return vm.reload();
            }).then(function () {
                // if the VM was originally on, turn it back on
                if (originalPowerstate !== 'off') {
                    Ember.debug('restoring original vm state');
                    return _this.powerOnOffInstance(vm, 'on');
                }
            }));
            return promise.finally(function () {
                vm.set('resizing', false);
            });
        },
        // requires user to be signed in
        deleteVolume: function deleteVolume(volume) {
            var _this2 = this;

            var accessToken = this.get('session.data.authenticated.accessToken');
            accessToken = accessToken;
            volume.set('deleting', true);
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.$.ajax({
                    type: 'POST',
                    url: _environment.default.apiHost + '/volume-action?volume-id=' + encodeURIComponent(volume.get('volumeId')) + '&action=delete&account=' + _this2.get('accounts').activeAccountSync().id,
                    json: true,
                    success: function success(data) {
                        if (data.status === 'success') {
                            Ember.debug('successfully deleted ' + volume.get('volumeId'));
                            // the api immediatley deletes this record so no need to commit
                            volume.unloadRecord();
                            resolve();
                        } else {
                            reject(data.errors);
                        }
                    },
                    headers: {
                        'x-access-token': accessToken
                    },
                    contentType: 'application/vnd.api+json'
                }).fail(function (data) {
                    reject(data.responseJSON);
                });
            });
            this.get('emberNotificationCenter').pushNotification({
                title: 'Deleting Volume',
                description: 'After this, volume ' + volume.get('volumeId') + ' will be permanently deleted and save you $' + volume.get('monthlyCost') + '/month.'
            }, promise);
            return promise;
        },
        backupInstance: function backupInstance(vm, days) {
            var _this3 = this;

            var accessToken = this.get('session.data.authenticated.accessToken');
            accessToken = accessToken;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.$.ajax({
                    type: 'POST',
                    url: _environment.default.apiHost + '/backup?vm=' + vm.id + '&days=' + days + '&account=' + _this3.get('accounts').activeAccountSync().id,
                    json: true,
                    success: function success(data) {
                        if (data.status === 'success') {
                            Ember.debug('successfully started backed of ' + vm.get('displayName'));
                            resolve();
                        } else {
                            reject(data.errors);
                        }
                    },
                    headers: {
                        'x-access-token': accessToken
                    },
                    contentType: 'application/vnd.api+json'
                }).fail(function (data) {
                    reject(data.responseJSON);
                });
            });
            this.get('emberNotificationCenter').pushNotification({
                title: 'Backing Up VM ' + vm.get('displayName'),
                description: 'All volumes attached to ' + vm.get('displayName') + ' will be securely backed up.'
            }, promise);
            return promise;
        },
        restoreVolumeFromSnapshot: function restoreVolumeFromSnapshot(snapshot, name) {
            var accessToken = this.get('session.data.authenticated.accessToken');
            var path = '/restore?snapshot=' + snapshot.id + '&account=' + this.get('accounts').activeAccountSync().id;
            if (name) {
                path += '&name=' + name;
            }
            accessToken = accessToken;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.$.ajax({
                    type: 'POST',
                    url: _environment.default.apiHost + path,
                    json: true,
                    success: function success(data) {
                        if (data.status === 'success') {
                            Ember.debug('successfully started creation of ' + snapshot.get('snapshotId'));
                            resolve();
                        } else {
                            reject(data.errors);
                        }
                    },
                    headers: {
                        'x-access-token': accessToken
                    },
                    contentType: 'application/vnd.api+json'
                }).fail(function (data) {
                    reject(data.responseJSON);
                });
            });
            this.get('emberNotificationCenter').pushNotification({
                title: 'Restoring Snapshot to new Disk',
                description: 'A new volume on ' + snapshot.get('provider') + ' based on a backup from ' + moment(snapshot.get('timestamp')).format() + ' will finish creating soon.'
            }, promise);
            return promise;
        },
        powerOnOffInstance: function powerOnOffInstance(vm, action, token) {
            var accessToken = this.get('session.data.authenticated.accessToken');
            accessToken = accessToken || token;
            vm.set('poweringOff', true);
            vm.set('poweringOn', true);
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.$.ajax({
                    type: 'POST',
                    url: _environment.default.apiHost + '/power?vm-id=' + vm.id + '&action=' + action,
                    json: true,
                    success: function success(data) {
                        if (data.status === 'success') {
                            Ember.debug('successfully powered ' + action + ' ' + vm.get('instanceId'));
                            // this may not be an ember model since the one-click
                            // functionality does not authenticate a user
                            if (!vm.reload) {
                                resolve();
                            }
                            vm.set('powerState', action);
                            vm.save().then(function () {
                                resolve();
                            }).catch(function (err) {
                                reject(err);
                            });
                        } else {
                            reject(data.errors);
                        }
                    },
                    headers: {
                        'x-access-token': accessToken
                    },
                    contentType: 'application/vnd.api+json'
                }).fail(function (data) {
                    reject(data.responseJSON);
                });
            });
            var notification;
            if (action === 'off') {
                notification = {
                    title: 'Deallocating VM ' + vm.get('instanceId') + ' from ' + vm.get('provider'),
                    description: 'After this is complete ' + vm.get('instanceId') + ' will be deallocated and won\'t incur compute charges.'
                };
            } else {
                notification = {
                    title: 'Allocating VM ' + vm.get('instanceId') + ' from ' + vm.get('provider'),
                    description: 'After this is complete ' + vm.get('instanceId') + ' will be allocated and will start incuring compute charges.'
                };
            }
            this.get('emberNotificationCenter').pushNotification(notification, promise);
            return promise.finally(function () {
                vm.set('poweringOff', false);
                vm.set('poweringOn', false);
            });
        }
    });
});