define('vmpower/components/ax-vm-overview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
        },

        vmListColumns: [100],
        store: Ember.inject.service(),
        hasMoreData: true,
        emberNotificationCenter: Ember.inject.service(),
        modals: Ember.inject.service(),
        actions: {
            oneClickModal: function oneClickModal(vm) {
                this.get('modals').openOneClickModal(vm);
            },
            scrolled: function scrolled(scrollLeft, scrollTop, lastObject) {
                var _this = this;

                if (lastObject.index + 7 > this.get('vms.length') && this.get('hasMoreData') && !this.get('loadingMore')) {
                    this.set('loadingMore', true);
                    this.get('store').query('virtual-machine', { limit: 15, next: lastObject.item.id }).then(function (results) {
                        results.forEach(function (vm) {
                            if (!_this.get('vms').contains(vm)) {
                                _this.get('vms').pushObject(vm);
                            }
                        });
                        if (results.get('length') === 0) {
                            Ember.$('#vm-overview-preload').hide();
                            _this.set('hasMoreData', false);
                        }
                        _this.set('loadingMore', false);
                    });
                }
            },
            refresh: function refresh() {
                var _this2 = this;

                var p = this.get('store').query('cloud-subscription', { reload: 'true' }).then(function () {
                    return _this2.get('store').findAll('virtual-machine');
                });
                this.get('emberNotificationCenter').pushNotification({
                    title: 'Updating Virtual Machines',
                    description: 'Updating available virtual machines across all cloud subscriptions'
                }, p);
            }
        }
    });
});