define('vmpower/routes/dashboard/subscriptions', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service('session'),
		accounts: Ember.inject.service('accounts'),
		controller: null,
		emberNotificationCenter: Ember.inject.service(),
		updating: Ember.computed.alias('controller.updating'),
		setupController: function setupController(controller, model) {
			var _this = this;

			var accessToken = this.get('session.data.authenticated.accessToken');
			appInsights.trackEvent('route:subscriptions', {
				email: this.get('session.data.authenticated.user.email')
			});
			this.set('controller', controller);
			this.set('controller.account', model);
			if (this.get('controller.state') === 'gcp' && this.get('controller.code')) {
				var promise = new Ember.RSVP.Promise(function (resolve, reject) {
					Ember.$.ajax({
						type: 'POST',
						url: _environment.default.apiHost + ('/gcp-credentials?account=' + _this.get('accounts').activeAccountSync().id),
						success: function success(data) {
							if (data.errors) {
								// the api sends a long poll response back so we need to
								// use the body to determine success/failure
								return reject(data.errors);
							}
							return resolve();
						},
						data: JSON.stringify({
							data: {
								type: 'gcp-credentials',
								attributes: {
									code: _this.get('controller.code')
								}
							}
						}),
						headers: {
							'x-access-token': accessToken
						},
						contentType: 'application/vnd.api+json'
					}).fail(function (err) {
						if (err.responseJSON) {
							return reject(err.responseJSON.errors);
						}
						return reject(err);
					});
				});
				this.get('emberNotificationCenter').pushNotification({
					title: 'Loading GCP Subscriptions',
					description: 'Loading up your Google Cloud Platform Subscriptions'
				}, promise);
				promise.then(function () {
					// clear the url bar
					_this.set('controller.code', undefined);
					_this.set('controller.state', undefined);
					// refresh vms and subs
					return Ember.RSVP.all([_this.store.query('cloud-subscription', { reload: true }).then(function () {
						return Ember.RSVP.all([_this.store.findAll('virtual-machine'), _this.store.findAll('volume')]);
					}), model.reload()]);
				});
			} else if (this.get('controller.state') && this.get('controller.code')) {
				var state = void 0;

				try {
					state = JSON.parse(this.get('controller.state'));
				} catch (err) {
					Ember.debug(err);
					return;
				}
				if (state.provider !== 'azure') {
					return;
				}
				// there is no such route but that is what this block does
				appInsights.trackEvent('route:subscriptions:addAzureAccount', {
					email: this.get('session.data.authenticated.user.email')
				});

				var _promise = new Ember.RSVP.Promise(function (resolve, reject) {
					Ember.$.ajax({
						type: 'POST',
						url: _environment.default.apiHost + ('/azure-credentials?account=' + _this.get('accounts').activeAccountSync().id + '&collectDw=' + state.collectDw),
						success: function success(data) {
							if (data.errors) {
								// the api sends a long poll response back so we need to
								// use the body to determine success/failure
								return reject(data.errors);
							}
							return resolve();
						},
						data: JSON.stringify({
							data: {
								type: 'azure-credentials',
								attributes: {
									'auth-code': _this.get('controller.code'),
									directory: state.directory
								}
							}
						}),
						headers: {
							'x-access-token': accessToken
						},
						contentType: 'application/vnd.api+json'
					}).fail(function (err) {
						if (err.responseJSON) {
							return reject(err.responseJSON.errors);
						}
						return reject(err);
					});
				});

				_promise.then(function () {
					// clear the url bar
					_this.set('controller.code', undefined);
					_this.set('controller.state', undefined);
					// refresh vms and subs
					return Ember.RSVP.all([_this.store.query('cloud-subscription', { reload: true }).then(function () {
						return Ember.RSVP.all([_this.store.findAll('virtual-machine'), _this.store.findAll('volume')]);
					}), model.reload()]);
				});
				this.get('emberNotificationCenter').pushNotification({
					title: 'Loading Azure Subscriptions',
					description: 'Loading up your Azure Subscriptions'
				}, _promise);
			}
			this.store.findAll('azure-credential');
			this.store.findAll('aws-credential');
			this.store.findAll('gcp-credential');
		},
		model: function model() {
			var user = this.get('session.data.authenticated.user');
			if (!user || !user.id) {
				return this.transitionTo('signin');
			}
			var id = this.get('session.data.authenticated.user.id');
			return this.get('accounts').activeAccount();
		},
		addGcpAccount: function addGcpAccount(projectId) {
			var accessToken = this.get('session.data.authenticated.accessToken');
			var promise = this.get('accounts').activeAccount().then(function (account) {
				return new Ember.RSVP.Promise(function (resolve, reject) {
					Ember.$.ajax({
						type: 'POST',
						url: _environment.default.apiHost + ('/gcp-credentials?account=' + account.id),
						success: function success(data) {
							if (data.errors) {
								// the api sends a long poll response back so we need to
								// use the body to determine success/failure
								return reject(data.errors);
							}
							return resolve();
						},
						data: JSON.stringify({
							data: {
								type: 'gcp-credentials',
								attributes: {
									'project-id': projectId
								}
							}
						}),
						headers: {
							'x-access-token': accessToken
						},
						contentType: 'application/vnd.api+json'
					}).fail(function (err) {
						if (err.responseJSON) {
							return reject(err.responseJSON.errors);
						}
						return reject(err);
					});
				});
			});
			this.get('emberNotificationCenter').pushNotification({
				title: 'Loading GCP Subscriptions',
				description: 'Loading up your GCP Subscriptions'
			}, promise);
		},
		addAwsAccount: function addAwsAccount(credentials) {
			var _this2 = this;

			appInsights.trackEvent('route:subscriptions:addAwsAccount', {
				email: this.get('session.data.authenticated.user.email')
			});
			return this.get('accounts').activeAccount().then(function (account) {
				var awsCreds = _this2.store.createRecord('aws-credential', {
					iamKey: credentials.iamKey,
					iamSecret: credentials.iamSecret
				});
				return awsCreds.save().then(function () {
					var sub = _this2.store.createRecord('cloud-subscription', {
						provider: 'aws',
						name: credentials.name,
						aws: awsCreds
					});
					return sub.save();
				}).then(function (sub) {
					account.get('cloudSubscriptions').pushObject(sub);
					return account.save();
				}).then(function () {
					return _this2.set('updating', false);
				}).then(function () {
					// refresh vms and subs
					return _this2.store.query('cloud-subscription', { reload: true }).then(function () {
						return Ember.RSVP.all([_this2.store.findAll('virtual-machine'), _this2.store.findAll('volume')]);
					});
				});
			});
		}
	});
});