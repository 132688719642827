define("vmpower/templates/dashboard/home", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 12,
                "column": 2
              }
            },
            "moduleName": "vmpower/templates/dashboard/home.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row center white-text");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "id", "computer-monthly-spend");
            dom.setAttribute(el2, "class", "material-icons tiny");
            dom.setAttribute(el2, "style", "float:unset;");
            var el3 = dom.createTextNode("computer");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "tooltipped");
            dom.setAttribute(el2, "data-position", "bottom");
            dom.setAttribute(el2, "data-tooltip", "Total Compute Cost (According to Pricing Sheet)");
            dom.setAttribute(el2, "style", "font-size: 1.1rem");
            var el3 = dom.createTextNode("$");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "material-icons tiny");
            dom.setAttribute(el2, "style", "float:unset; margin-top: 5px");
            var el3 = dom.createTextNode("storage");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "tooltipped");
            dom.setAttribute(el2, "data-position", "bottom");
            dom.setAttribute(el2, "data-tooltip", "Total Volume Cost (According to Pricing Sheet)");
            dom.setAttribute(el2, "style", "font-size: 1.1rem");
            var el3 = dom.createTextNode("$");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
            return morphs;
          },
          statements: [["inline", "to-fixed", [["get", "account.monthlyVmCost", ["loc", [null, [7, 44], [7, 65]]]], 2], [], ["loc", [null, [7, 33], [7, 69]]]], ["inline", "to-fixed", [["get", "account.monthlyVolumeCost", ["loc", [null, [10, 44], [10, 69]]]], 2], [], ["loc", [null, [10, 33], [10, 73]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "vmpower/templates/dashboard/home.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col s12 m6 l6");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col s12 m6 l6");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col s12 m6 l6");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col s12 m6 l6");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [0]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [7]), 1, 1);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [9]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["get", "account.monthlyVmCost", ["loc", [null, [3, 13], [3, 34]]]], ["get", "account.monthlyVolumeCost", ["loc", [null, [3, 35], [3, 60]]]]], [], ["loc", [null, [3, 8], [3, 61]]]]], [], 0, null, ["loc", [null, [3, 2], [12, 9]]]], ["inline", "ax-vm-overview", [], ["vms", ["subexpr", "@mut", [["get", "vms", ["loc", [null, [14, 25], [14, 28]]]]], [], []], "account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [14, 37], [14, 44]]]]], [], []], "loadingVms", ["subexpr", "@mut", [["get", "loadingVms", ["loc", [null, [14, 56], [14, 66]]]]], [], []]], ["loc", [null, [14, 4], [14, 68]]]], ["inline", "ax-vmgroup-overview", [], ["vms", ["subexpr", "@mut", [["get", "vms", ["loc", [null, [17, 30], [17, 33]]]]], [], []], "groups", ["subexpr", "@mut", [["get", "groups", ["loc", [null, [17, 41], [17, 47]]]]], [], []], "schedules", ["subexpr", "@mut", [["get", "schedules", ["loc", [null, [17, 58], [17, 67]]]]], [], []]], ["loc", [null, [17, 4], [17, 69]]]], ["inline", "ax-schedules-overview", [], ["schedules", ["subexpr", "@mut", [["get", "schedules", ["loc", [null, [20, 38], [20, 47]]]]], [], []]], ["loc", [null, [20, 4], [20, 49]]]], ["inline", "ax-analytics-overview", [], ["vms", ["subexpr", "@mut", [["get", "idleVms", ["loc", [null, [23, 32], [23, 39]]]]], [], []], "allVms", ["subexpr", "@mut", [["get", "vms", ["loc", [null, [23, 47], [23, 50]]]]], [], []], "account", ["subexpr", "@mut", [["get", "account", ["loc", [null, [23, 59], [23, 66]]]]], [], []]], ["loc", [null, [23, 4], [23, 68]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 7
          }
        },
        "moduleName": "vmpower/templates/dashboard/home.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "account", ["loc", [null, [1, 6], [1, 13]]]]], [], 0, null, ["loc", [null, [1, 0], [26, 7]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});