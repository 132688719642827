define('vmpower/routes/dashboard/savings/logs', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		_calculateSavings: function _calculateSavings(savingsDict) {
			moment.months().forEach(function (month) {
				if (!savingsDict.get(month)) {
					return;
				}
				var savingsRecords = savingsDict.get(month + '.records');
				var vmDict = {};
				savingsRecords.forEach(function (record) {
					if (!record.get('savings')) {
						return;
					}
					if (record.get('instanceId') && !vmDict[record.get('instanceId')]) {
						vmDict[record.get('instanceId')] = {
							pricePerHour: record.get('pricePerHour'),
							total: 0
						};
						vmDict[record.get('instanceId')].total += record.get('savings');
					} else if (record.get('volumeId') && !vmDict[record.get('volumeId')]) {
						vmDict[record.get('volumeId')] = {
							pricePerHour: record.get('pricePerHour'),
							total: 0
						};
						vmDict[record.get('volumeId')].total += record.get('savings');
					}
				});
				var totalSavings = 0;
				Object.keys(vmDict).forEach(function (key) {
					if (!vmDict[key].pricePerHour) {
						return;
					}
					if (vmDict[key].total < 0) {
						var monthCost = moment().utc().daysInMonth() * 24 * vmDict[key].pricePerHour;
						totalSavings += vmDict[key].total + monthCost;
					} else {
						totalSavings += vmDict[key].total;
					}
				});
				savingsDict.set(month + '.savingsTotal', totalSavings);
			});
		},
		_setDictEntryForCurrentMonth: function _setDictEntryForCurrentMonth(savingsDict, controller) {
			var month = moment.months()[moment().month()];
			savingsDict.set(month, new Ember.Object());
			savingsDict.set(month + '.month', month);
			savingsDict.set(month + '.year', moment().year());
			savingsDict.set(month + '.records', new Ember.A([]));
			savingsDict.set(month + '.savingsTotal', 0);
			savingsDict.set(month + '.selected', true);
			controller.set('savingsRecords', savingsDict.get(month));
		},
		renderTemplate: function renderTemplate(controller, savingsRecords) {
			var savingsDict = new Ember.Object();
			var vmDict = {};

			this._setDictEntryForCurrentMonth(savingsDict, controller);

			savingsRecords.forEach(function (record) {
				if (record.get('instanceId') && !vmDict[record.get('instanceId')]) {
					vmDict[record.get('instanceId')] = 0;
				}
				if (record.get('volumeId') && !vmDict[record.get('volumeId')]) {
					vmDict[record.get('volumeId')] = 0;
				}
				var timestampMoment = moment(record.get('timestamp'));
				var month = moment.months()[timestampMoment.month()];
				if (!savingsDict.get(month)) {
					var newMonth = new Ember.Object();
					savingsDict.set(month, newMonth);
					savingsDict.set(month + '.records', new Ember.A([]));
					savingsDict.set(month + '.savingsTotal', 0);
					savingsDict.set(month + '.month', month);
					savingsDict.set(month + '.year', timestampMoment.year());
				}
				savingsDict.get(month + '.records').pushObject(record);
			});

			this._calculateSavings(savingsDict);
			appInsights.trackEvent('route:savings', {
				email: this.get('session.data.authenticated.user.email')
			});
			controller.set('savingsDict', savingsDict);
			this._super(controller, savingsRecords);
		}
	});
});