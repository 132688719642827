define('vmpower/helpers/ember-notification-and', ['exports', 'ember-notif-hub/helpers/ember-notification-and'], function (exports, _emberNotificationAnd) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    Object.defineProperty(exports, 'default', {
        enumerable: true,
        get: function () {
            return _emberNotificationAnd.default;
        }
    });
    Object.defineProperty(exports, 'emberNotificationAnd', {
        enumerable: true,
        get: function () {
            return _emberNotificationAnd.emberNotificationAnd;
        }
    });
});