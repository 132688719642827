define('vmpower/routes/dashboard/change-password', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        emberNotificationCenter: Ember.inject.service(),
        setupController: function setupController(controller, model) {
            this.set('controller', controller);
            controller.set('user', model);
        },
        model: function model() {
            var user = this.get('session.data.authenticated.user');
            if (!user || !user.id) {
                return null;
            }
            var id = this.get('session.data.authenticated.user.id');
            return this.store.find('user', id);
        },
        validatePassword: function validatePassword(password) {
            return Ember.getOwner(this).lookup('authenticator:application').makeAuthCall({
                email: this.get('controller.user.email'),
                password: password
            });
        },
        actions: {
            changePassword: function changePassword(input) {
                var _this = this;

                var notifications = this.get('emberNotificationCenter');
                var promise;
                // the password on the user model is either user generated or undefined
                // the server doesn't have the password, nor does it return the hash
                if (!this.get('controller.authToken')) {
                    // user is authenticated and is changing password
                    promise = this.validatePassword(input.currentPassword).then(function () {
                        return _this.get('controller.user').save();
                    }).then(function () {
                        // clear local storage of password
                        _this.set('controller.user.password', undefined);
                        return _this.get('controller.user').save();
                    });
                } else {
                    // user forgot password and has been let into the change-password route
                    // because they have a token from their email
                    promise = new Ember.RSVP.Promise(function (resolve, reject) {
                        // since we haven't authenticated we need to use the auth token
                        // manually
                        Ember.$.ajax({
                            type: 'GET',
                            url: _environment.default.apiHost + '/users/' + _this.get('controller.id'),
                            headers: {
                                'x-access-token': _this.get('controller.authToken')
                            },
                            success: function success(response) {
                                var user = response.data;
                                user.attributes.password = input.newPassword;
                                resolve(user);
                            },
                            dataType: 'json',
                            contentType: 'application/vnd.api+json'
                        }).fail(function (err) {
                            reject(err);
                            Ember.debug(err);
                        });
                    }).then(function (user) {
                        // since we haven't authenticated we need to make this call directly
                        return new Ember.RSVP.Promise(function (resolve, reject) {
                            Ember.$.ajax({
                                type: 'PATCH',
                                url: _environment.default.apiHost + '/users/' + _this.get('controller.id'),
                                headers: {
                                    'x-access-token': _this.get('controller.authToken')
                                },
                                data: JSON.stringify({
                                    data: user
                                }),
                                success: function success() {
                                    resolve();
                                },
                                dataType: 'json',
                                contentType: 'application/vnd.api+json'
                            }).fail(function (err) {
                                reject(err);
                                Ember.debug(err);
                            });
                        });
                    }).then(function () {
                        _this.set('controller.authToken', undefined);
                        return _this.transitionTo('signin', { queryParams: { changedPassword: true } });
                    }).catch(function (err) {
                        var error = JSON.parse(err.responseText);
                        _this.set('controller.errors', error.errors);
                    });
                }

                notifications.pushNotification({
                    title: 'Updating Password',
                    description: 'Updating your account password'
                }, promise);
            }
        }
    });
});