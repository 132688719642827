define('vmpower/components/ax-pie-chart', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        randomColors: Ember.inject.service(),
        pieChartData: function () {
            var _this = this;

            var chartData = {
                // this chart just shows one dataset at a time
                datasets: [{
                    data: []
                }],
                labels: []
            };
            if (!this.get('pieChartDict')) {
                return chartData;
            }
            chartData.datasets[0].data = [];
            chartData.datasets[0].backgroundColor = [];
            Object.keys(this.get('pieChartDict')).forEach(function (category, i) {
                chartData.labels.push(category.toUpperCase());
                chartData.datasets[0].data.push(_this.get('pieChartDict')[category].value);
                chartData.datasets[0].backgroundColor.push(_this.get('randomColors.colors')[i]);
            });
            return chartData;
        }.property('pieChartDict'),
        chartOptions: function () {
            var opts = {
                hover: {
                    mode: 'label'
                },
                responsive: true,
                tooltips: {
                    mode: 'label'
                },
                // Boolean - Show a backdrop to the scale label
                scaleShowLabelBackdrop: true,

                // Boolean - Whether the scale should begin at zero
                scaleBeginAtZero: true,

                // Number - The backdrop padding above & below the label in pixels
                scaleBackdropPaddingY: 2,

                // Number - The backdrop padding to the side of the label in pixels
                scaleBackdropPaddingX: 2,

                // Boolean - Show line for each value in the scale
                scaleShowLine: true,

                // Boolean - Stroke a line around each segment in the chart
                segmentShowStroke: true,

                // String - The colour of the stroke on each segement.
                segmentStrokeColor: '#fff',

                // Number - The width of the stroke value in pixels
                segmentStrokeWidth: 2,

                datasetStroke: true,

                datasetFill: false,

                // Number - Pixel width of dataset stroke
                datasetStrokeWidth: 5,

                // Number - Amount of animation steps
                animationSteps: 0,

                // String - Animation easing effect.
                animationEasing: 'easeOutBounce',

                // Boolean - Whether to animate the rotation of the chart
                animateRotate: false,

                // Boolean - Whether to animate scaling the chart from the centre
                animateScale: false,
                legendTemplate: '<div class="collection timechart-legend white-text"><% for (var i=0; i<datasets.length; i++){%><%if(datasets[i].label.htmlUrl){%><a target="_blank" href="<%=datasets[i].label.htmlUrl%>" class="collection-item legend-selecter"><%} else{%><a class="collection-item legend-selecter"><%}%><span style="background-color:<%=datasets[i].strokeColor%>" class="circle"></span> <%if(datasets[i].label.name){%><%=datasets[i].label.name%><%}%></a><%}%></div>'
            };
            return opts;
        }.property()
    });
});