define('vmpower/components/ax-change-password-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
            if (!this.get('user')) {
                // we don't have a user because this is a forgot password scenario
                this.set('user', new Ember.Object());
            }
            Ember.TextSupport.reopen({
                attributeBindings: ['data-parsley-required', 'data-parsley-minlength', 'data-parsley-type', 'data-parsley-equalto']
            });
            this.initParsley();
        },
        initParsley: function initParsley() {
            var _this = this;

            Ember.run.scheduleOnce('afterRender', function () {
                // Form Validation
                Ember.$('#change-password-form').parsley().on('form:submit', function () {
                    try {
                        Ember.getOwner(_this).lookup('route:dashboard.change-password').send('changePassword', {
                            currentPassword: _this.get('currentPassword'),
                            newPassword: _this.get('user.password')
                        });
                    } catch (e) {
                        Ember.debug(e);
                    } finally {
                        return false;
                    }
                });
            });
        },
        userObserver: Ember.observer('user.hasDirtyAttributes', function () {
            this.initParsley();
        })
    });
});