define('vmpower/helpers/cdn-path', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.cdnPath = cdnPath;
    function cdnPath(params /*, hash*/) {
        return _environment.default.STATIC_ASSET_PATH + params[0];
    }

    exports.default = Ember.Helper.helper(cdnPath);
});