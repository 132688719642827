define('vmpower/components/ax-restore-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        powerManager: Ember.inject.service(),
        actions: {
            closeModal: function closeModal() {
                this.get('closeBackupModal')();
            },
            restoreSnapshot: function restoreSnapshot() {
                this.get('powerManager').restoreVolumeFromSnapshot(this.get('snapshot'), this.get('name'));
                this.get('closeBackupModal')();
            }
        }
    });
});