define('vmpower/routes/dashboard/savings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        model: function model() {
            var targetArray = new Ember.A([]);
            return this._fetchRecords(null, targetArray);
        },
        // recursive fetch of stats
        _fetchRecords: function _fetchRecords(scroll, array) {
            var _this = this;

            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            var startMoment = moment(user.get('createdAt'));
            return this.store.query('savings-record', {
                'start-date': startMoment.toDate().toISOString(),
                scroll: scroll
            }).then(function (records) {
                records.forEach(function (record) {
                    array.push(record);
                });
                if (array.length !== records.get('meta.total')) {
                    return _this._fetchRecords(records.get('meta.next'), array);
                }
                return array;
            });
        }
    });
});