define('vmpower/components/ax-schedules-overview', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		init: function init() {
			this._super();
			this.set('granularity', 'daily');
		},

		store: Ember.inject.service(),
		accounts: Ember.inject.service(),
		accountId: function () {
			return this.get('accounts').activeAccountSync().id;
		}.property('accountId'),
		recentWeekSavingsRecord: function () {
			return this.get('store').query('savingsRecord', {
				limit: 1,
				'start-date': new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString()
			});
		}.property('recentWeekSavingsRecord'),
		recentDaySavingsRecord: function () {
			return this.get('store').query('savingsRecord', {
				limit: 1,
				'start-date': new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString()
			});
		}.property('recentDaySavingsRecord'),
		actions: {
			openHourlyChart: function openHourlyChart() {
				this.set('chartGranularity', 'hourly');
			},
			openDailyChart: function openDailyChart() {
				this.set('chartGranularity', 'daily');
			}
		},
		chartGranularity: 'daily'
	});
});