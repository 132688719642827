define('vmpower/routes/dashboard/virtual-machines/team', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        model: function model(params) {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            return this.store.findRecord('team', params.team_id);
        },
        setupController: function setupController(controller, model) {
            var user = this.store.peekRecord('user', this.get('session.data.authenticated.user.id'));
            var loadedCount = 0;
            controller.set('loading', true);
            this.store.findRecord('user', user.id).then(function (user) {
                loadedCount += 1;
                if (loadedCount >= 3) {
                    controller.set('loading', false);
                }
                controller.set('user', user);
            });
            var teams = new Ember.A([]);
            teams.pushObject(model);
            controller.set('teams', teams);
            controller.set('subscriptions', new Ember.A([]));
            this.store.findAll('vm-group').then(function (vmGroups) {
                loadedCount += 1;
                if (loadedCount >= 3) {
                    controller.set('loading', false);
                }
                controller.set('groups', vmGroups);
            });
            var query = {
                team: model.id,
                limit: 15,
                'search-query': controller.get('searchQuery'),
                sort: controller.get('sort')
            };
            if (controller.get('searchQuery')) {
                delete query.limit;
            }
            this.store.query('virtual-machine', query).then(function (vms) {
                var holder = new Ember.A([]);
                vms.map(function (vm) {
                    return holder.push(vm);
                });
                loadedCount += 1;
                if (loadedCount >= 3) {
                    controller.set('loading', false);
                }
                controller.set('vms', holder);
            });
        },

        actions: {
            doQuery: function doQuery(query) {
                var controller = this.get('controller');
                if (controller.get('searchQuery') === '') {
                    controller.set('searchQuery', undefined);
                }
                controller.set('loading', true);
                this.store.query('virtual-machine', {
                    'search-query': query,
                    team: controller.get('teams.lastObject.id')
                }).then(function (vms) {
                    controller.set('loading', false);
                    var holder = new Ember.A([]);
                    vms.map(function (vm) {
                        return holder.push(vm);
                    });
                    controller.set('vms', holder);
                });
            },
            doSortQuery: function doSortQuery(sort) {
                var controller = this.get('controller');
                if (controller.get('searchQuery') === '') {
                    controller.set('searchQuery', undefined);
                }
                controller.set('sort', sort);
                this.store.query('virtual-machine', {
                    'cloud-subscription': controller.get('subscriptions.firstObject.id'),
                    sort: sort,
                    'search-query': controller.get('searchQuery')
                }).then(function (vms) {
                    controller.set('loading', false);
                    var holder = new Ember.A([]);
                    vms.map(function (vm) {
                        return holder.push(vm);
                    });
                    controller.set('vms', holder);
                });
            }
        }
    });
});