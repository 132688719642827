define('vmpower/models/aws-credential', ['exports', 'ember-data/model', 'ember-data'], function (exports, _model, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        iamSecret: _emberData.default.attr('string'),
        iamKey: _emberData.default.attr('string')
    });
});