define('vmpower/components/ax-api-config-modal', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    accounts: Ember.inject.service(),
    emberNotificationCenter: Ember.inject.service(),
    modals: Ember.inject.service(),
    actions: {
      closeModal: function closeModal() {
        this.get('modals').closeModal(Ember.$('#api-config-modal'));
        this.set('apiSecret', null);
        if (this.get('validation')) {
          this.get('validation').rollbackAttributes();
        }
      },
      generateApiSecret: function generateApiSecret() {
        var _this = this;

        var accessToken = this.get('session.data.authenticated.accessToken');
        var path = '/api-credentials?account=' + this.get('accounts').activeAccountSync().id;
        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.$.ajax({
            type: 'POST',
            url: _environment.default.apiHost + path,
            json: true,
            success: function success(data) {
              resolve(data);
            },
            headers: {
              'x-access-token': accessToken
            },
            contentType: 'application/vnd.api+json'
          }).fail(function (data) {
            reject(data.responseJSON);
          });
        });
        this.get('emberNotificationCenter').pushNotification({
          title: 'Generating API Secret',
          description: 'A new API secret access key will be generated for this account. Do not share this key and store it in a safe place.'
        }, promise);
        return promise.then(function (data) {
          return _this.set('apiSecret', data['api-secret']);
        });
      }
    }
  });
});