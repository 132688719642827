define('vmpower/helpers/guid', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.guid = guid;
    function guid() {
        return lil.uuid().toString();
    }

    exports.default = Ember.Helper.helper(guid);
});