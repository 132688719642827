define('vmpower/routes/dashboard/auto-off', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    emberNotificationCenter: Ember.inject.service(),
    modals: Ember.inject.service(),
    accounts: Ember.inject.service(),
    model: function model() {
      return this.store.findAll('vm-group');
    },
    setupController: function setupController(controller, groups) {
      var _this = this;

      appInsights.trackEvent('route:virtual-machines', {
        email: this.get('session.data.authenticated.user.email')
      });
      var array = new Ember.A([]);
      controller.set('user', this.store.peekRecord('user', this.get('session.data.authenticated.user.id')));
      controller.set('vmGroups', array);
      this.set('controller', controller);
      return this.store.findAll('vmSchedule').then(function (schedules) {
        groups.forEach(function (g) {
          array.pushObject(g);
          schedules.forEach(function (s) {
            if (g.id === s.get('vmGroup.id') && s.get('enabled')) {
              g.set('schedule', s);
            }
          });
        });
        _this.controller.set('schedules', schedules);

        return groups.get('firstObject.vms');
      }).then(function () {
        controller.set('account', _this.get('accounts').activeAccountSync());
        controller.set('autoOffHours', groups.get('firstObject.autoOffHours'));
        controller.set('autoOffWarningHours', groups.get('firstObject.autoOffWarningHours'));
        controller.set('ignoreHoursStartValue', groups.get('firstObject.ignoreHoursStart'));
        controller.set('ignoreHoursEndValue', groups.get('firstObject.ignoreHoursEnd'));
        controller.set('autoOffTimezone', groups.get('firstObject.autoOffTimezone'));
        var g = _this.store.peekRecord('vmGroup', controller.get('group'));
        // set the target group as specified by query parameter. if that fails pick the first in the model array
        if (g) {
          controller.set('targetGroup', g);
        } else {
          controller.set('targetGroup', groups.get('firstObject'));
        }
        if (controller.get('group') && controller.get('resetIdleVm')) {
          g.get('vms').forEach(function (vm) {
            if (vm.get('instanceId') === controller.get('resetIdleVm')) {
              vm.set('isIdle', false);
              vm.set('sinceIdleTime', null);
              Ember.debug('RESETING IDLE TIME FOR INSTANCE: ' + vm.get('instanceId'));
              _this.get('emberNotificationCenter').pushNotification({
                title: 'Resetting Auto-off Timer',
                description: 'Resetting auto-off timer for ' + vm.get('displayName') + '. This VM will not auto-off for at least ' + controller.get('targetGroup.autoOffHours') + ' hours'
              }, vm.save().then(function () {
                // clear this query parameter from url bar to prevent
                // a refresh clearing the timer again
                controller.set('resetIdleVm', null);
              }));
            }
          });
        }
      });
    },
    actions: {
      showUpgradeModal: function showUpgradeModal() {
        this.get('modals').upgradeModal();
      },
      willTransition: function willTransition(transition) {
        var _this2 = this;

        var hasDirtyGroups = false;
        this.get('controller.vmGroups').forEach(function (g) {
          hasDirtyGroups = g.get('hasDirtyAttributes') || hasDirtyGroups;
        });
        if (!this.get('tempTransition') && hasDirtyGroups) {
          this.set('tempTransition', transition);
          // temporarily abort the transition
          transition.abort();
        } else {
          this.set('tempTransition', undefined);
          // allow higher level routers to decide if transition should be cancelled
          return true;
        }
        return this.get('modals').confirmAction('Are you sure you want to abandon your Auto-off changes?', 'Your Auto-off setting changes will be lost.').then(function () {
          _this2.get('controller.vmGroups').forEach(function (g) {
            if (g.get('hasDirtyAttributes')) {
              g.rollbackAttributes();
            }
          });
          return transition.retry();
        }).catch(function (err) {
          Ember.debug(err);
          _this2.set('tempTransition', undefined);
          return transition.abort();
        });
      }
    }
  });
});