define('vmpower/components/ax-resize-overview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        count: 0,
        init: function init() {
            this._super();
            this.set('count', 0);
        },
        resizeCount: function () {
            var _this = this;

            if (!this.get('vms')) {
                return 0;
            }
            if (!this.get('count')) {
                this.get('vms').forEach(function (vm) {
                    if (vm.get('recommendedResizeTypes.length') > 0 && vm.get('recommendedResizeTypes.isPending')) {
                        _this.set('count', _this.get('count') + 1);
                    }
                });
            }
            return this.get('count');
        }.property('vms.@each.recommendedResizeTypes'),
        allTypes: function () {
            if (!this.get('vms')) {
                return;
            }
            var allTypes = new Ember.A([]);
            this.get('vms').forEach(function (vm) {
                vm.get('recommendedResizeTypes').forEach(function (type) {
                    allTypes.pushObject(type);
                });
            });
            return allTypes;
        }.property('vms.@each.recommendedResizeTypes.length'),
        resizeMonthlySavings: function () {
            this.get('allTypes');
            if (!this.get('vms')) {
                return 0;
            }
            var delta = 0;
            this.get('vms').forEach(function (vm) {
                var type = vm.get('recommendedResizeTypes.firstObject');
                if (!type || !type.get('isLoaded')) {
                    return;
                }
                delta += vm.get('pricePerHour') - type.get('pricePerHour');
            });
            return (delta * 720).toFixed(2);
        }.property('allTypes.@each.pricePerHour'),
        resizeMonthlyCost: function () {
            return (parseFloat(this.get('resizeMonthlySavings')) * -1).toFixed(2);
        }.property('resizeMonthlySavings')
    });
});