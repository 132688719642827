define('vmpower/components/ax-cloud-wizard', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var getOwner = Ember.getOwner;
    exports.default = Ember.Component.extend({
        modals: Ember.inject.service(),
        session: Ember.inject.service('session'),
        aadDirectory: '',
        providers: [{
            name: 'Select a Provider',
            shortName: null
        }, {
            name: 'Amazon Web Services',
            shortName: 'aws',
            steps: [{
                title: 'Excellent! AWS is a supported cloud provider! Click \'next\' and follow the steps to securly add your AWS subscription credentials.',
                image: 'img/aws-logo-black.png',
                buttons: [{
                    title: 'Skip Tutorial',
                    action: 'skipToEnd',
                    icon: 'fast_forward'
                }]
            }, {
                title: 'First, go to your AWS console (console.aws.amazon.com) and click \'Security Credentials\'',
                image: 'img/cloud-steps/aws/step0.png'
            }, {
                title: 'Head over to your IAM Roles. If you see this dialog, click \'Get started with IAM Users.\'',
                image: 'img/cloud-steps/aws/step1.png'
            }, {
                title: 'Next, click \Create new User\' to create a new IAM user.',
                image: 'img/cloud-steps/aws/step2.png'
            }, {
                title: 'Add a new user name called \'vmpower\' which we will use to access your EC2 instances:',
                image: 'img/cloud-steps/aws/step3.png'
            }, {
                title: 'Note the access key and secret. We\'ll need you to enter that at the end of this guide.',
                image: 'img/cloud-steps/aws/step4.png'
            }, {
                title: 'Back in your IAM users page, click the \'Attach Policy\' button under the new \'vmpower\' user.',
                image: 'img/cloud-steps/aws/step5.png'
            }, {
                title: 'Search \'ec2\' and select the \'AmazonEC2FullAccess\' policy',
                image: 'img/cloud-steps/aws/step6.png'
            }, {
                title: 'Enter your VMPower IAM Access Key and Secret in the secure form above.',
                inputs: [{
                    title: 'Name for Subscription',
                    name: 'subscriptionName'
                }, {
                    title: 'IAM Access Key',
                    name: 'awsAccessKey'
                }, {
                    title: 'IAM Access Secret',
                    name: 'awsAccessSecret'
                }],
                buttons: [{
                    title: 'Add Account',
                    action: 'addAwsAccount',
                    icon: 'lock'
                }]
            }]
        }, {
            name: 'Microsoft Azure',
            shortName: 'azure',
            steps: [{
                title: 'Great! Click \'next\' and follow the steps to securly add your Azure subscription.',
                image: 'img/azure_logo.png',
                buttons: [{
                    title: 'Skip Tutorial',
                    action: 'skipToEnd',
                    icon: 'fast_forward'
                }]
            }, {
                title: 'Go to https://portal.azure.com and find your subscription directory on the top right of the portal. Take note of this for the next step.',
                image: 'img/cloud-steps/azure/step0.png'
            }, {
                title: 'Using your Azure subscription directory name, sign into your Azure account. Typically if this is a personal account, your domain is \'youremail+domain.onmicrosoft.com\'. Otherwise it is your work or school domain name.',
                component: 'ax-azure-signin'
            }]
        }, {
            name: 'Google Cloud Platform',
            shortName: 'gcp',
            steps: [{
                title: 'Great! Google Cloud Platform is a supported cloud provider! Click \'next\' and follow the steps to securly add your GCP Subscription',
                image: 'img/google-cloud-platform-logo.png',
                buttons: [{
                    title: 'Skip Tutorial',
                    action: 'skipToEnd',
                    icon: 'fast_forward'
                }]
            }, {
                title: 'First, go to your IAM Roles dialog of your GCP Project and create a new "VMPower" role with the following permissions. Each permission is required for all VMPower features.',
                image: 'img/gcp-permissions.png',
                link: 'https://console.cloud.google.com/iam-admin/roles',
                buttons: [{
                    title: 'Go to IAM Roles',
                    action: 'openGcpIAMRolesTab',
                    icon: 'open_in_new'
                }, {
                    title: 'Skip Tutorial',
                    action: 'skipToEnd',
                    icon: 'fast_forward'
                }]
            }, {
                title: 'Now add the IAM user "' + _environment.default.GcpClientId + '" to your Google Cloud Project and assign it the custom "VMPower" role created in the previous step.',
                image: 'img/gcp-add-iam-user.png',
                buttons: [{
                    title: 'Go to IAM Users',
                    action: 'openGcpIAMUsersTab',
                    icon: 'open_in_new'
                }, {
                    title: 'Skip Tutorial',
                    action: 'skipToEnd',
                    icon: 'fast_forward'
                }]
            }, {
                title: 'Now, enter your Google Cloud Project ID',
                image: 'img/google-cloud-platform-logo.png',
                component: 'ax-google-signin'
            }]
        }],
        provider: null,
        currentStepIndex: 0,
        atEndOfSteps: function () {
            return this.get('currentStepIndex') === this.get('provider.steps.length') - 1;
        }.property('currentStepIndex'),
        hasPrev: function () {
            return this.get('currentStepIndex') > 0;
        }.property('currentStepIndex'),
        hasNext: function () {
            return this.get('currentStepIndex') < this.get('provider.steps.length') - 1;
        }.property('currentStepIndex'),
        currentStep: function () {
            return this.get('provider.steps')[this.get('currentStepIndex')];
        }.property('currentStepIndex', 'modalSuccess', 'updating', 'provider'),
        actions: {
            selectProvider: function selectProvider() {
                Ember.debug('selected provider:');
                this.set('currentStepIndex', 0);
                appInsights.trackEvent('ax-cloud-wizard:selectProvider', {
                    email: this.get('session.data.authenticated.user.email'),
                    provider: this.get('provider.name')
                });
            },
            closeModal: function closeModal() {
                var stepCount = this.get('providers')[1].steps.length;
                this.get('modals').closeModal('#cloud-wizard');
                this.set('modalSuccess', false);
                this.set('currentStepIndex', 0);
                this.set('providers.1.steps.' + (stepCount - 1) + '.inputs.0.value', null);
                this.set('providers.1.steps.' + (stepCount - 1) + '.inputs.1.value', null);
            },
            nextStep: function nextStep() {
                this.set('currentStepIndex', this.get('currentStepIndex') + 1);
                appInsights.trackEvent('ax-cloud-wizard:nextStep', { email: this.get('session.data.authenticated.user.email'), provider: this.get('provider.name') });
            },
            skipToEnd: function skipToEnd() {
                this.set('currentStepIndex', this.get('provider.steps.length') - 1);
                appInsights.trackEvent('ax-cloud-wizard:skipToEnd', { email: this.get('session.data.authenticated.user.email'), provider: this.get('provider.name') });
            },
            openGcpIAMRolesTab: function openGcpIAMRolesTab() {
                window.open('https://console.cloud.google.com/iam-admin/roles', '_blank');
            },
            openGcpIAMUsersTab: function openGcpIAMUsersTab() {
                window.open('https://console.cloud.google.com/iam-admin/iam', '_blank');
            },
            previousStep: function previousStep() {
                if (this.get('currentStepIndex') <= 0) {
                    return;
                }
                appInsights.trackEvent('ax-cloud-wizard:previousStep', { email: this.get('session.data.authenticated.user.email'), provider: this.get('provider.name') });
                return this.set('currentStepIndex', this.get('currentStepIndex') - 1);
            },
            addGcpAccount: function addGcpAccount() {
                var _this = this;

                getOwner(this).lookup('route:dashboard.subscriptions').addAwsAccount({
                    iamKey: iamKey,
                    iamSecret: iamSecret,
                    name: subName
                }).then(function () {
                    _this.set('modalSuccess', true);
                }).catch(function (err) {
                    Ember.debug('error!');
                    Ember.debug(err);
                    _this.set('errors', err.errors);
                }).finally(function () {
                    _this.set('updating', false);
                });
            },
            addAwsAccount: function addAwsAccount() {
                var _this2 = this;

                var stepCount = this.get('providers')[1].steps.length;
                var iamKey = this.get('providers')[1].steps[stepCount - 1].inputs[1].value;
                var iamSecret = this.get('providers')[1].steps[stepCount - 1].inputs[2].value;
                var subName = this.get('providers')[1].steps[stepCount - 1].inputs[0].value;
                this.set('errors', undefined);
                // call route to create new entry
                this.set('updating', true);
                appInsights.trackEvent('ax-cloud-wizard:addAwsAccount', { email: this.get('session.data.authenticated.user.email') });
                getOwner(this).lookup('route:dashboard.subscriptions').addAwsAccount({
                    iamKey: iamKey,
                    iamSecret: iamSecret,
                    name: subName
                }).then(function () {
                    _this2.set('modalSuccess', true);
                }).catch(function (err) {
                    Ember.debug('error!');
                    Ember.debug(err);
                    _this2.set('errors', err.errors);
                }).finally(function () {
                    _this2.set('updating', false);
                });
            }
        }
    });
});