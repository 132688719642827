define('vmpower/routes/dashboard/account-details/account', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        accounts: Ember.inject.service(),
        emberNotificationCenter: Ember.inject.service(),
        setupController: function setupController(controller, model) {
            this.set('controller', controller);
            controller.set('user', model);
            controller.set('account', this.get('accounts').activeAccountSync());
        },
        model: function model() {
            var user = this.get('session.data.authenticated.user');
            if (!user || !user.id) {
                return this.transitionTo('signin');
            }
            var id = this.get('session.data.authenticated.user.id');
            return this.store.find('user', id);
        }
    });
});