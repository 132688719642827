define('vmpower/components/ax-resize-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        modals: Ember.inject.service(),
        init: function init() {
            this._super();
            this.get('selectedVms').forEach(function (vm) {
                vm.reload();
            });
        },
        powerManager: Ember.inject.service(),
        selectedResizeSavings: function () {
            var total = 0;
            this.get('selectedVms').forEach(function (vm) {
                var delta = (vm.get('pricePerHour') - vm.get('selectedResizeType.pricePerHour')) * 720;
                total += delta;
            });
            return total;
        }.property('selectedVms.@each.selectedResizeType.pricePerHour'),
        selectedResizeCost: function () {
            return this.get('selectedResizeSavings') * -1;
        }.property('selectedResizeSavings'),
        emberNotificationCenter: Ember.inject.service(),
        actions: {
            closeResizeModal: function closeResizeModal() {
                this.get('closeResizeModal')();
            },
            scheduleResizes: function scheduleResizes() {
                var _this = this;

                if (!this.get('timespan')) {
                    Ember.debug('ax-resize-modal: did not get a timespan for scheduleResizes');
                    return;
                }
                var timespan = this.get('timespan');
                var promises = [];
                this.get('selectedVms').forEach(function (vm) {
                    var record = vm.store.createRecord('resizeAction', {
                        virtualMachine: vm,
                        resizeTarget: vm.get('selectedResizeType')
                    });
                    appInsights.trackEvent('ax-resize-modal:scheduleResize', {
                        email: _this.get('session.data.authenticated.user.email'),
                        sourceVm: vm.get('instanceId'),
                        targetSize: vm.get('selectedResizeType').get('instanceType')
                    });
                    promises.push(record.save().then(function () {
                        // is an ember-data model
                        if (!timespan.get('resizeActions')) {
                            timespan.set('resizeActions', new Ember.A([]));
                        }
                        timespan.get('resizeActions').pushObject(record);
                    }));
                });
                var emberNotificationCenter = this.get('emberNotificationCenter');
                emberNotificationCenter.pushNotification({
                    title: 'Adding ' + promises.length + ' scheduled resizes to VM Schedule Event',
                    description: 'Updating your schedule with scheduled VM resizes. When this event occurs VMs will be resized and restored to their original state.' }, Ember.RSVP.all(promises).then(function () {
                    return _this.send('closeResizeModal');
                }));
            },
            resizeSelectedVms: function resizeSelectedVms() {
                var _this2 = this;

                var modals = this.get('modals');
                return modals.confirmAction('Resize ' + this.get('selectedVms.length') + ' VMs?', 'Careful! Ensure these VMs are not running critical apps first. Data on data/OS disks will be safe however ephemeral disk storage will be erased.\n' + 'VMPower will do to each VM:', ['- Power off VM if it is running', '- Resize VM', '- Power on VM if it was originally running']).then(function () {
                    _this2.set('resizing', true);
                    var powerManager = _this2.get('powerManager');
                    var promises = [];
                    _this2.get('selectedVms').forEach(function (vm) {
                        var p = powerManager.resizeInstance(vm, vm.get('selectedResizeType.instanceType'));
                        p.then(function () {
                            vm.set('recommendedResizeTypes', []);
                            return vm.save();
                        }).then(function () {
                            _this2.get('table').row('#' + vm.id).remove();
                            Ember.$('#' + vm.id).remove();
                        });
                        promises.push(p);
                    });
                    _this2.send('closeResizeModal');
                    return Ember.RSVP.all(promises);
                }).then(function () {
                    _this2.get('selectedVms').forEach(function (vm) {
                        vm.set('resizeSelected', false);
                    });
                }).catch(function () {}).finally(function () {
                    _this2.set('resizing', false);
                });
            }
        }
    });
});