define('vmpower/components/ax-upsell-popover', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		modals: Ember.inject.service(),
		cursorX: Ember.computed.alias('modals.upsellPopoverX'),
		cursorY: Ember.computed.alias('modals.upsellPopoverY'),
		accounts: Ember.inject.service(),
		emberNotificationCenter: Ember.inject.service(),
		init: function init() {
			this._super();
			var account = this.get('accounts').activeAccountSync();
			this.set('account', this.get('accounts').activeAccountSync());
			this.set('originalSlotCount', account.get('idleTrackingCap'));
		},
		modified: Ember.computed.alias('account.hasDirtyAttributes'),
		actions: {
			upgrade: function upgrade(slots, slotType) {
				var _this = this;

				if (!this.get('account.hasDirtyAttributes')) {
					return;
				}
				var slotDescription = void 0;
				var pricePlan = this.get('account.pricePlan');
				var account = this.get('account');
				var difference = slots - this.get('originalSlotCount');
				var total = void 0;
				if (account.get('annualBilling')) {
					total = 12 * difference * pricePlan.get('annualIdleTrackingPrice');
				} else {
					total = difference * pricePlan.get('monthlyIdleTrackingPrice');
				}
				if (slotType === 'idle-tracking') {
					if (slots > 1) {
						slotDescription = 'idle VM tracking slots';
					} else {
						slotDescription = 'idle VM tracking slot';
					}
				}
				var message = 'Your VMPower subscription price will increase by $' + total + 'USD per ' + (account.get('annualBilling') ? 'year' : 'month');

				if (account.get('trialing')) {
					message = 'Your VMPower trial will be updated with the new upgrade';
				}
				this.get('modals').confirmAction('Purchase ' + slots + ' additional ' + slotDescription + '?', 'Your VMPower subscription price will increase by $' + total + 'USD per ' + (account.get('annualBilling') ? 'year' : 'month'), null, null, null, null).then(function (result) {
					return _this.get('emberNotificationCenter').pushNotification({
						title: 'Upgrading VMPower subscription',
						description: 'Adding ' + slots + ' additional VM tracking slots.'
					}, _this.get('account').save().catch(function () {
						_this.get('account').rollbackAttributes();
					})).catch(function (e) {
						_this.get('vm').rollbackAttributes();
						throw e;
					});
				}).catch(function (e) {
					_this.get('account').rollbackAttributes();
					throw e;
				});
			}
		}
	});
});