define('vmpower/app', ['exports', 'vmpower/resolver', 'ember-load-initializers', 'vmpower/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var App = void 0;
    Ember.MODEL_FACTORY_INJECTIONS = true;
    Ember.TextSupport.reopen({
        attributeBindings: ['data-stripe', 'style', 'data-parsley-required', 'data-parsley-minlength', 'data-parsley-maxlength', 'data-parsley-type', 'data-parsley-equalto', 'data-parsley-country-code', 'data-parsley-phone', 'data-parsley-validate']
    });
    if (_environment.default.environemnt !== 'production') {
        // disable telemetry
        appInsights.trackEvent = function () {};
        appInsights.trackMetric = function () {};
    }
    App = Ember.Application.extend({
        modulePrefix: _environment.default.modulePrefix,
        podModulePrefix: _environment.default.podModulePrefix,
        Resolver: _resolver.default
    });

    (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

    exports.default = App;
});