define('vmpower/components/ax-analytics-overview', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		idleVms: Ember.computed.filterBy('vms', 'isIdle', true),
		modals: Ember.inject.service(),
		accounts: Ember.inject.service(),
		init: function init() {
			this._super();
			this.set('cardView', 'heatmap');
		},
		getAccount: function () {
			return this.get('accounts').activeAccountSync();
		}.property('account'),
		idleVmMonthCost: function () {
			var total = 0;
			this.get('idleVms').forEach(function (vm) {
				total += vm.get('pricePerHour') * 720; // 720 hours in a 30 day month
			});
			return total.toFixed(2);
		}.property('idleVms'),
		hasIdleVmDetection: function () {
			if (this.get('allVms')) {
				return !!this.get('allVms').find(function (vm) {
					return vm.get('idleVmDetection');
				});
			} else {
				return false;
			}
		}.property('allVms'),
		actions: {
			showUpgradeModal: function showUpgradeModal() {
				this.get('modals').upgradeModal();
			},
			toggleTileView: function toggleTileView() {
				if (this.get('cardView') === 'tile') {
					this.set('cardView', 'heatmap');
				} else {
					this.set('cardView', 'tile');
				}
			}
		}
	});
});