define('vmpower/components/ax-backup-expiry-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        emberNotificationCenter: Ember.inject.service(),
        init: function init() {
            this._super();
            var created = moment(this.get('snapshot.timestamp'));
            var expiry = moment(this.get('snapshot.expiry'));
            this.set('backupRetentionDays', parseInt(expiry.diff(created, 'days')));
        },
        actions: {
            update: function update() {
                var timestamp = this.get('snapshot.timestamp');
                var newExpiry = moment(timestamp).add(parseInt(this.get('backupRetentionDays')), 'days').toDate();
                this.set('snapshot.expiry', newExpiry);
                this.get('emberNotificationCenter').pushNotification({
                    title: 'Updating Snapshot Expiration',
                    description: 'This snapshot will now be removed after ' + this.get('backupRetentionDays') + ' days from its creation'
                }, this.get('snapshot').save());
                this.send('closeModal');
            },
            closeModal: function closeModal() {
                this.get('closeBackupModal')();
            }
        }
    });
});