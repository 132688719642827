define('vmpower/models/historical-stat', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        timestamp: _emberData.default.attr('date'),
        totalCost: _emberData.default.attr('number'),
        count: _emberData.default.attr('number'),
        activeVmCost: _emberData.default.attr('number'),
        activeVmCount: _emberData.default.attr('number'),
        // the concatenated list of instance names
        // since some of them could be missing from todays
        // vms
        instancesConcat: _emberData.default.attr('string'),
        instances: _emberData.default.hasMany('virtualMachine')
    });
});