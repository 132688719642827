define("vmpower/models/price-plan", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    annualVmPrice: _emberData.default.attr("number", {
      defaultValue: 3.5
    }),
    monthlyVmPrice: _emberData.default.attr("number", {
      defaultValue: 4.0
    }),
    annualTeamPrice: _emberData.default.attr("number", {
      defaultValue: 10
    }),
    monthlyTeamPrice: _emberData.default.attr("number", {
      defaultValue: 12
    }),
    annualTeamMemberPrice: _emberData.default.attr("number", {
      defaultValue: 5
    }),
    monthlyTeamMemberPrice: _emberData.default.attr("number", {
      defaultValue: 6
    }),
    monthlySnapshotPrice: _emberData.default.attr("number", {
      defaultValue: 0.25
    }),
    annualSnapshotPrice: _emberData.default.attr("number", {
      defaultValue: 0.2
    }),
    monthlyDbPrice: _emberData.default.attr("number", {
      defaultValue: 79.0
    }),
    annualDbPrice: _emberData.default.attr("number", {
      defaultValue: 59.0
    }),
    monthlyIdleTrackingPrice: _emberData.default.attr("number", {
      defaultValue: 6
    }),
    annualIdleTrackingPrice: _emberData.default.attr("number", {
      defaultValue: 4.5
    })
  });
});