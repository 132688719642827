define("vmpower/templates/components/ax-notifications-form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 16
            },
            "end": {
              "line": 33,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-notifications-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tooltipped col s4");
          dom.setAttribute(el1, "data-tooltip", "Notification Email Confirmed");
          dom.setAttribute(el1, "data-position", "top");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "md-check", [], ["class", "white-text", "disabled", true, "checked", ["subexpr", "@mut", [["get", "user.notificationEmailConfirmed", ["loc", [null, [31, 76], [31, 107]]]]], [], []], "name", "Confirmed"], ["loc", [null, [31, 24], [31, 126]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 16
            },
            "end": {
              "line": 37,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-notifications-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tooltipped col s4");
          dom.setAttribute(el1, "data-tooltip", "Check Your Email to Confirm");
          dom.setAttribute(el1, "data-position", "top");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "md-check", [], ["class", "white-text", "disabled", true, "checked", ["subexpr", "@mut", [["get", "user.notificationEmailConfirmed", ["loc", [null, [35, 76], [35, 107]]]]], [], []], "name", "Confirmed"], ["loc", [null, [35, 24], [35, 126]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-notifications-form.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h4");
        dom.setAttribute(el1, "class", "center-align white-text");
        var el2 = dom.createTextNode("Notifications");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        dom.setAttribute(el1, "class", "center-align white-text");
        var el2 = dom.createTextNode("Here you can enable VM notifications for everything across VMPower");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2, "id", "account-details-form white-text");
        dom.setAttribute(el2, "class", "col s10 offset-s1");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h5");
        dom.setAttribute(el4, "class", "white-text");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "material-icons");
        var el6 = dom.createTextNode("alarm");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("Analysis Window");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "white-text");
        var el5 = dom.createTextNode("Select the number of days of cloud metric history before sending notifications about idle VMs, resize recommendations for unused volumes.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "white-text");
        var el5 = dom.createTextNode("Frequency:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h5");
        dom.setAttribute(el4, "class", "white-text");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "material-icons");
        var el6 = dom.createTextNode("mail");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("Email");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "class", "white-text");
        var el6 = dom.createTextNode("Enabled:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "switch");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6, "class", "white-text");
        var el7 = dom.createTextNode("\n                        Off\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7, "class", "lever");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        On\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col s8");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "white-text");
        var el5 = dom.createTextNode("Enabled:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "switch");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "class", "white-text");
        var el6 = dom.createTextNode("\n                    Off\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "lever");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    On\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [4, 1]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [6]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 7, 7);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 3, 1]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
        morphs[3] = dom.createMorphAt(element2, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [10, 1]), 1, 1);
        return morphs;
      },
      statements: [["inline", "ax-select", [], ["content", ["subexpr", "@mut", [["get", "frequencyTimes", ["loc", [null, [9, 32], [9, 46]]]]], [], []], "displayPath", "name", "valuePath", "days", "target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [9, 90], [9, 94]]]]], [], []], "targetValue", ["subexpr", "@mut", [["get", "account.detectionFrequencyDays", ["loc", [null, [9, 107], [9, 137]]]]], [], []], "selectedValue", ["subexpr", "@mut", [["get", "frequencySelected", ["loc", [null, [9, 152], [9, 169]]]]], [], []], "doNotInitFirstValue", true], ["loc", [null, [9, 12], [9, 196]]]], ["inline", "input", [], ["class", "white-text", "type", "checkbox", "checked", ["subexpr", "@mut", [["get", "user.slackNotifications", ["loc", [null, [18, 75], [18, 98]]]]], [], []]], ["loc", [null, [18, 24], [18, 100]]]], ["inline", "input", [], ["class", "white-text", "type", "text", "value", ["subexpr", "@mut", [["get", "user.notificationEmail", ["loc", [null, [27, 65], [27, 87]]]]], [], []], "placeholder", "Email to send notifications to"], ["loc", [null, [27, 20], [27, 134]]]], ["block", "if", [["get", "user.notificationEmailConfirmed", ["loc", [null, [29, 22], [29, 53]]]]], [], 0, 1, ["loc", [null, [29, 16], [37, 23]]]], ["inline", "input", [], ["class", "white-text", "type", "checkbox", "checked", ["subexpr", "@mut", [["get", "user.emailNotifications", ["loc", [null, [43, 71], [43, 94]]]]], [], []]], ["loc", [null, [43, 20], [43, 96]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});