define('vmpower/controllers/signin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['id_token', 'state', 'code', 'newUser', 'changedPassword', 'awsMarketplaceToken'],
        actions: {
            showRecovery: function showRecovery() {
                this.set('twoFactor', false);
                this.set('showRecovery', true);
            },
            hideRecovery: function hideRecovery() {
                this.set('twoFactor', true);
                this.set('showRecovery', false);
            }
        }
    });
});