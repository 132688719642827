define("vmpower/templates/components/ax-schedules-overview", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 22
                },
                "end": {
                  "line": 9,
                  "column": 72
                }
              },
              "moduleName": "vmpower/templates/components/ax-schedules-overview.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("VM schedules");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 8
              },
              "end": {
                "line": 12,
                "column": 8
              }
            },
            "moduleName": "vmpower/templates/components/ax-schedules-overview.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row placeholder-graph valign-wrapper");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col s12 center");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h5");
            dom.setAttribute(el3, "class", "valign");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "mdi-action-info");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("No recent automations found in the past 24 hours. When your\n                      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" execute, you'll see data appear here");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 2, 2);
            return morphs;
          },
          statements: [["block", "link-to", ["dashboard.subscriptions"], [], 0, null, ["loc", [null, [9, 22], [9, 84]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 8
              },
              "end": {
                "line": 14,
                "column": 8
              }
            },
            "moduleName": "vmpower/templates/components/ax-schedules-overview.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("iframe");
            dom.setAttribute(el1, "style", "background: #212121;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);");
            dom.setAttribute(el1, "width", "100%");
            dom.setAttribute(el1, "height", "100%");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element1, 'src');
            return morphs;
          },
          statements: [["attribute", "src", ["concat", ["https://charts.mongodb.com/charts-project-0-mrmlv/embed/charts?id=62a90c53-cc74-42f0-80ad-953617265775&maxDataAge=3600&theme=dark&autoRefresh=true&filter=%7B%22_id.owner%22%3A%20%22", ["get", "accountId", ["loc", [null, [13, 340], [13, 349]]]], "%22%7D&attribution=false"]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 15,
              "column": 6
            }
          },
          "moduleName": "vmpower/templates/components/ax-schedules-overview.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "recentWeekSavingsRecord.isPending", ["loc", [null, [5, 24], [5, 57]]]]], [], ["loc", [null, [5, 19], [5, 58]]]], ["subexpr", "is-equal", [["get", "recentWeekSavingsRecord.length", ["loc", [null, [5, 69], [5, 99]]]], 0], [], ["loc", [null, [5, 59], [5, 103]]]]], [], ["loc", [null, [5, 14], [5, 104]]]]], [], 0, 1, ["loc", [null, [5, 8], [14, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 20,
                    "column": 22
                  },
                  "end": {
                    "line": 20,
                    "column": 72
                  }
                },
                "moduleName": "vmpower/templates/components/ax-schedules-overview.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("VM schedules");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 8
                },
                "end": {
                  "line": 23,
                  "column": 8
                }
              },
              "moduleName": "vmpower/templates/components/ax-schedules-overview.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row placeholder-graph valign-wrapper");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col s12 center");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h5");
              dom.setAttribute(el3, "class", "valign");
              var el4 = dom.createElement("i");
              dom.setAttribute(el4, "class", "mdi-action-info");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("No recent automations found in the past 24 hours. When your\n                      ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" execute, you'll see data appear here");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 2, 2);
              return morphs;
            },
            statements: [["block", "link-to", ["dashboard.subscriptions"], [], 0, null, ["loc", [null, [20, 22], [20, 84]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 8
                },
                "end": {
                  "line": 25,
                  "column": 8
                }
              },
              "moduleName": "vmpower/templates/components/ax-schedules-overview.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("iframe");
              dom.setAttribute(el1, "style", "background: #212121;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);");
              dom.setAttribute(el1, "width", "100%");
              dom.setAttribute(el1, "height", "100%");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element0, 'src');
              return morphs;
            },
            statements: [["attribute", "src", ["concat", ["https://charts.mongodb.com/charts-project-0-mrmlv/embed/charts?id=64a506d9-9b77-4ef2-9d1c-15f0d9c8bfb3&maxDataAge=3600&theme=dark&autoRefresh=true&filter=%7B%22_id.owner%22%3A%20%22", ["get", "accountId", ["loc", [null, [24, 340], [24, 349]]]], "%22%7D&attribution=false"]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 6
              },
              "end": {
                "line": 26,
                "column": 6
              }
            },
            "moduleName": "vmpower/templates/components/ax-schedules-overview.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "recentDaySavingsRecord.isPending", ["loc", [null, [16, 24], [16, 56]]]]], [], ["loc", [null, [16, 19], [16, 57]]]], ["subexpr", "is-equal", [["get", "recentDaySavingsRecord.length", ["loc", [null, [16, 68], [16, 97]]]], 0], [], ["loc", [null, [16, 58], [16, 101]]]]], [], ["loc", [null, [16, 14], [16, 102]]]]], [], 0, 1, ["loc", [null, [16, 8], [25, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 6
            },
            "end": {
              "line": 26,
              "column": 6
            }
          },
          "moduleName": "vmpower/templates/components/ax-schedules-overview.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "is-equal", [["get", "chartGranularity", ["loc", [null, [15, 26], [15, 42]]]], "hourly"], [], ["loc", [null, [15, 16], [15, 52]]]]], [], 0, null, ["loc", [null, [15, 6], [26, 6]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 40,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-schedules-overview.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "blog-card");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "id", "task-card");
        dom.setAttribute(el2, "class", "collection");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "id", "ax-schedule-overview");
        dom.setAttribute(el3, "class", "vm-stats-card");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "class", "card-action-buttons");
        var el4 = dom.createTextNode("                           \n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "class", "tooltipped");
        dom.setAttribute(el4, "data-tooltip", "View by hour granularity");
        dom.setAttribute(el4, "data-position", "top");
        var el5 = dom.createElement("a");
        var el6 = dom.createTextNode("Hour");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "class", "tooltipped");
        dom.setAttribute(el4, "data-tooltip", "View by day granularity");
        dom.setAttribute(el4, "data-position", "top");
        var el5 = dom.createElement("a");
        var el6 = dom.createTextNode("Day");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3, "class", "collection-header pull-up-20");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "task-card-title");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "material-icons medium left");
        var el6 = dom.createTextNode("date_range");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("Automation stats");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "task-card-date");
        var el5 = dom.createTextNode("Schedule VM groups to turn on or off based on time and day of the week.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [1, 0]);
        var element5 = dom.childAt(element3, [3, 0]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
        morphs[1] = dom.createAttrMorph(element4, 'class');
        morphs[2] = dom.createElementMorph(element4);
        morphs[3] = dom.createAttrMorph(element5, 'class');
        morphs[4] = dom.createElementMorph(element5);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "is-equal", [["get", "chartGranularity", ["loc", [null, [4, 22], [4, 38]]]], "daily"], [], ["loc", [null, [4, 12], [4, 47]]]]], [], 0, 1, ["loc", [null, [4, 6], [26, 13]]]], ["attribute", "class", ["concat", ["btn waves-effect waves-light action-button-color ", ["subexpr", "if", [["subexpr", "is-equal", ["hourly", ["get", "chartGranularity", ["loc", [null, [29, 203], [29, 219]]]]], [], ["loc", [null, [29, 184], [29, 220]]]], "action-button-selected"], [], ["loc", [null, [29, 179], [29, 247]]]]]]], ["element", "action", ["openHourlyChart"], [], ["loc", [null, [29, 94], [29, 122]]]], ["attribute", "class", ["concat", ["btn waves-effect waves-light action-button-color ", ["subexpr", "if", [["subexpr", "is-equal", ["daily", ["get", "chartGranularity", ["loc", [null, [31, 200], [31, 216]]]]], [], ["loc", [null, [31, 182], [31, 217]]]], "action-button-selected"], [], ["loc", [null, [31, 177], [31, 244]]]]]]], ["element", "action", ["openDailyChart"], [], ["loc", [null, [31, 93], [31, 120]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});