define('vmpower/routes/dashboard/unused-volumes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        model: function model() {
            return this.store.findAll('volume');
        },
        setupController: function setupController(controller, model) {
            var user = this.get('session.data.authenticated.user');
            controller.set('volumes', model);
            controller.set('user', this.store.peekRecord('user', user.id));
            appInsights.trackEvent('route:dashboard:unused-volumes', { email: this.get('session.data.authenticated.user.email') });
        }
    });
});