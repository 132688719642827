define('vmpower/components/ax-azure-signin', ['exports', 'vmpower/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        directory: '',
        clientId: function () {
            return _environment.default.AzureActiveDirectoryClientId;
        }.property(),
        redirectUri: function () {
            return encodeURIComponent(_environment.default.AzureActiveDirectoryRedirectUri);
        }.property(),
        domainHint: function () {
            if (this.get('type') === 'msa') {
                return '&domain_hint=live.com';
            }
            return '';
        }.property(),
        aadDirectoryValid: function () {
            // don't think anyone has a domain shorter than 3 letters
            if (this.get('directory.length') <= 3) {
                return false;
            }
            return true;
        }.property('directory'),
        state: function () {
            return JSON.stringify({
                provider: 'azure',
                collectDw: this.get('collectDw') ? true : false,
                directory: this.get('directory')
            });
        }.property('directory', 'collectDw')
    });
});