define('vmpower/helpers/gt', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.greaterThan = greaterThan;
    function greaterThan(params /*, hash*/) {
        return params[0] > params[1];
    }

    exports.default = Ember.Helper.helper(greaterThan);
});