define('vmpower/routes/dashboard/idle-vms', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service('session'),
        setupController: function setupController(controller, vms) {
            var user = this.get('session.data.authenticated.user');
            appInsights.trackEvent('route:idle-vms', { email: this.get('session.data.authenticated.user.email') });
            return this.store.find('user', user.id).then(function (user) {
                controller.set('user', user);
                controller.set('vms', vms);
            });
        },
        model: function model() {
            return this.store.findAll('virtual-machine');
        }
    });
});