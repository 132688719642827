define('vmpower/services/schedule-calc', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        calculateSavings: function calculateSavings(schedule) {
            var totalCostPerHour = 0;
            var totalActiveDurationMilis = 0;
            var promises = [];

            schedule.get('vmGroup.vms').forEach(function (vm) {
                totalCostPerHour += vm.get('pricePerHour');
            });
            moment.weekdays().forEach(function (day) {
                if (!schedule.get('weeklySchedule.' + day.toLowerCase())) {
                    return;
                }
                promises.push(schedule.get('weeklySchedule.' + day.toLowerCase()).then(function (timespans) {
                    timespans.forEach(function (timespan) {
                        totalActiveDurationMilis += timespan.get('endMilis') - timespan.get('startMilis');
                    });
                }));
            });

            return Ember.RSVP.all(promises).then(function () {
                // the scheduler only allows for hours
                var totalActiveDurationHoursMontly = 4 * (totalActiveDurationMilis / 3600000).toFixed(0);

                if (schedule.get('normally') === 'on') {
                    // monthly savings
                    var savings = totalActiveDurationHoursMontly * totalCostPerHour;
                    return savings.toFixed(2);
                } else {
                    // monthly savings
                    var _savings = (30 * 24 - totalActiveDurationHoursMontly) * totalCostPerHour;
                    return _savings.toFixed(2);
                }
            });
        }
    });
});