define('vmpower/models/volume', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        provider: _emberData.default.attr('string'),
        storageType: _emberData.default.attr('string'),
        diskType: _emberData.default.attr('string'),
        volumeId: _emberData.default.attr('string'),
        iops: _emberData.default.attr('number'),
        attached: _emberData.default.attr('boolean'),
        unused: _emberData.default.attr('boolean'),
        unusedSince: _emberData.default.attr('date'),
        sizeGiB: _emberData.default.attr('number'),
        pricePerGiBHour: _emberData.default.attr('number'),
        attachedTo: _emberData.default.belongsTo('virtual-machine'),
        cloudSubscription: _emberData.default.belongsTo('cloud-subscription'),
        lastModified: _emberData.default.attr('date'),
        unusedVolumeDetection: _emberData.default.attr('boolean', {
            defaultValue: true
        }),
        diskTypeUpper: function () {
            return this.get('diskType').toUpperCase();
        }.property('diskType'),
        monthlyCost: function () {
            return parseFloat((this.get('pricePerGiBHour') * this.get('sizeGiB') * 720).toFixed(2));
        }.property('sizeGiB', 'pricePerGiBHour')
    });
});