define('vmpower/components/ax-vm-compact-list-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            goToLink: function goToLink(link, t) {
                window.open(link);
                return false;
            }
        }
    });
});