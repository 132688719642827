define("vmpower/templates/components/ax-resize-table", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 8
              },
              "end": {
                "line": 5,
                "column": 8
              }
            },
            "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h5");
            dom.setAttribute(el1, "class", "white-text");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "material-icons");
            var el3 = dom.createTextNode("lightbulb_outline");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("By optimizng these VMs you can potentially save ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("strong");
            var el3 = dom.createTextNode("$");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("/month.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 2]), 1, 1);
            return morphs;
          },
          statements: [["content", "totalPossibleSavings", ["loc", [null, [4, 139], [4, 163]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 8
              },
              "end": {
                "line": 7,
                "column": 8
              }
            },
            "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h5");
            dom.setAttribute(el1, "class", "white-text");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "material-icons");
            var el3 = dom.createTextNode("lightbulb_outline");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Increase your application performance for ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("strong");
            var el3 = dom.createTextNode("$");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("/month.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 2]), 1, 1);
            return morphs;
          },
          statements: [["content", "totalUpscaleCost", ["loc", [null, [6, 133], [6, 153]]]]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 32
                  },
                  "end": {
                    "line": 35,
                    "column": 96
                  }
                },
                "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "vm.instanceId", ["loc", [null, [35, 79], [35, 96]]]]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 44,
                    "column": 32
                  },
                  "end": {
                    "line": 46,
                    "column": 32
                  }
                },
                "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["content", "vm.instanceType", ["loc", [null, [45, 36], [45, 55]]]]],
              locals: [],
              templates: []
            };
          }();
          var child2 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 46,
                    "column": 32
                  },
                  "end": {
                    "line": 48,
                    "column": 32
                  }
                },
                "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    Enable VMPower to See\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          var child3 = function () {
            var child0 = function () {
              var child0 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 54,
                          "column": 87
                        },
                        "end": {
                          "line": 54,
                          "column": 205
                        }
                      },
                      "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["inline", "ax-resize-card", [], ["vm", ["subexpr", "@mut", [["get", "vm", ["loc", [null, [54, 152], [54, 154]]]]], [], []], "resizeType", ["subexpr", "@mut", [["get", "vm.recommendedResizeTypes.firstObject", ["loc", [null, [54, 166], [54, 203]]]]], [], []]], ["loc", [null, [54, 132], [54, 205]]]]],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 53,
                        "column": 40
                      },
                      "end": {
                        "line": 55,
                        "column": 40
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "material-icons");
                    var el2 = dom.createTextNode("trending_down");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                    return morphs;
                  },
                  statements: [["block", "link-to", ["dashboard.vm-rightsizing.vm", ["get", "vm", ["loc", [null, [54, 128], [54, 130]]]]], [], 0, null, ["loc", [null, [54, 87], [54, 217]]]]],
                  locals: [],
                  templates: [child0]
                };
              }();
              var child1 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 56,
                          "column": 85
                        },
                        "end": {
                          "line": 56,
                          "column": 203
                        }
                      },
                      "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["inline", "ax-resize-card", [], ["vm", ["subexpr", "@mut", [["get", "vm", ["loc", [null, [56, 150], [56, 152]]]]], [], []], "resizeType", ["subexpr", "@mut", [["get", "vm.recommendedResizeTypes.firstObject", ["loc", [null, [56, 164], [56, 201]]]]], [], []]], ["loc", [null, [56, 130], [56, 203]]]]],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 55,
                        "column": 40
                      },
                      "end": {
                        "line": 57,
                        "column": 40
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "material-icons");
                    var el2 = dom.createTextNode("trending_up");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                    return morphs;
                  },
                  statements: [["block", "link-to", ["dashboard.vm-rightsizing.vm", ["get", "vm", ["loc", [null, [56, 126], [56, 128]]]]], [], 0, null, ["loc", [null, [56, 85], [56, 215]]]]],
                  locals: [],
                  templates: [child0]
                };
              }();
              var child2 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 58,
                        "column": 40
                      },
                      "end": {
                        "line": 60,
                        "column": 40
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "material-icons red-text");
                    var el2 = dom.createTextNode("thumb_down");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element8);
                    return morphs;
                  },
                  statements: [["element", "action", ["thumbDownRec", ["get", "vm", ["loc", [null, [59, 71], [59, 73]]]]], [], ["loc", [null, [59, 47], [59, 75]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child3 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 60,
                        "column": 40
                      },
                      "end": {
                        "line": 62,
                        "column": 40
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "material-icons");
                    var el2 = dom.createTextNode("thumb_down");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element7 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element7);
                    return morphs;
                  },
                  statements: [["element", "action", ["thumbDownRec", ["get", "vm", ["loc", [null, [61, 71], [61, 73]]]]], [], ["loc", [null, [61, 47], [61, 75]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child4 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 63,
                        "column": 40
                      },
                      "end": {
                        "line": 65,
                        "column": 40
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "material-icons thumb-up-icon green-text");
                    var el2 = dom.createTextNode("thumb_up");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element6 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element6);
                    return morphs;
                  },
                  statements: [["element", "action", ["thumbUpRec", ["get", "vm", ["loc", [null, [64, 69], [64, 71]]]]], [], ["loc", [null, [64, 47], [64, 73]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child5 = function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 65,
                        "column": 40
                      },
                      "end": {
                        "line": 67,
                        "column": 40
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "material-icons thumb-up-icon");
                    var el2 = dom.createTextNode("thumb_up");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element5 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element5);
                    return morphs;
                  },
                  statements: [["element", "action", ["thumbUpRec", ["get", "vm", ["loc", [null, [66, 69], [66, 71]]]]], [], ["loc", [null, [66, 47], [66, 73]]]]],
                  locals: [],
                  templates: []
                };
              }();
              var child6 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 74,
                          "column": 60
                        },
                        "end": {
                          "line": 76,
                          "column": 60
                        }
                      },
                      "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                                                Oh no! How can we improve this recommendation?\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                var child1 = function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.5.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 76,
                          "column": 60
                        },
                        "end": {
                          "line": 78,
                          "column": 60
                        }
                      },
                      "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                                                Glad to see you agree! Anything else you'd like us to know?\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.5.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 68,
                        "column": 40
                      },
                      "end": {
                        "line": 90,
                        "column": 40
                      }
                    },
                    "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "resize");
                    var el2 = dom.createTextNode("\n                                                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "resize-card card-panel blog-beacon white-text");
                    dom.setAttribute(el2, "style", "top: 50px; right: 19px; height: 100%; width: 300px");
                    var el3 = dom.createTextNode("\n                                                    ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "card-content");
                    var el4 = dom.createTextNode("\n                                                        ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("i");
                    dom.setAttribute(el4, "class", "material-icons beacon-icon");
                    var el5 = dom.createTextNode("feedback");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("h5");
                    var el5 = dom.createElement("a");
                    dom.setAttribute(el5, "class", "white-text");
                    dom.setAttribute(el5, "target", "_blank");
                    var el6 = dom.createElement("strong");
                    var el7 = dom.createTextNode("Feedback");
                    dom.appendChild(el6, el7);
                    dom.appendChild(el5, el6);
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                                                        ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("h5");
                    var el5 = dom.createTextNode("\n");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("                                                                ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("\n                                                            ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createElement("span");
                    dom.setAttribute(el5, "class", "input-field feedback-button col s12");
                    var el6 = dom.createTextNode("\n                                                                ");
                    dom.appendChild(el5, el6);
                    var el6 = dom.createElement("a");
                    dom.setAttribute(el6, "class", "btn waves-effect waves-light action-button-color submit-button");
                    var el7 = dom.createTextNode("Tell us!");
                    dom.appendChild(el6, el7);
                    dom.appendChild(el5, el6);
                    var el6 = dom.createTextNode("\n                                                            ");
                    dom.appendChild(el5, el6);
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("\n                                                            ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createElement("span");
                    dom.setAttribute(el5, "class", "input-field close-button col s12");
                    var el6 = dom.createTextNode("\n                                                                ");
                    dom.appendChild(el5, el6);
                    var el6 = dom.createElement("a");
                    dom.setAttribute(el6, "class", "btn waves-effect waves-light red submit-button");
                    var el7 = dom.createTextNode("Close");
                    dom.appendChild(el6, el7);
                    dom.appendChild(el5, el6);
                    var el6 = dom.createTextNode("\n                                                            ");
                    dom.appendChild(el5, el6);
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("\n                                                        ");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                                                    ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1, 1, 1]);
                    var element1 = dom.childAt(element0, [2, 0]);
                    var element2 = dom.childAt(element0, [4]);
                    var element3 = dom.childAt(element2, [5, 1]);
                    var element4 = dom.childAt(element2, [7, 1]);
                    var morphs = new Array(5);
                    morphs[0] = dom.createAttrMorph(element1, 'href');
                    morphs[1] = dom.createMorphAt(element2, 1, 1);
                    morphs[2] = dom.createMorphAt(element2, 3, 3);
                    morphs[3] = dom.createElementMorph(element3);
                    morphs[4] = dom.createElementMorph(element4);
                    return morphs;
                  },
                  statements: [["attribute", "href", ["concat", [["get", "posts.firstObject.link", ["loc", [null, [72, 140], [72, 162]]]]]]], ["block", "if", [["subexpr", "is-equal", [["get", "vm.recFeedback", ["loc", [null, [74, 76], [74, 90]]]], "thumbdown"], [], ["loc", [null, [74, 66], [74, 103]]]]], [], 0, 1, ["loc", [null, [74, 60], [78, 67]]]], ["inline", "input", [], ["class", "white black-text", "type", "text", "value", ["subexpr", "@mut", [["get", "vm.recFeedbackText", ["loc", [null, [79, 115], [79, 133]]]]], [], []]], ["loc", [null, [79, 64], [79, 135]]]], ["element", "action", ["submitFeedback", ["get", "vm", ["loc", [null, [81, 93], [81, 95]]]]], [], ["loc", [null, [81, 67], [81, 97]]]], ["element", "action", ["closeFeedback", ["get", "vm", ["loc", [null, [84, 92], [84, 94]]]]], [], ["loc", [null, [84, 67], [84, 96]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              }();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.5.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 52,
                      "column": 36
                    },
                    "end": {
                      "line": 91,
                      "column": 36
                    }
                  },
                  "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                  morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "greater-than", [["get", "vm.scaleDownDimensions.length", ["loc", [null, [53, 60], [53, 89]]]], 0], [], ["loc", [null, [53, 46], [53, 92]]]]], [], 0, 1, ["loc", [null, [53, 40], [57, 47]]]], ["block", "if", [["subexpr", "is-equal", [["get", "vm.recFeedback", ["loc", [null, [58, 56], [58, 70]]]], "thumbdown"], [], ["loc", [null, [58, 46], [58, 83]]]]], [], 2, 3, ["loc", [null, [58, 40], [62, 47]]]], ["block", "if", [["subexpr", "is-equal", [["get", "vm.recFeedback", ["loc", [null, [63, 56], [63, 70]]]], "thumbup"], [], ["loc", [null, [63, 46], [63, 81]]]]], [], 4, 5, ["loc", [null, [63, 40], [67, 47]]]], ["block", "if", [["get", "vm.showDialog", ["loc", [null, [68, 46], [68, 59]]]]], [], 6, null, ["loc", [null, [68, 40], [90, 47]]]]],
                locals: [],
                templates: [child0, child1, child2, child3, child4, child5, child6]
              };
            }();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 32
                  },
                  "end": {
                    "line": 92,
                    "column": 32
                  }
                },
                "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "and", [["get", "vm.recommendedResizeTypes.firstObject", ["loc", [null, [52, 47], [52, 84]]]], ["subexpr", "or", [["subexpr", "and", [["get", "vm.scaleDownDimensions.length", ["loc", [null, [52, 94], [52, 123]]]], ["subexpr", "gt", [["get", "vm.resizeMonthlySavings", ["loc", [null, [52, 128], [52, 151]]]], 0], [], ["loc", [null, [52, 124], [52, 154]]]]], [], ["loc", [null, [52, 89], [52, 155]]]], ["subexpr", "and", [["get", "scaleUpDimensions.length", ["loc", [null, [52, 161], [52, 185]]]], ["subexpr", "lt", [["get", "vm.resizeMonthlySavings", ["loc", [null, [52, 190], [52, 213]]]], 0], [], ["loc", [null, [52, 186], [52, 216]]]]], [], ["loc", [null, [52, 156], [52, 217]]]]], [], ["loc", [null, [52, 85], [52, 218]]]]], [], ["loc", [null, [52, 42], [52, 219]]]]], [], 0, null, ["loc", [null, [52, 36], [91, 43]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          var child4 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 95,
                    "column": 32
                  },
                  "end": {
                    "line": 97,
                    "column": 32
                  }
                },
                "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("strong");
                dom.setAttribute(el1, "class", "green-text");
                var el2 = dom.createTextNode("Save $");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("/mo");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["content", "vm.resizeMonthlySavings", ["loc", [null, [96, 69], [96, 96]]]]],
              locals: [],
              templates: []
            };
          }();
          var child5 = function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.5.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 97,
                    "column": 32
                  },
                  "end": {
                    "line": 99,
                    "column": 32
                  }
                },
                "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("strong");
                var el2 = dom.createTextNode("Scale up for $");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("/mo");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["content", "vm.resizeMonthlyCost", ["loc", [null, [98, 58], [98, 82]]]]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 20
                },
                "end": {
                  "line": 102,
                  "column": 20
                }
              },
              "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" vCPUs, ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" GiB, ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" IOps\n                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "recommendation");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "style", "position:relative;");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var element10 = dom.childAt(element9, [9]);
              var element11 = dom.childAt(element9, [11]);
              var morphs = new Array(12);
              morphs[0] = dom.createAttrMorph(element9, 'id');
              morphs[1] = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element9, [5]), 1, 1);
              morphs[4] = dom.createMorphAt(dom.childAt(element9, [7]), 1, 1);
              morphs[5] = dom.createMorphAt(element10, 1, 1);
              morphs[6] = dom.createMorphAt(element10, 3, 3);
              morphs[7] = dom.createMorphAt(element10, 5, 5);
              morphs[8] = dom.createAttrMorph(element11, 'data-vm');
              morphs[9] = dom.createMorphAt(element11, 1, 1);
              morphs[10] = dom.createMorphAt(dom.childAt(element9, [13]), 1, 1);
              morphs[11] = dom.createMorphAt(dom.childAt(element9, [15]), 1, 1);
              return morphs;
            },
            statements: [["attribute", "id", ["concat", [["get", "vm.id", ["loc", [null, [27, 34], [27, 39]]]]]]], ["inline", "md-check", [], ["name", "", "checked", ["subexpr", "@mut", [["get", "vm.resizeSelected", ["loc", [null, [29, 59], [29, 76]]]]], [], []]], ["loc", [null, [29, 32], [29, 78]]]], ["content", "vm.displayName", ["loc", [null, [32, 32], [32, 50]]]], ["block", "link-to", ["dashboard.virtual-machines.vm", ["get", "vm", ["loc", [null, [35, 75], [35, 77]]]]], [], 0, null, ["loc", [null, [35, 32], [35, 108]]]], ["content", "vm.provider", ["loc", [null, [38, 32], [38, 47]]]], ["content", "vm.cpus", ["loc", [null, [41, 32], [41, 43]]]], ["content", "vm.memoryGiB", ["loc", [null, [41, 51], [41, 67]]]], ["content", "vm.iops", ["loc", [null, [41, 73], [41, 84]]]], ["attribute", "data-vm", ["concat", [["get", "vm.id", ["loc", [null, [43, 66], [43, 71]]]]]]], ["block", "if", [["get", "vm.planEnabled", ["loc", [null, [44, 38], [44, 52]]]]], [], 1, 2, ["loc", [null, [44, 32], [48, 39]]]], ["block", "if", [["get", "vm.planEnabled", ["loc", [null, [51, 38], [51, 52]]]]], [], 3, null, ["loc", [null, [51, 32], [92, 39]]]], ["block", "if", [["subexpr", "greater-than", [["get", "vm.resizeMonthlySavings", ["loc", [null, [95, 52], [95, 75]]]], 0], [], ["loc", [null, [95, 38], [95, 78]]]]], [], 4, 5, ["loc", [null, [95, 32], [99, 39]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5]
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 16
              },
              "end": {
                "line": 103,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "and", [["get", "vm.recommendedResizeTypes.firstObject", ["loc", [null, [26, 31], [26, 68]]]], ["subexpr", "or", [["subexpr", "and", [["get", "vm.scaleDownDimensions.length", ["loc", [null, [26, 78], [26, 107]]]], ["subexpr", "gt", [["get", "vm.resizeMonthlySavings", ["loc", [null, [26, 112], [26, 135]]]], 0], [], ["loc", [null, [26, 108], [26, 138]]]]], [], ["loc", [null, [26, 73], [26, 139]]]], ["subexpr", "and", [["get", "scaleUpDimensions.length", ["loc", [null, [26, 145], [26, 169]]]], ["subexpr", "lt", [["get", "vm.resizeMonthlySavings", ["loc", [null, [26, 174], [26, 197]]]], 0], [], ["loc", [null, [26, 170], [26, 200]]]]], [], ["loc", [null, [26, 140], [26, 201]]]]], [], ["loc", [null, [26, 69], [26, 202]]]]], [], ["loc", [null, [26, 26], [26, 203]]]]], [], 0, null, ["loc", [null, [26, 20], [102, 27]]]]],
          locals: ["vm"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["multiple-nodes"]
          },
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 108,
              "column": 0
            }
          },
          "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row left");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "material-icons left");
          var el4 = dom.createTextNode("photo_size_select_small");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("Open VM Resize Editor");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row table-wrapper");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("table");
          dom.setAttribute(el2, "id", "resize-table");
          dom.setAttribute(el2, "class", "table");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("thead");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tr");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "select");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "id");
          var el6 = dom.createTextNode("Name");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "id");
          var el6 = dom.createTextNode("Instance ID");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "id");
          var el6 = dom.createTextNode("Provider");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "name");
          var el6 = dom.createTextNode("Specs");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "name");
          var el6 = dom.createTextNode("Current Size");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "name");
          var el6 = dom.createTextNode("Recommended Size");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "data-field", "name");
          var el6 = dom.createTextNode("Monthly Savings");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tbody");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1]);
          var element13 = dom.childAt(element12, [3]);
          var element14 = dom.childAt(fragment, [3]);
          var element15 = dom.childAt(element14, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element12, 1, 1);
          morphs[1] = dom.createAttrMorph(element13, 'class');
          morphs[2] = dom.createElementMorph(element13);
          morphs[3] = dom.createMorphAt(dom.childAt(element15, [1, 1, 1]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element15, [3]), 1, 1);
          morphs[5] = dom.createMorphAt(element14, 3, 3);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "greater-than", [["get", "totalPossibleSavings", ["loc", [null, [3, 28], [3, 48]]]], 0], [], ["loc", [null, [3, 14], [3, 51]]]]], [], 0, 1, ["loc", [null, [3, 8], [7, 15]]]], ["attribute", "class", ["concat", ["btn action-button-color ", ["subexpr", "if", [["get", "enableResizeButton", ["loc", [null, [8, 76], [8, 94]]]], "", "disabled"], [], ["loc", [null, [8, 71], [8, 110]]]]]]], ["element", "action", ["showResizeModal"], [], ["loc", [null, [8, 11], [8, 39]]]], ["inline", "md-check", [], ["name", "Select", "checked", ["subexpr", "@mut", [["get", "allSelected", ["loc", [null, [14, 77], [14, 88]]]]], [], []]], ["loc", [null, [14, 44], [14, 90]]]], ["block", "each", [["get", "vms", ["loc", [null, [25, 24], [25, 27]]]]], ["key", "@index"], 2, null, ["loc", [null, [25, 16], [103, 25]]]], ["inline", "ax-error", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [106, 26], [106, 32]]]]], [], []]], ["loc", [null, [106, 8], [106, 34]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 115,
                "column": 99
              },
              "end": {
                "line": 115,
                "column": 143
              }
            },
            "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("VMs");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 108,
              "column": 0
            },
            "end": {
              "line": 118,
              "column": 0
            }
          },
          "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col s12 l12 m12 center placeholder-title");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row center icons-title-wrapper");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "material-icons large");
          var el5 = dom.createTextNode("photo_size_select_small");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row center");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          var el4 = dom.createTextNode("VM Rightsizing");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("VMPower will periodically provide recommendations on the best-fitting size for your ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" given their application load. Those recommendations will appear here.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3, 3]), 1, 1);
          return morphs;
        },
        statements: [["block", "link-to", ["dashboard.virtual-machines"], [], 0, null, ["loc", [null, [115, 99], [115, 155]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 119,
            "column": 0
          }
        },
        "moduleName": "vmpower/templates/components/ax-resize-table.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "hasResizeRecs", ["loc", [null, [1, 6], [1, 19]]]]], [], 0, 1, ["loc", [null, [1, 0], [118, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});