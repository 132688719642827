define('vmpower/models/local-setting', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        collapse: _emberData.default.attr('boolean', {
            defaultValue: false
        }),
        pinnedAccount: _emberData.default.attr('string', {
            defaultValue: null
        }),
        askForTrialActivation: _emberData.default.attr('boolean', {
            defaultValue: true
        }),
        showCompactVms: _emberData.default.attr('boolean', {
            defaultValue: false
        })
    });
});