define('vmpower/components/ax-bar-chart', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        colorIndex: 0,
        // vmpower theme colors
        colors: ['#ff9800', '#03a9f4', '#f44336'],
        barChartData: function () {
            var _this = this;

            var chartData = {
                // this chart just shows one dataset at a time
                datasets: [],
                labels: []
            };
            if (!this.get('barChartSets.length')) {
                return chartData;
            }
            this.get('barChartSets').forEach(function (set, i) {
                chartData.datasets.push({
                    label: _this.get('labels')[i],
                    hover: {
                        mode: 'label'
                    },
                    borderWidth: 1,
                    backgroundColor: _this.get('colors')[_this.get('colorIndex')],
                    data: []
                });
                set = _this._sortSet(set);
                set.forEach(function (value, z) {
                    if (!chartData.labels[z]) {
                        chartData.labels.push(moment(value.get('timestamp')).format('dddd, MMMM Do YYYY'));
                    }
                    if (i === 0) {
                        chartData.datasets[i].data.push(parseFloat(value.get(_this.get('pointName')).toFixed(2)));
                    } else {
                        chartData.datasets[i].data.push(parseFloat(value.get(_this.get('pointName')).toFixed(2)));
                    }
                });
                _this.set('colorIndex', _this.get('colorIndex') + 1);
            });
            return chartData;
        }.property('barChartSets.length'),
        _sortSet: function _sortSet(set) {
            return stable(set, function (a, b) {
                return new Date(a.get('timestamp')) > new Date(b.get('timestamp'));
            });
        },
        chartOptions: function () {
            var opts = {
                hover: {
                    mode: 'label'
                },
                responsive: true,
                tooltips: {
                    mode: 'label'
                },
                scaleFontSize: 16,
                stacked: true,
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true,
                        labelString: 'Dollars USD'
                    }]
                },
                // Boolean - Show a backdrop to the scale label
                scaleShowLabelBackdrop: true,

                // Boolean - Whether the scale should begin at zero
                scaleBeginAtZero: true,

                // Number - The backdrop padding above & below the label in pixels
                scaleBackdropPaddingY: 2,

                // Number - The backdrop padding to the side of the label in pixels
                scaleBackdropPaddingX: 2,

                // Boolean - Show line for each value in the scale
                scaleShowLine: true,

                // Boolean - Stroke a line around each segment in the chart
                segmentShowStroke: true,

                // String - The colour of the stroke on each segement.
                segmentStrokeColor: '#fff',

                // Number - The width of the stroke value in pixels
                segmentStrokeWidth: 2,

                datasetStroke: true,

                datasetFill: false,

                // Number - Pixel width of dataset stroke
                datasetStrokeWidth: 5,

                // Number - Amount of animation steps
                animationSteps: 0,

                // String - Animation easing effect.
                animationEasing: 'easeOutBounce',

                // Boolean - Whether to animate the rotation of the chart
                animateRotate: false,

                // Boolean - Whether to animate scaling the chart from the centre
                animateScale: false,
                legendTemplate: '<div class="collection timechart-legend"><% for (var i=0; i<datasets.length; i++){%><%if(datasets[i].label.htmlUrl){%><a target="_blank" href="<%=datasets[i].label.htmlUrl%>" class="collection-item legend-selecter"><%} else{%><a class="collection-item legend-selecter"><%}%><span style="background-color:<%=datasets[i].strokeColor%>" class="circle"></span> <%if(datasets[i].label.name){%><%=datasets[i].label.name%><%}%></a><%}%></div>'
            };
            return opts;
        }.property()
    });
});