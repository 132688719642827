define("vmpower/templates/components/ax-vmgroup-card", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 16
            },
            "end": {
              "line": 10,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-vmgroup-card.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "collection-item dismissable");
          dom.setAttribute(el1, "style", "touch-action: pan-y; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "ax-vm-list-item", [], ["vm", ["subexpr", "@mut", [["get", "vm", ["loc", [null, [8, 41], [8, 43]]]]], [], []], "oneClickModal", ["subexpr", "action", ["openOneClickModal"], [], ["loc", [null, [8, 58], [8, 86]]]], "removeFromGroup", ["subexpr", "action", ["removeVmFromGroup"], [], ["loc", [null, [8, 103], [8, 131]]]], "moveVmUp", ["subexpr", "action", ["moveVmUp"], [], ["loc", [null, [8, 141], [8, 160]]]], "moveVmDown", ["subexpr", "action", ["moveVmDown"], [], ["loc", [null, [8, 172], [8, 193]]]], "readonly", ["subexpr", "@mut", [["get", "readonly", ["loc", [null, [8, 203], [8, 211]]]]], [], []]], ["loc", [null, [8, 20], [8, 213]]]]],
        locals: ["vm"],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 16
            },
            "end": {
              "line": 14,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-vmgroup-card.hbs"
        },
        isEmpty: true,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 16
                },
                "end": {
                  "line": 21,
                  "column": 16
                }
              },
              "moduleName": "vmpower/templates/components/ax-vmgroup-card.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "tooltipped");
              dom.setAttribute(el1, "data-position", "top");
              dom.setAttribute(el1, "data-tooltip", "Powering Off VM Group...");
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "class", "btn-floating waves-effect waves-light glowing-off");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "material-icons left activator");
              var el4 = dom.createTextNode("power_settings_new");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 16
                },
                "end": {
                  "line": 26,
                  "column": 16
                }
              },
              "moduleName": "vmpower/templates/components/ax-vmgroup-card.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "tooltipped");
              dom.setAttribute(el1, "data-position", "top");
              dom.setAttribute(el1, "data-tooltip", "Power On VM Group");
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "class", "btn-floating waves-effect waves-light orange lighten-2");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "material-icons left activator");
              var el4 = dom.createTextNode("power_settings_new");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element1);
              return morphs;
            },
            statements: [["element", "action", ["powerOffGroup"], [], ["loc", [null, [23, 24], [23, 50]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 16
              },
              "end": {
                "line": 27,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-vmgroup-card.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "poweringOff", ["loc", [null, [16, 22], [16, 33]]]]], [], 0, 1, ["loc", [null, [16, 16], [26, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 16
                },
                "end": {
                  "line": 33,
                  "column": 16
                }
              },
              "moduleName": "vmpower/templates/components/ax-vmgroup-card.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "tooltipped");
              dom.setAttribute(el1, "data-position", "top");
              dom.setAttribute(el1, "data-tooltip", "Powering On VM Group...");
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "class", "btn-floating waves-effect waves-light glowing-on");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "material-icons left activator");
              var el4 = dom.createTextNode("power_settings_new");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.5.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 16
                },
                "end": {
                  "line": 38,
                  "column": 16
                }
              },
              "moduleName": "vmpower/templates/components/ax-vmgroup-card.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "tooltipped");
              dom.setAttribute(el1, "data-position", "top");
              dom.setAttribute(el1, "data-tooltip", "Power off VM Group");
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "class", "btn-floating waves-effect waves-light grey");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "material-icons left activator");
              var el4 = dom.createTextNode("power_settings_new");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 0]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [["element", "action", ["powerOnGroup"], [], ["loc", [null, [35, 24], [35, 49]]]]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.5.1",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 16
              },
              "end": {
                "line": 39,
                "column": 16
              }
            },
            "moduleName": "vmpower/templates/components/ax-vmgroup-card.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "poweringOn", ["loc", [null, [28, 22], [28, 32]]]]], [], 0, 1, ["loc", [null, [28, 16], [38, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 16
            },
            "end": {
              "line": 49,
              "column": 16
            }
          },
          "moduleName": "vmpower/templates/components/ax-vmgroup-card.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "tooltipped");
          dom.setAttribute(el1, "data-position", "top");
          dom.setAttribute(el1, "data-tooltip", "Delete VM Group");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "btn-floating waves-effect waves-light red");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "material-icons left activator");
          var el4 = dom.createTextNode("delete");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "tooltipped");
          dom.setAttribute(el1, "data-position", "top");
          dom.setAttribute(el1, "data-tooltip", "Edit or Create VM Schedule");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "btn-floating waves-effect waves-light action-button-color");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "material-icons left activator");
          var el4 = dom.createTextNode("date_range");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [2, 0]);
          var element3 = dom.childAt(fragment, [4, 0]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createElementMorph(element3);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "groupState", ["loc", [null, [15, 22], [15, 32]]]]], [], 0, 1, ["loc", [null, [15, 16], [39, 23]]]], ["element", "action", ["deleteGroup", ["get", "group", ["loc", [null, [41, 47], [41, 52]]]]], [], ["loc", [null, [41, 24], [41, 54]]]], ["element", "action", ["lookupOrCreateSchedule", ["get", "group", ["loc", [null, [45, 58], [45, 63]]]]], [], ["loc", [null, [45, 24], [45, 65]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 6
          }
        },
        "moduleName": "vmpower/templates/components/ax-vmgroup-card.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col s12 m4 l4");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "blog-card");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "id", "task-card");
        dom.setAttribute(el3, "class", "collection");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "vm-card");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "card-action-buttons");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4, "class", "collection-header pull-up-20");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h4");
        dom.setAttribute(el5, "class", "task-card-title");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5, "class", "white-text");
        var el6 = dom.createTextNode("Estimated Monthly Cost: ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("strong");
        var el7 = dom.createTextNode("$");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0, 1, 1]);
        var element5 = dom.childAt(element4, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
        morphs[2] = dom.createAttrMorph(element5, 'style');
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element5, [3, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "each", [["get", "group.vms", ["loc", [null, [5, 24], [5, 33]]]]], ["key", "@index"], 0, null, ["loc", [null, [5, 16], [10, 25]]]], ["block", "if", [["get", "readonly", ["loc", [null, [13, 22], [13, 30]]]]], [], 1, 2, ["loc", [null, [13, 16], [49, 23]]]], ["attribute", "style", ["concat", [["subexpr", "if", [["get", "account.primaryColor", ["loc", [null, [52, 28], [52, 48]]]], ["subexpr", "concat", ["background-color: ", ["get", "account.primaryColor", ["loc", [null, [52, 78], [52, 98]]]]], [], ["loc", [null, [52, 49], [52, 99]]]]], [], ["loc", [null, [52, 23], [52, 101]]]]]]], ["content", "group.name", ["loc", [null, [53, 44], [53, 58]]]], ["content", "group.monthlyCost", ["loc", [null, [54, 71], [54, 92]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});